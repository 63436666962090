<div class="d-flex justify-content-between align-items-center mb-2">
	<h2 class="mb-0">權限管理</h2></div>
<p class="text-muted">您可於此為帳號新增管理成員及管理其操作權限。
<hr>
<section style="">
	<div class="d-flex justify-content-between align-items-center my-4">
		<h3 class="mb-0">管理成員 <small class="text-muted" style="">{{users.length}}</small></h3>
		<button (click)="openInvitationModal()" type="button" class="btn btn-primary">新增管理成員</button>
	</div>
	<div class="position-relative overflow-auto">
		<table class="table table-striped table-hover table-layout-fixed">
			<tbody>
			<tr *ngFor="let user of users" >
				<td >
					<div class="d-flex align-items-center">
						<div title="" class="avatar mr-2 rounded-circle avatar-xs">
							<div class="w-100 h-100" [ngStyle]="{'background': 'url(' + user.photoUrl + ') center center / cover'}">
							</div>
						</div>
						<div>{{user?.displayName}}</div>
					</div>
				</td>
				<td class="text-nowrap">
					{{user?.permission}}
				</td>
				<td class="text-nowrap">
					<button (click)="openEditModal(user)" *ngIf="currentUser?.uid !== user?.id" type="button" class="btn btn-outline-secondary">變更</button>
				</td>
			</tr>
			</tbody>
		</table>
	</div>
	<hr>
	<!--  <nav aria-label="Page navigation" class="my-5">-->
	<!--    <ul class="pagination pagination-rg justify-content-center">-->
	<!--      <li class="page-item disabled"><a disabled="disabled" aria-label="Previous" href="#" class="page-link"><span aria-hidden="true"><i class="fas fa-chevron-left"></i></span></a></li>-->
	<!--      <li class="page-item active"><a href="#" class="page-link">1</a></li>-->
	<!--      <li class="page-item disabled"><a disabled="disabled" aria-label="Next" href="#" class="page-link"><span aria-hidden="true"><i class="fas fa-chevron-right"></i></span></a></li>-->
	<!--    </ul>-->
	<!--  </nav>-->
</section>
<div>
	<!---->
	<!---->
</div>
<div>
	<!---->
</div>



<div *ngIf="Modal" class="modal bg-backdrop d-block ">
	<div tabindex="-1" class="modal-dialog modal-dialog-centered modal-rg">
		<div class="modal-content h-100">
			<div class="modal-header flex-shrink-0">
				<h5 class="mb-0">權限管理</h5>
				<button (click)="Modal = false" class="close">×</button>
			</div>
			<div class="modal-body mh-100 overflow-hidden">
				<div>
					<table class="table table-striped table-hover table-layout-fixed">
						<colgroup>
							<col>
							<col span="4" width="19%">
						</colgroup>
						<thead class="thead-light">
						<tr>
							<th class="text-center small align-top">
                <span ><!---->
                  權限種類
                </span>
							</th>
							<th class="text-center small align-top">
                <span ><!---->
                  管理員
                </span>
							</th>
							<th class="text-center small align-top">
                <span >
                  行銷專員
                </span></th>
							<th class="text-center small align-top">
                <span >
                  客服專員
                </span>
							</th>
						</tr>
						</thead>
						<tbody>
						<tr >
							<td class="text-center align-middle small"><span><span>訊息/見面禮/問卷/歡迎詞</span></span>
							</td>
							<td class="text-center align-middle"><span><fa-icon [icon]="faCheck" class="text-primary"></fa-icon></span></td>
							<td class="text-center align-middle"><span><fa-icon [icon]="faCheck" class="text-primary"></fa-icon></span></td>
							<td class="text-center align-middle"><span></span></td>
						</tr>
						<tr >
							<td class="text-center align-middle small"><span><span>流量分析</span></span>
							</td>
							<td class="text-center align-middle"><span><fa-icon [icon]="faCheck" class="text-primary"></fa-icon></span></td>
							<td class="text-center align-middle"><span><fa-icon [icon]="faCheck" class="text-primary"></fa-icon></span></td>
							<td class="text-center align-middle"><span><!----></span></td>
						</tr>
						<tr >
							<td class="text-center align-middle small"><span><span>1對1聊天</span></span>
							</td>
							<td class="text-center align-middle"><span><fa-icon [icon]="faCheck" class="text-primary"></fa-icon></span></td>
							<td class="text-center align-middle"><span><fa-icon [icon]="faCheck" class="text-primary"></fa-icon></span></td>
							<td class="text-center align-middle"><span><fa-icon [icon]="faCheck" class="text-primary"></fa-icon></span></td>
						</tr>
						<tr >
							<td class="text-center align-middle small"><span><span>變更帳號設定</span></span>
							</td>
							<td class="text-center align-middle"><span><fa-icon [icon]="faCheck" class="text-primary"></fa-icon></span></td>
							<td class="text-center align-middle"><span><!----></span></td>
							<td class="text-center align-middle"><span><!----></span></td>
						</tr>
						<tr >
							<td class="text-center align-middle small"><span><span>管理帳號成員</span></span>
							</td>
							<td class="text-center align-middle"><span><fa-icon [icon]="faCheck" class="text-primary"></fa-icon></span></td>
							<td class="text-center align-middle"><span><!----></span></td>
							<td class="text-center align-middle"><span><!----></span></td>
						</tr>
						<!---->
						</tbody>
					</table>
				</div>
				<hr>
				<p *ngIf="invitationModal">您可透過發行網址並加以分享的方式，賦予權限給新增的管理成員。</p>
				<p *ngIf="editModal">您可變更此管理成員的權限。</p>
				<div class="form-group">
					<label for="formPermissonType">權限種類</label>
					<div class="input-group">
						<select [(ngModel)]="permissionType" id="formPermissonType" class="custom-select">
							<option value="ADMIN">管理員</option>
							<option value="MARKET">行銷專員</option>
							<option value="SUPPORT">客服專員</option>
						</select>
						<div *ngIf="editModal" class="input-group-append">
							<button (click)="changePermission()" type="button" class="w-fix-120 ml-2 btn btn-outline-secondary">儲存</button>
						</div>
					</div>
				</div>
				<div *ngIf="invitationModal" class="form-row">
					<div class="form-group col">
						<div class="input-group">
							<input [(ngModel)]="invitationURl" placeholder="已發行的網址24小時內有效，且只可使用一次。" type="text" autocomplete="off" value="" readonly="readonly" class="form-control">
							<div class="input-group-append">
								<button (click)="generateInvitation()" type="button" class="w-min-120 btn btn-primary">發行網址</button>
							</div>
						</div>
					</div>
				</div> <a *ngIf="editModal" (click)="deleteManager()" href="javascript:void(0)" class="text-danger border-danger border-bottom" >由帳號中刪除此管理成員</a></div>
			<div class="d-flex flex-row flex-shrink-0 modal-footer flex-row-reverse">
				<!---->
				<button (click)="closeModal()" type="button" class="btn btn-secondary">關閉</button>
			</div>
		</div>
	</div>
</div>
