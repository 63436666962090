<div class="d-flex align-items-stretch">
  <div id="header-logo" class="d-flex align-items-center justify-content-center flex-shrink-0 flex-grow-0">
    <a routerLink="/" style="color: #1c1e21" >
      <img src="assets/CastTalk_Logo.png" >
    </a>
  </div>
    <div id="header-user" class="d-flex align-items-center px-2" *ngIf="ss.currentShop">
      <div class="d-flex align-items-center">
        <div ngbDropdown  class="dropdown btn-group">
          <div ngbDropdownToggle class="d-flex align-items-center cursor-pointer">
            <div title class="avatar mr-2 rounded-circle avatar-xxs ">
              <div class="w-100 h-100" [ngStyle]="{'background': 'url(' + ss.currentShop.photoUrl + ') center center / cover'}">
              </div>
            </div>
            <div class="lead" >{{ss.currentShop.displayName}}
            </div>
          </div>
          <div ngbDropdownMenu class="dropdown-menu  dropdown-menu-right" >
            <div class="dropdown-item disabled"><h6>帳號</h6></div>
            <div *ngFor="let shop of ss.shops" class="dropdown-item">
              <a (click)="changeShop(shop)" class="d-flex align-items-center router-link-exact-active active">
                <div class="badged-avatar d-inline-block position-relative overflow-hidden">
                  <div title="" class="avatar mr-2 rounded-circle avatar-xs">
                    <div class="w-100 h-100" [ngStyle]="{'background': 'url(' + shop.photoUrl + ') center center / cover'}"></div>
                  </div>
                </div>
                <div class="break-name text-body">
                  <!--                <i class="las la-shield-star fa-sm mr-1 text-info"></i>-->
                  {{shop.displayName}}
                </div>
              </a>
            </div>
            <div class="dropdown-divider"></div>
            <a routerLink="/Accounts" class="dropdown-item">所有帳號</a>
          </div>
        </div>
        <!--      <div class="lead"><i class="las la-shield-star fa-sm mr-1 text-info"></i>{{shop.displayName || ''}}</div>-->
      </div>

      <div class="text-info ml-3 d-flex align-items-center">
        <span class="mr-3 cursor-pointer">{{ss.currentShop.lineAt}}</span>

        <span class="mr-3 cursor-pointer">
          <fa-icon [icon]="faUserFriends"></fa-icon>
          {{ss.currentShop.follower}}
        </span>
        <span class="mr-3 cursor-pointer">
          <fa-icon  [icon]="faBan"></fa-icon>
          {{ss.currentShop.blocks}}
        </span>

        <span *ngIf="!ss.currentShop.enabled" class="las mr-3 cursor-pointer text-danger" ngbPopover="line@尚未通過認證"
              [openDelay]="100" [closeDelay]="2000" triggers="mouseenter:mouseleave">
          <mat-icon aria-hidden="false" aria-label="Example home icon">error_outline</mat-icon>
        </span>
      </div>
    </div>
</div>
<div *ngIf="auth.user as user" class="d-flex align-items-stretch">
  <div id="header-menu" class="d-flex align-items-center pl-3">
    <ul *ngIf="user.admin" class="nav nav-bordered">
      <li class="nav-item position-relative">
        <a routerLink="/Admin" routerLinkActive="router-link-exact-active active-ignore active" class="nav-link">管理後台</a>
      </li>
    </ul>

    <div ngbDropdown  class="dropdown btn-group">
    <span ngbDropdownToggle class="mx-1 text-muted">
      <fa-icon size="2x" [icon]="faUser"></fa-icon>
    </span>
      <div ngbDropdownMenu class="dropdown-menu  dropdown-menu-right" >
        <h6 class="dropdown-header">{{user.displayName}}</h6>
        <a href="https://account.line.biz/profile" target="_blank" class="dropdown-item">用戶基本檔案</a>
        <div class="dropdown-divider"></div> <a  (click)="logout()" class="dropdown-item">登出</a>
      </div>
    </div>
    <div ngbDropdown class="dropdown btn-group">
    <span ngbDropdownToggle class="mx-1 text-muted">
      <fa-icon size="2x" [icon]="faQuestionCircle"></fa-icon>
    </span>
      <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right">
        <a class="dropdown-item">Help</a>
        <a class="dropdown-item">使用教學</a>
      </div>
    </div>
  </div>
</div>

