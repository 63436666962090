import { Injectable } from '@angular/core';
import {NotifyService} from './notify.service';
import {AngularFireStorage} from '@angular/fire/storage';
import {AngularEditorConfig} from '@kolkov/angular-editor/lib/config';

@Injectable({
  providedIn: 'root'
})
export class FroalaService {
  public options = {
    placeholder: 'Edit Me',
    charCounterCount: false,
    tableInsertHelper: false,
    quickInsertEnabled: false,
    events: {
    },
    // videoUpload: false,
    // videoInsertButtons: ['videoBack', '|', 'videoByURL', 'videoEmbed']

  };
  public config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '300px',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: '',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      {class: 'arial', name: 'Arial'},
      {class: 'times-new-roman', name: 'Times New Roman'},
      {class: 'calibri', name: 'Calibri'},
      {class: 'comic-sans-ms', name: 'Comic Sans MS'}
    ],
    customClasses: [
      // {
      //   name: 'quote',
      //   class: 'quote',
      // },
      // {
      //   name: 'redText',
      //   class: 'redText'
      // },
      // {
      //   name: 'titleText',
      //   class: 'titleText',
      //   tag: 'h1',
      // },
    ],
    uploadUrl: 'https://us-central1-circle-70720.cloudfunctions.net/upload',
    uploadWithCredentials: true,
    sanitize: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
    ]
  };
  constructor(private storage: AngularFireStorage,
              notify: NotifyService) {
    this.options.events['image.beforeUpload'] = function(images) {
      const file = images[0];
      const path = `test/${new Date().getTime()}_${file.name}`;
      storage.upload(path, images[0]).then(result => {
        storage.ref(path).getDownloadURL().subscribe(downloadURL => {
          this.image.insert(downloadURL, null, null, this.image.get());
          notify.pop('圖片上傳成功');
        });
      });
    };

    this.options.events['video.beforeUpload'] = function(video) {
      const file = video[0];
      const path = `test/${new Date().getTime()}_${file.name}`;
      storage.upload(path, video[0]).then(result => {
        storage.ref(path).getDownloadURL().subscribe(downloadURL => {
          this.image.insert(downloadURL, null, null, this.image.get());
          notify.pop('圖片上傳成功');
          console.log(downloadURL);
        });
      });
    };
  }
}
