import { Component, OnInit } from '@angular/core';
import {NotifyService} from '../../../../../shared/services/notify.service';
import domtoimage from 'dom-to-image';
import {ShopService} from '../../../../services/shop.service';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-shop-share-page',
  templateUrl: './shop-share-page.component.html',
  styleUrls: ['./shop-share-page.component.styl']
})
export class ShopSharePageComponent implements OnInit {

  htmlCode = '';
  constructor(private notify: NotifyService,
              public ss: ShopService,
              public snackBar: MatSnackBar) { }

  ngOnInit() {
    this.setHtmlCode();
  }
  copy() {
    this.snackBar.open('已複製，可以分享並宣傳囉！', '', {
      duration: 20000
    });
    // this.notify.pop('已複製，可以分享並宣傳囉！');
  }
  downloadImage() {
    domtoimage.toPng(document.getElementById('poster'))
      .then( (dataUrl) => {
        const link = document.createElement('a');
        link.download = 'poster.png';
        link.href = dataUrl;
        link.click();
      });
  }
  setHtmlCode() {
    const ImagePath = 'assets/images/CastTalk_Icon_JoinFriend.png';
    // tslint:disable-next-line:max-line-length
    this.htmlCode = `<a href="${this.ss.currentShop.shareLink}"><img src="${window.location.origin}/${ImagePath}" alt="加入好友" height="36" border="0"></a>`;
  }

}
