import { Injectable } from '@angular/core';
import {faFacebookF} from '@fortawesome/free-brands-svg-icons';
import {faTrash, faPen, faUserFriends} from '@fortawesome/free-solid-svg-icons';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import {faAddressCard, faClipboard, faCommentAlt, faCommentDots, faImage, faWindowClose} from '@fortawesome/free-regular-svg-icons';
import {faGift, faVideo} from '@fortawesome/free-solid-svg-icons';
@Injectable({
  providedIn: 'root'
})
export class IconService {

  public faFacebookF = faFacebookF;
  public faTrash = faTrash;
  public faPen = faPen;
  public faUserFriends = faUserFriends;
  public faCalendarAlt = faCalendarAlt;
  public faCommentAlt = faCommentAlt;
  public faWindowClose = faWindowClose;
  public faAddressCard = faAddressCard;
  public faImage = faImage;
  public faVideo = faVideo;
  public faGift = faGift;
  public faCommentDots = faCommentDots;
  public faClipboard = faClipboard;
  constructor() { }
}
