<div class="d-flex justify-content-between align-items-center mb-2"><h2 class="mb-0">搜尋好友</h2>
</div>
<hr>
<form #form="ngForm">
	<div class=" my-4">
		<div class="form-group row w-fix-400">
			<label class="col-sm-3 col-form-label text-sm-right font-weight-light">標籤</label>
			<div class="col-sm-9">
				<div class="form-inline text-muted">
					<input [(ngModel)]="name" required maxlength="15" type="text" name="name" placeholder="請輸入標籤" autocomplete="off" class="form-control w-fix-240">
					<span class="pl-1"><strong >{{name.length}}</strong><span >/15</span></span>
				</div>
			</div>
		</div>
		<div class="form-group row w-fix-400">
			<label class="col-sm-3 col-form-label text-sm-right font-weight-light">備註</label>
			<div class="col-sm-9">
				<div class="form-inline text-muted">
					<input [(ngModel)]="desc" required maxlength="15" type="text" name="desc" placeholder="請輸入備註" autocomplete="off" class="form-control w-fix-240">
					<span class="pl-1"><strong >{{desc.length}}</strong><span >/15</span></span>
				</div>
			</div>
		</div>
		<div class="btn-group ">
			<button [disabled]="form.invalid" (click)="addTag()" type="button" style="margin-left: 12rem" class=" btn btn-primary w-min-100 h-35">建立新標籤</button>
		</div>
	</div>
</form>


<hr>

<table class="table table-hover table-layout-fixed">
	<thead class="thead-light">
	<tr>
		<th >
      <span >
          名稱
      </span>
		</th>
		<th >
      <span >
          備註
      </span></th>
		<th >
			<span >
        建立時間
      </span>
		</th>
		<th >
			<span >
        好友人數
      </span>
		</th>
	</tr>
	</thead>
	<tbody >
	<tr *ngIf="tags.length === 0">
		<td colspan="3">
			<div class="text-center my-4">
				尚無標籤
			</div>
		</td>
	</tr>
	<ng-container *ngFor="let tag of currentPage; index as i">
		<tr>
			<td>
				{{tag.name}}
			</td>
			<td>
				{{tag.desc}}
			</td>
			<td>
				{{tag.timestamp.toDate().toLocaleString()}}
			</td>
			<td>
				{{tag.follower}}
			</td>
		</tr>
	</ng-container>
	</tbody>
</table>
<hr>
<app-list-paginator [data]="tags" (listChanged)="currentPage = $event"></app-list-paginator>
