import { Component, OnInit } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {AngularFireAuth} from '@angular/fire/auth';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.styl']
})
export class LoginComponent implements OnInit {

  loading = false;
  qrCode = '';
  production = environment.production;
  constructor(private db: AngularFirestore,
              private router: Router,
              private afAuth: AngularFireAuth
  ) { }

  ngOnInit(): void {
    this.getQrCode();
  }
  getQrCode() {
    this.db.collection('loginAuth').add({
      timestamp: new Date()
    }).then(res => {
      this.qrCode = environment.shareUrl + 'login' + res.id;
      this.db.doc(res.path).valueChanges().subscribe(doc => {
        if (doc) {
          // @ts-ignore
          if (doc.userId) {
            this.loading = true;
          }
          // @ts-ignore
          if (doc.token) {
            // @ts-ignore
            this.afAuth.signInWithCustomToken(doc.token).then(async login => {
              console.log(login);
              await this.afterSignIn();
            });
            res.delete().then();
          }
        }
      });
    }).catch(error => {
      console.log('error: ' + error);
    });
  }
  private async afterSignIn() {
    // Do after login stuff here, such router redirects, toast messages, etc.
    setTimeout(() => {
        this.router.navigate(['/Accounts']);
      },
      3000);
  }

}
