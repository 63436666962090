<div class="d-flex justify-content-between align-items-center mb-2"><h2 class="mb-0">建立活動</h2>
</div>
<hr>

<form #form="ngForm">
	<div class="form-group row w-max-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">名稱</label>
		<div class="col-sm-9 form-inline">
			<input [(ngModel)]="event.name" style="width: 320px !important;" required maxlength="30" name="name" placeholder="請輸入活動名稱" autocomplete="off" class="w-fix-360 form-control">
			<span class="ml-1"><strong class="text-muted">{{event.name.length}}</strong><span class="">/30</span></span>
		</div>
	</div>
	<div class="form-group row w-max-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">分類</label>
		<div class="col-sm-9">
			<div class="form-group d-flex align-items-end">
				<div class="flex-grow-1">
					<div class="input-group">
						<tag-input [theme]="'foundation-theme'"   [secondaryPlaceholder]= "'選取主題'" [placeholder]="'選取主題'"  name="category" [(ngModel)]="event.topics" [onlyFromAutocomplete] = "true" [modelAsStrings]="true" >
							<tag-input-dropdown [autocompleteItems]= "topics" [showDropdownIfEmpty]= "true">
							</tag-input-dropdown>
						</tag-input>
					</div>
				</div>
			</div>	</div>
	</div>
	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">報名成功標籤</label>
		<div class="col-sm-9">
			<div class="kv-select form-group d-flex align-items-end">
				<div class="flex-grow-1">
					<div class="input-group">
						<tag-input [theme]="'foundation-theme'"   [secondaryPlaceholder]= "'選取標籤'" [placeholder]="'選取標籤'"  name="tags" [(ngModel)]="tag" [onlyFromAutocomplete] = "true"  (ngModelChange)="onTagChange()" >
							<tag-input-dropdown [autocompleteItems]= "ss.tagsForDisplay" [showDropdownIfEmpty]= "true">
							</tag-input-dropdown>
						</tag-input>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">問卷
		</label>
		<div class="col-sm-9">
			<div class="card-body position-relative overflow-auto" style="padding: 0px !important;">
				<div *ngIf="!event.qnrRef" class="border text-center">
					<div  class="figure figure-grid-sm my-4">
						<a (click)="openQnrPicker()" href="javascript:void(0)">選擇問卷調查</a>
						<div class="text-muted opacity-30">
							<fa-icon [icon]="icon.faClipboard" size="3x"></fa-icon>
						</div>
					</div>
				</div>
				<div class="position-relative border" *ngIf="event.qnrRef">
					<button (click)="!event.qnrRef" type="button" class="position-absolute close" style=" top: 0px; right: 0px;">
						<fa-icon [icon]="faWindowClose"></fa-icon>
					</button>
					<div class="d-flex align-items-center">
						<div class="flex-none figure figure-grid figure-grid-sm">
							<img [src]="event.qnrData.photoUrl" class="figure-img figure-img">
						</div>
						<div class="flex-1 text-truncate p-3">
							<div class="text-truncate">
								<span class="text-dark">{{event.qnrData.title}}</span>
							</div>
							<small class="text-muted">問卷調查的結束時間： {{$any(event.qnrData.endTime).toDate().toLocaleString()}}</small>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="form-group row w-max-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">價格</label>
		<div class="col-sm-9 form-inline">
			<input [(ngModel)]="event.price" style="width: 320px !important;" required type="number" name="price"  autocomplete="off" class="w-fix-360 form-control">
		</div>
	</div>

	<div class="form-group row w-max-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">人數上限</label>
		<div class="col-sm-9 form-inline">
			<input [(ngModel)]="event.maxPle" style="width: 320px !important;" required type="number" maxlength="30" name="num"  autocomplete="off" class="w-fix-360 form-control">
		</div>
	</div>
	<div class="form-group row w-max-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">圖片</label>
		<div class="col-sm-9">
			<div class="form-inline position-relative">
				<div>
					<div  [ngClass]="{'border-danger' : img.invalid}" *ngIf="event.photoUrl === ''" class="figure figure-grid cursor-pointer border position-relative mr-2">
						<a (click)="openImgUploadDialog()" href="javascript:void(0)">上傳圖片</a>
					</div>
					<div *ngIf="event.photoUrl !== ''" class="figure figure-grid cursor-pointer border position-relative mr-2">
						<img [src]="event.photoUrl" class="figure-img">
						<button (click)="event.photoUrl = ''" type="button" class="position-absolute top-right close x"><fa-icon [icon]="faWindowClose"></fa-icon></button>
					</div>
				</div>
				<input #img=ngModel class="d-none" name="img" [(ngModel)]="event.photoUrl" required>
			</div>
		</div>
	</div>

	<div class="form-group row w-max-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">開始時間</label>
		<div class="col-sm-9">
			<div class="form-inline text-muted">
				<input class="form-control w-80 mr-2 " style="width: 400px !important;" required  name="eventStartTime" [(ngModel)]="event.eventStartTime"    [owlDateTime]="eventStartTime"  [owlDateTimeTrigger]="eventStartTime"  autocomplete="off">
				<span [owlDateTimeTrigger]="eventStartTime">
					<fa-icon class="text-muted" [icon]="faCalendarAlt"></fa-icon>
				</span>
				<owl-date-time  #eventStartTime></owl-date-time>
			</div>
		</div>
	</div>

	<div class="form-group row w-max-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">結束時間</label>
		<div class="col-sm-9">
			<div class="form-inline text-muted">
				<input class="form-control w-80 mr-2 " style="width: 400px !important;" required name="eventEndTime" [(ngModel)]="event.eventEndTime"    [owlDateTime]="eventEndTime" [min]="minDate" [owlDateTimeTrigger]="eventEndTime"  autocomplete="off">
				<span [owlDateTimeTrigger]="eventEndTime">
					<fa-icon class="text-muted" [icon]="faCalendarAlt"></fa-icon>
				</span>
				<owl-date-time  #eventEndTime></owl-date-time>
			</div>
		</div>
	</div>

	<div class="form-group row w-max-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">報名開始時間</label>
		<div class="col-sm-9">
			<div class="form-inline text-muted">
				<input class="form-control w-80 mr-2 " style="width: 400px !important;" required name="StartTime" [(ngModel)]="event.startTime"    [owlDateTime]="StartTime"  [owlDateTimeTrigger]="StartTime"  autocomplete="off">
				<span [owlDateTimeTrigger]="StartTime">
					<fa-icon class="text-muted" [icon]="faCalendarAlt"></fa-icon>
				</span>
				<owl-date-time  #StartTime></owl-date-time>
			</div>
		</div>
	</div>

	<div class="form-group row w-max-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">報名結束時間</label>
		<div class="col-sm-9">
			<div class="form-inline text-muted">
				<input class="form-control w-80 mr-2 " style="width: 400px !important;" required name="EndTime" [(ngModel)]="event.endTime"    [owlDateTime]="EndTime" [min]="minDate" [owlDateTimeTrigger]="EndTime"  autocomplete="off">
				<span [owlDateTimeTrigger]="EndTime">
					<fa-icon class="text-muted" [icon]="faCalendarAlt"></fa-icon>
				</span>
				<owl-date-time  #EndTime></owl-date-time>
			</div>
		</div>
	</div>

	<div class="form-group row w-max-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">簡介</label>
		<div class="col-sm-9">
			<angular-editor [config]="" [(ngModel)]="event.desc" [config]="froala.config" placeholder="簡介" name="desc" required></angular-editor>
		</div>
	</div>
	<hr>
	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">活動型態</label>
		<div class="col-sm-9">
			<div class="form-group d-flex align-items-end">
				<div class="flex-grow-1">
					<select [(ngModel)]="event.type" name="type" required style="width: 120px !important;" class="w-fix-160 form-control custom-select"  >
						<option [ngValue]="0">線上活動</option>
						<option [ngValue]="1">線下活動</option>
					</select>
				</div>
			</div>
		</div>
	</div>
	<div *ngIf="event.type === 0" class="form-group row w-max-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">上課網址</label>
		<div class="col-sm-9 form-inline">
			<input [(ngModel)]="event.eventUrl" style="width: 480px !important;" required name="eventUrl" placeholder="請輸入上課網址" autocomplete="off" class="w-fix-360 form-control">
		</div>
	</div>
	<div *ngIf="event.type === 1" class="form-group row w-max-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">上課地址</label>
		<div class="col-sm-9 form-inline">
			<input [(ngModel)]="event.address" style="width: 480px !important;" required name="address" placeholder="請輸入上課地址" autocomplete="off" class="w-fix-360 form-control">
		</div>
	</div>
	<div class="form-group row w-max-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">活動型態補充說明</label>
		<div class="col-sm-9">
			<textarea [(ngModel)]="event.note" maxlength="500" required name="note" placeholder="活動型態補充說明" rows="5" autocomplete="off" class="form-control"></textarea>
			<span class="float-right"><strong class="text-muted">{{event.note.length}}</strong><span class="">/500</span></span>
		</div>
	</div>

	<hr>
	<div class="d-flex justify-content-center p-4">
		<div class="p-4">
			<button (click)="save()" [disabled]="form.invalid" type="button" class="px-5 btn btn-primary btn-undefined">儲存</button>
		</div>
	</div>
</form>
