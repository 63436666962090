import { Component, OnInit } from '@angular/core';
import {FDraftMessage, FPushMessage} from '../../../../../firebase/models/message';
import {AngularFirestore, DocumentChangeAction} from '@angular/fire/firestore';
import {ShopService} from '../../../../services/shop.service';
import {MatDialog} from '@angular/material/dialog';
import {NotifyService} from '../../../../../shared/services/notify.service';
import {ConfirmDialogComponent} from '../../../../../shared/ui/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-shop-message-list',
  templateUrl: './shop-message-list.component.html',
  styleUrls: ['./shop-message-list.component.styl']
})
export class ShopMessageListComponent implements OnInit {

  test = '123';
  drafts: DocumentChangeAction<FDraftMessage>[] = [];
  draftCurrentPage: DocumentChangeAction<FDraftMessage>[] = [];
  schedules: DocumentChangeAction<FDraftMessage>[] = [];
  scheduleCurrentPage: DocumentChangeAction<FDraftMessage>[] = [];
  messages: DocumentChangeAction<FPushMessage>[] = [];
  messageCurrentPage: DocumentChangeAction<FPushMessage>[] = [];
  constructor(private ss: ShopService,
              private db: AngularFirestore,
              public dialog: MatDialog,
              public notify: NotifyService) { }

  ngOnInit(): void {
    this.getMessages();
  }
  getMessages() {
    this.ss.currentShopDoc.collection<FPushMessage>('messages',
        ref => ref.orderBy('timestamp', 'desc')).snapshotChanges().subscribe(messages => {
      this.messages = messages;
    });
    this.ss.currentShopDoc.collection<FDraftMessage>('drafts',
        ref => ref.orderBy('timestamp', 'desc')).snapshotChanges().subscribe(drafts => {
      this.drafts = drafts;
    });
    this.ss.currentShopDoc.collection<FDraftMessage>('schedules',
        ref => ref.orderBy('timestamp', 'desc')).snapshotChanges().subscribe(schedules => {
      this.schedules = schedules;
    });
  }
  getTagString(message: any) {
    if (!message.tags) {
      return '';
    }
    if (message.tags.all) {
      return '所有好友';
    } else {
      return Object.keys(message.tags).map(x => this.idToTag(x));
    }
  }
  idToTag(id: string): string {
    if (this.ss.tagsForDisplay.filter(x => (x.value === id))[0]) {
      return this.ss.tagsForDisplay.filter(x => (x.value === id))[0].display;
    } else {
      return '標籤不存在';
    }
  }

  deleteSchedule(doc: DocumentChangeAction<FDraftMessage>) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: {
        title: '刪除預約訊息',
        body: '確認要刪除預約訊息？'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        doc.payload.doc.ref.delete().then(() => this.notify.pop('已刪除'));
      }
    });
  }
  cancelSchedule(doc: DocumentChangeAction<FDraftMessage>) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: {
        title: '取消預約訊息',
        body: '確認要取消預約訊息？ (訊息將移至草稿)'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.ss.currentShopDoc.collection('drafts').add(doc.payload.doc.data()).then(() =>
            doc.payload.doc.ref.delete().then(() => this.notify.pop('已取消')));
      }
    });
  }

}
