import { Component, OnInit } from '@angular/core';
import {FAgentChargePlan} from '../../../../../firebase/models/agent';
import {AngularFirestore} from '@angular/fire/firestore';
import {ShopService} from '../../../../services/shop.service';
import { getPlanName } from 'src/app/firebase/models/chargePlan';

@Component({
  selector: 'app-shop-agent-fee-list-page',
  templateUrl: './shop-agent-fee-list-page.component.html',
  styleUrls: ['./shop-agent-fee-list-page.component.styl']
})
export class ShopAgentFeeListPageComponent implements OnInit {

  clients: FAgentChargePlan[] = [];
  currentPage: FAgentChargePlan[] = [];
  getPlanName = getPlanName;
  constructor(private db: AngularFirestore,
              public ss: ShopService) {
  }

  ngOnInit(): void {
    this.db.collection('agentChargePlan').doc(this.ss.currentShop.uid).collection<FAgentChargePlan>('agentMonthlyTotals')
        .valueChanges().subscribe(shops => {
      this.clients = shops;
    });
  }

}
