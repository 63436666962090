<div class="d-flex justify-content-between align-items-center mb-2">
	<h2 class="mb-0">拆帳報表</h2>
</div>
<hr>

<table class="table table-hover table-layout-fixed">
	<colgroup>
		<col width="10%">
		<col width="20%">
		<col width="20%">
		<col width="20%">
		<col width="5%">
		<col width="20%">
	</colgroup>
	<thead class="thead-light">
	<tr>
		<th class="">
      <span class="">
        計費期間
      </span>
		</th>
		<th class="">
      <span class=""><!---->
        訊息服務費
      </span>
		</th>
		<th class="">
      <span class=""><!---->
        廣告服務費
      </span>
		</th>
		<th class="">
      <span ><!---->
        收費總額（含稅）
      </span>
		</th>
		<th class="">
      <span ><!---->
        分潤比例
      </span>
		</th>
		<th class="">
      <span ><!---->
        分潤金額（含稅）
      </span>
		</th>
	</tr>
	</thead>
	<tbody>
	<tr *ngFor="let month of currentPage" class="">
		<td>
      <span class=""><!---->
        <a [routerLink]="month.date">{{month.date}}</a>
      </span>
		</td>
		<td>
      <span class=""><!---->
	      {{month.messageFee}}
      </span>
		</td>
		<td>
      <span class=""><!---->
	      {{month.advFee}}
      </span>
		</td>
		<td>
      <span class=""><!---->
	      {{month.totalFee}}
      </span>
		</td>
		<td>
      <span class=""><!---->
	      {{ss.currentShop.agent.percentage}}
      </span>
		</td>
		<td>
      <span class=""><!---->
	      {{month.totalFee * ss.currentShop.agent.percentage * 0.01}}
      </span>
		</td>
	</tr>
	</tbody>
</table>
<hr>
<app-list-paginator [data]="clients" (listChanged)="currentPage = $event"></app-list-paginator>
