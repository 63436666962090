import { Component, OnInit } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {AngularFireStorage} from '@angular/fire/storage';
import {NotifyService} from '../../../../../shared/services/notify.service';
import {FroalaService} from "../../../../../shared/services/froala.service";

@Component({
  selector: 'app-admin-ui-plan-page',
  templateUrl: './admin-ui-plan-page.component.html',
  styleUrls: ['./admin-ui-plan-page.component.styl']
})
export class AdminUiPlanPageComponent implements OnInit {

  htmlContent = '';

  constructor(private db: AngularFirestore, public storage: AngularFireStorage, private notify: NotifyService, public froala: FroalaService) {
    db.collection('system').doc('discountPage').get().subscribe(x => {
      this.htmlContent = x.data().content;
    });
  }

  ngOnInit() {
  }
  change() {
    this.db.collection('system').doc('discountPage').update({content: this.htmlContent}).then(() => {
      this.notify.pop('已儲存');
    });
  }

}

