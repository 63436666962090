import { Component, OnInit } from '@angular/core';
import {AngularFirestore, AngularFirestoreDocument, DocumentChangeAction} from '@angular/fire/firestore';
import {FAgentShare} from '../../../../../../firebase/models/agent';
import {ActivatedRoute} from '@angular/router';
import {NotifyService} from '../../../../../../shared/services/notify.service';
import {RouteService} from '../../../../../../shared/services/route.service';
import {ShopService} from '../../../../../services/shop.service';
import {StorageService} from '../../../../../../shared/services/storage.service';
import {ModalService} from '../../../../../../shared/services/modal.service';
import {IconService} from '../../../../../../shared/services/icon.service';
import {FSubscribe} from '../../../../../../firebase/models/user';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import {FCourse} from '../../../../../../firebase/models/course';

@Component({
  selector: 'app-shop-event-share-list-page',
  templateUrl: './shop-event-share-list-page.component.html',
  styleUrls: ['./shop-event-share-list-page.component.styl']
})
export class ShopEventShareListPageComponent implements OnInit {

  faDownload = faDownload;
  eventDoc: AngularFirestoreDocument;
  event: FCourse;
  list: DocumentChangeAction<FAgentShare>[] = [];
  currentPage: DocumentChangeAction<FAgentShare>[] = [];
  share: FAgentShare = {desc: '', shop: undefined, user: undefined, userRef: null, users: []};
  constructor(private activatedRoute: ActivatedRoute,
              private notify: NotifyService,
              private router: RouteService,
              private ss: ShopService,
              private img: StorageService,
              private db: AngularFirestore,
              private ms: ModalService,
              public icon: IconService) { }

  ngOnInit(): void {
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    this.eventDoc = this.ss.currentShopDoc.collection<FCourse>('events').doc(id);
    this.eventDoc.get().subscribe(event => {
      if (event.exists) {
        // @ts-ignore
        this.event = event.data();
        this.getShareLinks();
      } else {
        this.notify.pop('活動不存在');
        this.router.navigateTo('/Event');
      }
    });
  }
  getShareLinks() {
    this.eventDoc.collection<FAgentShare>('agentShare').snapshotChanges().subscribe(x => {
      this.list = x;
    });
  }
  openFriendPicker() {
    this.ms.openFriendPicker().afterClosed().subscribe((friend: DocumentChangeAction<FSubscribe>) => {
      this.share.user = friend.payload.doc.data().user;
      this.share.userRef = friend.payload.doc.ref;
    });
  }
  downloadQRCode(path: string) {
    this.ms.openQrCodeDownloadMadel( path);
  }
  addShareLink() {
    this.share.shop = this.ss.shopInfo;
    this.eventDoc.collection<FAgentShare>('agentShare').doc(this.share.user.uid).set(this.share).then(() => {
      this.notify.pop('儲存成功');
    });
  }
  deleteShareLink(doc: DocumentChangeAction<FAgentShare>) {
    doc.payload.doc.ref.delete().then(() => {
      this.notify.pop('刪除成功');
    });
  }

}
