import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import {ShopService} from '../services/shop.service';
import {NotifyService} from '../../shared/services/notify.service';
import {RouteService} from '../../shared/services/route.service';

@Injectable({
  providedIn: 'root'
})
export class ExtraServiceGuard implements CanActivate, CanActivateChild {
  constructor(private ss: ShopService,
              private notify: NotifyService,
              private rs: RouteService,
              private router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    console.log(this.ss.chargePlanLoaded.getValue());
    if (!this.ss.chargePlanLoaded.getValue()) {
      this.router.navigate(['/']);
      return false;
    }
    return this.check(next);
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.ss.chargePlanLoaded.getValue()) {
      this.router.navigate(['/']);
      return false;
    }
    return this.check(next);
  }
  check(next: ActivatedRouteSnapshot) {
    let passed = false;
    console.log(this.ss.chargePlan);
    switch (next.data.role) {
      case 'RewardCard':
        passed = this.ss.chargePlan.permission.rewardCard;
        break;
      case 'Course':
        passed = this.ss.chargePlan.permission.course;
        break;
      case 'Store':
        passed = this.ss.chargePlan.permission.store;
        break;
      case 'Event':
        passed = this.ss.chargePlan.permission.event;
        break;
      case 'Booking':
        passed = this.ss.chargePlan.permission.booking;
        break;
      default:
        break;
    }
    if (passed) {
      return true;
    }else {
      this.rs.navigateTo('/NotSupport');
      this.notify.pop('本項加值功能尚未啟用，請與您的代理商或CastTalk商家服務官方帳號聯繫，謝謝!');
    }
  }
}
