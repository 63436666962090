import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-shop',
  templateUrl: './admin-shop.component.html',
  styleUrls: ['./admin-shop.component.styl']
})
export class AdminShopComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
