<div class="d-flex justify-content-between align-items-center mb-2"><h2 class="mb-0">新聞分析</h2>
</div>
<hr>
<div class="mt-3 mb-3">
	<h4 >{{News ? News.title : ''}}</h4>

</div>
<table class="table table-hover table-layout-fixed">
	<thead class="thead-light">
	<tr>
		<th class="">
      <span class="">
        日期
      </span>
		</th>
		<th class="">
      <span class=""><!---->
        瀏覽次數
      </span>
	</tr>
	</thead>
	<tbody>
	<tr *ngFor="let analysis of list" class="">
		<td>
      <span class=""><!---->
	      {{analysis.id}}
      </span>
		</td>
		<td>
      <span class=""><!---->
	      {{analysis.data().count}}
      </span>
		</td>
	</tr>
	</tbody>
</table>
