<main class="w-100 d-flex flex-column position-relative bg-white" style="min-width: calc(100% - 280px);">
	<div class="m-5">
		<div *ngIf="error" class="container h-70v d-flex justify-content-center align-items-center">
			<div class="text-center">
				<h3 >此連結已失效</h3>
			</div>
		</div>
		<div *ngIf="auth" class="container h-70v d-flex justify-content-center align-items-center">

			<div class="text-center">
				<h3>官方帳號認證</h3>
				<p class="text-pre-line">「{{auth.lineAt}}」官方帳號申請為CastTalk官方帳號，敬請確認。 若此官方帳號與您無關，請點選「取消」。
				</p>
				<br>
				<h4 style="color: #3A5082">——    產品及廣告行銷代理商     ——</h4>
				<img class="w-80" src="assets/images/agent/SIMSIX.png">
				<p>炘世紀數位資訊股份有限公司</p>
				<p class="mt-3 mb-3">優惠推薦碼</p>
				<div class="d-flex align-items-center mb-3">
					<input [(ngModel)]="code" (ngModelChange)="codeChange()" style="margin:0px auto;" class=" form-control w-fix-120">
				</div>
				<small style="color: darkred">如果您有優惠推薦碼，將可直接升級更多優惠<br></small>
				<small style="color: blue">2020第一季優惠方案<br></small>
				<small style="color: darkred">＊每日訊息一則 升級為 三則<br></small>
				<small style="color: darkred">＊贈送七天主頁推薦位</small>


				<div class="mt-4">
					<a>
						<button (click)="confirm()" type="button" class="w-fix-320 btn btn-primary btn-lg">確認</button>
					</a>
				</div>
				<div class="mt-3">
					<button (click)="toHomePage()" type="button" class="w-fix-320 btn btn-outline-secondary btn-lg">取消</button>
				</div>
			</div>
		</div>
	</div>
</main>
