export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyDs6tsDOzsfmlMBifuoPbFrG9rzSIV98Eo',
    authDomain: 'circle-70720.firebaseapp.com',
    databaseURL: 'https://circle-70720.firebaseio.com',
    projectId: 'circle-70720',
    storageBucket: 'circle-70720.appspot.com',
    messagingSenderId: '516668112656',
    appId: '1:516668112656:web:eac97985952d55fa'
  },
// tslint:disable-next-line:max-line-length
  lineLoginUrl: `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=1653748136&redirect_uri=https://us-central1-circle-70720.cloudfunctions.net/getToken&state=${window.location.origin}/lineLogin&scope=openid%20profile`,
  siteUrl: 'https://test.casttalk.me/',
  shareUrl: 'https://s.casttalk.me/',
  functionsUrl: 'https://us-central1-circle-70720.cloudfunctions.net/',
  dynamicLinkDomainUriPrefix: 'https://gotest.casttalk.me',

};
// prod
export const senvironment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyDqNlWjZ7n5l4XFyHXFl18m7jryM1nIdpE',
    authDomain: 'linecast-w.firebaseapp.com',
    databaseURL: 'https://linecast-w.firebaseio.com',
    projectId: 'linecast-w',
    storageBucket: 'linecast-w.appspot.com',
    messagingSenderId: '605881748064',
    appId: '1:605881748064:web:bd4a247732c2a2680c5105',
    measurementId: 'G-SW19JNRY9R'
  },
  // tslint:disable-next-line:max-line-length
  lineLoginUrl: `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=1653748136&redirect_uri=https://us-central1-linecast-w.cloudfunctions.net/getToken&state=${window.location.origin}/lineLogin&scope=openid%20profile`,
  siteUrl: 'https://manager.casttalk.me/',
  shareUrl: 'https://s.casttalk.me/',
  functionsUrl: 'https://us-central1-linecast-w.cloudfunctions.net/',
  dynamicLinkDomainUriPrefix: 'https://go.casttalk.me'
};
