import { Component, OnInit } from '@angular/core';
import {Observable} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {AngularFirestore} from '@angular/fire/firestore';
import {NotifyService} from '../../services/notify.service';
import {environment} from '../../../../environments/environment';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-shop-auth-page',
  templateUrl: './shop-auth-page.component.html',
  styleUrls: ['./shop-auth-page.component.styl']
})
export class ShopAuthPageComponent implements OnInit {

  id: string;
  auth: any;
  error = false;
  url = '';
  code = '';
  verify: Observable<boolean>;
  constructor(private route: ActivatedRoute,
              private afs: AngularFirestore,
              private router: Router,
              private notify: NotifyService) {
  }
  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.afs.collection('lineAuth').doc(this.id).get().subscribe(auth => {
      if ( auth.exists) {
        this.auth = auth.data();
        this.url = environment.functionsUrl + 'lineAuth?id=' + this.id ;
      } else {
        this.error = true;
      }

    });
  }
  toHomePage() {
    this.router.navigate(['/Account']);
  }
  codeChange() {
    this.verify = this.afs.collection('shops', ref => ref
        .where('agent.code', '==', this.code)).valueChanges().pipe(map(x => x.length > 0));
    console.log(this.verify);
  }
  confirm() {
    if (this.code !== '') {
      this.afs.collection('shops', ref => ref
          .where('agent.code', '==', this.code)).get().subscribe(shop => {
        if (shop.size > 0) {
          this.afs.collection('lineAuth').doc(this.id).update({
            agentRef: shop.docs[0].ref,
            verified: true
          }).then(() => {
            this.notify.pop('推薦碼正確');
            this.router.navigate(['AuthResult/success']);
          });

        } else {
          this.notify.pop('推薦碼錯誤，請重新輸入');
        }
      });
    } else {
      this.afs.collection('lineAuth').doc(this.id).update({
        verified: true
      }).then(() => {
        this.router.navigate(['AuthResult/success']);
      });
    }
  }

}
