<form #form=ngForm>
	<div class="w-100 d-flex justify-content-between align-items-center mb-2"><h2 class="mb-0">圖文訊息</h2></div>
	<p class="text-muted">您可以使用圖片傳送視覺效果更豐富的訊息進行宣傳。</p>
	<hr>
	<div class="btn-toolbar justify-content-between my-4"><div aria-label="First group" class="btn-group"><button (click)="delete()" type="button" class="btn btn-secondary">刪除</button></div> <div class="input-group"><button (click)="save()" type="button" class="w-min-120 btn btn-primary">儲存</button></div></div>
	<hr class="my-4">
	<div class="mb-3">
		<h3 class="mb-0">名稱</h3>
	</div>
	<div class="form-group w-max-800">
		<div class="form-inline text-muted">
			<input [(ngModel)]="richMessage.name" required maxlength="30" name="title" type="text" autocomplete="off" class="form-control w-90 mr-2" aria-required="true" aria-invalid="false">
			<span class="">
        <strong class="">{{richMessage.name.length}}</strong>
        <span class="">/30</span>
      </span>
		</div>
	</div>
	<div class="d-flex align-items-center mt-4 mb-3">
		<h3 class="w-fix-240 mb-0">訊息設定</h3>
	</div>
	<hr class="my-4">
	<div class="d-flex">
		<div class="w-fix-240">
			<div class="w-fix-240" aria-required="true" aria-invalid="false">
				<div  class="d-flex justify-content-center align-items-center border rounded mb-2 h-fix-240 position-relative">
					<p *ngIf="richMessage.photoUrl === ''" class="test text-center text-muted no-background position-absolute">
						<small>請選擇版型並上傳背景圖片。</small>
					</p>
					<img *ngIf="richMessage.photoUrl !== ''" [src]="richMessage.photoUrl"  class="w-100 h-100">
					<div *ngIf="richMessage.styleType == 0" class="position-absolute w-100 h-100 border border-primary rounded" style="box-sizing: border-box; border-width: 3px !important;">
						<div class="d-flex justify-content-center align-items-center w-100 h-100 bg-dark-20 h2 mb-0 text-white">
							<div>A</div>
						</div>
					</div>
					<div *ngIf="richMessage.styleType == 1" class="position-absolute w-100 h-100 border border-primary rounded" style="box-sizing: border-box; border-width: 3px !important;">
						<div class="d-flex align-items-stretch justify-content-center align-items-center w-100 h-100 bg-dark-20 h2 mb-0 text-white">
							<div class="d-flex justify-content-center align-items-center w-50">A</div>
							<div class="d-flex justify-content-center align-items-center w-50 border-left border-primary" style="border-width: 3px !important;">B</div>
						</div>
					</div>
					<div *ngIf="richMessage.styleType == 2" class="position-absolute w-100 h-100 border border-primary rounded" style="box-sizing: border-box; border-width: 3px !important;">
						<div class="d-flex flex-column w-100 h-100 bg-dark-20 h2 mb-0 text-white">
							<div class="d-flex justify-content-center align-items-center w-100 h-100">A</div>
							<div class="d-flex justify-content-center align-items-center w-100 h-100 border-top border-primary" style="border-width: 3px !important;">B</div>
						</div>
					</div>
					<div *ngIf="richMessage.styleType == 3" class="position-absolute w-100 h-100 border border-primary rounded" style="box-sizing: border-box; border-width: 3px !important;">
						<div class="d-flex flex-column w-100 h-100 bg-dark-20 h2 mb-0 text-white">
							<div class="d-flex justify-content-center align-items-center w-100 h-100">A</div>
							<div class="d-flex justify-content-center align-items-center w-100 h-100 border-top border-primary" style="border-width: 3px !important;">B</div>
							<div class="d-flex justify-content-center align-items-center w-100 h-100 border-top border-primary" style="border-width: 3px !important;">C</div>
						</div>
					</div>
					<div *ngIf="richMessage.styleType == 4" class="position-absolute w-100 h-100 border border-primary rounded" style="box-sizing: border-box; border-width: 3px !important;">
						<div class="d-flex align-items-stretch justify-content-center align-items-center w-100 h-50 bg-dark-20 h2 mb-0 text-white">
							<div class="d-flex justify-content-center align-items-center w-50">A</div>
							<div class="d-flex justify-content-center align-items-center w-50 border-left border-primary" style="border-width: 3px !important;">B</div>
						</div>
						<div class="d-flex align-items-stretch justify-content-center align-items-center w-100 h-50 bg-dark-20 h2 mb-0 text-white border-top border-primary" style="border-width: 3px !important;">
							<div class="d-flex justify-content-center align-items-center w-50">C</div>
							<div class="d-flex justify-content-center align-items-center w-50 border-left border-primary" style="border-width: 3px !important;">D</div>
						</div>
					</div>
					<div *ngIf="richMessage.styleType == 5" class="position-absolute w-100 h-100 border border-primary rounded" style="box-sizing: border-box; border-width: 3px !important;">
						<div class="d-flex justify-content-center align-items-center w-100 h-50 bg-dark-20 h2 mb-0 text-white">
							<div>A</div>
						</div>
						<div class="d-flex align-items-stretch justify-content-center align-items-center w-100 h-50 bg-dark-20 h2 mb-0 text-white border-top border-primary" style="border-width: 3px !important;">
							<div class="d-flex justify-content-center align-items-center w-50">B</div>
							<div class="d-flex justify-content-center align-items-center w-50 border-left border-primary" style="border-width: 3px !important;">C</div>
						</div>
					</div>
					<div *ngIf="richMessage.styleType == 6" class="position-absolute w-100 h-100 border border-primary rounded" style="box-sizing: border-box; border-width: 3px !important;">
						<div class="d-flex justify-content-center align-items-center w-100 h-50 bg-dark-20 h2 mb-0 text-white">
							<div>A</div>
						</div>
						<div class="d-flex flex-column w-100 h-50 bg-dark-20 h2 mb-0 text-white border-top border-primary" style="border-width: 3px !important;">
							<div class="d-flex justify-content-center align-items-center w-100 h-100"><span>B</span></div>
							<div class="d-flex justify-content-center align-items-center w-100 h-100 border-top border-primary" style="border-width: 3px !important;"><span>C</span></div>
						</div>
					</div>
					<div *ngIf="richMessage.styleType == 7" class="position-absolute w-100 h-100 border border-primary rounded" style="box-sizing: border-box; border-width: 3px !important;">
						<div class="d-flex align-items-stretch justify-content-center align-items-center w-100 h-50 bg-dark-20 h2 mb-0 text-white">
							<div class="d-flex justify-content-center align-items-center w-35"><span>A</span></div>
							<div class="d-flex justify-content-center align-items-center w-35 border-left border-primary" style="border-width: 3px !important;"><span>B</span></div>
							<div class="d-flex justify-content-center align-items-center w-35 border-left border-primary" style="border-width: 3px !important;"><span>C</span></div>
						</div>
						<div class="d-flex align-items-stretch justify-content-center align-items-center w-100 h-50 bg-dark-20 h2 mb-0 text-white border-top border-primary" style="border-width: 3px !important;">
							<div class="d-flex justify-content-center align-items-center w-35"><span>D</span></div>
							<div class="d-flex justify-content-center align-items-center w-35 border-left border-primary" style="border-width: 3px !important;"><span>E</span></div>
							<div class="d-flex justify-content-center align-items-center w-35 border-left border-primary" style="border-width: 3px !important;"><span>F</span></div>
						</div>
					</div>
				</div>
			</div>
			<div class="my-2" aria-required="true" aria-invalid="false">
				<button class="button btn-block btn btn-secondary" (click)="openTypePicker()" >選擇版型</button>
			</div>
			<div class="my-2">
				<button type="file" class="btn-block btn btn-secondary" (click)="openUploadImageDialog()">上傳背景圖片</button>
			</div>
		</div>
		<div class="flex-1 ml-4 w-max-560">
			<div class="d-flex justify-content-between align-items-center mb-3">
				<h4 (click)="log(form.controls)" class="mb-0">動作</h4>
			</div>
			<mat-accordion multi>
				<ng-container  *ngFor = "let action of richMessage.urls; index as i" >
					<mat-expansion-panel  class="ard w-fix-520 my-3" >
						<mat-expansion-panel-header class="p-2" >
							<mat-panel-title class=" mb-0  p-1 pl-4 d-flex align-items-center justify-content-between">
								<a style="padding-left: 10px"> {{getOrderChar(i)}}</a>
							</mat-panel-title>
						</mat-expansion-panel-header>
						<div class="pt-3" >
							<app-shop-action-model required [(ngModel)]="richMessage.urls[i]" [name]="'action' + i"></app-shop-action-model>
						</div>
					</mat-expansion-panel>
				</ng-container>
			</mat-accordion>
		</div>
	</div>
	<div  class="d-flex justify-content-center my-5"><button (click)="save()"  [disabled]="form.invalid" type="button" class="px-5 mx-1 btn btn-primary btn-lg">儲存</button></div>
	<div class="my-4"><a href="javascript:void(0)" (click)="rs.navigateTo('/Home/RichMessage')" class="text-dark border-dark border-bottom"><i class="fas fa-chevron-left mr-1"></i> 返回一覽</a></div>
	<input [(ngModel)]="richMessage.photoUrl" required name="photoUrl" minlength="1" class="d-none">
	<input [(ngModel)]="richMessage.styleType" required name="styleType" class="d-none" type="number" max="7">
</form>
