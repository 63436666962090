<div class="d-flex justify-content-between align-items-center mb-2"><h2 class="mb-0">標籤設定</h2>
</div>
<hr>
<div class=" my-4">
	<div class="form-group row w-fix-400">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">標籤</label>
		<div class="col-sm-9">
			<div class="form-inline text-muted">
				<input [(ngModel)]="tagName" required maxlength="15" type="text" name="statusMessage" placeholder="請輸入標籤" autocomplete="off" class="form-control w-fix-240">
				<span class="pl-1"><strong class="">{{tagName.length}}</strong><span class="">/15</span></span>
			</div>
		</div>
	</div>
	<div class="form-group row w-fix-400">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">備註</label>
		<div class="col-sm-9">
			<div class="form-inline text-muted">
				<input  [(ngModel)]="tagDesc" required maxlength="15" type="text" name="statusMessage" placeholder="請輸入備註" autocomplete="off" class="form-control w-fix-240">
				<span class="pl-1"><strong class="">{{tagDesc.length}}</strong><span class="">/15</span></span>
			</div>
		</div>
	</div>
	<div class="btn-group ">
		<button   (click)="addTag()" type="button" style="margin-left: 12rem" class=" btn btn-primary w-min-100 h-35">建立新標籤</button>
	</div>
	<div>
		<span class="text-muted">排序 0~10是系統保留 請不要使用</span>
	</div>
</div>
<hr>
<table class="table table-hover table-layout-fixed">
	<thead class="thead-light">
	<tr>
		<th class="">
      <span class="">
        標籤名稱
      </span>
		</th>
		<th class="">
      <span class=""><!---->
        建立日期
      </span>
		</th>
		<th class="">
      <span class=""><!---->
        備註
      </span>
		</th>
		<th class="">
      <span ><!---->
        順序
      </span>
		</th>
		<th class="">
      <span ><!---->
        狀態
      </span>
		</th>
	</tr>
	</thead>
	<tbody>
	<tr *ngFor="let tag of tags" class="">
		<td>
      <span class=""><!---->
	      {{tag.payload.doc.data().name}}
	      <fa-icon [icon]="faPen" (click)="openTagNameDialog(tag)" style="color: #1e7e34" class="fas fa-pen fa-sm"></fa-icon>
      </span>
		</td>
		<td>
      <span class=""><!---->
	      {{tag.payload.doc.data().createTime.toDate().toLocaleString()}}
      </span>
		</td>
		<td>
      <span class=""><!---->
	      {{tag.payload.doc.data().desc}}
      </span>
		</td>
		<td>
      <span class=""><!---->
	      {{tag.payload.doc.data().order}}
	      <fa-icon [icon]="faPen" (click)="openOrderDialog(tag)" style="color: #1e7e34" class="fas fa-pen fa-sm"></fa-icon>
      </span>
		</td>
		<td>
      <span class=""><!---->
        <mat-checkbox class="mt-1" [checked] = tag.payload.doc.data().enabled (change)="toggleEnabled($event, tag)"></mat-checkbox>
      </span>
		</td>
	</tr>
	</tbody>
</table>
