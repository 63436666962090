import { Component, OnInit } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import FieldValue = firebase.firestore.FieldValue;
import * as firebase from 'firebase';

@Component({
  selector: 'app-admin-others-event-tag-page',
  templateUrl: './admin-others-event-tag-page.component.html',
  styleUrls: ['./admin-others-event-tag-page.component.styl']
})
export class AdminOthersEventTagPageComponent implements OnInit {

  tags = [];
  tagName = '';
  constructor(private db: AngularFirestore) { }

  ngOnInit(): void {
    this.db.collection('system').doc('info').valueChanges().subscribe(x => {
      // @ts-ignore
      this.tags = x.eventTopics;
    });
  }
  addTag() {
    this.db.collection('system').doc('info').update({
      eventTopics: FieldValue.arrayUnion(this.tagName)
    });
  }
  removeTag(tag: string) {
    this.db.collection('system').doc('info').update({
      eventTopics: FieldValue.arrayRemove(tag)
    });
  }

}
