import { Component, OnInit } from '@angular/core';
import {AngularFirestoreDocument} from '@angular/fire/firestore';
import {FCardMessage, FProduct} from '../../../../../../firebase/models/cardMessage';
import {NewPictureCard} from './card-picture/card-picture.component';
import {NewProductCard} from './card-product/card-product.component';
import {NewPlaceCard} from './card-place/card-place.component';
import {NewPeopleCard} from './card-people/card-people.component';
import {StorageService} from '../../../../../../shared/services/storage.service';
import {UploadImageDialogData} from '../../../../../../shared/ui/upload-image-dialog/upload-image-dialog.component';
import {FCoupon} from '../../../../../../firebase/models/coupon';
import {ShopService} from '../../../../../services/shop.service';
import {ActivatedRoute} from '@angular/router';
import {NotifyService} from '../../../../../../shared/services/notify.service';
import {RouteService} from '../../../../../../shared/services/route.service';
import {GetCardPeople} from './shop-card-message-people/shop-card-message-people.component';

@Component({
  selector: 'app-shop-card-message-edit-page',
  templateUrl: './shop-card-message-edit-page.component.html',
  styleUrls: ['./shop-card-message-edit-page.component.styl']
})
export class ShopCardMessageEditPageComponent implements OnInit {

  cardMessage: FCardMessage = {cardType: 'select' , closePage: null, createTime: new Date(), pages: [], title: ''};
  cardMessageDoc: AngularFirestoreDocument<FCardMessage>;
  create = false;
  pages = [];
  pageIndex = 0;

  constructor(private image: StorageService,
              private ss: ShopService,
              private activatedRoute: ActivatedRoute,
              private notify: NotifyService,
              private rs: RouteService) { }

  ngOnInit(): void {
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    if (id === 'Create') {
      this.create = true;
    } else  {
      this.create = false;
      this.cardMessageDoc = this.ss.currentShopDoc.collection<FCardMessage>('cardBases').doc(id);
      this.cardMessageDoc.get().subscribe(cardMessage => {
        if (cardMessage.exists) {
          // @ts-ignore
          this.cardMessage = cardMessage.data();
          this.pages = Object.assign([], this.cardMessage.pages);
        } else {
          this.notify.pop('多頁訊息不存在');
          this.rs.navigateTo('Home');
        }
      });
    }
  }

  save() {
    console.log(this.cardMessage);
    this.ss.currentShopDoc.collection<FCardMessage>('cardBases').add(this.cardMessage).then(() => {
      this.rs.navigateTo('/Home/CardMessage');
    })
  }
  delete() {}
  openClosePageUploadImageDialog() {
    const info: UploadImageDialogData = {height: 640, type: 'image', width: 640};
    this.image.openUploadDialog(info).afterClosed().subscribe(result => {
      if (result) {
        this.cardMessage.closePage.image = result;
      }
    });
  }

  cardTypeChanged() {
    switch (this.cardMessage.cardType) {
      case 'people':
        this.pages = [GetCardPeople()];

    }
    // @ts-ignore
    this.cardMessage.pages = [null];
  }
  addPage() {
    // @ts-ignore
    this.cardMessage.pages.push(null);
    this.pages.push(null);
  }
  closePageChecked(a) {
    if (a) {
      this.cardMessage.closePage = {action: undefined, image: '', type: 0};
    }
  }
  log(a) {
    console.log(a);
  }
}
