<div class="d-flex justify-content-between align-items-center mb-2">
	<h2 class="mb-0">以點數付款</h2>
</div>
<hr>

<div class="form-group row w-max-800">
	<label class="col-sm-2 col-form-label text-sm-right font-weight-light">持有點數</label>
	<div class="col-sm-9 form-inline">
		<span class="text-primary">{{point}}</span>
	</div>
</div>

<div class="d-flex justify-content-between align-items-center mb-2">
	<h3 class="mb-0">廣告</h3>
</div>
<hr>
<table class="table table-hover table-layout-fixed">
	<thead class="thead-light">
	<tr>
		<th class="">
      <span class="">
        購買日期
      </span>
		</th>
		<th class="">
      <span class=""><!---->
        刊登服務
      </span>
		</th>
		<th class="">
      <span class=""><!---->
        刊登狀態
      </span>
		</th>
		<th>
			付款狀態
		</th>
		<th>
			價格
		</th>
		<th class="">
      <span class=""><!---->
        加入好友人數
      </span>
		</th>
		<th class="">
      <span class=""><!---->
        付款
      </span>
		</th>
	</tr>
	</thead>
	<tbody>
	<tr *ngFor="let ad of adCurrentPage" class="">
		<td>
      <span class=""><!---->
	      {{ad.payload.doc.data().createTime.toDate().toLocaleString()}}
      </span>
		</td>
		<td>
      <span class=""><!---->
	      {{getPlanString(ad.payload.doc.data()) + ad.payload.doc.data().days + '天'}}
      </span>
			<span *ngIf="ad.payload.doc.data().agent" style="color: #1e7e34">
        (代理商贈刊）
      </span>
		</td>
		<td>
      <span class=""><!---->
	      {{getPlanStatus(ad.payload.doc.data())}}
      </span>
		</td>
		<td>
      <span class=""><!---->
	      {{ad.payload.doc.data().pay ? '已付款' : '未付款'}}
      </span>
		</td>
		<td>
      <span class="text-primary"><!---->
	      {{ad.payload.doc.data().totalFee}}
      </span>
		</td>
		<td>
      <span class=""><!---->
	      {{ad.payload.doc.data().follower}}
      </span>
		</td>
		<td>
      <span *ngIf="!ad.payload.doc.data().pay" class="text-primary"><!---->
	      <a href="javascript:void(0)" (click)="payAd(ad)">付款</a>
      </span>
		</td>
	</tr>
	</tbody>
</table>
<app-list-paginator [data]="ads" (listChanged)="adCurrentPage = $event"></app-list-paginator>

<hr>
<div class="d-flex justify-content-between align-items-center mb-2">
	<h3 class="mb-0">月費</h3>
</div>
<hr>

<table class="table table-hover table-layout-fixed">
	<thead class="thead-light">
	<tr>
		<th class="">
      <span class="">
        計費期間
      </span>
		</th>
		<th class="">
      <span class=""><!---->
        月費方案
      </span>
		</th>
		<th class="">
      <span class=""><!---->
        月費金額
      </span>
		</th>
		<th class="">
      <span ><!---->
        上期<br>加購則數
      </span>
		</th>
		<th class="">
      <span ><!---->
        上期<br>加購金額
      </span>
		</th>
		<th class="">
      <span ><!---->
        本期<br>應付總額
      </span>
		</th>
		<th class="">
      <span ><!---->
        稅額5%
      </span>
		</th>
		<th class="">
      <span ><!---->
        本期總額
      </span>
		</th>
		<th class="">
      <span ><!---->
        付款狀態
      </span>
		</th>
		<th class="">
      <span class=""><!---->
        付款
      </span>
		</th>
	</tr>
	</thead>
	<tbody>
	<tr *ngFor="let bill of billCurrentPage" class="">
		<td>
      <span class=""><!---->
	      {{bill.payload.doc.data().date}}
      </span>
		</td>
		<td>
      <span class=""><!---->
	      {{getPlanName(bill.payload.doc.data().status)}}
      </span>
		</td>
		<td>
      <span class=""><!---->
	      {{bill.payload.doc.data().monthlyFee}}
      </span>
		</td>
		<td>
      <span class=""><!---->
	      {{bill.payload.doc.data().extraMessages}}
      </span>
		</td>
		<td>
      <span class=""><!---->
	      {{bill.payload.doc.data().extraFee}}
      </span>
		</td>
		<td>
      <span class=""><!---->
	      {{bill.payload.doc.data().amount}}
      </span>
		<td>
      <span class=""><!---->
	      {{bill.payload.doc.data().taxFee}}
      </span>
		</td>
		<td>
      <span class=""><!---->
	      {{bill.payload.doc.data().totalFee}}
      </span>
		</td>
		<td>
			<span *ngIf="bill.payload.doc.data().payStatus">已付款</span>
			<a *ngIf="!bill.payload.doc.data().payStatus" [routerLink]="'/Billing/PaymentAccount/' + bill.payload.doc.id" class=""><!---->
				未付款
			</a>
		</td>
		<td>
      <span *ngIf="!bill.payload.doc.data().payStatus" class="text-primary"><!---->
	      <a href="javascript:void(0)" (click)="payBill(bill)">付款</a>
      </span>
		</td>
	</tr>
	</tbody>
</table>
<hr>
<app-list-paginator [data]="bills" (listChanged)="billCurrentPage = $event"></app-list-paginator>
