import { Component, OnInit } from '@angular/core';
import {FRewardCode} from '../../../../../../firebase/models/rewardCard';
import {ShopService} from '../../../../../services/shop.service';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import {RouteService} from '../../../../../../shared/services/route.service';

@Component({
  selector: 'app-shop-reward-code-edit-page',
  templateUrl: './shop-reward-code-edit-page.component.html',
  styleUrls: ['./shop-reward-code-edit-page.component.styl']
})
export class ShopRewardCodeEditPageComponent implements OnInit {

  faCalendar = faCalendar;
  minDate = new Date();
  rewardCode: FRewardCode = {createTime: new Date(), endTime: new Date(), point: 1, rewardCodeImgUrl: '', title: ''};
  constructor(private ss: ShopService,
              private rs: RouteService) { }

  ngOnInit(): void {
  }
  save() {
    this.ss.currentShopDoc.collection('rewardCodes').add(this.rewardCode).then(() => {
      this.rs.navigateTo('/Home/RewardCode');
    });
  }

}
