import { Component, OnInit } from '@angular/core';
import {DocumentChangeAction} from '@angular/fire/firestore';
import {ShopService} from '../../services/shop.service';
import {MatDialogRef} from '@angular/material/dialog';
import {FCourse} from '../../../firebase/models/course';

@Component({
  selector: 'app-event-picker-dialog',
  templateUrl: './event-picker-dialog.component.html',
  styleUrls: ['./event-picker-dialog.component.styl']
})
export class EventPickerDialogComponent implements OnInit {

  events: DocumentChangeAction<FCourse>[] = [];
  now = new Date();
  constructor(public ss: ShopService,
              public dialogRef: MatDialogRef<EventPickerDialogComponent>) { }

  ngOnInit() {
    this.ss.currentShopDoc.collection<FCourse>('events').snapshotChanges().subscribe(q => {
      this.events = q;
    });
  }
  onSelect(qnr: DocumentChangeAction<any>) {
    this.dialogRef.close(qnr);
  }

}
