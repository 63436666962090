import { Component, OnInit } from '@angular/core';
import {ShopService} from '../../../../../services/shop.service';
import {NotifyService} from '../../../../../../shared/services/notify.service';
import {ActivatedRoute} from '@angular/router';
import {RouteService} from '../../../../../../shared/services/route.service';
import {FRewardAnalysis} from '../../../../../../firebase/models/rewardCard';

@Component({
  selector: 'app-shop-reward-card-analysis-page',
  templateUrl: './shop-reward-card-analysis-page.component.html',
  styleUrls: ['./shop-reward-card-analysis-page.component.styl']
})
export class ShopRewardCardAnalysisPageComponent implements OnInit {

  recordsSource: FRewardAnalysis[] = [];

  records: FRewardAnalysis[] = [];

  constructor(private ss: ShopService,
              private notify: NotifyService,
              private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    this.ss.currentShopDoc.collection('rewardCards').doc(id).collection<FRewardAnalysis>('analysis')
        .valueChanges().subscribe(records => {
      this.recordsSource = records;
    });

  }

  getTypeString(type: number) {
    switch (type) {
      case 0:
        return '首次取得';
      case 1:
        return 'QRCode 領取';
      case 2:
        return '使用';
      default:
        return '';
    }
  }
}

