import {Component, OnInit} from '@angular/core';
import {getTypeName} from '../../../../../admin/pages/admin-root-page/admin-extra-features/admin-extra-features-price-page/admin-extra-features-price-page.component';
import {AngularFirestore} from '@angular/fire/firestore';
import {NotifyService} from '../../../../../shared/services/notify.service';
import {PaymentService} from '../../../../services/payment.service';
import {FOrder} from '../../../../../firebase/models/order';
import {ShopService} from '../../../../services/shop.service';
import {FExtraServicePlan, FPlan} from '../../../../../firebase/models/plan';
import {DocumentChangeAction} from '@angular/fire/firestore/interfaces';
import {AngularFireFunctions} from '@angular/fire/functions';
import {RouteService} from '../../../../../shared/services/route.service';
import {OrderExtraServiceReq} from '../../../../../../../functions/src/order/order';
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;
import * as firebase from 'firebase';

@Component({
  selector: 'app-shop-billing-extra-features-page',
  templateUrl: './shop-billing-extra-features-page.component.html',
  styleUrls: ['./shop-billing-extra-features-page.component.styl']
})
export class ShopBillingExtraFeaturesPageComponent implements OnInit {

  loading = false;
  getTypeName = getTypeName;
  plans: DocumentChangeAction<FExtraServicePlan>[] = [];
  constructor(private db: AngularFirestore,
              private notify: NotifyService,
              private payment: PaymentService,
              public ss: ShopService,
              private func: AngularFireFunctions,
              private rs: RouteService) { }

  ngOnInit(): void {
    this.db.collection<FExtraServicePlan>('plans', ref =>
        ref.where('type', '==' , 'extra').orderBy('feature') )
        .snapshotChanges().subscribe(plans => {
      this.plans = plans;
    });
  }
  buyPlan(plan: DocumentChangeAction<FExtraServicePlan>) {
    if (this.ss.chargePlan.permission[plan.payload.doc.data().feature]) {
      this.notify.pop('該服務尚未到期');
      return;
    }
    const order: OrderExtraServiceReq = {
      planPath: plan.payload.doc.ref.path,
      shopId: this.ss.currentShop.uid
    };
    console.log(order);
    this.loading = true;
    this.func.httpsCallable('OrderPlan')(order).subscribe(async res => {
      if (res) {
        console.log(res);
        this.db.collection('planOrders').doc<FOrder>(res).get().subscribe(x => {
          this.loading = false;
          this.payment.payWithCreditCard(x as DocumentSnapshot<FOrder>);
        });
      } else {
        this.loading = false;

        this.notify.pop('發生錯誤，請聯絡客服');
      }
    });
  }
}
