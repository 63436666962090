import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ShopService} from '../../services/shop.service';

@Component({
  selector: 'app-shop-root-page',
  templateUrl: './shop-root-page.component.html',
  styleUrls: ['./shop-root-page.component.styl']
})
export class ShopRootPageComponent implements OnInit {
  constructor(private route: ActivatedRoute,
              public ss: ShopService,
              private router: Router) { }

  ngOnInit(): void {
    this.setShop();
  }
  setShop() {
    this.ss.loaded.subscribe(loaded => {
      if (loaded) {
        const filter = this.ss.shops.filter(x => x.lineAt === this.route.snapshot.paramMap.get('id'));
        if (filter.length === 0) {
          this.router.navigate(['/Login']);
        } else {
          this.ss.selectShop(filter[0]);
        }
      }
    });
  }

}
