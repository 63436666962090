<div class="d-flex justify-content-between align-items-center mb-2"><h2 class="mb-0">建立服務</h2>
</div>
<hr>

<form #form="ngForm">
  <div class="form-group row w-max-800">
    <label class="col-sm-3 col-form-label text-sm-right font-weight-light">啟用此服務</label>
    <div class="col-sm-9 form-inline">
      <mat-checkbox [(ngModel)]="service.enabled" name="enable"></mat-checkbox>
    </div>

  </div>
  <div class="form-group row w-max-800">
    <label class="col-sm-3 col-form-label text-sm-right font-weight-light">服務提供者</label>
    <div class="col-sm-9 form-inline">
      <input [(ngModel)]="service.name" style="width: 320px !important;" required maxlength="30" name="name" placeholder="請輸入服務提供者" autocomplete="off" class="w-fix-360 form-control">
      <span class="ml-1"><strong class="text-muted">{{service.name.length}}</strong><span class="">/30</span></span>
    </div>
  </div>

  <div class="form-group row w-max-800">
    <label class="col-sm-3 col-form-label text-sm-right font-weight-light">圖片</label>
    <div class="col-sm-9">
      <div class="form-inline position-relative">
        <div>
          <div  [ngClass]="{'border-danger' : img.invalid}" *ngIf="service.photoUrl === ''" class="figure figure-grid cursor-pointer border position-relative mr-2">
            <a (click)="openImgUploadDialog()" href="javascript:void(0)">上傳圖片</a>
          </div>
          <div *ngIf="service.photoUrl !== ''" class="figure figure-grid cursor-pointer border position-relative mr-2">
            <img [src]="service.photoUrl" class="figure-img">
            <button (click)="service.photoUrl = ''" type="button" class="position-absolute top-right close x"><fa-icon [icon]="icon.faWindowClose"></fa-icon></button>
          </div>
        </div>
        <input #img=ngModel class="d-none" name="img" [(ngModel)]="service.photoUrl" required>
      </div>
    </div>
  </div>

  <div class="form-group row w-max-800">
    <label class="col-sm-3 col-form-label text-sm-right font-weight-light">開放預約時間</label>
    <div class="col-sm-9">
      <div class="d-flex mb-2">
        <label class="col-sm-2 col-form-label text-sm-right font-weight-light">日期</label>
        <div class="form-inline text-muted">
          <input class="form-control w-80 mr-2 " style="width: 100px !important;" required  name="eventStartTime" [(ngModel)]="service.schedule.startDate"    [owlDateTime]="eventStartDate"  [owlDateTimeTrigger]="eventStartDate"  autocomplete="off">
          <span [owlDateTimeTrigger]="eventStartDate">
					    <fa-icon class="text-muted" [icon]="icon.faCalendarAlt"></fa-icon>
				    </span>
          <owl-date-time [pickerType]="'calendar'" #eventStartDate></owl-date-time>
          ~
          <div class="form-inline text-muted">
            <input class="form-control w-80 mr-2 " style="width: 100px !important;" required name="eventEndTime" [(ngModel)]="service.schedule.endDate"    [owlDateTime]="eventEndDate" [min]="minDate" [owlDateTimeTrigger]="eventEndDate"  autocomplete="off">
            <span [owlDateTimeTrigger]="eventEndDate">
					    <fa-icon class="text-muted" [icon]="icon.faCalendarAlt"></fa-icon>
				    </span>
            <owl-date-time [pickerType]="'calendar'" #eventEndDate></owl-date-time>
          </div>
        </div>
      </div>
      <div class="d-flex mb-2">
        <label class="col-sm-2 col-form-label text-sm-right font-weight-light">星期</label>
        <mat-checkbox class="form-control mr-2" [(ngModel)]="service.schedule.weekday.MON" name="MON">星期一</mat-checkbox>
        <mat-checkbox class="form-control mr-2" [(ngModel)]="service.schedule.weekday.TUE" name="THU">星期二</mat-checkbox>
        <mat-checkbox class="form-control mr-2" [(ngModel)]="service.schedule.weekday.WED" name="WED">星期三</mat-checkbox>
        <mat-checkbox class="form-control mr-2" [(ngModel)]="service.schedule.weekday.THU" name="THU">星期四</mat-checkbox>
        <mat-checkbox class="form-control mr-2" [(ngModel)]="service.schedule.weekday.FRI" name="FRI">星期五</mat-checkbox>
        <mat-checkbox class="form-control mr-2" [(ngModel)]="service.schedule.weekday.SAT" name="SAT">星期六</mat-checkbox>
        <mat-checkbox class="form-control mr-2" [(ngModel)]="service.schedule.weekday.SUN" name="SUN">星期日</mat-checkbox>
      </div>
      <div class="d-flex  mb-2">
        <label class="col-sm-2 col-form-label text-sm-right font-weight-light">時段</label>
        <div class="form-inline">
          <div  class="d-flex  mb-2" *ngFor="let time of service.schedule.time index as i">
            <div class="form-inline text-muted">
              <input class="form-control w-80 mr-2 "  style="width: 100px !important;" required  [name]="'timeS' + i" [(ngModel)]="time.startTime"    [owlDateTime]="eventStartTime"  [owlDateTimeTrigger]="eventStartTime"  autocomplete="off">
              <span [owlDateTimeTrigger]="eventStartTime">
                <fa-icon class="text-muted" [icon]="icon.faCalendarAlt"></fa-icon>
              </span>
              <owl-date-time stepMinute="15" [pickerType]="'timer'" #eventStartTime></owl-date-time>
            </div>
            ~
            <div class="form-inline text-muted">
              <input class="form-control w-80 mr-2" style="width: 100px !important;" required [name]="'timeE' + i" [(ngModel)]="time.endTime"    [owlDateTime]="eventEndTime" [owlDateTimeTrigger]="eventEndTime"  autocomplete="off">
              <span [owlDateTimeTrigger]="eventEndTime">
					      <fa-icon class="text-muted" [icon]="icon.faCalendarAlt"></fa-icon>
				      </span>
              <owl-date-time stepMinute="15"  [pickerType]="'timer'" #eventEndTime></owl-date-time>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex  mb-2">
        <label class="col-sm-2 col-form-label text-sm-right font-weight-light"></label>
        <button class="btn btn-outline-accent" (click)="addTime()">新增時段</button>
      </div>



      </div>
  </div>
  <div class="form-group row w-fix-800">
    <label class="col-sm-3 col-form-label text-sm-right font-weight-light">問卷
    </label>
    <div class="col-sm-9">
      <div class="card-body position-relative overflow-auto" style="padding: 0px !important;">
        <div *ngIf="!service.qnrRef" class="border text-center">
          <div  class="figure figure-grid-sm my-4">
            <a (click)="openQnrPicker()" href="javascript:void(0)">選擇問卷調查</a>
            <div class="text-muted opacity-30">
              <fa-icon [icon]="icon.faClipboard" size="3x"></fa-icon>
            </div>
          </div>
        </div>
        <div class="position-relative border" *ngIf="service.qnrRef">
          <button (click)="!service.qnrRef" type="button" class="position-absolute close" style=" top: 0px; right: 0px;">
            <fa-icon [icon]="icon.faWindowClose"></fa-icon>
          </button>
          <div class="d-flex align-items-center">
            <div class="flex-none figure figure-grid figure-grid-sm">
              <img [src]="service.qnrData.photoUrl" class="figure-img figure-img">
            </div>
            <div class="flex-1 text-truncate p-3">
              <div class="text-truncate">
                <span class="text-dark">{{service.qnrData.title}}</span>
              </div>
              <small class="text-muted">問卷調查的結束時間： {{$any(service.qnrData.endTime).toDate().toLocaleString()}}</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="form-group row w-max-800">
    <label class="col-sm-3 col-form-label text-sm-right font-weight-light">簡介</label>
    <div class="col-sm-9 form-inline">
      <textarea [(ngModel)]="service.desc" style="width: 320px !important;" ROWS="5" required name="desc" placeholder="請輸入簡介" autocomplete="off" class="w-fix-360 form-control">
      </textarea>
    </div>
  </div>



  <div class="form-group row w-max-800">
    <label class="col-sm-3 col-form-label text-sm-right font-weight-light">服務項目</label>
    <div class="col-sm-9 form-inline">
      <div *ngFor="let item of service.services; index as i" class="p-2 border mb-2">

        <div class="form-group row w-max-800 mb-2">
          <label class="col-sm-3 col-form-label text-sm-right font-weight-light">名稱</label>
          <div class="col-sm-9 form-inline">
            <input [(ngModel)]="item.name" style="width: 320px !important;" required maxlength="30" [name]="'name' + i" placeholder="請輸入服務名稱" autocomplete="off" class="w-fix-360 form-control">
          </div>
        </div>

        <div class="form-group row w-max-800 mb-2">
          <label class="col-sm-3 col-form-label text-sm-right font-weight-light">價格</label>
          <div class="col-sm-9 form-inline">
            <input [(ngModel)]="item.price" style="width: 320px !important;" type="number"  maxlength="30" [name]="'price' + i" placeholder="可不填 現場收取" autocomplete="off" class="w-fix-360 form-control">
          </div>
        </div>

        <div class="form-group row w-max-800 mb-2">
          <label class="col-sm-3 col-form-label text-sm-right font-weight-light">分鐘數</label>
          <div class="col-sm-9 form-inline">
            <select [(ngModel)]="item.period" style="width: 320px !important;" required  maxlength="30" [name]="'period' + i"  autocomplete="off" class="w-fix-360 form-control">
              <option [ngValue]="15">15</option>
              <option [ngValue]="30">30</option>
              <option [ngValue]="45">45</option>
              <option [ngValue]="60">60</option>
              <option [ngValue]="75">75</option>
              <option [ngValue]="90">90</option>
              <option [ngValue]="120">120</option>
              <option [ngValue]="1500">150</option>
            </select>
          </div>
        </div>

      </div>
    </div>
  </div>
  <div class="d-flex justify-content-center">
    <button class="btn btn-outline-accent" (click)="addService()">新增服務項目</button>
  </div>

  <hr>
  <div class="d-flex justify-content-center p-4">
    <div class="p-4">
      <button (click)="save()" [disabled]="form.invalid" type="button" class="px-5 btn btn-primary btn-undefined">儲存</button>
    </div>
  </div>
</form>
