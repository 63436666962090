import { Component, OnInit } from '@angular/core';
import {ShopService} from '../../../../services/shop.service';
import {FCourse} from '../../../../../firebase/models/course';
import {DocumentChangeAction} from '@angular/fire/firestore';

@Component({
  selector: 'app-shop-course-list-page',
  templateUrl: './shop-course-list-page.component.html',
  styleUrls: ['./shop-course-list-page.component.styl']
})
export class ShopCourseListPageComponent implements OnInit {

  courses: DocumentChangeAction<FCourse>[] = [];
  currentPage: DocumentChangeAction<FCourse>[] = [];
  constructor(private ss: ShopService) { }

  ngOnInit(): void {
    this.ss.currentShopDoc.collection<FCourse>('courses').snapshotChanges().subscribe(cs => {
      this.courses = cs;
    });
  }

}
