import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {ShopRootPageComponent} from '../shop/pages/shop-root-page/shop-root-page.component';
import {AuthGuard} from '../shared/guards/auth.guard';
import {AdminRootPageComponent} from './pages/admin-root-page/admin-root-page.component';
import {AdminGuard} from '../shared/guards/admin.guard';
import {ShopCouponEditComponent} from '../shop/pages/shop-root-page/shop-home/shop-coupon-list/shop-coupon-edit/shop-coupon-edit.component';
import {AdminShopListPageComponent} from './pages/admin-root-page/admin-shop/admin-shop-list-page/admin-shop-list-page.component';
import {AdminShopComponent} from './pages/admin-root-page/admin-shop/admin-shop.component';
import {AdminShopCategoryListPageComponent} from './pages/admin-root-page/admin-shop/admin-shop-category-list-page/admin-shop-category-list-page.component';
import {AdminUiComponent} from './pages/admin-root-page/admin-ui/admin-ui.component';
import {AdminUiPlanPageComponent} from './pages/admin-root-page/admin-ui/admin-ui-plan-page/admin-ui-plan-page.component';
import {AdminUiHomePageComponent} from './pages/admin-root-page/admin-ui/admin-ui-home-page/admin-ui-home-page.component';
import {AdminUiPaymentPageComponent} from './pages/admin-root-page/admin-ui/admin-ui-payment-page/admin-ui-payment-page.component';
import {AdminNewsTagPageComponent} from './pages/admin-root-page/admin-news/admin-news-tag-page/admin-news-tag-page.component';
import {AdminNewsComponent} from './pages/admin-root-page/admin-news/admin-news.component';
import {NewsReviewListPageComponent} from '../shared/pages/news-review-list-page/news-review-list-page.component';
import {AdminShopMeetGiftListPageComponent} from './pages/admin-root-page/admin-shop/admin-shop-meet-gift-list-page/admin-shop-meet-gift-list-page.component';
import {AdminAgentComponent} from './pages/admin-root-page/admin-agent/admin-agent.component';
import {AdminAgentListPageComponent} from './pages/admin-root-page/admin-agent/admin-agent-list-page/admin-agent-list-page.component';
import {AdminAccountsComponent} from './pages/admin-root-page/admin-accounts/admin-accounts.component';
import {AdminAccountsMothlyIncomeListPageComponent} from './pages/admin-root-page/admin-accounts/admin-accounts-mothly-income-list-page/admin-accounts-mothly-income-list-page.component';
import {AdminAccountsMothlyIncomePageComponent} from './pages/admin-root-page/admin-accounts/admin-accounts-mothly-income-list-page/admin-accounts-mothly-income-page/admin-accounts-mothly-income-page.component';
import {AdminShopAuthListPageComponent} from './pages/admin-root-page/admin-shop/admin-shop-auth-list-page/admin-shop-auth-list-page.component';
import {NewsReviewEditPageComponent} from '../shared/pages/news-review-list-page/news-review-edit-page/news-review-edit-page.component';
import {NewsAnalysisPageComponent} from '../shared/pages/news-review-list-page/news-analysis-page/news-analysis-page.component';
import {AdminShopManagementPageComponent} from './pages/admin-root-page/admin-shop/admin-shop-list-page/admin-shop-management-page/admin-shop-management-page.component';
import {AdminAccountsPointCardPageComponent} from './pages/admin-root-page/admin-accounts/admin-accounts-point-card-page/admin-accounts-point-card-page.component';
import {AdminUiExtraServicePageComponent} from './pages/admin-root-page/admin-ui/admin-ui-extra-service-page/admin-ui-extra-service-page.component';
import {AdminOthersComponent} from './pages/admin-root-page/admin-others/admin-others.component';
import {AdminOthersCourseTagPageComponent} from './pages/admin-root-page/admin-others/admin-others-course-tag-page/admin-others-course-tag-page.component';
import {AdminOthersEventTagPageComponent} from './pages/admin-root-page/admin-others/admin-others-event-tag-page/admin-others-event-tag-page.component';
import {AdminExtraFeaturesComponent} from './pages/admin-root-page/admin-extra-features/admin-extra-features.component';
import {AdminExtraFeaturesPricePageComponent} from './pages/admin-root-page/admin-extra-features/admin-extra-features-price-page/admin-extra-features-price-page.component';
import {AdminAccountPlanSetttingPageComponent} from './pages/admin-root-page/admin-accounts/admin-account-plan-settting-page/admin-account-plan-settting-page.component';

const adminRoutes: Routes = [
  {
    // tslint:disable-next-line:max-line-length
    path: 'Admin', component: AdminRootPageComponent, canActivate: [AuthGuard, AdminGuard], canActivateChild: [AuthGuard, AdminGuard], children:
        [
          { path: '', redirectTo: 'Shop', pathMatch: 'full'},
          { path: 'Shop', component: AdminShopComponent, children: [
              { path: '', redirectTo: 'Shop', pathMatch: 'full'},
              { path: 'Shop', component: AdminShopListPageComponent},
              { path: 'Shop/:id', component: AdminShopManagementPageComponent},
              { path: 'Auth', component: AdminShopAuthListPageComponent},
              { path: 'Category', component: AdminShopCategoryListPageComponent},
              { path: 'MeetGift', component: AdminShopMeetGiftListPageComponent}
            ]
          },
          { path: 'UI', component: AdminUiComponent, children: [
              { path: '', redirectTo: 'Home', pathMatch: 'full'},
              { path: 'Plan', component: AdminUiPlanPageComponent},
              { path: 'Home', component: AdminUiHomePageComponent},
              { path: 'Payment', component: AdminUiPaymentPageComponent},
              { path: 'ExtraService', component: AdminUiExtraServicePageComponent},
            ]
          },
          { path: 'News', component: AdminNewsComponent, children: [
              { path: '', redirectTo: 'Review', pathMatch: 'full'},
              { path: 'Tag', component: AdminNewsTagPageComponent},
              { path: 'Review', component: NewsReviewListPageComponent},
              { path: 'Review/:id', component: NewsReviewEditPageComponent },
              { path: 'Review/Analysis/:id', component: NewsAnalysisPageComponent },
            ]
          },
          { path: 'Agent', component: AdminAgentComponent, children: [
              { path: '', redirectTo: 'Agents', pathMatch: 'full'},
              { path: 'Agents', component: AdminAgentListPageComponent},
            ]
          },
          { path: 'Accounts', component: AdminAccountsComponent, children: [
              { path: '', redirectTo: 'Revenue', pathMatch: 'full'},
              { path: 'PointCard', component: AdminAccountsPointCardPageComponent},
              { path: 'ExtraFeatures', component: AdminExtraFeaturesPricePageComponent},
              { path: 'Revenue', component: AdminAccountsMothlyIncomeListPageComponent},
              { path: 'ChargePlan', component: AdminAccountPlanSetttingPageComponent},
              { path: 'Revenue/:id', component: AdminAccountsMothlyIncomePageComponent},
            ]
          },
          { path: 'Others', component: AdminOthersComponent, children: [
              { path: '', redirectTo: 'CourseTopic', pathMatch: 'full'},
              { path: 'CourseTopic', component: AdminOthersCourseTagPageComponent},
              { path: 'EventTopic', component: AdminOthersEventTagPageComponent},
            ]
          },
          { path: 'ExtraFeatures', component: AdminExtraFeaturesComponent, children: [
              { path: '', redirectTo: 'Price', pathMatch: 'full'},
              { path: 'Price', component: AdminExtraFeaturesPricePageComponent},
            ]
          },
        ]
  }
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild(adminRoutes)
  ],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
