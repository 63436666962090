import { Component, OnInit } from '@angular/core';
import {AngularFirestore, DocumentChangeAction} from '@angular/fire/firestore';
import {FShop} from '../../../../../firebase/models/shop';
import {NotifyService} from '../../../../../shared/services/notify.service';
import {ModalService} from '../../../../../shared/services/modal.service';
import {Observable} from 'rxjs';
import {Action, DocumentSnapshot} from '@angular/fire/firestore/interfaces';
import {IconService} from '../../../../../shared/services/icon.service';
import FieldValue = firebase.firestore.FieldValue;
import * as firebase from 'firebase';

@Component({
  selector: 'app-admin-agent-list-page',
  templateUrl: './admin-agent-list-page.component.html',
  styleUrls: ['./admin-agent-list-page.component.styl']
})
export class AdminAgentListPageComponent implements OnInit {

  shops: FSHopWithPoint[] = [];
  currentPage: FSHopWithPoint[] = [];
  constructor(private db: AngularFirestore,
              private notify: NotifyService,
              private ms: ModalService,
              public icon: IconService,
              ) { }
  // this.db.collection('agentPoint').doc(s)
  ngOnInit(): void {
    this.db.collection<FShop>('shops', ref => ref.where('agent.enabled', '==', true))
        .snapshotChanges().subscribe(shops => {
          const agents: FSHopWithPoint[] = [];
          shops.forEach(shop => {
            agents.push({
              shop,
              point: this.db.collection('agentPoints').doc(shop.payload.doc.id).snapshotChanges()
            });
          });
          this.shops = agents;
    });
  }
  openPercentageDialog(shop: DocumentChangeAction<FShop>) {
    this.ms.openInputEditor('修改分潤比例').afterClosed().subscribe((result: string) => {
      if (result) {
        shop.payload.doc.ref.update({
          'agent.percentage': Number(result)
        }).then(() => {
          this.notify.pop('修改成功');
        });
      }
    });
  }
  givePoint(shop: DocumentChangeAction<FShop>) {
    this.ms.openInputEditor('點數加值').afterClosed().subscribe(point => {
      this.db.collection('agentPoints').doc(shop.payload.doc.id).set({
        point: FieldValue.increment(Number(point))
      }, {merge: true})
          .then(() => {
        this.notify.pop('加值成功');
      }).catch(() => {
        this.notify.pop('加值失敗');
      });
    });
  }


}
interface FSHopWithPoint {
  shop: DocumentChangeAction<FShop>;
  point?: Observable<Action<DocumentSnapshot<any>>>;
}
