import { Component, OnInit } from '@angular/core';
import {DocumentChangeAction} from '@angular/fire/firestore';
import {FQuestionnaire} from '../../../firebase/models/questionnaire';
import {ShopService} from '../../services/shop.service';
import {MatDialogRef} from '@angular/material/dialog';
import {FCourse} from '../../../firebase/models/course';

@Component({
  selector: 'app-course-picker-dialog',
  templateUrl: './course-picker-dialog.component.html',
  styleUrls: ['./course-picker-dialog.component.styl']
})
export class CoursePickerDialogComponent implements OnInit {

  courses: DocumentChangeAction<FCourse>[] = [];
  now = new Date();
  constructor(public ss: ShopService,
              public dialogRef: MatDialogRef<CoursePickerDialogComponent>) { }

  ngOnInit() {
    this.ss.currentShopDoc.collection<FCourse>('courses').snapshotChanges().subscribe(q => {
      this.courses = q;
    });
  }
  onSelect(qnr: DocumentChangeAction<any>) {
    this.dialogRef.close(qnr);
  }
}
