import { Component, OnInit } from '@angular/core';
import {FTag} from '../../../../../firebase/models/shop';
import {ShopService} from '../../../../services/shop.service';
import {Guid} from 'guid-typescript';


@Component({
  selector: 'app-shop-tag-list-page',
  templateUrl: './shop-tag-list-page.component.html',
  styleUrls: ['./shop-tag-list-page.component.styl']
})
export class ShopTagListPageComponent implements OnInit {

  currentPage: FTag[] = [];
  tags: FTag[] = [];
  name = '';
  desc = '';
  constructor(private ss: ShopService) { }

  ngOnInit(): void {
    this.ss.currentShopDoc.valueChanges().subscribe(shop => {
      this.tags = Object.values(shop.tags);
    });
  }
  OnAddTag() {

  }
  addTag() {
    const update: FTag = {
      id: Guid.create().toString(),
      follower: 0,
      name: this.name,
      desc: this.desc,
      timestamp: new Date()
    };
    this.ss.currentShopDoc.update({
      ['tags.' + update.id]: update
    }).then(() => {
      this.name = '';
      this.desc = '';
    });
  }

}
