import { Component, OnInit } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {FPointCard} from '../../../../../firebase/models/pointCard';
import {DocumentChangeAction} from '@angular/fire/firestore/interfaces';
import {IconService} from '../../../../../shared/services/icon.service';

@Component({
  selector: 'app-admin-accounts-point-card-page',
  templateUrl: './admin-accounts-point-card-page.component.html',
  styleUrls: ['./admin-accounts-point-card-page.component.styl']
})
export class AdminAccountsPointCardPageComponent implements OnInit {
  point = 0;
  endTime = new Date();
  pointCards: DocumentChangeAction<FPointCard>[] = [];
  currentPage: DocumentChangeAction<FPointCard>[] = [];
  constructor(private db: AngularFirestore,
              public icon: IconService) { }

  ngOnInit(): void {
    this.db.collection<FPointCard>('pointCards').snapshotChanges().subscribe(x => {
      this.pointCards = x;
    });
  }
  generate() {
    this.db.collection<FPointCard>('pointCards').add({
      used: false,
      endTime: this.endTime, point: this.point, timestamp: new Date()
    });
  }

}
