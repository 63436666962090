<div class="modal-content h-100">
	<div class="modal-header flex-shrink-0">
		<h5 class="mb-0">選擇聊天室背景</h5>
		<button (click)="onNoClick()" class="close">×</button>
	</div>
	<div class="modal-body mh-100 overflow-hidden">
		<div class="row no-gutters flex-nowrap text-center mb-2">
			<div class="col">
				<div class="avatar avatar-sm avatar-initials rounded flex-shrink-0 flex-grow-0 cursor-pointer" style="background: rgb(127, 0, 25);"><span style="display: none;"><fa-icon [icon]="faCheck" class="text-white"></fa-icon></span></div>
			</div>
			<div class="col">
				<div class="avatar avatar-sm avatar-initials rounded flex-shrink-0 flex-grow-0 cursor-pointer" style="background: rgb(231, 28, 44);"><span style="display: none;"><fa-icon [icon]="faCheck" class="text-white"></fa-icon></span></div>
			</div>
			<div class="col">
				<div class="avatar avatar-sm avatar-initials rounded flex-shrink-0 flex-grow-0 cursor-pointer" style="background: rgb(250, 74, 77);"><span style="display: none;"><fa-icon [icon]="faCheck" class="text-white"></fa-icon></span></div>
			</div>
			<div class="col">
				<div class="avatar avatar-sm avatar-initials rounded flex-shrink-0 flex-grow-0 cursor-pointer" style="background: rgb(236, 95, 71);"><span style="display: none;"><fa-icon [icon]="faCheck" class="text-white"></fa-icon></span></div>
			</div>
			<div class="col">
				<div class="avatar avatar-sm avatar-initials rounded flex-shrink-0 flex-grow-0 cursor-pointer" style="background: rgb(233, 187, 50);"><span style="display: none;"><fa-icon [icon]="faCheck" class="text-white"></fa-icon></span></div>
			</div>
			<div class="col">
				<div class="avatar avatar-sm avatar-initials rounded flex-shrink-0 flex-grow-0 cursor-pointer" style="background: rgb(29, 197, 109);"><span style="display: none;"><fa-icon [icon]="faCheck" class="text-white"></fa-icon></span></div>
			</div>
			<div class="col">
				<div class="avatar avatar-sm avatar-initials rounded flex-shrink-0 flex-grow-0 cursor-pointer" style="background: rgb(0, 176, 139);"><span style="display: none;"><fa-icon [icon]="faCheck" class="text-white"></fa-icon></span></div>
			</div>
			<div class="col">
				<div class="avatar avatar-sm avatar-initials rounded flex-shrink-0 flex-grow-0 cursor-pointer" style="background: rgb(44, 183, 213);"><span style="display: none;"><fa-icon [icon]="faCheck" class="text-white"></fa-icon></span></div>
			</div>
			<div class="col">
				<div class="avatar avatar-sm avatar-initials rounded flex-shrink-0 flex-grow-0 cursor-pointer" style="background: rgb(22, 138, 206);"><span style="display: none;"><fa-icon [icon]="faCheck" class="text-white"></fa-icon></span></div>
			</div>
			<div class="col">
				<div class="avatar avatar-sm avatar-initials rounded flex-shrink-0 flex-grow-0 cursor-pointer" style="background: rgb(85, 114, 217);"><span style="display: none;"><fa-icon [icon]="faCheck" class="text-white"></fa-icon></span></div>
			</div>
		</div>
		<div class="row no-gutters flex-nowrap text-center mb-2">
			<div class="col">
				<div class="avatar avatar-sm avatar-initials rounded flex-shrink-0 flex-grow-0 cursor-pointer" style="background: rgb(58, 113, 192);"><span style="display: none;"><fa-icon [icon]="faCheck" class="text-white"></fa-icon></span></div>
			</div>
			<div class="col">
				<div class="avatar avatar-sm avatar-initials rounded flex-shrink-0 flex-grow-0 cursor-pointer" style="background: rgb(170, 82, 225);"><span style="display: none;"><fa-icon [icon]="faCheck" class="text-white"></fa-icon></span></div>
			</div>
			<div class="col">
				<div class="avatar avatar-sm avatar-initials rounded flex-shrink-0 flex-grow-0 cursor-pointer" style="background: rgb(143, 98, 208);"><span style="display: none;"><fa-icon [icon]="faCheck" class="text-white"></fa-icon></span></div>
			</div>
			<div class="col">
				<div class="avatar avatar-sm avatar-initials rounded flex-shrink-0 flex-grow-0 cursor-pointer" style="background: rgb(233, 90, 187);"><span style="display: none;"><fa-icon [icon]="faCheck" class="text-white"></fa-icon></span></div>
			</div>
			<div class="col">
				<div class="avatar avatar-sm avatar-initials rounded flex-shrink-0 flex-grow-0 cursor-pointer" style="background: rgb(236, 135, 204);"><span style="display: none;"><fa-icon [icon]="faCheck" class="text-white"></fa-icon></span></div>
			</div>
			<div class="col">
				<div class="avatar avatar-sm avatar-initials rounded flex-shrink-0 flex-grow-0 cursor-pointer" style="background: rgb(54, 67, 94);"><span style="display: none;"><fa-icon [icon]="faCheck" class="text-white"></fa-icon></span></div>
			</div>
			<div class="col">
				<div class="avatar avatar-sm avatar-initials rounded flex-shrink-0 flex-grow-0 cursor-pointer" style="background: rgb(103, 59, 76);"><span style="display: none;"><fa-icon [icon]="faCheck" class="text-white"></fa-icon></span></div>
			</div>
			<div class="col">
				<div class="avatar avatar-sm avatar-initials rounded flex-shrink-0 flex-grow-0 cursor-pointer" style="background: rgb(202, 150, 96);"><span style="display: none;"><fa-icon [icon]="faCheck" class="text-white"></fa-icon></span></div>
			</div>
			<div class="col">
				<div class="avatar avatar-sm avatar-initials rounded flex-shrink-0 flex-grow-0 cursor-pointer" style="background: rgb(134, 112, 89);"><span style="display: none;"><fa-icon [icon]="faCheck" class="text-white"></fa-icon></span></div>
			</div>
			<div class="col">
				<div class="avatar avatar-sm avatar-initials rounded flex-shrink-0 flex-grow-0 cursor-pointer" style="background: rgb(87, 69, 59);"><span style="display: none;"><fa-icon [icon]="faCheck" class="text-white"></fa-icon></span></div>
			</div>
		</div>
		<div class="row no-gutters flex-nowrap text-center mb-2">
			<div class="col">
				<div class="avatar avatar-sm avatar-initials rounded flex-shrink-0 flex-grow-0 cursor-pointer" style="background: rgb(51, 42, 38);"><span style="display: none;"><fa-icon [icon]="faCheck" class="text-white"></fa-icon></span></div>
			</div>
			<div class="col">
				<div class="avatar avatar-sm avatar-initials rounded flex-shrink-0 flex-grow-0 cursor-pointer" style="background: rgb(121, 156, 84);"><span style="display: none;"><fa-icon [icon]="faCheck" class="text-white"></fa-icon></span></div>
			</div>
			<div class="col">
				<div class="avatar avatar-sm avatar-initials rounded flex-shrink-0 flex-grow-0 cursor-pointer" style="background: rgb(0, 113, 64);"><span style="display: none;"><fa-icon [icon]="faCheck" class="text-white"></fa-icon></span></div>
			</div>
			<div class="col">
				<div class="avatar avatar-sm avatar-initials rounded flex-shrink-0 flex-grow-0 cursor-pointer" style="background: rgb(126, 108, 134);"><span style="display: none;"><fa-icon [icon]="faCheck" class="text-white"></fa-icon></span></div>
			</div>
			<div class="col">
				<div class="avatar avatar-sm avatar-initials rounded flex-shrink-0 flex-grow-0 cursor-pointer" style="background: rgb(135, 109, 120);"><span style="display: none;"><fa-icon [icon]="faCheck" class="text-white"></fa-icon></span></div>
			</div>
			<div class="col">
				<div class="avatar avatar-sm avatar-initials rounded flex-shrink-0 flex-grow-0 cursor-pointer" style="background: rgb(79, 105, 120);"><span style="display: none;"><fa-icon [icon]="faCheck" class="text-white"></fa-icon></span></div>
			</div>
			<div class="col">
				<div class="avatar avatar-sm avatar-initials rounded flex-shrink-0 flex-grow-0 cursor-pointer" style="background: rgb(71, 89, 100);"><span style="display: none;"><fa-icon [icon]="faCheck" class="text-white"></fa-icon></span></div>
			</div>
			<div class="col">
				<div class="avatar avatar-sm avatar-initials rounded flex-shrink-0 flex-grow-0 cursor-pointer" style="background: rgb(151, 151, 151);"><span style="display: none;"><fa-icon [icon]="faCheck" class="text-white"></fa-icon></span></div>
			</div>
			<div class="col">
				<div class="avatar avatar-sm avatar-initials rounded flex-shrink-0 flex-grow-0 cursor-pointer" style="background: rgb(76, 76, 76);"><span style="display: none;"><fa-icon [icon]="faCheck" class="text-white"></fa-icon></span></div>
			</div>
			<div class="col">
				<div class="avatar avatar-sm avatar-initials rounded flex-shrink-0 flex-grow-0 cursor-pointer" style="background: rgb(0, 0, 0);"><span style="display: none;"><fa-icon [icon]="faCheck" class="text-white"></fa-icon></span></div>
			</div>
		</div>
	</div>
	<div class="d-flex flex-row flex-shrink-0 modal-footer flex-row-reverse">
		<button type="button" [mat-dialog-close]="data" class="btn btn-primary">儲存</button>
		<!---->
	</div>
</div>
