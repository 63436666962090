<div class="d-flex justify-content-between align-items-center mb-2">
	<h2 class="mb-0">訊息一覽</h2>
	<button routerLink="Create" type="button" class="btn btn-primary w-min-120">建立</button>
</div>
<hr>

<mat-tab-group>
	<mat-tab label="已預約">
		<ng-template matTabContent>
			<div>
				<table class="table table-hover table-layout-fixed">
					<thead class="thead-light">
					<tr>
						<th class="">
              <span class="">
                內容
              </span>
						</th>
						<th class="">
              <span class="">
                對象
              </span>
						</th>
						<th class="">
              <span class="">
                傳送時間
              </span>
						</th>
						<th class="">
              <span class="cursor-pointer">
                編輯時間
               <i class="fas fas-sm ml-1 fa-sort text-muted"></i>
              </span>
						</th>
						<th class="">
              <span class="">
                動作
              </span>
            </th>
					</tr>
					</thead>
					<tbody>
					<tr class="" *ngFor="let schedule of scheduleCurrentPage">
						<td>
							<a class="d-flex align-items-center  text-dark">
								<div class="flex-shrink-0 figure figure-grid figure-grid-xs position-relative mr-2">
									<img [src]="schedule.payload.doc.data().messages[0].content.photoUrl" class="figure-img figure-img"
									     alt="">
								</div>
								<div class="w-max-280">
									<div class="text-truncate text-dark text-truncate-2">{{schedule.payload.doc.data().title}}</div>
								</div>
								<div class="flex-1"></div>
							</a>
						</td>
						<td>
							<div class="align-items-center">
								{{getTagString(schedule.payload.doc.data())}}
							</div>
						</td>
						<td>
							<div class="d-flex align-items-center">
								{{schedule.payload.doc.data().schedule.toDate().toLocaleString()}}
							</div>
						</td>
						<td>
							{{schedule.payload.doc.data().timestamp.toDate().toLocaleString()}}
						</td>
						<td>
							<div class="d-flex align-items-center">
								<button (click)="deleteSchedule(schedule)" type="button" class="btn btn-secondary ml-2">刪除</button>
								<button (click)="cancelSchedule(schedule)" type="button" class="btn btn-secondary ml-2">重新編輯</button>
							</div>
						</td>
					</tr>
					</tbody>
				</table>
				<section>
					<hr>
					<app-list-paginator [data]="schedules" (listChanged)="scheduleCurrentPage = ($event)"></app-list-paginator>
				</section>
			</div>
		</ng-template>

	</mat-tab>
	<mat-tab label="草搞">
		<ng-template matTabContent>
			<div>
				<table class="table table-hover table-layout-fixed">
					<colgroup>
						<col width="15%">
						<col width="13%">
						<col width="13%">
					</colgroup>
					<thead class="thead-light">
					<tr>
						<th class=""><span class="">
          內容
              </span></th>
						<th class=""><span class="">
          對象
              </span></th>
						<th class=""><span class="cursor-pointer">
          編輯時間
         <i class="fas fas-sm ml-1 fa-sort text-muted"></i></span></th>
					</tr>
					</thead>
					<tbody>
					<tr class="" *ngFor="let draft of draftCurrentPage">
						<td>
							<a [routerLink]="draft.payload.doc.id"
							   class="d-flex align-items-center hljs-link text-dark">
								<div class="flex-shrink-0 figure figure-grid figure-grid-xs position-relative mr-2">
									<img [src]="draft.payload.doc.data().messages[0].content.photoUrl" class="figure-img figure-img"
									     alt="">
								</div>
								<div class="w-max-280">
									<div class="text-truncate text-info hljs-link"></div>
									<div
											class="text-truncate text-dark hljs-link text-truncate-2">{{draft.payload.doc.data().title}}</div>
								</div>
								<div class="flex-1"></div>
							</a>
						</td>
						<td>
							{{getTagString(draft.payload.doc.data())}}
						</td>
						<td>
							{{draft.payload.doc.data().timestamp.toDate().toLocaleString()}}
						</td>
					</tr>
					</tbody>
				</table>
				<section>
					<hr>
					<app-list-paginator *ngIf="drafts.length > 0" [data]="drafts"
					                (listChanged)="draftCurrentPage = ($event)"></app-list-paginator>
				</section>
			</div>
		</ng-template>
	</mat-tab>
	<mat-tab label="已傳送">
		<table class="table table-hover table-layout-fixed">
			<colgroup>
				<col width="15%">
				<col width="13%">
				<col width="13%">
			</colgroup>
			<thead class="thead-light">
			<tr>
				<th class=""><span class="">
          內容
          </span></th>
				<th class=""><span class="">
          對象
          </span></th>
				<th class=""><span class="cursor-pointer">
          編輯時間
         <i class="fas fas-sm ml-1 fa-sort text-muted"></i></span></th>
			</tr>
			</thead>
			<tbody>
			<tr class="" *ngFor="let messages of messageCurrentPage">
				<td>
					<a class="d-flex align-items-center hljs-link text-dark">
						<div class="flex-shrink-0 figure figure-grid figure-grid-xs position-relative mr-2"><img
								[src]="messages.payload.doc.data().content.photoUrl" class="figure-img figure-img" alt="">
						</div>
						<div class="w-max-280">
							<div class="text-truncate text-info hljs-link"></div>
							<div class="text-truncate text-dark hljs-link text-truncate-2">{{messages.payload.doc.data().title}}</div>
						</div>
						<div class="flex-1"></div>
					</a>
				</td>
				<td>
					{{getTagString(messages.payload.doc.data())}}
				</td>
				<td>
					{{messages.payload.doc.data().timestamp.toDate().toLocaleString()}}
				</td>
			</tr>
			</tbody>
		</table>
		<section>
			<hr>
			<app-list-paginator *ngIf="messages.length > 0" [data]="messages"
			                (listChanged)="messageCurrentPage = ($event)"></app-list-paginator>
		</section>
	</mat-tab>

</mat-tab-group>

