import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../../firebase/auth.service';
import {ShopService} from '../../services/shop.service';

@Component({
  selector: 'app-shops-list-page',
  templateUrl: './shops-list-page.component.html',
  styleUrls: ['./shops-list-page.component.styl']
})
export class ShopsListPageComponent implements OnInit {

  constructor(public shopService: ShopService) { }

  ngOnInit(): void {
  }

  permissionToSting(permission: number) {
    switch (permission) {
      case 0:
        return '管理員';
      case 1:
        return '行銷專員';
      case 2:
        return '客服專員';
      default :
        return '';
    }
  }

}
