<div>
	<div class="d-flex justify-content-between align-items-center mb-2">
		<h2 class="mb-0">自動回應訊息</h2> <a routerLink="Create" class="btn btn-primary w-min-120">建立
	</a></div>
	<p class="text-muted">若在此事先建立回應內容，當用戶傳訊息給您且符合設定條件時，系統將會自動回傳訊息。</p>
	<hr>
	<table class="table table-hover table-layout-fixed" style="">
		<thead class="thead-light">
		<tr>
			<th class="">
				<span class="">
        標題
				</span>
			</th>
			<th class="">
				<span class="">
        關鍵字
				</span>
			</th>
			<th class="">
				<span class="">
        指定日期或時間
				</span>
			</th>
			<th class="">
				<span class="cursor-pointer">
          啟用
          <i class="fas fas-sm ml-1 fa-sort text-muted"></i>
				</span>
			</th>
		</tr>
		</thead>
		<tbody class="table-hover">
		<tr class="" *ngFor="let message of docs">
			<td>
				<a [routerLink]="message.payload.doc.id" class="hljs-link text-dark">
					{{message.payload.doc.data().title}}
				</a>
			</td>
			<td>
				<div class="d-flex align-items-center">
					<div class="flex-shrink-0 small text-muted ml-1">
						({{message.payload.doc.data().keywords.length}})
					</div>
				</div>
			</td>
			<td>
				<div class="d-flex align-items-center">
					<span class="text-muted">
						{{message.payload.doc.data().schedule ? '排程' : '永遠'}}
					</span>
				</div>
			</td>
			<td>
				<div>
					<mat-checkbox [checked] = message.payload.doc.data().enabled (change)="toggleEnabled($event, message.payload.doc.id)"></mat-checkbox>
				</div>
			</td>
		</tr>
		</tbody>
	</table>
	<hr>
</div>
