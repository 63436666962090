<div>
	<div>
		<div class="d-flex justify-content-between align-items-center mb-2">
			<h2 class="mb-0">多頁訊息</h2>
			<div>
				<button routerLink="Create" type="button" class="w-min-120 btn btn-primary">建立</button>
			</div>
		</div>
		<div class="text-muted text-pre-line">您可將多項要宣傳的同類型內容集結成一則「多頁訊息」並傳送。 多頁訊息將以橫向捲軸的格式呈現，用戶可左右滑動來瀏覽其他頁面的內容。
		</div>
		<hr>
	</div>
	<section>
<!--		<div class="d-flex justify-content-between align-items-center my-4">-->
<!--			<div class="form-inline">-->
<!--				<div class="input-group ml-2">-->
<!--					<div class="kv-input form-group d-flex align-items-end" aria-required="false" aria-invalid="false">-->
<!--						&lt;!&ndash;&ndash;&gt;-->
<!--						<div class="flex-grow-1">-->
<!--							<div class="input-group">-->
<!--								&lt;!&ndash;&ndash;&gt;-->
<!--								<input id="l181m5jvac7" type="text" name="searchBox" placeholder="輸入標題" class="form-control form-control-rg">-->
<!--								&lt;!&ndash;&ndash;&gt;-->
<!--							</div>-->
<!--							&lt;!&ndash;&ndash;&gt;-->
<!--						</div>-->
<!--					</div>-->
<!--				</div>-->
<!--				<div>-->
<!--					<button type="button" class="ml-2 btn btn-primary"><i class="fas fa-search text-white"></i></button>-->
<!--				</div>-->
<!--				<button type="button" class="ml-2 btn btn-secondary">清除</button>-->
<!--			</div>-->
<!--		</div>-->
		<section style="">
			<table class="table table-hover table-layout-fixed">

				<thead class="thead-light">
				<tr>
					<th class=""><span class=""><!---->
          標題
						<!----></span></th>
					<th class=""><span class=""><!---->
          建立時間
						<!----></span></th>
					<th class=""><span class=""><!---->
          頁面類型
						<!----></span></th>
					<th class=""><span class=""><!---->
          頁數
						<!----></span></th>
				</tr>
				</thead>
				<tbody>
				<tr *ngFor="let message of currentPage" class="">
					<td><a [routerLink]="message.payload.doc.id" class="text-dark hljs-link">
						{{message.payload.doc.data().title}}
					</a></td>
					<td>
						{{message.payload.doc.data().createTime.toDate().toLocaleString()}}
					</td>
					<td>
						{{getTypeName(message.payload.doc.data().cardType)}}

					</td>
					<td>
						{{message.payload.doc.data().pages.length}}
					</td>
				</tr>
				</tbody>
			</table>
		</section>
		<hr>
		<app-list-paginator [data]="list" (listChanged)="currentPage = $event"></app-list-paginator>
	</section>
	<!---->
	<!---->
	<!---->
	<!---->
</div>
