import { Component, OnInit } from '@angular/core';
import {DocumentChangeAction} from '@angular/fire/firestore';
import {FCourse} from '../../../firebase/models/course';
import {ShopService} from '../../services/shop.service';
import {MatDialogRef} from '@angular/material/dialog';
import {FRewardCard} from '../../../firebase/models/rewardCard';

@Component({
  selector: 'app-reward-card-picker-dialog',
  templateUrl: './reward-card-picker-dialog.component.html',
  styleUrls: ['./reward-card-picker-dialog.component.styl']
})
export class RewardCardPickerDialogComponent implements OnInit {

  rewardCards: DocumentChangeAction<FRewardCard>[] = [];
  now = new Date();
  constructor(public ss: ShopService,
              public dialogRef: MatDialogRef<RewardCardPickerDialogComponent>) { }

  ngOnInit() {
    this.ss.currentShopDoc.collection<FRewardCard>('rewardCards').snapshotChanges().subscribe(q => {
      this.rewardCards = q;
    });
  }
  onSelect(qnr: DocumentChangeAction<any>) {
    this.dialogRef.close(qnr);
  }

}
