import { Injectable } from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFirestore} from '@angular/fire/firestore';
import {Router} from '@angular/router';
import {FUser} from './models/user';
import {Observable, of} from 'rxjs';
import {switchMap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public user$: Observable<FUser | null>;
  public user: FUser;
  constructor( private afAuth: AngularFireAuth,
               private afs: AngularFirestore,
               private router: Router
  ) {
    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.afs.collection<FUser>('users').doc(user.uid).valueChanges().subscribe((x: FUser) => {
          this.user = x;
        });
      }
    });

    this.user$ = this.afAuth.authState.pipe(
        switchMap(user => {
          if (user) {
            this.user$ = this.afs.doc<FUser>(`users/${user.uid}`).valueChanges();
          } else {
            return of(null);
          }
        })
    );
  }
  signOut() {
    this.afAuth.signOut().then(() => {
      this.router.navigate(['/Login']);
    });
  }
}
