<div>
  <div class="mt-n3 p-2 d-flex justify-content-end">
    <button *ngIf="pages.length < 9" (click)="addPage()" type="button" class="btn btn-outline-primary">新增頁面</button>
  </div>
  <mat-tab-group>
    <mat-tab *ngFor="let page of pages; index as i" [label]="i">
      <ng-template matTabContent>
        <div class="btn-toolbar justify-content-end my-4">
          <div class="p-3">
            <!--                  <button type="button" class="hide-box-shadow btn bg-transparent"><i class="far fa-copy"></i></button>-->
            <!--                  <button disabled="disabled" type="button" class="hide-box-shadow btn bg-transparent"><i class="lar la-chevron-left"></i></button>-->
            <!--                  <button type="button" class="hide-box-shadow btn bg-transparent"><i class="lar la-chevron-right"></i></button>-->
            <button type="button" class="hide-box-shadow btn bg-transparent"><fa-icon [icon]="faWindowClose" class="lar la-times"></fa-icon></button>
          </div>
        </div>
        <div class="d-flex mb-4">
          <div class="flex-1 ml-4 w-max-800">
            <div class="form-group row mx-0">
              <div class="col-sm-3 col-form-label pl-4">
                圖片
              </div>
              <div class="col-sm-9">
                <div class="form-inline">
                  <div class="form-inline">
                    <div>
                      <div class="figure figure-grid cursor-pointer border position-relative mr-2">
                        <img *ngIf="page.image !== ''" [src]="page.image" class="figure-img">
                        <a *ngIf="page.image === ''" (click)="openUploadImageDialog(i)" href="javascript:void(0)">上傳圖片</a>
                        <button *ngIf="page.image !== ''" type="button" class="position-absolute top-right close"><fa-icon [icon]="faWindowClose"></fa-icon></button>
                      </div>
                    </div>
                  </div>
                </div>
                <!---->
                <!---->
              </div>
            </div>

            <div class="row mx-0">
              <div class="col-sm-3 col-form-label pl-4 mb-3">
                姓名
              </div>
              <div class="col-sm-9">
                <div class="form-inline">
                  <div class="w-100">
                    <input [(ngModel)]="page.name" maxlength="20" required placeholder="輸入姓名" name="name" type="text" autocomplete="off" class="form-control w-80" aria-required="true" aria-invalid="false">
                    <span class=""><strong class="text-muted">{{page.name.length}}</strong><span class="">/20</span></span>
                  </div>
                  <!---->
                </div>
              </div>
            </div>
            <div class="form-group row mx-0">
              <div class="col-sm-3 col-form-label pl-0">
                <div class="kv-checkbox form-check custom-control custom-checkbox mb-3">
                  <input #f1 [checked]="page.tags[0].text !== ''" id="1hxo3b5i29x" name="iconSetting" type="checkbox" class="custom-control-input">
                  <label for="1hxo3b5i29x" class="custom-control-label">人物特點1</label>
                  <!---->
                </div>
              </div>
              <div class="col-sm-9">
                <div class="form-inline">
                  <div class="w-100">
                    <input [disabled]="!f1.checked" [(ngModel)]="page.tags[0].text" maxlength="10" placeholder="輸入宣傳標語" name="iconName0" type="text" autocomplete="off" class="form-control w-80" aria-required="true" aria-invalid="false">
                    <span class=""><strong class="text-muted">{{page.tags[0].text.length}}</strong><span class="">/10</span></span>
                  </div>
                  <!---->
                </div>
                <app-shop-card-message-color-picker [color]="page.tags[0].color" (ColorChanged)="page.tags[0].color = $event"></app-shop-card-message-color-picker>
              </div>
            </div>
            <div class="form-group row mx-0">
              <div class="col-sm-3 col-form-label pl-0">
                <div class="kv-checkbox form-check custom-control custom-checkbox mb-3">
                  <input [checked]="page.tags[1].text !== ''" (change)="fieldChecked($event)" id="6urcokpdvip" name="iconSetting" type="checkbox" class="custom-control-input">
                  <label for="6urcokpdvip" class="custom-control-label">人物特點2</label>
                  <!---->
                </div>
              </div>
              <div class="col-sm-9 disabled-content">
                <div class="form-inline">
                  <div class="w-100">
                    <input [(ngModel)]="page.tags[1].text" maxlength="10" placeholder="輸入宣傳標語" name="iconName1" readonly="readonly" type="text" autocomplete="off" class="form-control w-80" aria-required="false" aria-invalid="false">
                    <span class=""><strong class="text-muted">{{page.tags[1].text.length}}</strong><span class="">/10</span></span>
                  </div>
                  <!---->
                </div>
                <app-shop-card-message-color-picker [color]="page.tags[1].color" (ColorChanged)="page.tags[1].color = $event"></app-shop-card-message-color-picker>

              </div>
            </div>
            <div class="form-group row mx-0">
              <div class="col-sm-3 col-form-label pl-0">
                <div class="kv-checkbox form-check custom-control custom-checkbox mb-3">
                  <input  [checked]="page.tags[2].text !== ''" id="jx1owtdgdan" name="iconSetting" type="checkbox" class="custom-control-input">
                  <label for="jx1owtdgdan" class="custom-control-label">人物特點3</label>
                  <!---->
                </div>
              </div>
              <div class="col-sm-9 disabled-content">
                <div class="form-inline">
                  <div class="w-100">
                    <input [(ngModel)]="page.tags[2].text" maxlength="10" placeholder="輸入宣傳標語" name="iconName2" readonly="readonly" type="text" autocomplete="off" class="form-control w-80" aria-required="false" aria-invalid="false"> <span class="">
                    <strong class="text-muted">{{page.tags[2].text.length}}</strong><span class="">/10</span></span>
                  </div>
                  <!---->
                </div>
                <app-shop-card-message-color-picker [color]="page.tags[2].color" (ColorChanged)="page.tags[2].color = $event"></app-shop-card-message-color-picker>

              </div>
            </div>
            <div class="form-group row mx-0">
              <div class="col-sm-3 col-form-label pl-0">
                <div class="kv-checkbox form-check custom-control custom-checkbox mb-3">
                  <input [checked]="page.desc !== ''" id="9tvbpqgp1ab" name="iconSetting" type="checkbox" class="custom-control-input">
                  <label for="9tvbpqgp1ab" class="custom-control-label">文字說明</label>
                  <!---->
                </div>
              </div>
              <div class="col-sm-9">
                <div class="">
                  <div class="">
                    <div class="w-100">
                      <textarea [(ngModel)]="page.desc" maxlength="60" placeholder="輸入文字說明" name="description" rows="3" type="text" autocomplete="off" class="form-control w-100" aria-required="true" aria-invalid="false"></textarea> <span class="float-right">
                      <strong class="text-muted">{{page.desc.length}}</strong><span class="">/60</span></span>
                    </div>
                    <!---->
                  </div>
                  <!---->
                </div>
              </div>
              <!---->
            </div>
            <div>
              <div class="row mx-0">
                <div class="col-sm-3 col-form-label pl-0 pl-0">
                  <div class="kv-checkbox form-check custom-control custom-checkbox mb-3">
                    <input [checked]="page.actions[0].text != ''" id="qb4m06lk597" name="link" type="checkbox" class="custom-control-input">
                    <label for="qb4m06lk597" class="custom-control-label">動作1</label>
                  </div>
                </div>
              </div>

              <app-shop-action-model [actionText]="true" [(ngModel)]="page.actions[0]"></app-shop-action-model>
            </div>
            <div>
              <div class="row mx-0">
                <div class="col-sm-3 col-form-label pl-0 pl-0">
                  <div class="kv-checkbox form-check custom-control custom-checkbox mb-3">
                    <input [checked]="page.actions[1].text != ''" id="0txigjx8eugi" name="link" type="checkbox" class="custom-control-input">
                    <label for="0txigjx8eugi" class="custom-control-label">動作2</label>
                  </div>
                </div>
              </div>
              <app-shop-action-model [actionText]="true" [(ngModel)]="page.actions[1]"></app-shop-action-model>
            </div>
          </div>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
