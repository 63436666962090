import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-emoji-picker-dialog',
  templateUrl: './emoji-picker-dialog.component.html',
  styleUrls: ['./emoji-picker-dialog.component.styl']
})
export class EmojiPickerDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<EmojiPickerDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }
  addEmoji(e) {
    const emoji = e.emoji.native;
    this.dialogRef.close(emoji);
  }
}
