import {Component, ElementRef, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {faCheck} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-shop-setting-theme-picker-dialog',
  templateUrl: './shop-setting-theme-picker-dialog.component.html',
  styleUrls: ['./shop-setting-theme-picker-dialog.component.styl']
})
export class ShopSettingThemePickerDialogComponent implements OnInit {

  recClassName = 'avatar avatar-sm avatar-initials rounded flex-shrink-0 flex-grow-0 cursor-pointer';
  allColorRec;
  selectColor;
  faCheck = faCheck;
  constructor(public dialogRef: MatDialogRef<ShopSettingThemePickerDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private el: ElementRef) { }

  ngOnInit() {
  }
  onclick(para) {
    const list = document.querySelectorAll('.avatar');
    list.forEach( qq => {
      console.log(qq);
    });
  }
  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit() {
    this.RegisterMouseDown();
    this.allColorRec = this.el.nativeElement.querySelectorAll('.avatar');
  }

  RegisterMouseDown() {
    this.el.nativeElement.removeEventListener('mousedown', this.OnMouseDown.bind(this));
    this.el.nativeElement.addEventListener('mousedown', this.OnMouseDown.bind(this));
  }

  OnMouseDown(event: any) {
    if (event.target != null
        && event.target.className === this.recClassName) {
      this.allColorRec.forEach(x => {
        x.children[0].style.display = 'none';
      });
      event.target.children[0].style.display = '';
      this.data.color = '#' + this.parseColor(event.target.style.background).hex;
    }
  }

  parseColor(color) {
    const arr = []; color.replace(/[\d+\.]+/g, (v) => { arr.push(parseFloat(v)); });
    return {
      hex: arr.slice(0, 3).map(this.toHex).join(''),
      opacity: arr.length === 4 ? arr[3] : 1
    };
  }
  toHex(int) {
    const hex = int.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
