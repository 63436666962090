import { Component, OnInit } from '@angular/core';
import {FMeetGift} from '../../../../../firebase/models/shop';
import { faWindowClose, faClipboard } from '@fortawesome/free-regular-svg-icons';
import {ModalService} from '../../../../../shared/services/modal.service';
import {DocumentChangeAction} from '@angular/fire/firestore';
import {FCoupon} from '../../../../../firebase/models/coupon';
import { faGift } from '@fortawesome/free-solid-svg-icons';
import {NotifyService} from '../../../../../shared/services/notify.service';
import {ShopService} from '../../../../services/shop.service';

@Component({
  selector: 'app-shop-meet-gift-page',
  templateUrl: './shop-meet-gift-page.component.html',
  styleUrls: ['./shop-meet-gift-page.component.styl']
})
export class ShopMeetGiftPageComponent implements OnInit {

  faWindowClose = faWindowClose;
  faGift = faGift;
  faClipboard = faClipboard;
  meetGift: FMeetGift = {coupon: null, enabled: false, qnrRef: null, ref: null};
  constructor(private ms: ModalService,
              private notify: NotifyService,
              private ss: ShopService) { }

  ngOnInit(): void {
    this.meetGift = this.ss.currentShop.meetGift;
  }

  openCouponPicker() {
    this.ms.openCouponPicker().afterClosed().subscribe((coupon: DocumentChangeAction<FCoupon>) => {
      this.meetGift.coupon = coupon.payload.doc.data();
      this.meetGift.ref = coupon.payload.doc.ref;
    });
  }
  save() {
    this.ss.currentShopDoc.update({
      meetGift: this.meetGift
    }).then(() => this.notify.pop('已儲存'));
  }
}
