import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FPeople} from '../../../../../../../firebase/models/cardMessage';
import {ModalService} from '../../../../../../../shared/services/modal.service';
import {StorageService} from '../../../../../../../shared/services/storage.service';
import {UploadImageDialogData} from '../../../../../../../shared/ui/upload-image-dialog/upload-image-dialog.component';
import {faCheck, faWindowClose} from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-card-people',
  templateUrl: './card-people.component.html',
  styleUrls: ['./card-people.component.scss']
})
export class CardPeopleComponent implements OnInit {

  faWindowClose = faWindowClose;
  faCheck = faCheck;
  @Input()
  pages: FPeople[];
  @Output()
  pagesChange: EventEmitter<FPeople[]> = new EventEmitter<FPeople[]>();
  constructor(private ss: StorageService) { }

  ngOnInit(): void {
  }
  openUploadImageDialog(index: number): void {
    const info: UploadImageDialogData = {height: 640, type: 'image', width: 640};
    this.ss.openUploadDialog(info).afterClosed().subscribe(result => {
      if (result) {
        (this.pages[index] as FPeople).image = result;
        this.pagesChanged();
      }
    });
  }
  addPage() {
    this.pages.push(NewPeopleCard());
    this.pagesChanged();
  }
  pagesChanged() {
    this.pagesChange.emit(this.pages);
  }
  fieldChecked(event) {
    console.log(event);
  }


}
export function NewPeopleCard(): FPeople {
  return {
    actions: [
      {
        text: '',  // 動作文字 20
        type: 0,  // (0: NoAction 1: Link,2: Coupon,3: text),
        content: ''
      },
      {
        text: '',  // 動作文字 20
        type: 0,  // (0: NoAction 1: Link,2: Coupon,3: text)
        content: ''
      }
      ],
    desc: '',
    image: '',
    name: '',
    tags: [
      {
        text: '',
        color: '666F86'
      },
      {
        text: '',
        color: '666F86'
      },
      {
        text: '',
        color: '666F86'
      }
    ]
  };
}
