import {Component, EventEmitter, forwardRef, OnInit, Output, ViewChild} from '@angular/core';
import {AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, NgForm, ValidationErrors, Validator} from '@angular/forms';
import {FPeople} from '../../../../../../../firebase/models/cardMessage';
import { faWindowClose } from '@fortawesome/free-regular-svg-icons';
import {StorageService} from '../../../../../../../shared/services/storage.service';
import {UploadImageDialogData} from '../../../../../../../shared/ui/upload-image-dialog/upload-image-dialog.component';

export const CARD_MESSAGE_PEOPLE_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ShopCardMessagePeopleComponent),
  multi: true
};

export const CARD_MESSAGE_PEOPLE_VALIDATORS: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => ShopCardMessagePeopleComponent),
  multi: true
};

@Component({
  selector: 'app-shop-card-message-people',
  templateUrl: './shop-card-message-people.component.html',
  styleUrls: ['./shop-card-message-people.component.styl'],
  providers: [CARD_MESSAGE_PEOPLE_VALUE_ACCESSOR, CARD_MESSAGE_PEOPLE_VALIDATORS]
})
export class ShopCardMessagePeopleComponent implements OnInit, ControlValueAccessor, Validator {
  @ViewChild('form') form: NgForm;
  cardPeople: FPeople = {
    actions: [null, null],
    desc: '', image: '', name: '',
    tags: [{text: '', color : '666F86'}, {text: '', color : '666F86'}, {text: '', color : '666F86'}]};
  valid = true;
  @Output() cardData: EventEmitter<any> = new EventEmitter<any>();
  faWindowClose = faWindowClose;
  feature1Checked = false;
  feature2Checked = false;
  feature3Checked = false;
  action1Checked = false;
  action2Checked = false;
  descChecked = false;

  constructor(private img: StorageService) { }

  ngOnInit(): void {
    this.cardPeople = {
      actions: [null, null],
      desc: '', image: '', name: '',
      tags: [{text: '', color : '666F86'}, {text: '', color : '666F86'}, {text: '', color : '666F86'}]};

  }
  check() {
    if (this.cardPeople.actions[0]) {
      this.action1Checked = true;
    }
    if (this.cardPeople.actions[1]) {
      this.action2Checked = true;
    }
    if (this.cardPeople.tags[0]) {
      this.feature1Checked = true;
    }
    if (this.cardPeople.tags[1]) {
      this.feature2Checked = true;
    }
    if (this.cardPeople.tags[2]) {
      this.feature3Checked = true;
    }
    if (this.cardPeople.actions[1]) {
      this.action2Checked = true;
    }
    if (this.cardPeople.desc) {
      this.descChecked = true;
    }
  }

  openUploadImageDialog() {
    const info: UploadImageDialogData = {height: 310, type: 'image', width: 560};
    this.img.openUploadDialog(info).afterClosed().subscribe(result => {
      if (result) {
        this.cardPeople.image = result;
        setTimeout(() => {
          this.pagesChanged();
          }, 500);
      }
    });
  }
  pagesChanged() {
    console.log('changed');
    const page = Object.assign({}, this.cardPeople);
    page.tags = Object.assign([], this.cardPeople.tags);
    page.actions = Object.assign([], this.cardPeople.actions);
    if (!this.feature1Checked) {
      page.tags[0] = null;
    }
    if (!this.feature2Checked) {
      page.tags[1] = null;
    }
    if (!this.feature3Checked) {
      page.tags[2] = null;
    }
    if (!this.action1Checked) {
      page.actions[0] = null;
    }
    if (!this.action2Checked) {
      page.actions[1] = null;
    }
    if (!this.descChecked) {
      page.desc = null;
    }
    this.onChange(this.cardPeople);
    this.cardData.emit(page);
  }
  onChange(value){
    console.log(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const requiredError = {
      error: 'message most be filled'
    };
    if (this.form.invalid) {
      return requiredError;
    }else {
      return null;
    }
  }

  writeValue(obj: any): void {
    Object.assign(this.cardPeople, obj);
    this.check();
    setTimeout(() => { this.onChange(this.cardPeople); }, 0);
  }
  log(a) {
    console.log(a);
  }

}
export function GetCardPeople() {
  return {
    actions: [null, null],
    desc: '', image: '', name: '',
    tags: [{text: '', color : '666F86'}, {text: '', color : '666F86'}, {text: '', color : '666F86'}]};
}
