import { Component, OnInit } from '@angular/core';
import {FCoupon} from '../../../../../../firebase/models/coupon';
import { faCalendarAlt, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import {StorageService} from '../../../../../../shared/services/storage.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NotifyService} from '../../../../../../shared/services/notify.service';
import Timestamp = firebase.firestore.Timestamp;
import {AngularFirestoreDocument} from '@angular/fire/firestore';
import * as firebase from 'firebase';
import {ShopService} from '../../../../../services/shop.service';
import {RouteService} from '../../../../../../shared/services/route.service';
import {UploadImageDialogData} from '../../../../../../shared/ui/upload-image-dialog/upload-image-dialog.component';


@Component({
  selector: 'app-shop-coupon-edit',
  templateUrl: './shop-coupon-edit.component.html',
  styleUrls: ['./shop-coupon-edit.component.styl']
})
export class ShopCouponEditComponent implements OnInit {

  faCalendar = faCalendarAlt;
  faWindowClose = faWindowClose;
  create = true;
  couponDoc: AngularFirestoreDocument<FCoupon>;
  coupon: FCoupon = {
    code: '',
    desc: '',
    discountPrice: 0,
    discountType: 0,
    discountValue: 0,
    endTime: undefined,
    lottery: false,
    lotteryMaxWinner: 0,
    lotteryRate: 0,
    lotteryWinner: 0,
    photoUrl: '',
    privacy: 0,
    shop: undefined,
    startTime: undefined,
    title: '',
    type: 0
  };
  minDate = new Date();
  constructor(private storage: StorageService,
              private activatedRoute: ActivatedRoute,
              private notify: NotifyService,
              private router: RouteService,
              private ss: ShopService) {
  }

  ngOnInit(): void {
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    if (id === 'Create') {
      this.create = true;
    } else  {
      this.create = false;
      this.couponDoc = this.ss.currentShopDoc.collection<FCoupon>('coupons').doc(id);
      this.couponDoc.get().subscribe(coupon => {
        if (coupon.exists) {
          // @ts-ignore
          this.coupon = coupon.data();
          this.coupon.startTime = (this.coupon.startTime as Timestamp).toDate();
          this.coupon.endTime = (this.coupon.endTime as Timestamp).toDate();
          this.minDate = this.coupon.startTime;
        } else {
          this.notify.pop('優惠券不存在');
          this.router.toPreviousUrl();
        }
      });
    }
  }
  openDialog(){
    const info: UploadImageDialogData = {
      height: 330, type: 'image', width: 500

    };
    this.storage.openUploadDialog(info).afterClosed().subscribe(url => {
      if (url) {
        this.coupon.photoUrl = url;
      }
    });
  }
  submit(){
    this.coupon.shop =  this.ss.shopInfo;
    if (this.create) {
      this.ss.currentShopDoc.collection('coupons').add(this.coupon).then(res => {
        this.router.toPreviousUrl();
      });
    } else {
      this.couponDoc.update(this.coupon).then(() => {
        this.router.toPreviousUrl();
      });
    }
  }

}
