import { Component, OnInit } from '@angular/core';
import {ShopService} from '../../../../services/shop.service';
import {FMessage} from '../../../../../firebase/models/message';
import {NotifyService} from '../../../../../shared/services/notify.service';
import { faCalendarAlt, faWindowClose } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-shop-welcome-message-page',
  templateUrl: './shop-welcome-message-page.component.html',
  styleUrls: ['./shop-welcome-message-page.component.styl']
})
export class ShopWelcomeMessagePageComponent implements OnInit {

  faWindowClose = faWindowClose;
  messages: FMessage[] = [];
  constructor(private ss: ShopService, private notify: NotifyService) { }

  ngOnInit(): void {
    this.messages = this.ss.currentShop.welcomeMessage;
    console.log(this.messages);
  }
  addMessage() {
    const message: FMessage = {
      content: {
        text: ''
      },
      timestamp: new Date(),
      type: 0,
      urlTags: null
    };
    this.messages.push(message);
  }
  save() {
    const timestamp = new Date();
    this.messages.forEach(message => {
      message.timestamp = timestamp;
      timestamp.setSeconds(timestamp.getSeconds() + 1);
    });
    this.ss.currentShopDoc.update({
      welcomeMessage: this.messages
    }).then(() => this.notify.pop('已儲存'));
  }
}
