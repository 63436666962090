import { Injectable } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {EmojiPickerDialogComponent} from '../ui/emoji-picker-dialog/emoji-picker-dialog.component';
import {ConfirmDialogComponent} from '../ui/confirm-dialog/confirm-dialog.component';
import {RichMessagePickerDialogComponent} from '../../shop/modals/rich-message-picker-dialog/rich-message-picker-dialog.component';
import {CouponPickerDialogComponent} from '../../shop/modals/coupon-picker-dialog/coupon-picker-dialog.component';
import {QnrPickerDialogComponent} from '../../shop/modals/qnr-picker-dialog/qnr-picker-dialog.component';
import {QrCodeDownloadDialogComponent} from '../ui/qr-code-download-dialog/qr-code-download-dialog.component';
import {CoursePickerDialogComponent} from '../../shop/modals/course-picker-dialog/course-picker-dialog.component';
import {RewardCardPickerDialogComponent} from '../../shop/modals/reward-card-picker-dialog/reward-card-picker-dialog.component';
import {FriendPickerDialogComponent} from '../../shop/modals/friend-picker-dialog/friend-picker-dialog.component';
import {InputEditDialogComponent} from '../dialogs/input-edit-dialog/input-edit-dialog.component';
import {EventPickerDialogComponent} from '../../shop/modals/event-picker-dialog/event-picker-dialog.component';
import {StoreProductPickerDialogComponent} from '../../shop/modals/store-product-picker-dialog/store-product-picker-dialog.component';
import {StoreProductCategoryPickerDialogComponent} from '../../shop/modals/store-product-category-picker-dialog/store-product-category-picker-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(private dialog: MatDialog) { }
  public openEmojiPicker() {
    return this.dialog.open(EmojiPickerDialogComponent, {
      panelClass: 'custom-dialog-container',
    });
  }
  public openConfirmDialog(title: string, body: string) {
    return this.dialog.open(ConfirmDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: {
        title,
        body
      }
    });
  }
  public openRichMessagePicker() {
    return this.dialog.open(RichMessagePickerDialogComponent, {
      panelClass: 'custom-dialog-container',
    });
  }
  public openCouponPicker() {
    return this.dialog.open(CouponPickerDialogComponent, {
      panelClass: 'custom-dialog-container',
    });
  }
  public openQnrPicker() {
    return this.dialog.open(QnrPickerDialogComponent, {
      panelClass: 'custom-dialog-container',
    });
  }
  public openRewardCardPicker() {
    return this.dialog.open(RewardCardPickerDialogComponent, {
      panelClass: 'custom-dialog-container',
    });
  }
  public openQrCodeDownloadMadel(QrCodeData: string) {
    return this.dialog.open(QrCodeDownloadDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: QrCodeData
    });
  }
  public openCoursePicker() {
    return this.dialog.open(CoursePickerDialogComponent, {
      panelClass: 'custom-dialog-container',
    });
  }
  public openEventPicker() {
    return this.dialog.open(EventPickerDialogComponent, {
      panelClass: 'custom-dialog-container',
    });
  }
  public openStoreProductPicker() {
    return this.dialog.open(StoreProductPickerDialogComponent, {
      panelClass: 'custom-dialog-container',
    });
  }
  public openStoreCategoryPicker() {
    return this.dialog.open(StoreProductCategoryPickerDialogComponent, {
      panelClass: 'custom-dialog-container',
    });
  }
  public openInputEditor(title: string) {
    return this.dialog.open(InputEditDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: title
    });
  }
  public openFriendPicker() {
    return this.dialog.open(FriendPickerDialogComponent, {
      panelClass: 'custom-dialog-container',
    });
  }
}
