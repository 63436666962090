import { Component, OnInit } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {FCourseDetail} from '../../../../../../firebase/models/course';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-shop-course-detail-list-page',
  templateUrl: './shop-course-detail-list-page.component.html',
  styleUrls: ['./shop-course-detail-list-page.component.styl']
})
export class ShopCourseDetailListPageComponent implements OnInit {

  list: FCourseDetail[] = [];
  currentPage: FCourseDetail[] = [];
  constructor(private db: AngularFirestore,
              private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    const id = this.activatedRoute.snapshot.paramMap.get('id');

    this.db.collectionGroup<FCourseDetail>('courses', ref =>
        ref.where('isCreditAuth', '==', true)
            .where('course.documentId', '==', id)).valueChanges().subscribe(list => {
              this.list = list;
    });
  }

}
