import { Component, OnInit } from '@angular/core';
import {ShopService} from '../../../../services/shop.service';
import {ModalService} from '../../../../../shared/services/modal.service';
import {NotifyService} from '../../../../../shared/services/notify.service';
import {FRewardCode} from '../../../../../firebase/models/rewardCard';
import {DocumentChangeAction} from '@angular/fire/firestore';
import { faCalendar, faDownload, faTrash } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-shop-reward-code-list-page',
  templateUrl: './shop-reward-code-list-page.component.html',
  styleUrls: ['./shop-reward-code-list-page.component.styl']
})
export class ShopRewardCodeListPageComponent implements OnInit {

  faDownload = faDownload;
  faTrash = faTrash;
  rewardCodes: DocumentChangeAction<FRewardCode>[]
  constructor(private ss: ShopService,
              private notify: NotifyService,
              private ms: ModalService) { }

  ngOnInit(): void {
    this.ss.currentShopDoc.collection<FRewardCode>('rewardCodes').snapshotChanges().subscribe(rewardCodes => {
      this.rewardCodes = rewardCodes;
    });
  }
  deleteRewardCode(rewardCode: DocumentChangeAction<FRewardCode>) {
    rewardCode.payload.doc.ref.delete().then();
  }
  downloadQRCode(path: string) {
    this.ms.openQrCodeDownloadMadel('https://s.casttalk.me/' + path);
  }

}
