import { Injectable } from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFirestore, AngularFirestoreDocument} from '@angular/fire/firestore';
import {FShop, FShopData} from '../../firebase/models/shop';
import {BehaviorSubject, Observable} from 'rxjs';
import {TagModel} from '../../shared/models/tags';
import {FChargePlan} from '../../firebase/models/chargePlan';
import {FTagsForSearch} from '../../firebase/models/shared';
import {RouteService} from '../../shared/services/route.service';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ShopService {
  tagsForDisplay: TagModel[] = [];
  loaded  = new BehaviorSubject<boolean>(false);
  $shopSelected: Observable<boolean> = new Observable();
  shopLoaded  = new BehaviorSubject<boolean>(false);
  chargePlanLoaded  = new BehaviorSubject<boolean>(false);
  permission: number;
  shops: FShop[] = [];
  currentShop: FShop;
  currentShopDoc: AngularFirestoreDocument;
  chargePlan: FChargePlan;
  chargePlanDoc: FChargePlan;
  shopInfo: FShopData;
  constructor(private afAuth: AngularFireAuth,
              private db: AngularFirestore,
              private rs: Router
  ) {
    this.getShops();
  }
  getShops() {
    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.db.collection<FShop>('shops', ref => ref.where(`managers.${user.uid}.enabled`, '==', true))
          .valueChanges().subscribe(shops => {
          shops.forEach(shop => {
            shop.permission = shop.managers[user.uid].permission;
          });
          this.shops = shops;
          this.loaded.next(true);
        });
      }
    });
  }
  getChargePlan(shopId: string) {
    this.db.collection<FChargePlan>('chargePlan').doc(shopId).valueChanges().subscribe((chargePlan: FChargePlan) => {
      this.chargePlan = chargePlan;
      this.chargePlanLoaded.next(true);
    });
  }
  selectShop(shop: FShop){
    this.currentShop = shop;
    this.permission = shop.permission;
    this.currentShopDoc = this.db.collection('shops').doc(shop.uid);
    this.currentShopDoc.valueChanges().subscribe((x: FShop) => {
      this.currentShop = x;
      this.setTags();
      this.setShopInfo();
      this.shopLoaded.next(true);
      this.getChargePlan(x.uid);
    });
  }
  private setTags() {
    this.tagsForDisplay = Object.keys(this.currentShop.tags).map(id => {
      return{
        display: this.currentShop.tags[id].name,
        value: this.currentShop.tags[id].id
      };
    });
  }
  private setShopInfo() {
    this.shopInfo = {
      category: this.currentShop.category,
      desc: this.currentShop.desc,
      displayName: this.currentShop.displayName,
      lineAt: this.currentShop.lineAt,
      photoUrl: this.currentShop.photoUrl,
      uid: this.currentShop.uid
    };
  }
  public tagForSearchToTag(tags: FTagsForSearch): TagModel[] {
    const tagsIds = Object.keys(tags);
    return tagsIds.map(x => {
      return this.tagsForDisplay.filter(y => y.value === x )[0];
    });
  }
  public tagToTagForSearch(tags: TagModel[]) {
    const result = {};
    tags.forEach(tag => {
      result[tag.value] = true;
    });
    return result;
  }
  public tagIdToTagForDisplay(tagId: string) {
    return this.tagsForDisplay.filter(x => x.value === tagId)[0];
  }
}
