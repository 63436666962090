import { Component, OnInit } from '@angular/core';
import {FActionModel} from '../../../../../../firebase/models/shared';
import {ConfirmDialogComponent} from '../../../../../../shared/ui/confirm-dialog/confirm-dialog.component';
import {DocumentChangeAction} from '@angular/fire/firestore';
import {ActivatedRoute} from '@angular/router';
import {ShopService} from '../../../../../services/shop.service';
import {NotifyService} from '../../../../../../shared/services/notify.service';
import {RouteService} from '../../../../../../shared/services/route.service';
import {ModalService} from '../../../../../../shared/services/modal.service';
import {FRichMenu} from '../../../../../../firebase/models/richMenu';
import {StorageService} from '../../../../../../shared/services/storage.service';
import {UploadImageDialogData} from '../../../../../../shared/ui/upload-image-dialog/upload-image-dialog.component';
import {FCoupon} from '../../../../../../firebase/models/coupon';

@Component({
  selector: 'app-shop-rich-menu-edit-page',
  templateUrl: './shop-rich-menu-edit-page.component.html',
  styleUrls: ['./shop-rich-menu-edit-page.component.styl']
})
export class ShopRichMenuEditPageComponent implements OnInit {

  imgHeight = 160;
  stylePicker = false;
  richMenu: FRichMenu = {
    behavior: 'shown',
    createTime: new Date(),
    label: '',
    photoUrl: '',
    styleType: 0,
    title: '',
    urls: [],
    actionNum: 0
  };
  create = false;
  richDoc: any;
  constructor(private activatedRoute: ActivatedRoute,
              private ss: ShopService,
              private notify: NotifyService,
              private router: RouteService,
              public storageService: StorageService,
              private ms: ModalService) {
  }
  ngOnInit() {
    this.initActions();
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    if (id === 'Create') {
      this.create = true;
    } else  {
      this.ss.currentShopDoc.collection<FRichMenu>('richs').doc(id).get().subscribe(richMenu => {
        if (richMenu.exists) {
          // @ts-ignore
          this.richMenu = richMenu.data();
          this.richDoc = richMenu.ref;
          this.figureChange();
          this.log();
        } else {
          this.notify.pop('圖文選單不存在');
          this.router.navigateTo('/Home/RichMenu');
        }
      });
    }
  }
  log() {
    console.log(this.richMenu);
  }
  onFigureClick(type: number, num: number) {
    this.stylePicker = false;
    this.richMenu.styleType = type;
    this.richMenu.actionNum = num;
    this.figureChange();
  }
  initActions() {
    for (let i = 0; i < 6; i++) {
      this.richMenu.urls.push({
        type: 0,  // (0:NoAction 1:Link,2:Coupon,3:text)
        content: ''  // (Link=網址、Coupon=Coupon的RefPath、Survey=問卷的RefPath、文字)
      });
    }
  }
  getOrderChar(index: number) {
    return String.fromCharCode(index + 65);
  }
  figureChange() {
    if (this.richMenu.styleType < 20) {
      this.imgHeight = 160;
    } else {
      this.imgHeight = 80;
    }
  }
  openUploadImageDialogBig() {
    const info: UploadImageDialogData = {height: 500, type: 'image', width: 750};
    this.storageService.openUploadDialog(info).afterClosed().subscribe(result => {
      if (result) {
        this.richMenu.photoUrl = result;
      }
    });
  }
  openUploadImageDialog() {
    const info: UploadImageDialogData = {height: 250, type: 'image', width: 750};
    this.storageService.openUploadDialog(info).afterClosed().subscribe(result => {
      if (result) {
        this.richMenu.photoUrl = result;
      }
    });
  }

  openCouponPicker(action: FActionModel) {
    this.ms.openCouponPicker().afterClosed().subscribe((result: DocumentChangeAction<FCoupon>) => {
      if (result) {
        action.content = result.payload.doc.ref.path;
        action.contentData = result.payload.doc.data();
      }
    });
  }
  save() {
    if (this.create) {
      this.ss.currentShopDoc.collection<FRichMenu>('richs').add(this.richMenu).then(() => {
        this.notify.pop('已儲存');
        this.router.navigateTo('/Home/RichMenu');
      });
    } else {
      this.richDoc.update(this.richMenu).then(() => {
        this.notify.pop('已儲存');
        this.router.navigateTo('/Home/RichMenu');
      });
    }
  }
  delete() {
    this.ms.openConfirmDialog('刪除圖文選單' , '確定要刪除此則圖文選單嗎？').afterClosed().subscribe(result => {
      if (result) {
        this.richDoc.delete().then(() => {
          this.notify.pop('已刪除');
          this.router.navigateTo('/Home/RichMenu');
        });
      }
    });
  }
  actionChange(action: FActionModel) {
    action.contentData = null;
    action.content = '';
  }
}



