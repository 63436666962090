
<div class="d-flex justify-content-between align-items-center mb-2">
	<h2 class="mb-0">帳號設定</h2>
</div>
<hr>

<form #form="ngForm" *ngIf="shop">
	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">顯示名稱</label>
		<div class="col-sm-9">
			<div class="form-inline text-muted">
				<input  [(ngModel)]="shop.displayName" name="displayName" type="text" required maxlength="20" placeholder="顯示名稱" autocomplete="off" class="form-control w-fix-40">
				<span class="ml-1"><strong class="">{{shop.displayName.length}}</strong><span class="">/20</span></span>
			</div>
		</div>
	</div>

	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">狀態消息</label>
		<div class="col-sm-9">
			<div class="form-inline text-muted">
				<input  [(ngModel)]="shop.desc" name="desc" type="text" required maxlength="20" placeholder="狀態消息" autocomplete="off" class="form-control form-control-rg">
				<span class="ml-1"><strong class="">{{shop.desc.length}}</strong><span class="">/20</span></span>
			</div>
		</div>
	</div>
  <div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">官方網站</label>
		<div class="col-sm-9">
			<div class="form-inline text-muted">
				<input  [(ngModel)]="shop.officialWeb" name="officialWeb" type="text" placeholder="網址" autocomplete="off" class="form-control form-control-rg w-fix-400">
			</div>
		</div>
	</div>

	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">類別</label>
		<div class="col-sm-9">
			<div class="kv-select form-group d-flex align-items-end">
				<div class="flex-grow-1">
					<div class="input-group">
						<div class="select" >
							<select class="form-control custom-select form-control-rg" [(ngModel)]="shop.category" name="category" >
								<option *ngFor="let cat of categories;let i = index" [ngValue]=cat>{{cat}}</option>
							</select>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">大頭貼
		</label>
		<div class="col-sm-9">
			<div class="field w-fix-500">
				<div class="control">
					<button (click)="openImgDialog()" type="button" class="btn btn-outline-secondary">編輯</button>
				</div>
				<img [src]="shop.photoUrl" style="width: 150px">
			</div>
		</div>
	</div>

	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">Line@</label>
		<div class="col-sm-9">
			<div class="input-group align-items-center text-muted">
				<div class="kv-input form-group mb-0 flex-1 d-flex align-items-end" aria-required="false" aria-invalid="false">
					<div class="flex-grow-1">
						<div class="input-group">
							<label>
								<input disabled [(ngModel)]="shop.lineAt" type="text" name="Lineat"  placeholder="Line@" autocomplete="off" class="form-control">
							</label>
						</div>
					</div>
				</div>
			</div>
			<!--      <p *ngIf="!enabled" class="text-danger invalid-feedback d-block">line@尚未通過認證-->
			<!--      </p>-->
		</div>

	</div>
	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">帳號簡介</label>
		<div class="col-sm-9">
			<div class="input-group align-items-center text-muted">
				<div class="kv-input form-group mb-0 flex-1 d-flex align-items-end" aria-required="false" aria-invalid="false">
					<div class="flex-grow-1">
						<div class="control">
							<textarea [(ngModel)]="shop.brief" name="brief" required maxlength="200" rows="5" class="form-control" aria-required="false" aria-invalid="false"></textarea>
						</div>
						<span class="float-right"><strong class="">{{shop.brief.length}}</strong><span class="">/200</span></span>
						<!--					<button (click)="openBottomSheet()" type="button" class="btn btn-outline-secondary mr-2">表情貼</button>-->
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">聊天室背景
		</label>
		<div class="col-sm-9">
			<div class="input-group align-items-center">
				<div [style.background-color]="shop.theme" class="avatar avatar-initials avatar-xs rounded mr-2" style="background: rgb(54, 67, 94);"></div>
				<div class="input-group-append">
					<button class="btn btn-outline-secondary" (click)="openThemePicker()">編輯</button>
				</div>
			</div>
		</div>
	</div>

	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">一對一聊天</label>
		<div class="col-sm-9 d-flex flex-md-column justify-content-center">
			<mat-slide-toggle class="align-items-center d-flex mt-2"
			                  [checked]="shop.chatMode"
			                  (change)="shop.chatMode = $event.checked">
			</mat-slide-toggle>
		</div>
	</div>
	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">預設開啟圖文選單</label>
		<div class="col-sm-9 d-flex flex-md-column justify-content-center">
			<mat-slide-toggle class="align-items-center d-flex mt-2"
			                  [checked]="shop.richMenuFlag"
			                  (change)="shop.richMenuFlag = $event.checked">
			</mat-slide-toggle>
		</div>
	</div>
	<div class="form-group row w-fix-800" *ngIf="ss.chargePlan.permission.store">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">商店標籤</label>
		<div class="col-sm-9 d-flex flex-md-column justify-content-center">
			<mat-slide-toggle class="align-items-center d-flex mt-2"
			                  [checked]="shop.chatMenu.store"
			                  (change)="shop.chatMenu.store = $event.checked">
			</mat-slide-toggle>
		</div>
	</div>
	<div class="form-group row w-fix-800" *ngIf="ss.chargePlan.permission.course">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">課程標籤</label>
		<div class="col-sm-9 d-flex flex-md-column justify-content-center">
			<mat-slide-toggle class="align-items-center d-flex mt-2"
			                  [checked]="shop.chatMenu.course"
			                  (change)="shop.chatMenu.course = $event.checked">
			</mat-slide-toggle>
		</div>
	</div>
	<div class="form-group row w-fix-800" *ngIf="ss.chargePlan.permission.event">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">活動標籤</label>
		<div class="col-sm-9 d-flex flex-md-column justify-content-center">
			<mat-slide-toggle class="align-items-center d-flex mt-2"
			                  [checked]="shop.chatMenu.event"
			                  (change)="shop.chatMenu.event = $event.checked">
			</mat-slide-toggle>
		</div>
	</div>
	<div class="form-group row w-fix-800" *ngIf="ss.chargePlan.permission.rewardCard">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">集點卡標籤</label>
		<div class="col-sm-9 d-flex flex-md-column justify-content-center">
			<mat-slide-toggle class="align-items-center d-flex mt-2"
			                  [checked]="shop.chatMenu.reward"
			                  (change)="shop.chatMenu.reward = $event.checked">
			</mat-slide-toggle>
		</div>
	</div>

  <div class="form-group row w-fix-800" *ngIf="ss.chargePlan.permission.booking">
    <label class="col-sm-3 col-form-label text-sm-right font-weight-light">預約標籤</label>
    <div class="col-sm-9 d-flex flex-md-column justify-content-center">
      <mat-slide-toggle class="align-items-center d-flex mt-2"
                        [checked]="shop.chatMenu.booking"
                        (change)="shop.chatMenu.booking = $event.checked">
      </mat-slide-toggle>
    </div>
  </div>



	<hr class="my-4">
	<div class="d-flex justify-content-center my-5">
		<button (click)="save()" [disabled]="form.invalid" class="btn btn-lg btn-primary px-5 mx-1">儲存</button>
	</div>
</form>


