<div class="d-flex justify-content-between align-items-center mb-2"><h2 class="mb-0">訂單資訊</h2>
</div>
<hr>

<div class="d-flex justify-content-between align-items-center mb-2"><h3 class="mb-0"> 訂單狀態</h3>
</div>
<hr>

<div class="form-group row w-fix-800">
	<label class="col-sm-3  text-sm-right font-weight-light">狀態</label>
	<div class="col-sm-9">
		<div class="form-inline text-muted">
			{{getStatus(order.status)}}
		</div>
	</div>
</div>

<div class="form-group row w-fix-800">
	<label class="col-sm-3  text-sm-right font-weight-light">付款狀態</label>
	<div class="col-sm-9">
		<div class="form-inline text-muted">
			{{order.payStatus ? '已付款' : '尚未付款'}}
		</div>
	</div>
</div>

<div class="form-group row w-fix-800">
	<label class="col-sm-3  text-sm-right font-weight-light">建立時間</label>
	<div class="col-sm-9">
		<div class="form-inline text-muted">
			{{$any(order.createTime).toDate().toLocaleString()}}
		</div>
	</div>
</div>
<hr>

<div class="d-flex justify-content-between align-items-center mb-2"><h3 class="mb-0">商品資訊</h3>
</div>
<hr>
<div *ngFor="let detail of order.details" class="border">
	<div class="form-group row w-fix-800">
		<label class="col-sm-3  text-sm-right font-weight-light">商品名稱</label>
		<div class="col-sm-9">
			<div class="form-inline text-muted">
				{{detail.name}}
			</div>
		</div>
	</div>
	<div class="form-group row w-fix-800">
		<label class="col-sm-3  text-sm-right font-weight-light">商品規格</label>
		<div class="col-sm-9">
			<div class="form-inline text-muted">
				{{detail.specName}}
			</div>
		</div>
	</div>
	<div class="form-group row w-fix-800">
		<label class="col-sm-3  text-sm-right font-weight-light">商品數量</label>
		<div class="col-sm-9">
			<div class="form-inline text-muted">
				{{detail.qty}}
			</div>
		</div>
	</div>
	<div class="form-group row w-fix-800">
		<label class="col-sm-3  text-sm-right font-weight-light">商品單價</label>
		<div class="col-sm-9">
			<div class="form-inline text-muted">
				{{detail.price}}
			</div>
		</div>
	</div>

</div>


<div class="d-flex justify-content-between align-items-center mb-2"><h3 class="mb-0">帳單資訊</h3>
</div>
<hr>

<div class="form-group row w-fix-800">
	<label class="col-sm-3  text-sm-right font-weight-light">總金額</label>
	<div class="col-sm-9">
		<div class="form-inline text-muted">
			{{order.totalPrice}}
		</div>
	</div>
</div>
<div class="form-group row w-fix-800">
	<label class="col-sm-3  text-sm-right font-weight-light">折扣金額</label>
	<div class="col-sm-9">
		<div class="form-inline text-muted">
			{{order.discountPrice}}
		</div>
	</div>
</div>
<div class="form-group row w-fix-800">
	<label class="col-sm-3  text-sm-right font-weight-light">運費</label>
	<div class="col-sm-9">
		<div class="form-inline text-muted">
			{{order.deliveryPrice}}
		</div>
	</div>
</div>
<div class="form-group row w-fix-800">
	<label class="col-sm-3  text-sm-right font-weight-light">付款人姓名</label>
	<div class="col-sm-9">
		<div class="form-inline text-muted">
			{{order.payName}}
		</div>
	</div>
</div>
<div class="form-group row w-fix-800">
	<label class="col-sm-3  text-sm-right font-weight-light">付款人電話</label>
	<div class="col-sm-9">
		<div class="form-inline text-muted">
			{{order.payPhone}}
		</div>
	</div>
</div>
<div class="form-group row w-fix-800">
	<label class="col-sm-3  text-sm-right font-weight-light">付款人Email</label>
	<div class="col-sm-9">
		<div class="form-inline text-muted">
			{{order.payEmail}}
		</div>
	</div>
</div>
<div class="form-group row w-fix-800">
	<label class="col-sm-3  text-sm-right font-weight-light">收件人姓名</label>
	<div class="col-sm-9">
		<div class="form-inline text-muted">
			{{order.recvName}}
		</div>
	</div>
</div>
<div class="form-group row w-fix-800">
	<label class="col-sm-3  text-sm-right font-weight-light">收件人電話</label>
	<div class="col-sm-9">
		<div class="form-inline text-muted">
			{{order.recvPhone}}
		</div>
	</div>
</div>
<div class="form-group row w-fix-800">
	<label class="col-sm-3  text-sm-right font-weight-light">訂單備註</label>
	<div class="col-sm-9">
		<div class="form-inline text-muted">
			{{order.note}}
		</div>
	</div>
</div>
<div class="form-group row w-fix-800">
	<label class="col-sm-3  text-sm-right font-weight-light">寄送城市</label>
	<div class="col-sm-9">
		<div class="form-inline text-muted">
			{{order.dCity}}
		</div>
	</div>
</div>
<div class="form-group row w-fix-800">
	<label class="col-sm-3  text-sm-right font-weight-light">寄送地址</label>
	<div class="col-sm-9">
		<div class="form-inline text-muted">
			{{order.dAddress}}
		</div>
	</div>
</div>
<div class="form-group row w-fix-800">
	<label class="col-sm-3  text-sm-right font-weight-light">統一編號</label>
	<div class="col-sm-9">
		<div class="form-inline text-muted">
			{{order.invNumber}}
		</div>
	</div>
</div>
<div class="form-group row w-fix-800">
	<label class="col-sm-3  text-sm-right font-weight-light">發票寄送城市</label>
	<div class="col-sm-9">
		<div class="form-inline text-muted">
			{{order.invCity}}
		</div>
	</div>
</div>
<div class="form-group row w-fix-800">
	<label class="col-sm-3  text-sm-right font-weight-light">發票寄送地址</label>
	<div class="col-sm-9">
		<div class="form-inline text-muted">
			{{order.invAddress}}
		</div>
	</div>
</div>
