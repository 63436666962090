import {Component, Inject, OnInit} from '@angular/core';
import {ShopService} from '../../services/shop.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DocumentChangeAction} from '@angular/fire/firestore';
import Timestamp = firebase.firestore.Timestamp;
import {FQuestionnaire} from '../../../firebase/models/questionnaire';
import * as firebase from 'firebase';

@Component({
  selector: 'app-qnr-picker-dialog',
  templateUrl: './qnr-picker-dialog.component.html',
  styleUrls: ['./qnr-picker-dialog.component.styl']
})
export class QnrPickerDialogComponent implements OnInit {

  questionnaires: DocumentChangeAction<FQuestionnaire>[] = [];
  now = new Date();
  constructor(public ss: ShopService,
              public dialogRef: MatDialogRef<QnrPickerDialogComponent>) { }

  ngOnInit() {
    this.ss.currentShopDoc.collection<FQuestionnaire>('questionnaires').snapshotChanges().subscribe(q => {
      this.questionnaires = q;
    });
  }
  getStatus(qnr: FQuestionnaire, qnrDoc: DocumentChangeAction<FQuestionnaire>): string {
    if (this.ss.currentShop.meetGift.qnrRef ) {
      if (qnrDoc.payload.doc.ref.path === this.ss.currentShop.meetGift.qnrRef.path) {
        return '已綁定LINE優惠券';
      }
    }
    if (this.now > (qnr.endTime as Timestamp).toDate()) {
      return '已過期';
    }
    return '進行中';
  }
  onSelect(qnr: DocumentChangeAction<FQuestionnaire>) {
    this.dialogRef.close(qnr);
  }
}
