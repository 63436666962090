import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ShopRoutingModule} from './shop-routing.module';
import { ShopsListPageComponent } from './pages/shops-list-page/shops-list-page.component';
import { ShopRootPageComponent } from './pages/shop-root-page/shop-root-page.component';
import { ShopMainNavComponent } from './pages/shop-root-page/shop-main-nav/shop-main-nav.component';
import {UiModule} from '../shared/ui/ui.module';
import { ShopHomeComponent } from './pages/shop-root-page/shop-home/shop-home.component';
import { ShopSharePageComponent } from './pages/shop-root-page/shop-home/shop-share-page/shop-share-page.component';
import {FormsModule} from '@angular/forms';
import {QRCodeModule} from 'angularx-qrcode';
import {ClipboardModule} from 'ngx-clipboard';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ShopMessageListComponent } from './pages/shop-root-page/shop-home/shop-message-list/shop-message-list.component';
import {MatTabsModule} from '@angular/material/tabs';
import { ShopCouponListComponent } from './pages/shop-root-page/shop-home/shop-coupon-list/shop-coupon-list.component';
import { ShopCouponEditComponent } from './pages/shop-root-page/shop-home/shop-coupon-list/shop-coupon-edit/shop-coupon-edit.component';
import {OwlDateTimeModule, OwlNativeDateTimeModule} from '@danielmoncada/angular-datetime-picker';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import { ShopMessageEditComponent } from './pages/shop-root-page/shop-home/shop-message-list/shop-message-edit/shop-message-edit.component';
import {MatExpansionModule} from '@angular/material/expansion';
import { ShopMessageContentCardComponent } from './components/shop-message-content-card/shop-message-content-card.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {TagInputModule} from 'ngx-chips';
import { RichMessagePickerDialogComponent } from './modals/rich-message-picker-dialog/rich-message-picker-dialog.component';
import { CouponPickerDialogComponent } from './modals/coupon-picker-dialog/coupon-picker-dialog.component';
import { QnrPickerDialogComponent } from './modals/qnr-picker-dialog/qnr-picker-dialog.component';
import { ShopWelcomeMessagePageComponent } from './pages/shop-root-page/shop-home/shop-welcome-message-page/shop-welcome-message-page.component';
import {HtmlPipe, ShopHomePageComponent} from './pages/shop-root-page/shop-home/shop-home-page/shop-home-page.component';
import { ShopAutoResponseListPageComponent } from './pages/shop-root-page/shop-home/shop-auto-response-list-page/shop-auto-response-list-page.component';
import { ShopAutoResponseEditPageComponent } from './pages/shop-root-page/shop-home/shop-auto-response-list-page/shop-auto-response-edit-page/shop-auto-response-edit-page.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { ShopRichMessageListPageComponent } from './pages/shop-root-page/shop-home/shop-rich-message-list-page/shop-rich-message-list-page.component';
import { ShopRichMessageEditPageComponent } from './pages/shop-root-page/shop-home/shop-rich-message-list-page/shop-rich-message-edit-page/shop-rich-message-edit-page.component';
import { ShopRichMessageTypePickerComponent } from './pages/shop-root-page/shop-home/shop-rich-message-list-page/shop-rich-message-edit-page/shop-rich-message-type-picker/shop-rich-message-type-picker.component';
import { ComponentsComponent } from './components/components.component';
import { ShopActionModelComponent } from './components/shop-action-model/shop-action-model.component';
import { ShopMeetGiftPageComponent } from './pages/shop-root-page/shop-home/shop-meet-gift-page/shop-meet-gift-page.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { ShopRewardCardListPageComponent } from './pages/shop-root-page/shop-home/shop-reward-card-list-page/shop-reward-card-list-page.component';
import { ShopRewardCardEditPageComponent } from './pages/shop-root-page/shop-home/shop-reward-card-list-page/shop-reward-card-edit-page/shop-reward-card-edit-page.component';
import { ShopRewardCodeListPageComponent } from './pages/shop-root-page/shop-home/shop-reward-code-list-page/shop-reward-code-list-page.component';
import { ShopRewardCodeEditPageComponent } from './pages/shop-root-page/shop-home/shop-reward-code-list-page/shop-reward-code-edit-page/shop-reward-code-edit-page.component';
import { ShopRewardCardAnalysisPageComponent } from './pages/shop-root-page/shop-home/shop-reward-card-list-page/shop-reward-card-analysis-page/shop-reward-card-analysis-page.component';
// tslint:disable-next-line:max-line-length
import { ShopRichMenuListPageComponent } from './pages/shop-root-page/shop-home/shop-rich-menu-list-page/shop-rich-menu-list-page.component';
import { ShopRichMenuEditPageComponent } from './pages/shop-root-page/shop-home/shop-rich-menu-list-page/shop-rich-menu-edit-page/shop-rich-menu-edit-page.component';
import { ShopCardMessageListPageComponent } from './pages/shop-root-page/shop-home/shop-card-message-list-page/shop-card-message-list-page.component';
import { ShopCardMessageEditPageComponent } from './pages/shop-root-page/shop-home/shop-card-message-list-page/shop-card-message-edit-page/shop-card-message-edit-page.component';
import {CardPlaceComponent} from './pages/shop-root-page/shop-home/shop-card-message-list-page/shop-card-message-edit-page/card-place/card-place.component';
import {CardProductComponent} from './pages/shop-root-page/shop-home/shop-card-message-list-page/shop-card-message-edit-page/card-product/card-product.component';
import {CardPeopleComponent} from './pages/shop-root-page/shop-home/shop-card-message-list-page/shop-card-message-edit-page/card-people/card-people.component';
import {CardPictureComponent} from './pages/shop-root-page/shop-home/shop-card-message-list-page/shop-card-message-edit-page/card-picture/card-picture.component';
import { ShopCourseComponent } from './pages/shop-root-page/shop-course/shop-course.component';
import { ShopCourseListPageComponent } from './pages/shop-root-page/shop-course/shop-course-list-page/shop-course-list-page.component';
import { ShopCourseEditPageComponent } from './pages/shop-root-page/shop-course/shop-course-list-page/shop-course-edit-page/shop-course-edit-page.component';
import {AngularEditorModule} from '@kolkov/angular-editor';
import {HttpClientModule} from '@angular/common/http';
import { ShopQuestionnareListPageComponent } from './pages/shop-root-page/shop-home/shop-questionnare-list-page/shop-questionnare-list-page.component';
import { ShopQuestionnareEditPageComponent } from './pages/shop-root-page/shop-home/shop-questionnare-list-page/shop-questionnare-edit-page/shop-questionnare-edit-page.component';
import { ShopFriendComponent } from './pages/shop-root-page/shop-friend/shop-friend.component';
import { ShopFriendListPageComponent } from './pages/shop-root-page/shop-friend/shop-friend-list-page/shop-friend-list-page.component';
import { ShopTagListPageComponent } from './pages/shop-root-page/shop-friend/shop-tag-list-page/shop-tag-list-page.component';
import { ShopAdComponent } from './pages/shop-root-page/shop-ad/shop-ad.component';
import { ShopAdPurchasePageComponent } from './pages/shop-root-page/shop-ad/shop-ad-purchase-page/shop-ad-purchase-page.component';
import { ShopAdListPageComponent } from './pages/shop-root-page/shop-ad/shop-ad-list-page/shop-ad-list-page.component';
import { ShopBillingComponent } from './pages/shop-root-page/shop-billing/shop-billing.component';
import { ShopBillingMessageListPageComponent } from './pages/shop-root-page/shop-billing/shop-billing-message-list-page/shop-billing-message-list-page.component';
import { ShopBillingPurchasePageComponent } from './pages/shop-root-page/shop-billing/shop-billing-purchase-page/shop-billing-purchase-page.component';
import { ShopBillingBillListPageComponent } from './pages/shop-root-page/shop-billing/shop-billing-bill-list-page/shop-billing-bill-list-page.component';
import { ShopBillingPaymentPageComponent } from './pages/shop-root-page/shop-billing/shop-billing-payment-page/shop-billing-payment-page.component';
import { ShopStoreComponent } from './pages/shop-root-page/shop-store/shop-store.component';
import { ShopStoreCategoryPageComponent } from './pages/shop-root-page/shop-store/shop-store-category-page/shop-store-category-page.component';
import { ShopStoreProductListPageComponent } from './pages/shop-root-page/shop-store/shop-store-product-list-page/shop-store-product-list-page.component';
import { ShopStoreProductEditPageComponent } from './pages/shop-root-page/shop-store/shop-store-product-list-page/shop-store-product-edit-page/shop-store-product-edit-page.component';
import { ShopStoreOrderListPageComponent } from './pages/shop-root-page/shop-store/shop-store-order-list-page/shop-store-order-list-page.component';
import { ShopStoreOrderEditPageComponent } from './pages/shop-root-page/shop-store/shop-store-order-list-page/shop-store-order-edit-page/shop-store-order-edit-page.component';
import { ShopStoreSettingPageComponent } from './pages/shop-root-page/shop-store/shop-store-setting-page/shop-store-setting-page.component';
import {ColorPickerModule} from 'ngx-color-picker';
import {FroalaEditorModule, FroalaViewModule} from 'angular-froala-wysiwyg';
import 'froala-editor/js/plugins.pkgd.min.js';
import {OrderBy, ShopChatComponent} from './pages/shop-root-page/shop-chat/shop-chat.component';
import { ScrollableDirective } from './pages/shop-root-page/shop-chat/scrollable.directive';
import { ShopNewsComponent } from './pages/shop-root-page/shop-news/shop-news.component';
import { ShopSettingComponent } from './pages/shop-root-page/shop-setting/shop-setting.component';
import { ShopBillingReceiptPageComponent } from './pages/shop-root-page/shop-billing/shop-billing-receipt-page/shop-billing-receipt-page.component';
import {MatRadioModule} from '@angular/material/radio';
import { ShopSettingSetiingPageComponent } from './pages/shop-root-page/shop-setting/shop-setting-setiing-page/shop-setting-setiing-page.component';
import { ShopSettingPermissionPageComponent } from './pages/shop-root-page/shop-setting/shop-setting-permission-page/shop-setting-permission-page.component';
import { ShopBillingDiscountPageComponent } from './pages/shop-root-page/shop-billing/shop-billing-discount-page/shop-billing-discount-page.component';
import { ShopSettingThemePickerDialogComponent } from './pages/shop-root-page/shop-setting/shop-setting-setiing-page/shop-setting-theme-picker-dialog/shop-setting-theme-picker-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import { ShopAgentComponent } from './pages/shop-root-page/shop-agent/shop-agent.component';
import { ShopAgentClientListPageComponent } from './pages/shop-root-page/shop-agent/shop-agent-client-list-page/shop-agent-client-list-page.component';
import { ShopAgentSettingPageComponent } from './pages/shop-root-page/shop-agent/shop-agent-setting-page/shop-agent-setting-page.component';
import { ShopAgentFeeListPageComponent } from './pages/shop-root-page/shop-agent/shop-agent-fee-list-page/shop-agent-fee-list-page.component';
import { ShopAgentFeePageComponent } from './pages/shop-root-page/shop-agent/shop-agent-fee-list-page/shop-agent-fee-page/shop-agent-fee-page.component';
import { ShopAgentClientShareListPageComponent } from './pages/shop-root-page/shop-agent/shop-agent-client-list-page/shop-agent-client-share-list-page/shop-agent-client-share-list-page.component';
import { CoursePickerDialogComponent } from './modals/course-picker-dialog/course-picker-dialog.component';
import { RewardCardPickerDialogComponent } from './modals/reward-card-picker-dialog/reward-card-picker-dialog.component';
import { ShopCardMessageColorPickerComponent } from './pages/shop-root-page/shop-home/shop-card-message-list-page/shop-card-message-edit-page/shop-card-message-color-picker/shop-card-message-color-picker.component';
import { ShopCardMessagePeopleComponent } from './pages/shop-root-page/shop-home/shop-card-message-list-page/shop-card-message-edit-page/shop-card-message-people/shop-card-message-people.component';
import { ShopFriendShareLinkListPageComponent } from './pages/shop-root-page/shop-friend/shop-friend-share-link-list-page/shop-friend-share-link-list-page.component';
import {FacebookModule} from '@jemys89/ngx-facebook';
import { ShopCourseShareListPageComponent } from './pages/shop-root-page/shop-course/shop-course-list-page/shop-course-share-list-page/shop-course-share-list-page.component';
import { FriendPickerDialogComponent } from './modals/friend-picker-dialog/friend-picker-dialog.component';
import { ShopCourseDetailListPageComponent } from './pages/shop-root-page/shop-course/shop-course-list-page/shop-course-detail-list-page/shop-course-detail-list-page.component';
import {ShareButtonsPopupModule} from 'ngx-sharebuttons/popup';
import {ShareButtonsConfig} from 'ngx-sharebuttons';
import {ShareButtonsModule} from 'ngx-sharebuttons/buttons';
import {MatButtonModule} from '@angular/material/button';
import { ShopCardMessageProductComponent } from './pages/shop-root-page/shop-home/shop-card-message-list-page/shop-card-message-edit-page/shop-card-message-product/shop-card-message-product.component';
import { ShopCardMessagePlaceComponent } from './pages/shop-root-page/shop-home/shop-card-message-list-page/shop-card-message-edit-page/shop-card-message-place/shop-card-message-place.component';
import { ShopCardMessagePictureComponent } from './pages/shop-root-page/shop-home/shop-card-message-list-page/shop-card-message-edit-page/shop-card-message-picture/shop-card-message-picture.component';
import { ShopAgentPointPageComponent } from './pages/shop-root-page/shop-agent/shop-agent-point-page/shop-agent-point-page.component';
import { ShopAgentClientPaymentPageComponent } from './pages/shop-root-page/shop-agent/shop-agent-client-list-page/shop-agent-client-payment-page/shop-agent-client-payment-page.component';
import { ShopBillingPointPageComponent } from './pages/shop-root-page/shop-billing/shop-billing-point-page/shop-billing-point-page.component';
import { ShopEventComponent } from './pages/shop-root-page/shop-event/shop-event.component';
import { ShopEventListPageComponent } from './pages/shop-root-page/shop-event/shop-event-list-page/shop-event-list-page.component';
import { ShopEventDetailListPageComponent } from './pages/shop-root-page/shop-event/shop-event-list-page/shop-event-detail-list-page/shop-event-detail-list-page.component';
import { ShopEventEditPageComponent } from './pages/shop-root-page/shop-event/shop-event-list-page/shop-event-edit-page/shop-event-edit-page.component';
import { ShopEventShareListPageComponent } from './pages/shop-root-page/shop-event/shop-event-list-page/shop-event-share-list-page/shop-event-share-list-page.component';
import { EventPickerDialogComponent } from './modals/event-picker-dialog/event-picker-dialog.component';
import { ShopNotSupportPageComponent } from './pages/shop-root-page/shop-not-support-page/shop-not-support-page.component';
import { ShopBillingExtraFeaturesPageComponent } from './pages/shop-root-page/shop-billing/shop-billing-extra-features-page/shop-billing-extra-features-page.component';
import { StoreProductPickerDialogComponent } from './modals/store-product-picker-dialog/store-product-picker-dialog.component';
import { StoreProductCategoryPickerDialogComponent } from './modals/store-product-category-picker-dialog/store-product-category-picker-dialog.component';
import { ShopBillingOrderListPageComponent } from './pages/shop-root-page/shop-billing/shop-billing-order-list-page/shop-billing-order-list-page.component';
import { ShopBookingComponent } from './pages/shop-root-page/shop-booking/shop-booking.component';
import { ShopBookingServicePageComponent } from './pages/shop-root-page/shop-booking/shop-booking-service-list-page/shop-booking-service-page/shop-booking-service-page.component';
import { ShopBookingServiceListPageComponent } from './pages/shop-root-page/shop-booking/shop-booking-service-list-page/shop-booking-service-list-page.component';
import { ShopBookingServiceCalendarComponent } from './pages/shop-root-page/shop-booking/shop-booking-service-list-page/shop-booking-service-calendar/shop-booking-service-calendar.component';
import {NgZorroAntdModule, NzCalendarModule} from 'ng-zorro-antd';

const customConfig: ShareButtonsConfig = {
  include: ['facebook'],
  exclude: ['tumblr', 'stumble', 'vk'],
  theme: 'modern-light',
  gaTracking: true,
  twitterAccount: 'twitterUsername'
};

@NgModule({
  declarations: [
    ShopsListPageComponent,
    ShopRootPageComponent,
    ShopMainNavComponent,
    ShopHomeComponent,
    ShopSharePageComponent,
    ShopMessageListComponent,
    ShopCouponListComponent,
    ShopCouponEditComponent,
    ShopMessageEditComponent,
    ShopMessageContentCardComponent,
    RichMessagePickerDialogComponent,
    CouponPickerDialogComponent,
    QnrPickerDialogComponent,
    ShopWelcomeMessagePageComponent,
    ShopHomePageComponent,
    ShopAutoResponseListPageComponent,
    ShopAutoResponseEditPageComponent,
    ShopRichMessageListPageComponent,
    ShopRichMessageEditPageComponent,
    ShopRichMessageTypePickerComponent,
    ComponentsComponent,
    ShopActionModelComponent,
    ShopMeetGiftPageComponent,
    ShopRewardCardListPageComponent,
    ShopRewardCardEditPageComponent,
    ShopRewardCodeListPageComponent,
    ShopRewardCodeEditPageComponent,
    ShopRewardCardAnalysisPageComponent,
    ShopRichMenuListPageComponent,
    ShopRichMenuEditPageComponent,
    ShopCardMessageListPageComponent,
    ShopCardMessageEditPageComponent,
    CardPlaceComponent,
    CardProductComponent,
    CardPeopleComponent,
    CardPictureComponent,
    ShopCourseComponent,
    ShopCourseListPageComponent,
    ShopCourseEditPageComponent,
    ShopQuestionnareListPageComponent,
    ShopQuestionnareEditPageComponent,
    ShopFriendComponent,
    ShopFriendListPageComponent,
    ShopTagListPageComponent,
    ShopAdComponent,
    ShopAdPurchasePageComponent,
    ShopAdListPageComponent,
    ShopBillingComponent,
    ShopBillingMessageListPageComponent,
    ShopBillingPurchasePageComponent,
    ShopBillingBillListPageComponent,
    ShopBillingPaymentPageComponent,
    ShopStoreComponent,
    ShopStoreCategoryPageComponent,
    ShopStoreProductListPageComponent,
    ShopStoreProductEditPageComponent,
    ShopStoreOrderListPageComponent,
    ShopStoreOrderEditPageComponent,
    ShopStoreSettingPageComponent,
    ShopChatComponent,
    ScrollableDirective,
    OrderBy,
    ShopNewsComponent,
    ShopSettingComponent,
    ShopBillingReceiptPageComponent,
    ShopSettingSetiingPageComponent,
    ShopSettingPermissionPageComponent,
    ShopBillingDiscountPageComponent,
    ShopSettingThemePickerDialogComponent,
    ShopAgentComponent,
    ShopAgentClientListPageComponent,
    ShopAgentSettingPageComponent,
    ShopAgentFeeListPageComponent,
    ShopAgentFeePageComponent,
    ShopAgentClientShareListPageComponent,
    CoursePickerDialogComponent,
    RewardCardPickerDialogComponent,
    ShopCardMessageColorPickerComponent,
    ShopCardMessagePeopleComponent,
    ShopFriendShareLinkListPageComponent,
    ShopCourseShareListPageComponent,
    FriendPickerDialogComponent,
    ShopCourseDetailListPageComponent,
    ShopCardMessageProductComponent,
    ShopCardMessagePlaceComponent,
    ShopCardMessagePictureComponent,
    ShopAgentPointPageComponent,
    ShopAgentClientPaymentPageComponent,
    ShopBillingPointPageComponent,
    ShopEventComponent,
    ShopEventListPageComponent,
    ShopEventDetailListPageComponent,
    ShopEventEditPageComponent,
    ShopEventShareListPageComponent,
    EventPickerDialogComponent,
    ShopNotSupportPageComponent,
    ShopBillingExtraFeaturesPageComponent,
    StoreProductPickerDialogComponent,
    StoreProductCategoryPickerDialogComponent,
    ShopBillingOrderListPageComponent,
    ShopBookingComponent,
    ShopBookingServicePageComponent,
    ShopBookingServiceListPageComponent,
    ShopBookingServiceCalendarComponent
  ],
  imports: [
    CommonModule,
    ShopRoutingModule,
    MatProgressSpinnerModule,
    UiModule,
    FormsModule,
    QRCodeModule,
    ClipboardModule,
    MatTabsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    FontAwesomeModule,
    MatExpansionModule,
    NgbModule,
    TagInputModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    AngularEditorModule,
    HttpClientModule,
    ColorPickerModule,
    FroalaEditorModule.forRoot(), FroalaViewModule.forRoot(), MatRadioModule, MatDialogModule, FacebookModule.forRoot(),
    ShareButtonsModule.withConfig(customConfig), MatButtonModule, NzCalendarModule, NgZorroAntdModule
  ]
})
export class ShopModule { }
