import Timestamp = firebase.firestore.Timestamp;
import * as firebase from 'firebase';

export interface FAdOrder {
  position: number; //   1見面禮 2主頁推薦 3首次登入推薦 (4酷券  5QRCode  6URL)
  shopId: string; // 店家ID
  follower: number; // 加入好友數
  targetFollower: number; // 目標好友數
  startTime?: Date| Timestamp ; // 廣告開始時間
  endTime?: Date| Timestamp; // 廣告結束時間
  createTime: Date| Timestamp; // 建立時間
  totalFee: number; // 訂單金額
  note: string; // 訂單說明
  days: number;
  pay: boolean;
  enabled: boolean;
  agent?: boolean;
}
export function getPlanString(order: FAdOrder) {
  switch (order.position) {
    case 1:
      return '見面禮推薦版位';

    case 2:
      return '主頁推薦版位';
    case 3:
      return '首次註冊推薦版位';
    default:
      return '錯誤';
  }
}
export function getPlanStatus(ad: FAdOrder) {
  if (ad.startTime) {
    // @ts-ignore
    // tslint:disable-next-line:max-line-length
    return ad.startTime.toDate().toLocaleString('zh-tw', this.dateOption ) + ' - ' + ad.endTime.toDate().toLocaleString('zh-tw', this.dateOption );
  } else {
    return '尚未刊登';
  }

}
