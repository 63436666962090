<div class="d-flex justify-content-between align-items-center mb-2">
	<h2 class="mb-0">點數明細</h2>
</div>
<hr>
<div class="form-group row w-fix-800">
	<label class="col-sm-3 col-form-label text-sm-right font-weight-light">目前擁有點數</label>
	<div class="col-sm-9">
		<div class="form-inline text-primary">
			{{point}}
		</div>
	</div>
</div>

<hr>

<table class="table table-hover table-layout-fixed">
	<thead class="thead-light">
	<tr>
		<th class="w-20">
      <span class="">
        付款時間
      </span>
		</th>
		<th class="w-10">
      <span class=""><!---->
        類型
      </span>
		</th>
		<th class="">
      <span class=""><!---->
        官方帳號
      </span>
		</th>
		<th class="">
      <span ><!---->
        價格
      </span>
		</th>
	</tr>
	</thead>
	<tbody>
	<tr *ngFor="let record of currentPage" class="">
		<td class="w-20">
      <span class=""><!---->
	      {{record.timestamp.toDate().toLocaleString()}}
      </span>
		</td>
		<td class="w-10">
      <span class=""><!---->
	      {{getTypeString(record.type)}}
      </span>
		</td>
		<td>
			<div class="div3">
				<div  class=" avatar avatar-sm rounded-circle div1">
					<div class="w-100 h-100" [ngStyle]="{'background': 'url(' + record.shop.photoUrl + ') center center / cover'}"></div>
				</div>
				<div class="div2">
					<label class="user col-form-label text-sm-left font-weight-normal">{{record.shop.displayName}}</label>
					<!--            <label class="user col-form-label-xs text-sm-left font-weight-light">{{shop.brief }}</label>-->
				</div>
			</div>
		</td>
		<td>
      <span class=""><!---->
	      {{record.price}}
      </span>
		</td>
	</tr>
	</tbody>
</table>
<hr>
<app-list-paginator [data]="pointRecord" (listChanged)="currentPage = $event"></app-list-paginator>
