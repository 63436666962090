<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
<div id="page" class="content phone">

  <app-main-nav id="header"></app-main-nav>

  <router-outlet></router-outlet>
  <footer id="footer" class="border-top bg-white">
    <div><span class="text-muted">©&nbsp;LINECAST&nbsp;Inc</span></div>
    <div class="ml-4">
      <a href="http://biz.linecast.com.tw/circle/index.php/2019/10/14/conditions_of_use/" target="terms" class="text-info small">服務條款</a>
      <span routerLink="Api/Doc" target="policy" class="text-info small ml-3">API說明</span>
      <a href="http://biz.linecast.com.tw/circle/index.php/2019/10/14/guidelines/" target="policy" class="text-info small ml-3">操作準則</a>
      <a href="http://biz.linecast.com.tw/circle/index.php/2019/10/14/terms_trademark/" target="policy" class="text-info small ml-3">著作權聲明</a>
    </div>
  </footer>
</div>

