import { Component, OnInit } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {AuthService} from '../../../../../firebase/auth.service';
import {ShopService} from '../../../../services/shop.service';
import {FUser} from '../../../../../firebase/models/user';
import {environment} from '../../../../../../environments/environment';
import {FShopManager} from '../../../../../firebase/models/shop';
import {faCheck} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-shop-setting-permission-page',
  templateUrl: './shop-setting-permission-page.component.html',
  styleUrls: ['./shop-setting-permission-page.component.styl']
})
export class ShopSettingPermissionPageComponent implements OnInit {

  faCheck = faCheck
  Modal = false;
  invitationModal = false;
  editModal = false;
  invitationURl = '';
  permissionType = 'ADMIN';
  users: Manager[] = [];
  currentUser: FUser;
  editUserId: string;
  constructor(private auth: AuthService,
              private afs: AngularFirestore,
              private ss: ShopService) { }

  ngOnInit() {
    this.users = [];
    for (const [key, value] of Object.entries(this.ss.currentShop.managers)) {
      if (value.enabled === true) {
        this.afs.doc<FUser>('users/' + key).get().subscribe(user => {
          this.users.push( {
            enables: value.enabled,
            owner: value.owner,
            permission: this.permissionToString(value.permission),
            id: key,
            displayName: user.data().displayName,
            photoUrl: user.data().photoUrl
          });
        });
      }
    }
  }
  generateInvitation() {
    this.afs.collection('invitations').add({
      createTime: new Date(),
      shopId: this.ss.currentShop.uid,
      permission: this.permissionToNumber(this.permissionType)
    }).then(ref => {
      this.invitationURl = 'https://s.casttalk.me/' + 'invitations/' + ref.id;
    });
  }
  changePermission() {
    this.ss.currentShopDoc.update({
      [`managers.${this.editUserId}`]: {
        owner: false,
        enabled: true,
        permission: this.permissionToNumber(this.permissionType)
      }
    }).then(() => {
      this.closeModal();
    });
  }
  openEditModal(user: Manager) {
    this.editUserId = user.id;
    this.Modal = true;
    this.editModal = true;
  }
  openInvitationModal() {
    this.Modal = true;
    this.invitationModal = true;
    this.editModal = false;

  }
  closeModal() {
    this.invitationModal = false;
    this.Modal = false;
    this.editModal = false;
  }
  permissionToNumber(n: string): number {
    switch (n) {
      case 'ADMIN': {
        return 0;
      }
      case 'MARKET': {
        return 1 ;
      }
      case 'SUPPORT': {
        return 2 ;
      }
      default: {
        return 6 ;
      }
    }
  }
  permissionToString(n: number): string {
    switch (n) {
      case 0: {
        return '管理員';
      }
      case 1: {
        return '行銷專員' ;
      }
      case 2: {
        return '客服專員' ;
      }
      default: {
        return '' ;
      }
    }
  }
  deleteManager() {
    this.ss.currentShopDoc.update({
      [`managers.${this.editUserId}`]: {
        owner: false,
        enabled: false,
        permission: 6
      }
    }).then(() => {
      this.closeModal();
    });
  }

}
interface Manager {
  id: string;
  owner: boolean;
  permission: string;
  enables: boolean;
  displayName: string;
  photoUrl: string;
}
