import { Component, OnInit } from '@angular/core';
import {FAdOrder} from '../../../../../firebase/models/ad';
import {ShopService} from '../../../../services/shop.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {AngularFireFunctions} from '@angular/fire/functions';
import {ModalService} from '../../../../../shared/services/modal.service';
import {PayBillWithPointRequest} from '../../../../../../../functions/src/agent/point';
import {ShopPayAdWithPointRequest} from '../../../../../../../functions/src/shop/point';
import {DocumentChangeAction} from '@angular/fire/firestore/interfaces';
import {NotifyService} from '../../../../../shared/services/notify.service';

@Component({
  selector: 'app-shop-ad-list-page',
  templateUrl: './shop-ad-list-page.component.html',
  styleUrls: ['./shop-ad-list-page.component.styl']
})
export class ShopAdListPageComponent implements OnInit {

  ads: DocumentChangeAction<FAdOrder>[] = [];
  currentPage: DocumentChangeAction<FAdOrder>[] = [];
  constructor(private ss: ShopService,
              private db: AngularFirestore,
              private func: AngularFireFunctions,
              private ms: ModalService,
              private notify: NotifyService) { }

  ngOnInit(): void {
    this.db.collection<FAdOrder>('advOrders', ref => ref.where('shopId', '==', this.ss.currentShop.uid))
        .snapshotChanges().subscribe(ads => {
      this.ads = ads;
    });
  }

  getPlanString(order: FAdOrder) {
    switch (order.position) {
      case 1:
        return '見面禮推薦版位';

      case 2:
        return '主頁推薦版位';
      case 3:
        return '首次註冊推薦版位';
    }
  }
  getPlanStatus(ad: FAdOrder) {
    if (ad.startTime) {
      // @ts-ignore
      // tslint:disable-next-line:max-line-length
      return ad.startTime.toDate().toLocaleString('zh-tw', this.dateOption ) + ' - ' + ad.endTime.toDate().toLocaleString('zh-tw', this.dateOption );
    } else {
      return '尚未刊登';
    }
  }
  payWithPoint(ad: DocumentChangeAction<FAdOrder>) {
    this.ms.openConfirmDialog('確認付款', '付款金額: ' + ad.payload.doc.data().totalFee).afterClosed().subscribe(res => {
      if (res) {
        const data: ShopPayAdWithPointRequest = {
          shopId: this.ss.currentShop.uid,
          advId: ad.payload.doc.id
        };
        const payFunction = this.func.httpsCallable('ShopPayAdWithPoint');
        payFunction(data).subscribe(x => {
          this.notify.pop(x);
        });
      }
    });
  }
  getPaymentLink(order: FAdOrder){
    const memo = this.getPlanString(order);
    const url = 'https://n.gomypay.asia/TestShuntClass.aspx?Send_Type=0&Pay_Mode_No=2&CustomerId=42638468&Order_No=A1592806799998&TransMode=1&Callback_Url=https://us-central1-circle-70720.cloudfunctions.net/test&Amount=' + order.totalFee + '&Installment=0&TransCode=00&Buyer_Memo=' + memo;
    window.open(url, 'test', 'width=700,height=300');
  }

}
