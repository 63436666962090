<div>
	<div class="d-flex justify-content-between align-items-center mb-2">
		<h2 class="mb-0">集點卡一覽</h2>
		<button routerLink="Create" type="button" class="btn btn-primary w-min-120">建立</button>
	</div>
	<hr>
	<section style="">
		<div>
			<table class="table table-layout-fixed">

				<thead class="thead-light">
				<tr>
					<th class="">
            <span class="">
              集點卡名稱
            </span></th>
					<th class="">
            <span class="cursor-pointer">
              截止時間
            </span>
					</th>
					<th class="">
            <span class=""><!---->
              狀態
            </span>
					</th>
					<th class="">
            <span class=""><!---->
              領取紀錄
            </span>
					</th>
				</tr>
				</thead>
				<tbody>
				<tr *ngFor="let rewardCard of rewardCards">
					<td>
						<a [routerLink]="rewardCard.payload.doc.id">
							{{rewardCard.payload.doc.data().title}}
						</a>
					</td>
					<td>
						{{rewardCard.payload.doc.data().endTime.toDate().toLocaleString()}}
					</td>
					<td>
						{{rewardCard.payload.doc.data().enabled ? '啟用' : '停用'}}
						<button *ngIf="rewardCard.payload.doc.data().enabled" (click)="disableRewardCard()">停用</button>
						<button *ngIf="!rewardCard.payload.doc.data().enabled" (click)="enableRewardCard(rewardCard)">啟用</button>
					</td>
					<td>
						<a [routerLink]="'Analysis/' + rewardCard.payload.doc.id">
							領取紀錄
						</a>
					</td>
				</tr>
				</tbody>
			</table>
			<hr>
			<div>
			</div>
		</div>
	</section>
	<div>
	</div>
</div>
