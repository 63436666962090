import { Component, OnInit } from '@angular/core';
import {FRichContent} from '../../../../../firebase/models/message';
import {ShopService} from '../../../../services/shop.service';
import {DocumentChangeAction} from '@angular/fire/firestore';

@Component({
  selector: 'app-shop-rich-message-list-page',
  templateUrl: './shop-rich-message-list-page.component.html',
  styleUrls: ['./shop-rich-message-list-page.component.styl']
})
export class ShopRichMessageListPageComponent implements OnInit {

  richMessages: DocumentChangeAction<FRichContent>[] = [];
  currentPage: DocumentChangeAction<FRichContent>[];
  constructor(private ss: ShopService) { }
  ngOnInit() {
    this.ss.currentShopDoc.collection<FRichContent>('richMessages',
        ref => ref.orderBy('createTime', 'desc')).snapshotChanges().subscribe(messages => {
      this.richMessages = messages;
    });
  }
}
