import { Component, OnInit } from '@angular/core';
import {DocumentChangeAction} from '@angular/fire/firestore';
import {FAutoResponse} from '../../../../../firebase/models/autoResponse';
import {ShopService} from '../../../../services/shop.service';
import {NotifyService} from '../../../../../shared/services/notify.service';

@Component({
  selector: 'app-shop-auto-response-list-page',
  templateUrl: './shop-auto-response-list-page.component.html',
  styleUrls: ['./shop-auto-response-list-page.component.styl']
})
export class ShopAutoResponseListPageComponent implements OnInit {

  docs: DocumentChangeAction<FAutoResponse>[];
  constructor(private ss: ShopService,
              private notify: NotifyService) { }

  ngOnInit() {
    this.ss.currentShopDoc.collection<FAutoResponse>('autoReplies').snapshotChanges().subscribe(ar => {
      this.docs = ar;
    });
  }
  toggleEnabled(event, id: string) {
    this.ss.currentShopDoc.collection('autoReplies').doc(id).update({
      enabled: event.checked
    });
    this.notify.pop('修改成功');
  }

}
