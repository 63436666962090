import { Component, OnInit } from '@angular/core';
import {FShop} from '../../../../../firebase/models/shop';
import {AngularFirestore} from '@angular/fire/firestore';
import {ShopService} from '../../../../services/shop.service';

@Component({
  selector: 'app-shop-agent-client-list-page',
  templateUrl: './shop-agent-client-list-page.component.html',
  styleUrls: ['./shop-agent-client-list-page.component.styl']
})
export class ShopAgentClientListPageComponent implements OnInit {

  client: FShop[] = [];
  currentPage: FShop[] = [];
  constructor(private ss: ShopService,
              private db: AngularFirestore) {
  }

  ngOnInit(): void {
    this.db.collection<FShop>('shops', ref => ref.where('agentRef', '==', this.ss.currentShopDoc.ref))
        .valueChanges().subscribe(shops => {
      this.client = shops;
    });
  }
}
