import {Component, OnInit} from '@angular/core';
import {ShopService} from '../../../../services/shop.service';
import {FQuestion, FQuestionnaire} from '../../../../../firebase/models/questionnaire';
import {AngularFirestore, DocumentChangeAction} from '@angular/fire/firestore';
import * as firebase from 'firebase';
import Timestamp = firebase.firestore.Timestamp;
import {FBooking} from '../../../../../firebase/models/booking';

@Component({
  selector: 'app-shop-questionnare-list-page',
  templateUrl: './shop-questionnare-list-page.component.html',
  styleUrls: ['./shop-questionnare-list-page.component.styl']
})
export class ShopQuestionnareListPageComponent implements OnInit {

  qnrs: DocumentChangeAction<FQuestionnaire>[] = [];
  currentPage: DocumentChangeAction<FQuestionnaire>[] = [];

  constructor(private ss: ShopService,
              private db: AngularFirestore) { }

  ngOnInit(): void {
    this.ss.currentShopDoc.collection<FQuestionnaire>('questionnaires').snapshotChanges().subscribe(qnrs => {
      this.qnrs = qnrs;
    });

  }
  getStatusString(qnr: DocumentChangeAction<FQuestionnaire>) {
    return (qnr.payload.doc.data().endTime as Timestamp).toDate() > new Date() ? '進行中' : '過期';
  }
  download(data: DocumentChangeAction<FQuestionnaire>, filename: string){
    this.db.collectionGroup('questionnaires',
        ref => ref.where('ref', '==', data.payload.doc.ref)).get().subscribe(x => {
          const qnrData = x.docs.map(y => {
            const res = y.data() as any;
            delete res.ref;
            delete res.user.uid;
            delete res.survey.documentId;
            delete res.survey.shop;
            res.survey.createTime = res.survey?.createTime?.toDate().toLocaleString();
            res.createTime = res.createTime?.toDate();
            res.survey.startTime = res.survey?.startTime?.toDate();
            res.survey.endTime = res.survey?.endTime?.toDate();
            console.log(res);
            return res;
          });

          const csvData = this.ConvertToCSV(qnrData);
          const a: any = document.createElement('a');
          a.setAttribute('style', 'display:none;');
          document.body.appendChild(a);
          const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
          a.href = window.URL.createObjectURL(blob);
          const isIE = /*@cc_on!@*/false || !!(document as any).documentMode;
          if (isIE)
          {
            const retVal = navigator.msSaveBlob(blob, filename + '.csv');
          }
          else{
            a.download = filename + '.csv';
          }
          // If you will any error in a.download then dont worry about this.
          a.click();
    });

  }


  // convert Json to CSV data
  ConvertToCSV(objArray: any[]) {
    // console.log(objArray);
    let res = '';
    objArray.forEach(qnr => {
      res += qnr.user.displayName + ',';
      res += qnr.survey.createTime + ',';
      if (qnr.survey.questions) {
        qnr.survey.questions.forEach(qus => {
          res += qus.title + ',';
          qus.answers.forEach(ans => {
            if (qus.choiceType === 2) {
              res += ans.title + ': ';
              res += ans.result + ' ';
            } else {
              if (ans.isCheck) {
                res += ans.title + ' ';
              }
            }
          });
          res += ',';
        });
      }
      res += '\r\n';
    });

    return res;
    // const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    // let str = '';
    // let row = '';
    // const we = Object.entries(objArray);
    // console.log(we);
    // // tslint:disable-next-line:forin
    // for (const index in objArray[0])
    // {
    //   // Now convert each value to string and comma-seprated
    //   row += index + ',';
    // }
    // row = row.slice(0, -1);
    // // append Label row with line break
    // str += row + '\r\n';
    //
    // // tslint:disable-next-line:prefer-for-of
    // for (let i = 0; i < array.length; i++) {
    //   let line = '';
    //   // tslint:disable-next-line:forin
    //   for (const index in array[i]) {
    //     if (line !== '') { line += ','; }
    //
    //     line += '"' + array[i][index] + '"';
    //   }
    //
    //   str += line + '\r\n';
    // }
    //
    // console.log(str);
    // return str;
  }


}
