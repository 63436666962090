import { Component, OnInit } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {ShopService} from '../../../../services/shop.service';
import {FAgentPointRecord} from '../../../../../firebase/models/agent';

@Component({
  selector: 'app-shop-agent-point-page',
  templateUrl: './shop-agent-point-page.component.html',
  styleUrls: ['./shop-agent-point-page.component.styl']
})
export class ShopAgentPointPageComponent implements OnInit {

  point = 0;
  pointRecord: FAgentPointRecord[] = [];
  currentPage: FAgentPointRecord[] = [];
  constructor(private db: AngularFirestore,
              private ss: ShopService) { }

  ngOnInit(): void {
    this.db.collection('agentPoints').doc<any>(this.ss.currentShop.uid).valueChanges().subscribe(p => {
      if (p.point) {
        this.point = p.point;
      }
    });
    this.db.collection('agentPoints').doc(this.ss.currentShop.uid)
        .collection<FAgentPointRecord>('pointRecord').valueChanges().subscribe(x => {
      console.log(x);
      this.pointRecord = x;
    });
  }
  getTypeString(type: number) {
    if ( type === 0) {
      return '訊息';
    } else {
      return '廣告';
    }
  }

}
