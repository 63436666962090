<div class="d-flex justify-content-between align-items-center mb-2"><h2 class="mb-0">新聞稿內容</h2>
</div>
<hr>
<form #form="ngForm">


</form>
<div *ngIf="News">
	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">所屬標籤</label>
		<div class="col-sm-9">
			<div class="kv-select form-group d-flex align-items-end">
				<div class="flex-grow-1">
					<div class="input-group">
						<tag-input [theme]="'foundation-theme'"   [secondaryPlaceholder]= "'選取標籤'" [placeholder]="'選取標籤'"  [(ngModel)]="tags" name="tags" [onlyFromAutocomplete] = "true"  >
							<tag-input-dropdown [autocompleteItems]= "tagsSource" [showDropdownIfEmpty]= "true">
							</tag-input-dropdown>
						</tag-input>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">顯示設定</label>
		<div class="col-sm-9">
			<div class="kv-select form-group d-flex align-items-end">
				<div class="flex-grow-1">
					<div >
						<div class="align-items-center">
							<mat-checkbox class="mt-2" [checked] = News.isHot (change)="News.isHot = $event.checked"></mat-checkbox>
							熱門區大圖
						</div>
						<div>
							<mat-checkbox [checked] = News.isTop (change)="News.isTop = $event.checked"></mat-checkbox>
							標籤內置頂
						</div>
						<div>
							<mat-checkbox [checked] = News.isHead (change)="News.isHead = $event.checked"></mat-checkbox>
							標籤內大圖
						</div>
						<div>
							<mat-checkbox [checked] = News.isMarquee (change)="News.isMarquee = $event.checked"></mat-checkbox>
							跑馬燈(快訊)
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">狀態</label>
		<div class="col-sm-9">
			<div class="kv-select form-group d-flex align-items-end">
				<div class="flex-grow-1">
					<div class="input-group">
						<div class="select" >
							<select class="form-control custom-select form-control-rg" [(ngModel)]="News.status" name="status" required   >
								<option [ngValue]="0">審稿中</option>
								<option [ngValue]="1">上刊</option>
								<option [ngValue]="2">停刊</option>
								<option [ngValue]="3">退回改稿</option>
							</select>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">標題</label>
		<div class="col-sm-9">
			<div class="form-inline text-muted">
				<input [maxLength]="20" [(ngModel)]="News.title" required name="title" class="form-control w-80 mr-2 " style="width: 400px !important;" type="text" placeholder="標題">
				<span class="">
          <strong >{{ News.title.length}}</strong>
          <span class="">/20</span>
        </span>
			</div>
		</div>
	</div>

	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">作者</label>
		<div class="col-sm-9">
			<div class="form-inline text-muted">
				<input [maxLength]="30" [(ngModel)]="News.author" required name="author" class="form-control w-80 mr-2 " style="width: 400px !important;" type="text" placeholder="作者">
				<span class="">
          <strong >{{ News.author.length}}</strong>
          <span class="">/30</span>
        </span>
			</div>
		</div>
	</div>

	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">圖片</label>
		<div class="col-sm-9">
			<input  [(ngModel)]="News.photoUrl" name="img" required class="form-control w-80 mr-2 " style="width: 400px !important;" type="text" placeholder="圖片網址">
			<div class="figure figure-grid figure-grid-xl border position-relative mr-3 mb-3">
				<img  [src]="News.photoUrl" alt="01" class="figure-img">
			</div>
		</div>
	</div>
	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">內文</label>
		<div class="col-sm-9">
			<angular-editor [config]="froala.config" [placeholder]="'內文'" [(ngModel)]="News.desc" name="desc"></angular-editor>
		</div>
	</div>

	<hr>
	<div class="d-flex justify-content-center my-5">
		<button (click)="save()" [disabled]="form.invalid" class="btn btn-lg btn-primary px-5 mx-1">儲存</button>
	</div>
</div>
