<div class="modal-content h-100">
	<div class="modal-header  flex-shrink-0">
		<h5 class="mb-0">選擇好友</h5>
		<button (click)="dialogRef.close()" class="close">×</button>
	</div>
	<div class="modal-body mh-100 overflow-hidden">
		<div class="form-group row w-fix-400">
<!--			<label class="col-sm-3 col-form-label text-sm-right font-weight-light">名稱搜尋</label>-->
			<div class="d-flex pl-2">
				<div class="form-inline text-muted">
					<input [(ngModel)]="keyword"  maxlength="15" type="text" name="name" placeholder="請輸入名稱" autocomplete="off" class="form-control w-fix-240">
					<div class="btn-group ">
						<button [disabled]="keyword === ''" (click)="search()" type="button" style="margin-left: 2rem" class=" btn btn-primary w-min-100 h-35">搜尋</button>
					</div>
				</div>
			</div>

		</div>
		<hr class="m-0">
		<div class="overflow-auto h-fix-400">
			<div class="list-group list-group-flush pr-3">
				<div *ngFor="let friend of friends " class="list-group-item d-flex justify-content-between align-items-center px-0">
					<div class=" mr-4">
						<div class="div3">
							<div  class=" avatar avatar-sm rounded-circle div1">
								<div class="w-100 h-100" [ngStyle]="{'background': 'url(' + friend.payload.doc.data().user.photoUrl + ') center center / cover'}"></div>
							</div>
							<div class="div2">
								<label class="user col-form-label text-sm-left font-weight-normal">{{friend.payload.doc.data().user.displayName}}</label>
								<!--            <label class="user col-form-label-xs text-sm-left font-weight-light">{{user.user.statusMessage }}</label>-->
							</div>
						</div>
					</div>
					<div class="flex-none ml-3">
						<button (click)="onSelect(friend)"  type="button" class="btn btn-outline-primary">選擇</button>
					</div>
					<hr>
				</div>
			</div>
			<div class="infinite-loading-container">
				<div *ngIf="friends.length === 0 && keyword === ''" class="infinite-status-prompt">
					<div class="text-center py-5 my-5">請輸入搜尋條件</div>
				</div>
				<div *ngIf="result !== ''" class="infinite-status-prompt">
					<div class="text-center py-5 my-5">{{result}}</div>
				</div>
			</div>
		</div>
	</div>
	<div class="d-flex flex-row flex-shrink-0 modal-footer flex-row-reverse">
		<button (click)="dialogRef.close()" type="button" class="btn btn-secondary">關閉</button>
	</div>
</div>
