import { Component, OnInit } from '@angular/core';
import {FMonthlyTotal, getPlanName} from 'src/app/firebase/models/chargePlan';
import {ActivatedRoute} from '@angular/router';
import {AngularFirestore} from '@angular/fire/firestore';

@Component({
  selector: 'app-admin-accounts-mothly-income-list-page',
  templateUrl: './admin-accounts-mothly-income-list-page.component.html',
  styleUrls: ['./admin-accounts-mothly-income-list-page.component.styl']
})
export class AdminAccountsMothlyIncomeListPageComponent implements OnInit {

  currentPage = [];
  NewsListSource = [];
  constructor(private db: AngularFirestore) { }

  ngOnInit(): void {
    this.parseBill();
  }
  parseBill() {
    this.db.collectionGroup('monthlyTotals').get().subscribe(x => {
      const data = this.groupBy(x.docs, 'id');
      const rawData = Object.entries(data);
      rawData.forEach(month => {
        // @ts-ignore
        const totalFee = month[1].reduce((total, monthly) => {
          if (monthly.data().totalFee) {
            // @ts-ignore
            return total + monthly.data().totalFee;
          } else {
            return total;
          }

        }, 0);
        const monthlyMessageRev = {
          month: month[0],
          // @ts-ignore
          data: month[1],
          totalFee
        };
        this.NewsListSource.push(monthlyMessageRev);
      });

    });
  }
  groupBy(xs, key) {
    return xs.reduce((rv, x) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

}
