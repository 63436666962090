<div class="d-flex justify-content-between align-items-center mb-2"><h2 class="mb-0">月費設定</h2>
</div>
<hr>

<div class="d-flex flex-column">
  <form #form="ngForm">
    <div class=" my-4">
      <div class="form-group row ">
        <label class="w-fix-120 col-form-label text-sm-right font-weight-light">名稱</label>
        <div class="col-sm-9">
          <div class="form-inline text-muted">
            <input [(ngModel)]="newPlan.name" required name="name"  autocomplete="off" class="form-control w-fix-240">
          </div>
        </div>
      </div>
      <div class="form-group row ">
        <label class="w-fix-120 col-form-label text-sm-right font-weight-light">價格</label>
        <div class="col-sm-9">
          <div class="form-inline text-muted">
            <input [(ngModel)]="newPlan.price" required name="price" type="number"  autocomplete="off" class="form-control w-fix-240">
          </div>
        </div>
      </div>
      <div class="form-group row ">
        <label class="w-fix-120 col-form-label text-sm-right font-weight-light">好友數限制</label>
        <div class="col-sm-9">
          <div class="form-inline text-muted">
            <input [(ngModel)]="newPlan.followerLimit" required name="followerLimit" type="number"  autocomplete="off" class="form-control w-fix-240">
          </div>
        </div>
      </div>
      <div class="form-group row ">
        <label class="w-fix-120 col-form-label text-sm-right font-weight-light">額外訊息費率</label>
        <div class="col-sm-9">
          <div class="form-inline text-muted">
            <input [(ngModel)]="newPlan.extraFeePerMessage" required name="extraFeePerMessage" type="number"  autocomplete="off" class="form-control w-fix-240">
          </div>
        </div>
      </div>

      <div class="btn-group ">
        <button [disabled]="form.invalid" (click)="addPlan()" type="button" style="margin-left: 12rem" class=" btn btn-primary w-min-100 h-35">建立</button>
      </div>
    </div>
  </form>
  <div class="d-flex align-items-center">
<!--    <input>-->
<!--    <span>人以上 </span>-->
<!--    <span>月費</span>-->
<!--    <input>-->
<!--    <span>每</span>-->
<!--    <input>-->
<!--    <span>人數 *</span>-->
<!--    <input>-->
<!--    <span>元</span>-->
<!--    <span>，每日</span>-->
<!--    <input>-->
<!--    <span>則訊息</span>-->

    <div class="btn-group ">
      <button (click)="addBigPlan()" type="button" style="margin-left: 12rem" class=" btn btn-primary w-min-100 h-35">建立超額方案</button>
    </div>
  </div>
</div>
<hr>
<table class="table table-hover table-layout-fixed">
  <thead class="thead-light">
  <tr>
    <th class="">
      <span class="">
        名稱
      </span>
    </th>
    <th class="">
      <span class=""><!---->
        價格
      </span>
    </th>
    <th class="">
      <span ><!---->
        好友數上限
      </span>
    </th>
    <th class="">
      <span ><!---->
        每日訊息數
      </span>
    </th>
    <th class="">
      <span ><!---->
        超額訊息費用（每則）
      </span>
    </th>
<!--    <th class="">-->
<!--      <span >&lt;!&ndash;&ndash;&gt;-->
<!--        狀態-->
<!--      </span>-->
<!--    </th>-->
    <th class="">
      <span ><!---->
        動作
      </span>
    </th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let plan of plans" class="">
    <td>
      <input [(ngModel)]="plan.name" class="form-control" required>
    </td>
    <td>
      <span class=""><!---->
        <input [(ngModel)]="plan.price" class="form-control" type="number" required>
      </span>
    </td>
    <td>
      <span class=""><!---->
        <input [(ngModel)]="plan.followerLimit" class="form-control" type="number"  required>
      </span>
    </td>
    <td>
      <span class=""><!---->
        <input [(ngModel)]="plan.messagePerDay" class="form-control" type="number" required>
      </span>
    </td>
    <td>
      <span class=""><!---->
        <input [(ngModel)]="plan.extraFeePerMessage" class="form-control" type="number"  required>
      </span>
    </td>
<!--    <td>-->
<!--      <span class="cursor-pointer text-primary" (click)="toggleEnabled(plan)">&lt;!&ndash;&ndash;&gt;-->
<!--        {{plan.enabled ? '啟用' : '停用'}}-->
<!--      </span>-->
<!--    </td>-->
    <td>
      <span class="cursor-pointer text-primary" (click)="save(plan)"><!---->
        儲存
      </span>
    </td>
  </tr>
  </tbody>
</table>
<hr>
<table class="table table-hover table-layout-fixed">
  <thead class="thead-light">
  <tr>
    <th class="">
      <span class="">
        名稱
      </span>
    </th>
    <th class="">
      <span class=""><!---->
        價格
      </span>
    </th>
    <th class="">
      <span ><!---->
        起始人數
      </span>
    </th>
    <th class="">
      <span ><!---->
        人數單位
      </span>
    </th>
    <th class="">
      <span ><!---->
        每單位/元
      </span>
    </th>
    <th class="">
      <span ><!---->
        超額訊息費用（每則）
      </span>
    </th>
    <th class="">
      <span ><!---->
        每日/則
      </span>
    </th>
<!--    <th class="">-->
<!--      <span >&lt;!&ndash;&ndash;&gt;-->
<!--        狀態-->
<!--      </span>-->
<!--    </th>-->
    <th class="">
      <span ><!---->
        動作
      </span>
    </th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let plan of outRangePlans" class="">
    <td>
      <input [(ngModel)]="plan.name" class="form-control" required>
    </td>
    <td>
      <span class=""><!---->
        <input [(ngModel)]="plan.price" class="form-control" type="number" required>
      </span>
    </td>
    <td>
      <span class=""><!---->
        <input [(ngModel)]="plan.outRangeFollowers" class="form-control" type="number"  required>
      </span>
    </td>
    <td>
      <span class=""><!---->
        <input [(ngModel)]="plan.followerUnit" class="form-control" type="number" required>
      </span>
    </td>
    <td>
      <span class=""><!---->
        <input [(ngModel)]="plan.followerUnitPrice" class="form-control" type="number" required>
      </span>
    </td>
    <td>
      <span class=""><!---->
        <input [(ngModel)]="plan.extraFeePerMessage" class="form-control" type="number"  required>
      </span>
    </td>
    <td>
      <span class=""><!---->
        <input [(ngModel)]="plan.messagePerDay" class="form-control" type="number"  required>
      </span>
    </td>
<!--    <td>-->
<!--      <span class="cursor-pointer text-primary" >&lt;!&ndash;&ndash;&gt;-->
<!--        {{plan.enabled ? '啟用' : '停用'}}-->
<!--      </span>-->
<!--    </td>-->
    <td>
      <span class="cursor-pointer text-primary" (click)="saveBigPlan(plan)" ><!---->
        儲存
      </span>
    </td>
  </tr>
  </tbody>
</table>
<hr>
