<div>
	<div>
		<div class="d-flex justify-content-between align-items-center mb-2">
			<h2 class="mb-0">圖文選單</h2> <a routerLink="Create" class="btn btn-primary w-fix-120">
			建立
		</a></div>
		<p class="text-muted">
			您可建立具有視覺效果的互動型選單，於聊天室中提供優惠券、網址連結及相關行銷資訊以進行宣傳。
		</p>
		<hr>
	</div>
	<section>
		<div style="">
			<div class="position-relative overflow-auto">
				<table class="table table table-hover">
					<thead class="thead-light">
					<tr>
						<th class="">
							<span class="">
                標題
							</span>
						</th>
						<th class="">
							<span class="">
                圖片
							</span>
						</th>
						<th>
							<span class ="">啟用</span>
						</th>
					</thead>
					<tbody>
					<tr *ngFor="let richMenu of richMenus" class="">
						<td><a [routerLink]=" richMenu.payload.doc.id" class="text-dark hljs-link">
							{{richMenu.payload.doc.data().title}}
						</a></td>
						<td>
							<a [routerLink]="'/Home/RichMenu/' + richMenu.payload.doc.id">
								<img [src]="richMenu.payload.doc.data().photoUrl" width="200"></a>
						</td>
						<td>
							<span *ngIf="ss.currentShop.advRef && ss.currentShop.advRef.path === richMenu.payload.doc.ref.path; else select" class="badge avatar-badge badge-primary">已選擇</span>
							<ng-template #select>
								<button (click)="onSelect(richMenu)" type="button" class="btn btn-secondary">選擇
								</button>
							</ng-template>
						</td>
					</tr>
					</tbody>
				</table>
			</div>
			<hr>
			<div>
			</div>
		</div>
	</section>
</div>
