<form #form="ngForm" (invalid)="log($event)">
	<div>
		<div class="d-flex justify-content-between align-items-center mb-2">
			<h2 class="mb-0">多頁訊息</h2> </div>
		<div class="text-muted text-pre-line">您可將多項要宣傳的同類型內容集結成一則「多頁訊息」並傳送。 多頁訊息將以橫向捲軸的格式呈現，用戶可左右滑動來瀏覽其他頁面的內容。
		</div>
		<hr>
	</div>
	<div class="btn-toolbar justify-content-between my-4">
		<div class="btn-group">
			<button *ngIf="!create && cardMessageDoc" (click)="delete()" type="button" class="mr-2 btn btn-secondary">刪除</button>
		</div>
		<div class="btn-group">
			<button (click)="save()" type="button" class="w-min-120 btn btn-primary">儲存</button>
		</div>
	</div>
	<div>
		<hr class="my-4">
		<section>
			<h4 class="mb-4">標題</h4>
			<div class="form-group row w-max-800">
				<label class="col-sm-3 col-form-label text-sm-right font-weight-light"></label>
				<div class="col-sm-9">
					<div>
						<input [(ngModel)]="cardMessage.title" required maxlength="20" placeholder="輸入標題" type="text" name="mainTitle" autocomplete="off" class="form-control w-100 mr-2" aria-required="true" aria-invalid="false">
						<span class="float-right">
              <strong class="text-info">
                {{cardMessage.title.length}}
              </strong>
              <span class="">/20</span></span>
					</div>
					<small class="form-text text-muted">訊息標題將顯示於推播通知及聊天一覽中。</small></div>
			</div>
		</section>
		<hr class="my-4">
	</div>
	<div>
		<div>
			<h4 class="mb-4">頁面設定 <i class="lar la-question-circle ml-1"></i></h4>
			<div>
				<div class="form-group row w-max-800">
					<label class="col-sm-3 col-form-label text-sm-right font-weight-light">頁面類型</label>
					<div class="col-sm-9">
						<div class="kv-select form-group d-flex align-items-end">
							<div class="flex-grow-1">
								<div class="input-group">
									<div class="select" >
										<select class="form-control custom-select form-control-rg" [(ngModel)]="cardMessage.cardType" (ngModelChange)="cardTypeChanged()" name="cardType" required >
											<option value="select">選擇</option>
											<option value="product">商品服務</option>
											<option value="people">人物</option>
											<option value="place">地點</option>
											<option value="picture">影像</option>
										</select>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="mt-n3 p-2 d-flex justify-content-end">
			<button *ngIf="pages.length < 9" (click)="addPage()" type="button" class="btn btn-outline-primary">新增頁面</button>
		</div>
		<nav id="nav" class="bg-white border-bottom pt-1 flex-shrink-0">
			<div class="container-fluid d-flex justify-content-between align-items-center">
				<ul class="nav nav-bordered">
					<ng-container>
						<li *ngFor="let page of pages; index as i" class="nav-item position-relative">
							<a [ngClass]="{'router-link-exact-active active-ignore active': pageIndex === i}" (click)="pageIndex = i" class="nav-link">{{i}}</a>
						</li>
					</ng-container>
				</ul>
			</div>
		</nav>
		<ng-container *ngIf="cardMessage.cardType === 'people'">
			<app-shop-card-message-people *ngFor="let page of pages; index as i"  [ngClass]="{'d-none': pageIndex !== i}" required  [(ngModel)]="pages[pageIndex]" (cardData)="cardMessage.pages[pageIndex] = $event"  [name]="'card' + i"></app-shop-card-message-people>

		</ng-container>
	</div>
	<hr class="my-4">
	<section>
		<div  class="my-4 d-inline-block">
			<h4>結尾頁 <i  class="lar la-question-circle ml-1"></i></h4>
			<p  class="text-muted">將顯示為多頁訊息中的最後一頁，您可為其設定相關連結以吸引用戶點選。</p>
		</div>
		<div  class="d-flex">
			<div  class="flex-1 ml-4 w-max-800">
				<div class="form-group row mx-0">
					<div class="col-sm-12 col-form-label pl-0">
						<div class="kv-checkbox form-check custom-control custom-checkbox mb-3">
							<input #closePageActive [checked]="cardMessage.closePage" (change)="closePageChecked(closePageActive.checked)" id="8os3v30fq58" name="viewmore" type="checkbox" class="custom-control-input">
							<label for="8os3v30fq58" class="custom-control-label">加入結尾頁</label>
						</div>
					</div>
				</div>
				<div *ngIf="closePageActive.checked">
					<div class="form-group row w-max-800">
						<label class="col-sm-3 col-form-label text-sm-right font-weight-light">版型</label>
						<div class="col-sm-9">
							<select class="w-fix-160 form-control custom-select form-control-rg" [(ngModel)]="cardMessage.closePage.type" name="closePageType">
								<option [ngValue]="0">無圖</option>
								<option [ngValue]="1">有圖</option>
							</select>
						</div>
					</div>
					<div *ngIf="cardMessage.closePage.type === 1" class="form-group row w-max-800">
						<label class="col-sm-3 col-form-label text-sm-right font-weight-light">圖片</label>
						<input #closeImg="ngModel" [(ngModel)]="cardMessage.closePage.image" required name="closeImg" class="d-none">
						<div class="col-sm-9">
								<div class="form-inline">
									<div>
										<div  [ngClass]="{'border-danger' : closeImg.invalid}" *ngIf="cardMessage.closePage.image === ''" class="figure figure-grid cursor-pointer border position-relative mr-2">
											<a (click)="openClosePageUploadImageDialog()" href="javascript:void(0)">上傳圖片</a>
										</div>
										<div *ngIf="cardMessage.closePage.image !== ''" class="figure figure-grid cursor-pointer border position-relative mr-2">
											<img [src]="cardMessage.closePage.image" class="figure-img">
											<button (click)="cardMessage.closePage.image = ''" type="button" class="position-absolute top-right close"><i class="fas fa-window-close"></i></button>
										</div>
									</div>
								</div>
						</div>
					</div>
					<app-shop-action-model required [actionText]="true" [(ngModel)]="cardMessage.closePage.action" name="closeAction"></app-shop-action-model>
				</div>
			</div>
		</div>
	</section>
	<div class="d-flex justify-content-center p-4">
		<div class="p-4">
			<button (click)="save()" [disabled]="form.invalid" type="button" class="px-5 btn btn-primary btn-undefined">儲存</button>
		</div>
	</div>
</form>
