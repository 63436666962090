import {Component, Inject, OnInit} from '@angular/core';
import {FUser} from '../../../../../../firebase/models/user';
import {FShop} from '../../../../../../firebase/models/shop';
import {AngularFirestore, AngularFirestoreDocument} from '@angular/fire/firestore';
import {FChargePlan, FMonthlyTotal, getPlanName} from '../../../../../../firebase/models/chargePlan';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ModalService} from '../../../../../../shared/services/modal.service';
import {NotifyService} from '../../../../../../shared/services/notify.service';
import {RouteService} from '../../../../../../shared/services/route.service';
import {ShopService} from '../../../../../../shop/services/shop.service';
import {ActivatedRoute} from '@angular/router';
import {IconService} from '../../../../../../shared/services/icon.service';

@Component({
  selector: 'app-admin-shop-management-page',
  templateUrl: './admin-shop-management-page.component.html',
  styleUrls: ['./admin-shop-management-page.component.styl']
})
export class AdminShopManagementPageComponent implements OnInit {


  getPlanName = getPlanName;
  manager: FUser;
  shop: FShop;
  shopDoc: AngularFirestoreDocument;
  chargePlan: FChargePlan;
  chargePlanDoc: AngularFirestoreDocument;
  monthlyTotal: FMonthlyTotal;
  monthlyTotalDoc: AngularFirestoreDocument;
  constructor(private activatedRoute: ActivatedRoute,
              private db: AngularFirestore,
              private ms: ModalService,
              private notify: NotifyService,
              private rs: RouteService,
              private ss: ShopService,
              public icon: IconService) { }

  ngOnInit(): void {
    const id = this.activatedRoute.snapshot.paramMap.get('id');

    this.shopDoc = this.db.collection('shops').doc(id);
    this.shopDoc.valueChanges().subscribe((shop: FShop) => {
      this.shop = shop;
      Object.keys(shop.managers).forEach(manager => {
        if (shop.managers[manager].owner) {
          this.db.collection('users').doc<FUser>(manager).get().subscribe((user: any) => {
            this.manager = user.data();
          });
        }
      });
      this.db.collection('shops').doc(this.shop.uid)
          .collection<FMonthlyTotal>('monthlyTotals', ref => ref.orderBy('createTime', 'desc').limit(2)).get().subscribe(bill => {
        // @ts-ignore
        this.monthlyTotal = bill.docs[1].data();
        this.monthlyTotalDoc = this.db.doc(bill.docs[1].ref.path);
      });
      this.chargePlanDoc = this.db.collection('chargePlan').doc(this.shop.uid);
      this.chargePlanDoc.valueChanges().subscribe((chargePlan: FChargePlan) => {
        this.chargePlan = chargePlan;
      });
    });

  }
  openOffsetDialog() {
    this.ms.openInputEditor('修改好友數offset').afterClosed().subscribe((result: string) => {
      if (result) {
        this.shopDoc.update({
          followerOffset: Number(result)
        }).then(() => {
          this.notify.pop('修改成功');
        });
      }
    });
  }
  homePageOrderChanged() {
    this.shopDoc.update({
      homePageOrder: this.shop.homePageOrder
    }).then(() => {
      this.notify.pop('修改成功');
    });
  }
  startPageOrderChanged() {
    this.shopDoc.update({
      startPageOrder: this.shop.startPageOrder
    }).then(() => {
    });
  }
  MeetGiftOrderChanged() {
    this.shopDoc.update({
      'meetGift.order': this.shop.meetGift.order
    }).then(() => {
      this.notify.pop('修改成功');
    });
  }
  toggleStore(event) {
    this.shopDoc.update({
      store: event.checked
    }).then(() => {
      this.notify.pop('修改成功');
    });
  }
  toggleCourse(event) {
    this.shopDoc.update({
      course: event.checked
    }).then(() => {
      this.notify.pop('修改成功');
    });
  }
  toggleEnabled(event) {
    this.db.doc('shops/' + this.shop.uid).update({
      enabled: event.checked
    });
    this.notify.pop('修改成功');
  }
  toggleMessagePermission(event) {
    this.db.doc('chargePlan/' + this.shop.uid).update({
      enabled: event.checked
    });
    this.notify.pop('修改成功');
  }
  toggleMonthPay(event) {
    this.monthlyTotalDoc.update({
      payStatus: event.checked
    });
    this.notify.pop('修改成功');
  }
  hackShop() {
    this.shop.permission = 0;
    this.ss.shops.push(this.shop);
    this.ss.selectShop(this.shop);
    this.rs.navigateTo('');
  }

}
