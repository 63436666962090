<div id="border" [ngClass]="{invalid: !valid, valid: valid }" class="card border-secondary">
	<ul ngbNav #nav="ngbNav" [(activeId)]="message.type" (activeIdChange)="tabChanged()" class="nav-pills">

		<li [ngbNavItem] = FMessageType.text >
			<a ngbNavLink>
				<fa-icon  [icon]="faCommentAlt" ngbTooltip="文字" class="icon btn btn-light far fa-comment-alt"></fa-icon>
			</a>
			<ng-template ngbNavContent>
				<div name="text card" class="card-body">
					<div class="control text-muted">
						<textarea appCursorPosition  (cursorPosition)="cursorPosition = $event" [(ngModel)]="($any(message.content)).text" (ngModelChange)="messageChanged()" name="notes" maxlength="5000" rows="5" class="form-control"></textarea>
						<span class="float-right"><strong class="">{{$any(message.content).text.length}}</strong><span class="">/500</span></span>
					</div>
					<button type="button" (click)="addEmoji()" class="btn btn-outline-secondary mr-2 mt-3">表情貼</button>
				</div>
			</ng-template>
		</li>

		<li [ngbNavItem] = FMessageType.photo>
			<a ngbNavLink>
				<fa-icon [icon]="faImage" ngbTooltip="圖片" class="icon btn btn-light"></fa-icon>
			</a>
			<ng-template ngbNavContent>
				<div class="card-body position-relative overflow-auto">
					<div *ngIf="$any(message.content).photoUrl !== '' && $any(message.content).photoUrl" class="figure figure-grid figure-grid-sm border position-relative mr-3 mb-3">
						<img [src]="$any(message.content).photoUrl" (change)="messageChanged()" alt="01" class="figure-img">
						<button (click)="$any(message.content).photoUrl = ''; messageChanged();" type="button" class="position-absolute close" style="top: 0px; right: 0px;">
							<fa-icon [icon]="faWindowClose"></fa-icon>
						</button>
					</div>
					<div *ngIf="$any(message.content).photoUrl === '' || !$any(message.content).photoUrl">
						<div class="border text-center">
							<div  class="figure figure-grid-sm my-4"><a (click)="uploadImage()" href="javascript:void(0)">上傳照片</a>
								<div class="text-muted opacity-30">
									<fa-icon [icon]="faImage" size="3x"></fa-icon>

								</div>
							</div>
						</div>
					</div>
				</div>
			</ng-template>
		</li>
		<li [ngbNavItem] = FMessageType.rich>
			<a ngbNavLink>
				<fa-icon [icon]="faCommentAlt" ngbTooltip="圖文訊息" class="icon btn btn-light"></fa-icon>
			</a>
			<ng-template ngbNavContent>
				<div class="card-body position-relative overflow-auto">
					<div *ngIf="$any(message.content).photoUrl === ''; else richMessage" class="border text-center">
						<div class="figure figure-grid-sm my-4"><a (click)="openRichMessagePicker()" href="javascript:void(0)">選擇圖文訊息</a>
							<div class="text-muted opacity-30">
								<fa-icon [icon]="faCommentAlt" size="3x"></fa-icon>

							</div>
						</div>
					</div>
					<ng-template #richMessage>
						<div class="position-relative border">
							<div class="d-flex align-items-start">
								<div class="flex-none figure figure-grid figure-grid-sm"><img [src]="$any(message.content).photoUrl" class="figure-img"></div>
								<div class="flex-1 text-truncate p-3 h-fix-120 overflow-y-auto">
									<div class="text-truncate"><span class="text-dark">{{$any(message.content).name}}</span></div>
								</div>
							</div>
							<button (click)="$any(message.content).photoUrl = ''; messageChanged()" type="button" class="position-absolute close" style="top: 0px; right: 0px;">
								<fa-icon [icon]="faWindowClose"></fa-icon>
							</button>
						</div>
					</ng-template>
				</div>
			</ng-template>
		</li>
		<li [ngbNavItem] = FMessageType.video>
			<a ngbNavLink>
				<fa-icon [icon]="faVideo" ngbTooltip="影片" class="icon btn btn-light"></fa-icon>
			</a>
			<ng-template ngbNavContent>
					<div class="card-body position-relative overflow-auto">
						<div *ngIf="$any(message.content).videoUrl !== ''" class="figure figure-grid figure-grid-sm border position-relative mr-3 mb-3">
							<video [src]="$any(message.content).videoUrl" alt="01" class="figure-img"></video>
							<button (click)="$any(message.content).videoUrl = '' ; messageChanged()" type="button" class="position-absolute close" style="top: 0px; right: 0px;">
								<fa-icon [icon]="faWindowClose"></fa-icon>
							</button>
						</div>
						<div *ngIf="$any(message.content).videoUrl === ''">
							<div class="border text-center">
								<div  class="figure figure-grid-sm my-4"><a (click)="openUploadVideoDialog()" href="javascript:void(0)">上傳影片</a>
									<div class="text-muted opacity-30">
										<fa-icon [icon]="faVideo" size="3x"></fa-icon>
									</div>
								</div>
							</div>
						</div>
					</div>
			</ng-template>
		</li>
		<li [ngbNavItem] = FMessageType.coupon>
			<a ngbNavLink>
				<fa-icon [icon]="faGift" ngbTooltip="優惠券" class="icon btn btn-light"></fa-icon>
			</a>
			<ng-template ngbNavContent>
				<div class="card-body position-relative overflow-auto">
					<div *ngIf=" $any(message.content).ref"  class="position-relative border">
						<div class="d-flex align-items-center">
							<div class="flex-none figure figure-grid figure-grid-sm"><img [src]="$any(message.content).photoUrl" class="figure-img"></div>
							<div class="flex-1 text-truncate p-3">
								<div class="text-truncate"><span class="text-dark">{{$any(message.content).title}}</span></div> <small class="text-muted">{{"有效期限: " + $any(message.content).endTime.toDate().toLocaleString()}}</small></div>
						</div>
						<button (click)="$any(message.content).ref = null; messageChanged()" type="button" class="position-absolute close" style="top: 0px; right: 0px;">
							<fa-icon [icon]="faWindowClose"></fa-icon>
						</button>
					</div>
					<div *ngIf="!$any(message.content).ref" class="border text-center">
						<div class="figure figure-grid-sm my-4">
							<a (click)="openCouponPicker()" href="javascript:void(0)">選擇優惠券</a>
							<div class="text-muted opacity-30">
								<fa-icon [icon]="faGift" size="3x"></fa-icon>
							</div>
						</div>
					</div>
				</div>
			</ng-template>
		</li>
		<li [ngbNavItem] = FMessageType.qnr>
			<a ngbNavLink>
				<fa-icon [icon]="faClipboard" ngbTooltip="問卷調查" class="icon btn btn-light"></fa-icon>
			</a>
			<ng-template ngbNavContent>
				<div class="card-body position-relative overflow-auto">
					<div *ngIf="$any(message.content).qnrRef === null" class="border text-center">
						<div  class="figure figure-grid-sm my-4">
							<a (click)="openQnrPicker()" href="javascript:void(0)">選擇問卷調查</a>
							<div class="text-muted opacity-30">
								<fa-icon [icon]="faClipboard" size="3x"></fa-icon>
							</div>
						</div>
					</div>
					<div class="position-relative border" *ngIf="$any(message.content).qnrRef">
						<button (click)="$any(message.content).qnrRef = null; messageChanged()" type="button" class="position-absolute close" style="top: 0px; right: 0px;">
							<fa-icon [icon]="faWindowClose"></fa-icon>
						</button>
						<div class="d-flex align-items-center">
							<div class="flex-none figure figure-grid figure-grid-sm">
								<img [src]="$any(message.content).photoUrl" class="figure-img figure-img">
							</div>
							<div class="flex-1 text-truncate p-3">
								<div class="text-truncate">
									<span class="text-dark">{{$any(message.content).title}}</span>
								</div>
								<small class="text-muted">問卷調查的結束時間： {{$any(message.content).endTime.toDate().toLocaleString()}}</small>
							</div>
						</div>
					</div>
				</div>
			</ng-template>
		</li>
		<li [ngbNavItem] = FMessageType.course>
			<a ngbNavLink>
				<fa-icon [icon]="faAddressCard" ngbTooltip="課程" class="icon btn btn-light"></fa-icon>
			</a>
			<ng-template ngbNavContent>
				<div class="card-body position-relative overflow-auto">
					<div *ngIf="$any(message.content).ref === null" class="border text-center">
						<div  class="figure figure-grid-sm my-4">
							<a (click)="openCoursePicker()" href="javascript:void(0)">選擇課程</a>
							<div class="text-muted opacity-30">
								<fa-icon [icon]="faAddressCard" size="3x"></fa-icon>
							</div>
						</div>
					</div>
					<div class="position-relative border" *ngIf="$any(message.content).ref">
						<button (click)="$any(message.content).ref = null; messageChanged()" type="button" class="position-absolute close" style="top: 0px; right: 0px;">
							<fa-icon [icon]="faWindowClose"></fa-icon>
						</button>
						<div class="d-flex align-items-center">
							<div class="flex-none figure figure-grid figure-grid-sm">
								<img [src]="$any(message.content).photoUrl" class="figure-img figure-img">
							</div>
							<div class="flex-1 text-truncate p-3">
								<div class="text-truncate">
									<span class="text-dark">{{$any(message.content).name}}</span>
								</div>
								<small class="text-muted">課程開始時間： {{$any(message.content).eventStartTime.toDate().toLocaleString()}}</small>
							</div>
						</div>
					</div>
				</div>
			</ng-template>
		</li>
		<li [ngbNavItem] = FMessageType.event>
			<a ngbNavLink>
				<fa-icon [icon]="icon.faCalendarAlt" ngbTooltip="活動" class="icon btn btn-light"></fa-icon>
			</a>
			<ng-template ngbNavContent>
				<div class="card-body position-relative overflow-auto">
					<div *ngIf="$any(message.content).ref === null" class="border text-center">
						<div  class="figure figure-grid-sm my-4">
							<a (click)="openEventPicker()" href="javascript:void(0)">選擇活動</a>
							<div class="text-muted opacity-30">
								<fa-icon [icon]="icon.faCalendarAlt" size="3x"></fa-icon>
							</div>
						</div>
					</div>
					<div class="position-relative border" *ngIf="$any(message.content).ref">
						<button (click)="$any(message.content).ref = null; messageChanged()" type="button" class="position-absolute close" style="top: 0px; right: 0px;">
							<fa-icon [icon]="faWindowClose"></fa-icon>
						</button>
						<div class="d-flex align-items-center">
							<div class="flex-none figure figure-grid figure-grid-sm">
								<img [src]="$any(message.content).photoUrl" class="figure-img figure-img">
							</div>
							<div class="flex-1 text-truncate p-3">
								<div class="text-truncate">
									<span class="text-dark">{{$any(message.content).name}}</span>
								</div>
								<small class="text-muted">活動開始時間： {{$any(message.content).eventStartTime.toDate().toLocaleString()}}</small>
							</div>
						</div>
					</div>
				</div>
			</ng-template>
		</li>
	</ul>
	<div [ngbNavOutlet]="nav"></div>
</div>
