<div id="container" class="bg-light d-flex flex-column">
  <div id="wrapper" class="d-flex align-items-stretch flex-fill">
    <aside id="menu" class="overflow-auto w-fix-280 flex-shrink-0 flex-grow-0 position-relative border-right">
      <div class="container-fluid bg-light py-3 min-vh-100">
        <ul class="nav flex-column">
          <li class="nav-item"><a routerLink="Services" routerLinkActive="router-link-exact-active active" class="nav-link d-inline-block">服務設定</a>
          </li>
        </ul>
      </div>

    </aside>
    <div id="contents" class="w-100 d-flex flex-column position-relative bg-white" style="min-width: calc(100% - 280px);">
      <main class="m-5" >
        <router-outlet *ngIf="(ss.chargePlanLoaded| async ) && (ss.shopLoaded | async)"></router-outlet>
      </main>
    </div>

  </div>
</div>
