<div>
	<div class="d-flex justify-content-between align-items-center mb-2">
		<h2 class="mb-0">優惠券</h2>
		<button routerLink="Create" type="button" class="btn btn-primary w-min-120">建立</button>
	</div>
	<hr>
	<section style="">
		<div>
			<table class="table table-layout-fixed">
				<thead class="thead-light">
				<tr>
					<th class=""><span class="">
          優惠券名稱
						</span></th>
					<th class=""><span class="cursor-pointer">
          有效期間
         <i class="fas fas-sm ml-1 fa-sort text-muted"></i></span></th>
					<th class=""><span class="">
          狀態
						</span></th>
				</tr>
				</thead>
				<tbody>
				<tr *ngFor="let coupon of couponCurrentPage">
					<td>
						<a [routerLink]="coupon.payload.doc.id" class="d-flex align-items-center hljs-link text-dark">
							<div class="flex-shrink-0 figure figure-grid figure-grid-xs position-relative mr-2"><img [src]="coupon.payload.doc.data().photoUrl" alt="" class="rounded figure-img"></div>
							<div class="text-truncate-2">{{coupon.payload.doc.data().title}}</div>
						</a>
					</td>
					<td>
						{{coupon.payload.doc.data().startTime.toDate().toLocaleString()}} ~<br>{{coupon.payload.doc.data().endTime.toDate().toLocaleString()}}
						<br><small class="text-muted"></small></td>
					<td><span class="text-success">{{getStatusWord(coupon.payload.doc.data().startTime.toDate(), coupon.payload.doc.data().endTime.toDate())}}</span></td>
				</tr>
				</tbody>
			</table>
			<hr>
			<app-list-paginator [data]="coupons" (listChanged)="couponCurrentPage = $event"></app-list-paginator>

			<div>
			</div>
		</div>
	</section>
	<div>
	</div>
</div>
