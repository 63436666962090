import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {UiModule} from '../shared/ui/ui.module';
import {AdminRoutingModule} from './admin-routing.module';
import { AdminRootPageComponent } from './pages/admin-root-page/admin-root-page.component';
import { AdminShopListPageComponent } from './pages/admin-root-page/admin-shop/admin-shop-list-page/admin-shop-list-page.component';
import { AdminShopComponent } from './pages/admin-root-page/admin-shop/admin-shop.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {FormsModule} from '@angular/forms';
import { AdminShopCategoryListPageComponent } from './pages/admin-root-page/admin-shop/admin-shop-category-list-page/admin-shop-category-list-page.component';
import { AdminUiComponent } from './pages/admin-root-page/admin-ui/admin-ui.component';
import { AdminUiHomePageComponent } from './pages/admin-root-page/admin-ui/admin-ui-home-page/admin-ui-home-page.component';
import { AdminUiPlanPageComponent } from './pages/admin-root-page/admin-ui/admin-ui-plan-page/admin-ui-plan-page.component';
import { AdminUiPaymentPageComponent } from './pages/admin-root-page/admin-ui/admin-ui-payment-page/admin-ui-payment-page.component';
import {FroalaEditorModule, FroalaViewModule} from 'angular-froala-wysiwyg';
import { AdminNewsComponent } from './pages/admin-root-page/admin-news/admin-news.component';
import { AdminNewsTagPageComponent } from './pages/admin-root-page/admin-news/admin-news-tag-page/admin-news-tag-page.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { AdminShopMeetGiftListPageComponent } from './pages/admin-root-page/admin-shop/admin-shop-meet-gift-list-page/admin-shop-meet-gift-list-page.component';
import { AdminAgentListPageComponent } from './pages/admin-root-page/admin-agent/admin-agent-list-page/admin-agent-list-page.component';
import { AdminAgentComponent } from './pages/admin-root-page/admin-agent/admin-agent.component';
import { AdminAccountsComponent } from './pages/admin-root-page/admin-accounts/admin-accounts.component';
import { AdminAccountsMothlyIncomeListPageComponent } from './pages/admin-root-page/admin-accounts/admin-accounts-mothly-income-list-page/admin-accounts-mothly-income-list-page.component';
import { AdminAccountsMothlyIncomePageComponent } from './pages/admin-root-page/admin-accounts/admin-accounts-mothly-income-list-page/admin-accounts-mothly-income-page/admin-accounts-mothly-income-page.component';
import { AdminShopAuthListPageComponent } from './pages/admin-root-page/admin-shop/admin-shop-auth-list-page/admin-shop-auth-list-page.component';
import { AdminShopManageDialogComponent } from './pages/admin-root-page/admin-shop/admin-shop-list-page/admin-shop-manage-dialog/admin-shop-manage-dialog.component';
import { AdminShopManagementPageComponent } from './pages/admin-root-page/admin-shop/admin-shop-list-page/admin-shop-management-page/admin-shop-management-page.component';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {AngularEditorModule} from '@kolkov/angular-editor';
import { AdminAccountsPointCardPageComponent } from './pages/admin-root-page/admin-accounts/admin-accounts-point-card-page/admin-accounts-point-card-page.component';
import {OwlDateTimeModule} from '@danielmoncada/angular-datetime-picker';
import { AdminUiExtraServicePageComponent } from './pages/admin-root-page/admin-ui/admin-ui-extra-service-page/admin-ui-extra-service-page.component';
import { AdminOthersComponent } from './pages/admin-root-page/admin-others/admin-others.component';
import { AdminOthersCourseTagPageComponent } from './pages/admin-root-page/admin-others/admin-others-course-tag-page/admin-others-course-tag-page.component';
import { AdminOthersEventTagPageComponent } from './pages/admin-root-page/admin-others/admin-others-event-tag-page/admin-others-event-tag-page.component';
import { AdminExtraFeaturesComponent } from './pages/admin-root-page/admin-extra-features/admin-extra-features.component';
import { AdminExtraFeaturesPricePageComponent } from './pages/admin-root-page/admin-extra-features/admin-extra-features-price-page/admin-extra-features-price-page.component';
import { AdminAccountPlanSetttingPageComponent } from './pages/admin-root-page/admin-accounts/admin-account-plan-settting-page/admin-account-plan-settting-page.component';



@NgModule({
  declarations: [AdminRootPageComponent,
    AdminShopListPageComponent,
    AdminShopComponent,
    AdminShopCategoryListPageComponent,
    AdminUiComponent,
    AdminUiHomePageComponent,
    AdminUiPlanPageComponent,
    AdminUiPaymentPageComponent,
    AdminNewsComponent,
    AdminNewsTagPageComponent,
    AdminShopMeetGiftListPageComponent,
    AdminAgentListPageComponent,
    AdminAgentComponent,
    AdminAccountsComponent,
    AdminAccountsMothlyIncomeListPageComponent,
    AdminAccountsMothlyIncomePageComponent,
    AdminShopAuthListPageComponent,
    AdminShopManageDialogComponent,
    AdminShopManagementPageComponent,
    AdminAccountsPointCardPageComponent,
    AdminUiExtraServicePageComponent,
    AdminOthersComponent,
    AdminOthersCourseTagPageComponent,
    AdminOthersEventTagPageComponent,
    AdminExtraFeaturesComponent,
    AdminExtraFeaturesPricePageComponent,
    AdminAccountPlanSetttingPageComponent],
  imports: [
    CommonModule,
    UiModule,
    AdminRoutingModule,
    FontAwesomeModule,
    FormsModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    MatCheckboxModule,
    NgbTooltipModule,
    AngularEditorModule,
    OwlDateTimeModule,
  ]
})
export class AdminModule { }
