import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {FCourse} from '../../../../../../firebase/models/course';
import {datetimeFix} from '../../../../../../firebase/extensions/datetimeFixed';
import {ShopService} from '../../../../../services/shop.service';
import {NotifyService} from '../../../../../../shared/services/notify.service';
import {RouteService} from '../../../../../../shared/services/route.service';
import {IconService} from '../../../../../../shared/services/icon.service';
import {StorageService} from '../../../../../../shared/services/storage.service';
import {UploadImageDialogData} from '../../../../../../shared/ui/upload-image-dialog/upload-image-dialog.component';
import {FBooking} from '../../../../../../firebase/models/booking';
import {AngularFirestore, DocumentChangeAction} from '@angular/fire/firestore';
import Timestamp = firebase.firestore.Timestamp;
import * as firebase from 'firebase';
import {FQuestionnaire} from '../../../../../../firebase/models/questionnaire';
import {ModalService} from '../../../../../../shared/services/modal.service';

@Component({
  selector: 'app-shop-booking-service-page',
  templateUrl: './shop-booking-service-page.component.html',
  styleUrls: ['./shop-booking-service-page.component.styl']
})
export class ShopBookingServicePageComponent implements OnInit {

  minDate = new Date();
  create = false;
  serviceDoc;
  service: FBooking = {
    desc: '',
    enabled: false,
    name: '',
    photoUrl: '',
    qnrRef: null,
    schedule: {
      endDate: undefined,
      startDate: undefined,
      time: [{endTime: new Date('2020/07/02'), startTime: new Date('2020/07/02')}],
      weekday: {FRI: false, MON: false, SAT: false, SUN: false, THU: false, TUE: false, WED: false}
    },
    services: [],
    shop: undefined
  };
  constructor(private activatedRoute: ActivatedRoute,
              private ss: ShopService,
              private notify: NotifyService,
              private router: RouteService,
              public icon: IconService,
              private storage: StorageService,
              private db: AngularFirestore,
              private ms: ModalService) { }

  ngOnInit(): void {
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    if (id === 'Create') {
      this.create = true;
    } else  {
      this.create = false;
      this.serviceDoc = this.db.collection('bookings').doc(id);
      this.serviceDoc.get().subscribe(service => {
        if (service.exists) {
          this.service = service.data();
          // @ts-ignore
          this.service.schedule.endDate = this.service.schedule.endDate.toDate();
          // @ts-ignore
          this.service.schedule.startDate = this.service.schedule.startDate.toDate();
          this.service.schedule.time = this.service.schedule.time.map(x => {
            return {
              // @ts-ignore
              startTime: x.startTime.toDate(),
              // @ts-ignore
              endTime: x.endTime.toDate()
            };
          });
        } else {
          this.notify.pop('服務不存在');
          this.router.navigateTo('/Booking');
        }
      });
    }
  }
  openQnrPicker() {
    this.ms.openQnrPicker().afterClosed().subscribe((qnr: DocumentChangeAction<FQuestionnaire>) => {
      if (qnr) {
        this.service.qnrRef = qnr.payload.doc.ref;
        this.service.qnrData = qnr.payload.doc.data();
      }
    });
  }
  openImgUploadDialog() {
    const info: UploadImageDialogData = {height: 350, type: 'image', width: 640};
    this.storage.openUploadDialog(info).afterClosed().subscribe(result => {
      if (result) {
        this.service.photoUrl = result;
      }
    });
  }
  save() {
    this.service.shop = this.ss.shopInfo;
    if (this.create) {
      this.db.collection('bookings').add(this.service).then(() => {
        this.notify.pop('已儲存');
        this.router.navigateTo('/Services');
      });
    } else {
      this.serviceDoc.update(this.service).then(() => {
        this.notify.pop('已儲存');
        this.router.navigateTo('/Booking/Services');
      });
    }
  }

  addService(): void {
    this.service.services.push({
      name: '', period: 0, price: null
    });
  }
  addTime() {
    const date = {endTime: new Date('2020/07/02'), startTime: new Date('2020/07/02')};
    this.service.schedule.time.push(date);
  }
  log() {
    console.log(this.service);
  }

}
