import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../firebase/auth.service';
import {ShopService} from '../../shop/services/shop.service';
// icons
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import {Router} from '@angular/router';
import {FShop} from '../../firebase/models/shop';


@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.styl']
})
export class MainNavComponent implements OnInit {
  faQuestionCircle = faQuestionCircle;
  faUser = faUser;
  faBan = faBan;
  faUserFriends = faUserFriends;
  constructor(public auth: AuthService,
              public ss: ShopService,
              private router: Router) { }

  ngOnInit(): void {
  }
  logout() {
    this.auth.signOut();
  }
  changeShop(shop: FShop) {
    this.ss.selectShop(shop);
    this.router.navigate(['/Account/' + shop.lineAt]);
  }
}
