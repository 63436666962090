import { Component, OnInit } from '@angular/core';
import {AngularFirestore, DocumentChangeAction, DocumentReference} from '@angular/fire/firestore';
import {ShopService} from '../../../../services/shop.service';
import {FSubscribe, FUser} from '../../../../../firebase/models/user';
import { faBan, faUserFriends } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-shop-friend-list-page',
  templateUrl: './shop-friend-list-page.component.html',
  styleUrls: ['./shop-friend-list-page.component.styl']
})
export class ShopFriendListPageComponent implements OnInit {

  faUserFriends = faUserFriends;
  faBan = faBan;
  friends: Friend[] = [];
  friendsSource: Friend[] = [];
  currentPage: Friend[] = [];
  name = '';
  constructor(private db: AngularFirestore,
              public ss: ShopService) { }

  ngOnInit(): void {
    this.db.collectionGroup<FSubscribe>('subscribes', ref =>
        ref.where('shopId', '==', this.ss.currentShop.uid)
            .orderBy('subscribeTime', 'desc'))
        .snapshotChanges().subscribe(users => {
          // @ts-ignore
      this.friendsSource = users.map(x => {
            // @ts-ignore
            const data: Friend = x.payload.doc.data();
            data.ref = x.payload.doc.ref;
            // @ts-ignore
            data.tags = this.ss.tagForSearchToTag(data.tags);
            return data;
          });
      this.nameSearch();
    });

  }
  tagChanged(user: Friend) {
    // @ts-ignore
    const tags = this.ss.tagToTagForSearch(user.tags);
    const tagsA = Object.keys(tags);
    console.log(tagsA);
    user.ref.update({
      // @ts-ignore
      tags,
      tagsA
    });
  }
  nameSearch() {
    this.friends = this.friendsSource.filter(friend => {
      return friend.user.displayName.includes(this.name);
    });
  }
  tagSearch() {}
}
interface Friend extends FSubscribe{
  ref: DocumentReference;
}
