import Timestamp = firebase.firestore.Timestamp;
import * as firebase from 'firebase';
import { FActionModel, FTagsForSearch, FUrls } from './shared';
import { DocumentReference } from '@angular/fire/firestore';

export interface FPushMessage extends FMessage {
  index?: number;
  title: string;
  shop: FShopInMessage;
  sent: number;
  tags: FTagsForSearch;

}

export interface FDraftMessage {
  title: string;
  shop: FShopInMessage;
  sent: number;
  tags: FTagsForSearch;
  timestamp: Timestamp|Date;
  schedule: Timestamp|Date;
  messages: FMessage[];
}

export interface FMessage {
  timestamp: Timestamp|Date;
  type: FMessageType;
  content: FTextContent | FPhotoContent | FVideoContent | FQuestionnaireContent | FCouponContent | FRichContent | FCourseContent;
  urlTags?: any;
}

export interface FTextContent {
  text: string;
}

export interface FPhotoContent {
  photoUrl: string;
  urls: FUrls;
}

export interface FVideoContent {
  videoUrl: string;
  link: string;
  linkName: string;
}

export interface FQuestionnaireContent {
  title: string;
  photoUrl: string;
  couponLink: string;
  endTime: Timestamp|Date;
  qnrRef: DocumentReference | null;
}

export interface FCouponContent {
  photoUrl: string; // #圖片網址
  title: string; // #標題
  endTime: Timestamp|Date; // #結束時間
  ref: DocumentReference | null; // #關聯到Coupon 的Ref
}

export interface FRichContent  {
  name: string;
  photoUrl: string;
  urls: FActionModel[];
  styleType: number;
  createTime: Timestamp|Date;
}

export interface FCourseContent {
  photoUrl: string; // 課程圖片
  name: string; // 課程名稱
  endTime: Timestamp|Date; // 報名截止時間
  eventStartTime: Timestamp|Date; // 活動開始時間
  eventEndTime: Timestamp|Date; // 活動結束時間
  ref: DocumentReference; // 關聯到Course 的Ref
}

export interface FShopInMessage {
  uid: string;
  displayName: string;
  photoUrl: string;
  desc: string;
  lineAt: string;
  category: string;
}
export enum FMessageType {
  text = 0,
  photo = 1,
  rich = 18,
  video = 13,
  coupon = 15,
  qnr = 12,
  course = 20,
  event = 21,
}

export interface FChatMessage extends FMessage {
  idFrom: string;
  idTo: string;
  user: {
    uid: string;
    displayName: string;
    photoUrl: string;
  };
}
