<div>
	<div class="d-flex justify-content-between align-items-center mb-2">
		<h2 class="mb-0">印製用的點數發放行動條碼
		</h2>
		<button routerLink="Create" type="button" class="btn btn-primary w-min-120">建立</button>
	</div>
	<hr>
	<section style="">
		<div>
			<table class="table table-layout-fixed">

				<thead class="thead-light">
				<tr>
					<th class="">
            <span class="">
              行動條碼名稱
            </span></th>
					<th class="">
            <span class="cursor-pointer">
              發放點數
            </span>
					</th>
					<th class="">
            <span class=""><!---->
              有效期限
            </span>
					</th>
					<th class="">
            <span class=""><!---->
              功能
            </span>
					</th>
				</tr>
				</thead>
				<tbody>
				<tr *ngFor="let rewardCode of rewardCodes">
					<td>
						{{rewardCode.payload.doc.data().title}}
					</td>
					<td>
						{{rewardCode.payload.doc.data().point}}
					</td>
					<td>
						{{rewardCode.payload.doc.data().endTime.toDate().toLocaleString()}}
					</td>
					<td>
						<fa-icon (click)="downloadQRCode(rewardCode.payload.doc.ref.path)" ngbTooltip="下載" class="p-2 cursor-pointer text-primary" [icon]="faDownload"></fa-icon>
						<fa-icon [icon]="faTrash" class="cursor-pointer p-2 text-danger" (click)="deleteRewardCode(rewardCode)" ngbTooltip="刪除"></fa-icon>

					</td>
				</tr>
				</tbody>
			</table>
			<hr>
			<div>
			</div>
		</div>
	</section>
	<div>
	</div>
</div>
