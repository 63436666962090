import { Component } from '@angular/core';
import {ShopService} from './shop/services/shop.service';
import {FacebookService} from '@jemys89/ngx-facebook';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.styl']
})
export class AppComponent {
  title = 'CastTalk';
  constructor(ss: ShopService,
              private fb: FacebookService) {
    fb.init({
      status: true,
      cookie: true,
      xfbml: true,
      version: 'v2.4'
    });
  }
}
