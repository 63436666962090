import { Injectable } from '@angular/core';
import {CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {Observable, of} from 'rxjs';
import {map, take, tap} from 'rxjs/operators';
import {AngularFireAuth} from '@angular/fire/auth';
import {AuthService} from '../../firebase/auth.service';
import {NotifyService} from '../services/notify.service';
import {FUser} from '../../firebase/models/user';
import {AngularFirestore} from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate, CanActivateChild {
  constructor(private auth: AngularFireAuth, private notify: NotifyService, private db: AngularFirestore) {}
  canActivate(
      next: ActivatedRouteSnapshot,
      state: RouterStateSnapshot
  ): Promise<boolean>{
    return new Promise((resolve, reject) => {
      this.auth.authState.subscribe(f => {
        this.db.collection<FUser>('users').doc(f.uid).valueChanges().subscribe((u: FUser) => {
          if (u.admin) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
      });
    });
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean>{
    return new Promise((resolve, reject) => {
      this.auth.authState.subscribe(f => {
        this.db.collection<FUser>('users').doc(f.uid).valueChanges().subscribe((u: FUser) => {
          if (u.admin) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
      });
    });
  }
}
