<div class="d-flex justify-content-between align-items-center mb-2">
	<h2 class="mb-0">月費報表</h2>
</div>
<hr>

<table class="table table-hover table-layout-fixed">
	<thead class="thead-light">
	<tr>
		<th class="">
      <span class="">
        計費期間
      </span>
		</th>
		<th class="">
      <span class=""><!---->
        月費方案
      </span>
		</th>
		<th class="">
      <span class=""><!---->
        月費金額
      </span>
		</th>
		<th class="">
      <span ><!---->
        上期<br>加購則數
      </span>
		</th>
		<th class="">
      <span ><!---->
        上期<br>加購金額
      </span>
		</th>
		<th class="">
      <span ><!---->
        本期<br>應付總額
      </span>
		</th>
		<th class="">
      <span ><!---->
        稅額5%
      </span>
		</th>
		<th class="">
      <span ><!---->
        本期總額
      </span>
		</th>
		<th class="">
      <span ><!---->
        付款狀態
      </span>
		</th>
		<th class="">
      <span ><!---->
        以點數付款
      </span>
		</th>
	</tr>
	</thead>
	<tbody>
	<tr *ngFor="let bill of currentPage" class="">
		<td>
      <span class=""><!---->
	      {{bill.payload.doc.data().date}}
      </span>
		</td>
		<td>
      <span class=""><!---->
	      {{getPlanName(bill.payload.doc.data().status)}}
      </span>
		</td>
		<td>
      <span class=""><!---->
	      {{bill.payload.doc.data().monthlyFee}}
      </span>
		</td>
		<td>
      <span class=""><!---->
	      {{bill.payload.doc.data().extraMessages}}
      </span>
		</td>
		<td>
      <span class=""><!---->
	      {{bill.payload.doc.data().extraFee}}
      </span>
		</td>
		<td>
      <span class=""><!---->
	      {{bill.payload.doc.data().amount}}
      </span>
		<td>
      <span class=""><!---->
	      {{bill.payload.doc.data().taxFee}}
      </span>
		</td>
		<td>
      <span class=""><!---->
	      {{bill.payload.doc.data().totalFee}}
      </span>
		</td>
		<td>
			<span *ngIf="bill.payload.doc.data().payStatus">已付款</span>
			<a *ngIf="!bill.payload.doc.data().payStatus" [routerLink]="'../Payment/' + bill.payload.doc.id" class=""><!---->
				未付款
			</a>
		</td>
		<td>
			<span *ngIf="bill.payload.doc.data().payStatus">已付款</span>
			<a *ngIf="!bill.payload.doc.data().payStatus" href="javascript:void(0)" (click)="payWithPoint(bill)"  class=""><!---->
				以點數付款
			</a>
		</td>
	</tr>
	</tbody>
</table>
<hr>
<app-list-paginator [data]="list" (listChanged)="currentPage = $event"></app-list-paginator>
