import { Component, OnInit } from '@angular/core';
import {FNewsContent, FNewsTags} from '../../../firebase/models/news';
import {AngularFirestore, DocumentChangeAction} from '@angular/fire/firestore';

@Component({
  selector: 'app-news-review-list-page',
  templateUrl: './news-review-list-page.component.html',
  styleUrls: ['./news-review-list-page.component.styl']
})
export class NewsReviewListPageComponent implements OnInit {

  NewsList: DocumentChangeAction<FNewsContent>[];
  NewsListSource: DocumentChangeAction<FNewsContent>[];

  tags: FNewsTags[];
  constructor(private db: AngularFirestore) {
  }
  ngOnInit(): void {
    this.db.collection<FNewsTags>('newsTags').valueChanges().subscribe(tags => {
      this.tags = tags;
      this.db.collection<FNewsContent>('newsContents', ref => ref.orderBy('pubTime', 'desc')).snapshotChanges().subscribe(News => {
        this.NewsListSource = News;
      });
    });
  }
  getDisplayWords(news: FNewsContent) {
    const result = [];
    if (news.isHead) {
      result.push('置頂');
    }
    if (news.isHot) {
      result.push('熱門大圖');
    }
    if (news.isTop) {
      result.push('標籤主圖');
    }
    if (news.isMarquee) {
      result.push('跑馬燈');
    }
    if (result.length === 0) {
      result.push('無');
    }
    return result;
  }
  getTags(news: FNewsContent) {
    return news.tagsA.map(tagId => {
      return this.tags.filter(tag => tag.tagId === tagId)[0].name;
    });
  }
  getStatusString(news: FNewsContent) {
    // 審稿中(0)default  上刊(1) 停刊(2)  退回改稿(3)
    switch (news.status) {
      case 0:
        return '審稿中';
      case 1:
        return '上刊';
      case 2:
        return '停刊';
      case 3:
        return '退回改稿';
    }
    return 'error';
  }
}
