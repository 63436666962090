<div class="d-flex justify-content-between align-items-center mb-2">
	<h2 class="mb-0">商品類別管理</h2>
</div>
<hr>
<div class="mt-2 d-flex flex-row">
	<input #categoryName="ngModel" [(ngModel)]="name"  required maxlength="15" type="text" placeholder="請輸入類別名稱" autocomplete="off" class="form-control w-fix-240">
	<button  [disabled]="categoryName.invalid" (click)="addCategory(categoryName.value)" type="button"  class="ml-2 btn btn-primary w-min-100 h-35">建立新類別</button>
</div>
<table class="mt-2 table table-layout-fixed">
	<thead class="thead-light">
	<tr>
		<th class="">
			建立時間
		</th>
		<th class="">
			類別名稱
		</th>
		<th class="">
			順序
		</th>
		<th class="">
			動作
		</th>
	</tr>
	</thead>
	<tbody>
	<tr *ngFor="let category of categories">
		<td>
			{{category.data.createTime.toDate().toLocaleString()}}
		</td>
		<td>
			<input class="form-control" [(ngModel)]="category.data.name" required maxlength="15">
		</td>
		<td>
			<input class="form-control w-fix-120" type="number" required [(ngModel)]="category.data.order">
		</td>
		<td>
			<button (click)="save(category)" ngbTooltip="儲存變更"><fa-icon [icon]="faSave"></fa-icon></button>
			<button (click)="delete(category)" ngbTooltip="刪除"><fa-icon [icon]="faTrash"></fa-icon></button>
		</td>
	</tr>
	</tbody>
</table>
