<div>
	<div class="d-flex justify-content-between align-items-center mb-2">
		<h2 class="mb-0">活動報名狀態</h2>
	</div>
	<hr>
	<section style="">
		<div>
			<table class="table table-layout-fixed">
				<thead class="thead-light">
				<tr>
					<th >
						<span>
              報名人
						</span>
					</th>
					<th >
						<span class="cursor-pointer">
	            報名時間
						</span>
					</th>
					<th >
						<span class="cursor-pointer">
	            付款時間
						</span>
					</th>
				</tr>
				</thead>
				<tbody>
				<tr *ngFor="let event of currentPage">
					<td>
						<div class="div3">
							<div  class=" avatar avatar-sm rounded-circle div1">
								<div class="w-100 h-100" [ngStyle]="{'background': 'url(' + event.user.photoUrl + ') center center / cover'}"></div>
							</div>
							<div class="div2">
								<label class="user col-form-label text-sm-left font-weight-normal">{{event.user.displayName}}</label>
								<!--            <label class="user col-form-label-xs text-sm-left font-weight-light">{{user.user.statusMessage }}</label>-->
							</div>
						</div>
					</td>
					<td>
						{{event.createTime?.toDate().toLocaleString()}}
					</td>
					<td>
						{{event.payTime?.toDate().toLocaleString()}}
					</td>
				</tr>
				</tbody>
			</table>
			<hr>
			<app-list-paginator [data]="list" (listChanged)="currentPage = $event"></app-list-paginator>
		</div>
	</section>
</div>
