import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-shop-auth-result-page',
  templateUrl: './shop-auth-result-page.component.html',
  styleUrls: ['./shop-auth-result-page.component.styl']
})
export class ShopAuthResultPageComponent implements OnInit {

  result = '';
  message = '';
  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.result = this.route.snapshot.paramMap.get('id');
    if (this.result === 'success') {
      this.message = '官方帳號認證成功';
    } else {
      this.message = '官方帳號認證失敗';

    }
  }

}
