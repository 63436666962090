import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {ShopService} from '../../services/shop.service';
import {DocumentChangeAction} from '@angular/fire/firestore';
import {FCoupon} from '../../../firebase/models/coupon';

@Component({
  selector: 'app-coupon-picker-dialog',
  templateUrl: './coupon-picker-dialog.component.html',
  styleUrls: ['./coupon-picker-dialog.component.styl']
})
export class CouponPickerDialogComponent implements OnInit {

  coupons: DocumentChangeAction<FCoupon>[] = [];
  constructor(public dialogRef: MatDialogRef<CouponPickerDialogComponent>,
              private ss: ShopService) { }

  ngOnInit(): void {
    this.ss.currentShopDoc.collection('coupons').snapshotChanges().subscribe((coupons: DocumentChangeAction<FCoupon>[]) => {
      this.coupons = coupons;
    });
  }
  onSelect(coupon: DocumentChangeAction<FCoupon>) {
    this.dialogRef.close(coupon);
  }
  getStatusWord(start: Date, end: Date) {
    const now = new Date();
    if (now > start && now < end) {
      return '有效';

    } else if (now > end) {
      return '過期';
    } else {
      return '即將生效';
    }
  }

}
