<div class="d-flex justify-content-between align-items-center mb-2">
	<h2 class="mb-0">商品一覽</h2>
	<button routerLink="Create" type="button" class="btn btn-primary w-min-120">建立</button>
</div>
<hr>
<table class="table table-layout-fixed">
	<thead class="thead-light">
	<tr>
		<th class="">
			商品名稱
		</th>
		<th class="">
			上架狀態
		</th>
	</tr>
	</thead>
	<tbody>
	<tr *ngFor="let product of currentPage">
		<td>
			<a [routerLink]="product.payload.doc.id" class="d-flex align-items-center hljs-link text-dark">
				{{product.payload.doc.data().name}}
			</a>
		</td>
		<td>
			{{product.payload.doc.data().enabled ? '上架' : '未上架'}}
		</td>
	</tr>
	</tbody>
</table>
<hr>
<app-list-paginator [data]="products" (listChanged)="currentPage = $event"></app-list-paginator>
