import {Directive, ElementRef, EventEmitter, HostListener, Output} from '@angular/core';

@Directive({
  selector: '[appCursorPosition]'
})
export class CursorPositionDirective {

  @Output()
  cursorPosition: EventEmitter<any> = new EventEmitter();
  constructor(el: ElementRef) {
  }
  @HostListener('focusout', ['$event.target'])
  onClick(btn: any) {
    console.log(12);
    this.cursorPosition.emit(btn.selectionStart);
  }

}
