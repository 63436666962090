import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FPicture} from '../../../../../../../firebase/models/cardMessage';
import {StorageService} from '../../../../../../../shared/services/storage.service';
import {UploadImageDialogData} from '../../../../../../../shared/ui/upload-image-dialog/upload-image-dialog.component';

@Component({
  selector: 'app-card-picture',
  templateUrl: './card-picture.component.html',
  styleUrls: ['./card-picture.component.scss']
})
export class CardPictureComponent implements OnInit {

  @Input()
  pages: FPicture[];
  @Output()
  pagesChange: EventEmitter<FPicture[]> = new EventEmitter<FPicture[]>();
  constructor(private ss: StorageService) { }

  ngOnInit(): void {
  }
  openUploadImageDialog(index: number): void {
    const info: UploadImageDialogData = {height: 640, type: 'image', width: 640};
    this.ss.openUploadDialog(info).afterClosed().subscribe(result => {
      if (result) {
        (this.pages[index] as FPicture).image = result;
      }
    });
  }
  addPage() {
    this.pages.push(NewPictureCard());
  }
}
export function NewPictureCard(): FPicture {
  return {
    action: {
      text : '',
      type: 0,
      content: ''
    },
    banner: '',
    bannerColor: '666F86',
    image: ''
  };
}
