
<div style="min-height: 78vh">
  <ng-container *ngIf="!(ss.shopLoaded | async)">
    <app-progress-spinner></app-progress-spinner>
  </ng-container>

  <ng-container *ngIf="ss.shopLoaded | async">
    <app-shop-main-nav></app-shop-main-nav>
    <router-outlet></router-outlet>
  </ng-container>


</div>
