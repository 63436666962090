import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {faCheck} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-shop-card-message-color-picker',
  templateUrl: './shop-card-message-color-picker.component.html',
  styleUrls: ['./shop-card-message-color-picker.component.styl']
})
export class ShopCardMessageColorPickerComponent implements OnInit {

  @Input() color = '666F86';
  @Output() ColorChanged: EventEmitter<any> = new EventEmitter<any>();
  faCheck = faCheck;
  constructor() { }
  selectChanged() {
    this.ColorChanged.emit(this.color);
  }

  ngOnInit(): void {
  }

}
