import { Component, OnInit } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {NotifyService} from '../../../../../shared/services/notify.service';
import {DocumentChangeAction} from '@angular/fire/firestore/interfaces';
import {FExtraServicePlan, FExtraServicePlanType} from '../../../../../firebase/models/plan';

@Component({
  selector: 'app-admin-extra-features-price-page',
  templateUrl: './admin-extra-features-price-page.component.html',
  styleUrls: ['./admin-extra-features-price-page.component.styl']
})
export class AdminExtraFeaturesPricePageComponent implements OnInit {
  type: FExtraServicePlanType = 'store';
  followerLimit = 0;
  price = 0;
  plans: DocumentChangeAction<FExtraServicePlan>[] = [];
  getTypeName = getTypeName;
  constructor(private db: AngularFirestore,
              private notify: NotifyService) { }

  ngOnInit(): void {
    // this.db.collection('system').doc<FExtraService>('extraService').valueChanges().subscribe(x => {
    //   const storePlans = this.getPlan(x.store, 'store');
    //   const coursePlans = this.getPlan(x.course, 'course');
    //   const eventPlans = this.getPlan(x.event, 'event');
    //   const rewardCardPlans = this.getPlan(x.rewardCard, 'rewardCard');
    //   const plan = [].concat(storePlans).concat(coursePlans).concat(eventPlans).concat(rewardCardPlans);
    //   this.plans = plan;
    //   console.log(this.plans);
    // });
    this.db.collection<FExtraServicePlan>('plans', ref =>
        ref.where('type', '==', 'extra').orderBy('feature') ).snapshotChanges().subscribe(plans => {
      this.plans = plans;
    });
  }
  getPlan(x, type: string) {
    return Object.entries(x).map(store => {
      const days: number = +store[0];
      return {
        type,
        days,
        price: store[1]
      };
    });
  }
  addPlan() {
    this.db.collection<FExtraServicePlan>('plans').add({
      type: 'extra',
      enabled: true,
      price: this.price,
      feature: this.type
    }).then(() => {
      this.notify.pop('建立成功');
    });
    // const path = this.type + '.' + this.days;
    // this.db.collection('system').doc<FExtraService>('extraService').update({
    //   [path]: this.price
    // }).then(() => {
    //   this.notify.pop('建立成功');
    // });
  }
  deletePlan(plan: DocumentChangeAction<FExtraServicePlan>){
    // const path = plan.type + '.' + plan.days;
    // this.db.collection('system').doc<FExtraService>('extraService').update({
    //   [path]: firebase.firestore.FieldValue.delete()
    // }).then(() => {
    //   this.notify.pop('刪除成功');
    // });
    plan.payload.doc.ref.update({
      enabled: !plan.payload.doc.data().enabled
    });
  }

}

export function getTypeName(type: string) {
  switch (type) {
    case 'store':
      return '商店';
    case 'event':
      return '活動';
    case 'course':
      return '課程';
    case 'rewardCard':
      return '集點卡';
    case 'booking':
      return '預約服務';
  }
}
