import { Component, OnInit } from '@angular/core';
import {AngularFirestore, DocumentChangeAction} from '@angular/fire/firestore';
import {FAgentShare} from '../../../../../../firebase/models/agent';
import {ShopService} from '../../../../../services/shop.service';
import {ActivatedRoute} from '@angular/router';
import {FShop} from '../../../../../../firebase/models/shop';

@Component({
  selector: 'app-shop-agent-client-share-list-page',
  templateUrl: './shop-agent-client-share-list-page.component.html',
  styleUrls: ['./shop-agent-client-share-list-page.component.styl']
})
export class ShopAgentClientShareListPageComponent implements OnInit {
  desc = '';
  shares: DocumentChangeAction<FAgentShare>[] = [];
  currentPage: DocumentChangeAction<FAgentShare>[] = [];
  shop: FShop;
  constructor(private ss: ShopService,
              private route: ActivatedRoute,
              private db: AngularFirestore) { }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    this.db.collection<FShop>('shops').doc(id).get().subscribe(shop => {
      // @ts-ignore
      this.shop = shop.data();
      console.log(this.shop);
      this.ss.currentShopDoc.collection<FAgentShare>('agentShare', ref => ref.where('shop.uid', '==' , this.shop.uid))
          .snapshotChanges().subscribe(shares => {
        this.shares = shares;
      });
    });
  }
  OnAddShareLink() {
    // @ts-ignore
    this.ss.currentShopDoc.collection<FAgentShare>('agentShare').add({
      desc: this.desc, shop: {
        category: this.shop.category,
        desc: this.shop.desc,
        displayName: this.shop.displayName,
        lineAt: this.shop.lineAt,
        photoUrl: this.shop.photoUrl,
        uid: this.shop.uid,
      }, users: []
    }).then(() => {
      this.desc = '';
    });
  }

}
