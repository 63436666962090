
<div style="min-height: 78vh">
	<nav id="nav" class="bg-white border-bottom pt-1 flex-shrink-0">
		<div class="container-fluid d-flex justify-content-between align-items-center">
			<ul class="nav nav-bordered">
				<li class="nav-item position-relative">
					<a routerLink="Shop" routerLinkActive="router-link-exact-active active-ignore active" class="nav-link">官方帳號</a>
				</li>
				<li class="nav-item position-relative">
						<a routerLink="UI" routerLinkActive="router-link-exact-active active-ignore active" class="nav-link">介面</a>
				</li>
				<li class="nav-item position-relative">
					<a routerLink="News" routerLinkActive="router-link-exact-active active-ignore active" class="nav-link">新聞</a>
				</li>
				<li class="nav-item position-relative">
					<a routerLink="Agent" routerLinkActive="router-link-exact-active active-ignore active" class="nav-link">代理商</a>
				</li>
				<li class="nav-item position-relative">
					<a routerLink="Accounts" routerLinkActive="router-link-exact-active active-ignore active" class="nav-link">帳務</a>
				</li>
				<li class="nav-item position-relative">
					<a routerLink="Others" routerLinkActive="router-link-exact-active active-ignore active" class="nav-link">其他</a>
				</li>
<!--				<li class="nav-item position-relative">-->
<!--					<a routerLink="ExtraFeatures" routerLinkActive="router-link-exact-active active-ignore active" class="nav-link">加值服務</a>-->
<!--				</li>-->


			</ul>
<!--			<ul class="nav nav-bordered">-->
<!--				<li *ngIf="ss.currentShop && ss.permission === 0" class="nav-item"><a routerLink="AccountSetting" routerLinkActive="router-link-exact-active active-ignore active" class="nav-link"><i class="fas fa-cog mr-1 text-muted"></i>設定</a></li>-->
<!--			</ul>-->
		</div>
	</nav>

	<router-outlet></router-outlet>


</div>
