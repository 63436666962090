import { Component, OnInit } from '@angular/core';
import {FStore} from '../../../../../firebase/models/store';
import {ShopService} from '../../../../services/shop.service';
import {NotifyService} from '../../../../../shared/services/notify.service';
import {AngularFireStorage} from '@angular/fire/storage';
import {StorageService} from '../../../../../shared/services/storage.service';
import {AngularFirestore, AngularFirestoreDocument} from '@angular/fire/firestore';
import {UploadImageDialogData} from '../../../../../shared/ui/upload-image-dialog/upload-image-dialog.component';
import {FroalaService} from "../../../../../shared/services/froala.service";
import {RouteService} from "../../../../../shared/services/route.service";

@Component({
  selector: 'app-shop-store-setting-page',
  templateUrl: './shop-store-setting-page.component.html',
  styleUrls: ['./shop-store-setting-page.component.styl']
})
export class ShopStoreSettingPageComponent implements OnInit {

  color;
  config = {
    allowedContent: true,
    toolbar: ['imageUpload', '|', 'bold']
  };
  store: FStore = {
    companyAddr: '',
    companyEmail: '',
    companyName: '',
    companyPhone: '',
    createTime: new Date(),
    deliveries: [],
    enabled: false,
    name: '',
    photoUrl: '',
    primaryColor: '',
    secondColor: '',
    shop: this.ss.shopInfo,
    story: '',
    title: '',
    updateTime: new Date()
  };
  storeDoc: AngularFirestoreDocument<FStore>;

  constructor(public ss: ShopService,
              private notify: NotifyService,
              public storage: AngularFireStorage,
              private img: StorageService,
              private db: AngularFirestore,
              public froala: FroalaService,
              public rs: RouteService
  ) {
  }

  ngOnInit(): void {
    this.initStore();
  }

  save() {
    console.log(this.store);
    this.storeDoc.set(this.store, {merge: true}).then(() => {
      this.notify.pop('儲存成功');
    }).catch(error => {
      console.log(error);
    });
  }

  openImgDialog(): void {
    const info: UploadImageDialogData = {
      height: 640, type: 'image', width: 640
    };
    this.img.openUploadDialog(info).afterClosed().subscribe(result => {
      this.store.photoUrl = result;
    });
  }

  initStore() {
    this.store.shop = this.ss.shopInfo;
    this. storeDoc = this.db.collection('stores').doc<FStore>(this.ss.currentShop.uid);
    this.storeDoc.get().subscribe(store => {
      Object.assign(this.store, store.data());
    });
  }
  addDelivery() {
    this.store.deliveries.push({
      name: '',
      price: 0
    });
  }

}
