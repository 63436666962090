<div>
  <div class="d-flex justify-content-between align-items-center mb-2">
    <h2 class="mb-0">加入好友</h2></div>
  <hr>
  <div>
    <div>
      <div class="form-group row w-fix-800">
        <label class="col-sm-3 col-form-label text-sm-right font-weight-light">網址</label>
        <div class="col-sm-9">
          <div class="input-group">
            <input [(ngModel)]="ss.currentShop.shareLink" type="url" readonly="readonly" class="form-control w-max-800">
            <div class="input-group-append">
              <button (click)="copy()" ngxClipboard [cbContent]="ss.currentShop.shareLink" type="button" class="btn btn-outline-primary">複製</button>
            </div>
          </div> <small class="form-text text-muted text-pre-line">存取此網址後，用戶即可將您的帳號加入好友。
          快複製此網址，並利用社群平台或電子郵件分享給更多用戶吧！</small></div>
      </div>
    </div>
    <div>
      <div class="form-group row w-fix-800">
        <label class="col-sm-3 col-form-label text-sm-right font-weight-light">行動條碼</label>
        <div class="col-sm-9">
          <div class="d-flex align-items-start">
            <div class="figure figure-grid">
              <qrcode  id="qrCodeData" class="qrcode"  [qrdata]="ss.currentShop.shareLink" [size]="130" [level]="'M'"></qrcode>
            </div>
          </div>

          <small class="form-text text-muted text-pre-line">掃描此行動條碼後，用戶即可將您的帳號加入好友。
          </small></div>
      </div>
    </div>

    <div>
      <div class="form-group row w-fix-800">
        <label class="col-sm-3 col-form-label text-sm-right font-weight-light">加入好友鍵</label>
        <div class="col-sm-9">
          <a [href]="ss.currentShop.shareLink"><img src="assets/images/CastTalk_Icon_JoinFriend.png" alt="加入好友" height="36" border="0"></a>
          <textarea [(ngModel)]="htmlCode" id="tooltipAddFriend" rows="3" readonly="readonly" class="form-control w-max-800 mt-2"></textarea>
          <div class="d-flex justify-content-between mt-2"><small class="text-muted text-pre-line">點選或按一下此按鍵後，用戶即可將您的帳號加入好友。
            快複製HTML標籤，並張貼至網站或部落格等處分享給更多用戶吧！</small>
            <div class="col-sm-2 w-max-80 p-0 ">
              <div class="float-right">
                <button (click)="copy()" ngxClipboard [cbContent]="htmlCode" type="button" class="btn btn-outline-primary" id="addFriendId">複製</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="form-group row w-fix-800">
        <label class="col-sm-3 col-form-label text-sm-right font-weight-light">宣傳海報</label>
        <div class="col-sm-9">
          <div #poster id="poster" style="width: 395px; height: 555px;" class="d-flex align-items-center flex-column position-relative">
            <h3 style="color: #57B83A; z-index: 1; font-weight: bolder" class="w-100 text-center mt-5">{{ss.currentShop.displayName}}</h3>
            <qrcode class="mt-5"  style="z-index: 1" [qrdata]="ss.currentShop.shareLink" [size]="160" [level]="'M'"></qrcode>
            <img class="position-absolute" style="z-index: 0" src="../../../../../assets/images/ScanToAddFriend.png">

          </div>
          <button (click)="downloadImage()" type="button" class="btn btn-outline-primary">下載</button>
        </div>
      </div>
    </div>
  </div>
</div>
