<!--<div [froalaEditor]="froala.options" [(froalaModel)]="htmlContent"   >Hello, Froala!</div>-->
<div class="d-flex justify-content-between align-items-center mb-2">
	<h2 class="mb-0">加值服務 不支援頁面</h2>
</div>
<angular-editor [(ngModel)]="htmlContent" [config]="froala.config"  placeholder=" " name="desc" required></angular-editor>
<div class="d-flex justify-content-center my-5">
	<button (click)="change()" class="btn btn-lg btn-primary px-5 mx-1">儲存</button>
</div>


<hr class="my-4">
<div class="d-flex justify-content-between align-items-center mb-2">
	<h2 class="mb-0">購買加值服務上方</h2>
</div>
<angular-editor [(ngModel)]="htmlContentTop" [config]="froala.config"  placeholder=" " name="desc" required></angular-editor>
<div class="d-flex justify-content-center my-5">
	<button (click)="changeTop()" class="btn btn-lg btn-primary px-5 mx-1">儲存</button>
</div>



<hr class="my-4">
<div class="d-flex justify-content-between align-items-center mb-2">
	<h2 class="mb-0">購買加值服務下方</h2>
</div>
<angular-editor [(ngModel)]="htmlContentBottom" [config]="froala.config"  placeholder=" " name="desc" required></angular-editor>
<div class="d-flex justify-content-center my-5">
	<button (click)="changeBottom()" class="btn btn-lg btn-primary px-5 mx-1">儲存</button>
</div>
<hr class="my-4">
