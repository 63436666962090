<div class="d-flex justify-content-between align-items-center mb-2">
	<h2 class="mb-0">設定</h2>
</div>
<div class="form-group row w-fix-800">
	<label class="col-sm-3 col-form-label text-sm-right font-weight-light">推薦碼</label>
	<div class="col-sm-9">
		<div class="form-inline text-muted">
			<input  [(ngModel)]="agent.code" name="desc" type="text" required maxlength="20" placeholder="推薦碼" autocomplete="off" class="form-control form-control-rg">
			<span class="ml-1"><strong class="">{{agent.code.length}}</strong><span class="">/20</span></span>
		</div>
	</div>
</div>
<div class="form-group row w-fix-800">
  <label class="col-sm-3 col-form-label text-sm-right font-weight-light">推薦連結</label>
  <div class="col-sm-9">
    <div class="form-inline text-muted">
      <a  [href]="agent.shareLink">{{agent.shareLink}}</a>
    </div>
  </div>
</div>
<hr class="my-4">
<div class="d-flex justify-content-center my-5">
	<button (click)="save()" class="btn btn-lg btn-primary px-5 mx-1">儲存</button>
</div>
