import {Component, Inject, OnInit} from '@angular/core';
import {AngularFirestore, AngularFirestoreDocument} from '@angular/fire/firestore';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ModalService} from '../../../../../../shared/services/modal.service';
import {NotifyService} from '../../../../../../shared/services/notify.service';
import {RouteService} from '../../../../../../shared/services/route.service';
import {FShop} from '../../../../../../firebase/models/shop';
import {FChargePlan, FMonthlyTotal, getPlanName} from 'src/app/firebase/models/chargePlan';
import {FUser} from '../../../../../../firebase/models/user';
import {ShopService} from '../../../../../../shop/services/shop.service';
import {Router} from '@angular/router';
import {IconService} from "../../../../../../shared/services/icon.service";

@Component({
  selector: 'app-admin-shop-manage-dialog',
  templateUrl: './admin-shop-manage-dialog.component.html',
  styleUrls: ['./admin-shop-manage-dialog.component.styl']
})
export class AdminShopManageDialogComponent implements OnInit {

  getPlanName = getPlanName;
  manager: FUser;
  shop: FShop;
  shopDoc: AngularFirestoreDocument;
  chargePlan: FChargePlan;
  chargePlanDoc: AngularFirestoreDocument;
  monthlyTotal: FMonthlyTotal;
  monthlyTotalDoc: AngularFirestoreDocument;
  constructor(public dialogRef: MatDialogRef<AdminShopManageDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public shopData: FShop,
              private db: AngularFirestore,
              private ms: ModalService,
              private notify: NotifyService,
              private rs: RouteService,
              private ss: ShopService,
              public icon: IconService) { }

  ngOnInit(): void {
    console.log(this.shopData);
    Object.keys(this.shopData.managers).forEach(manager => {
      if (this.shopData.managers[manager].owner) {
        this.db.collection('users').doc<FUser>(manager).get().subscribe((user: any) => {
          console.log(manager);
          this.manager = user.data();
        });
      }
    });
    this.shopDoc = this.db.collection('shops').doc(this.shopData.uid);
    this.shopDoc.valueChanges().subscribe((shop: FShop) => {
      this.shop = shop;
    });
    this.db.collection('shops').doc(this.shopData.uid)
        .collection<FMonthlyTotal>('monthlyTotals', ref => ref.orderBy('createTime', 'desc').limit(2)).get().subscribe(bill => {
      // @ts-ignore
      this.monthlyTotal = bill.docs[1].data();
      this.monthlyTotalDoc = this.db.doc(bill.docs[1].ref.path);
    });
    this.chargePlanDoc = this.db.collection('chargePlan').doc(this.shopData.uid);
    this.chargePlanDoc.valueChanges().subscribe((chargePlan: FChargePlan) => {
      this.chargePlan = chargePlan;
    });
  }
  openOffsetDialog() {
    this.ms.openInputEditor('修改好友數offset').afterClosed().subscribe((result: string) => {
      if (result) {
        this.shopDoc.update({
          followerOffset: Number(result)
        }).then(() => {
          this.notify.pop('修改成功');
        });
      }
    });
  }
  homePageOrderChanged() {
    this.shopDoc.update({
      homePageOrder: this.shop.homePageOrder
    }).then(() => {
      this.notify.pop('修改成功');
    });
  }
  startPageOrderChanged() {
    this.shopDoc.update({
      startPageOrder: this.shop.startPageOrder
    }).then(() => {
    });
  }
  MeetGiftOrderChanged() {
    this.shopDoc.update({
      'meetGift.order': this.shop.meetGift.order
    }).then(() => {
      this.notify.pop('修改成功');
    });
  }
  toggleStore(event) {
    this.chargePlanDoc.update({
      'permission.store': event.checked
    }).then(() => {
      this.notify.pop('修改成功');
    });
  }
  toggleEvent(event) {
    this.chargePlanDoc.update({
      'permission.event': event.checked
    }).then(() => {
      this.notify.pop('修改成功');
    });
  }
  toggleBooking(event) {
    this.chargePlanDoc.update({
      'permission.booking': event.checked
    }).then(() => {
      this.notify.pop('修改成功');
    });
  }
  toggleCourse(event) {
    this.chargePlanDoc.update({
      'permission.course': event.checked
    }).then(() => {
      this.notify.pop('修改成功');
    });
  }
  toggleRewardCardPermission(event) {
    this.chargePlanDoc.update({
      'permission.rewardCard': event.checked
    });
    this.notify.pop('修改成功');
  }
  toggleMessagePermission(event) {
    this.chargePlanDoc.update({
      'permission.authMessage': event.checked
    });
    this.notify.pop('修改成功');
  }
  toggleMonthPay(event) {
    this.monthlyTotalDoc.update({
      payStatus: event.checked
    });
    this.notify.pop('修改成功');
  }
  hackShop() {
    this.shop.permission = 0;
    this.ss.shops.push(this.shop);
    this.ss.selectShop(this.shop);
    this.rs.navigateTo('');
    this.dialogRef.close();
  }

}
