import { Component, OnInit } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {FMonthlyPlan, FMonthlyPlanOutRange} from '../../../../../firebase/models/plan';
import {NotifyService} from '../../../../../shared/services/notify.service';

@Component({
  selector: 'app-admin-account-plan-settting-page',
  templateUrl: './admin-account-plan-settting-page.component.html',
  styleUrls: ['./admin-account-plan-settting-page.component.styl']
})
export class AdminAccountPlanSetttingPageComponent implements OnInit {
  plans: FMonthlyPlan[] = [];
  outRangePlans: FMonthlyPlanOutRange[] = [];
  newPlan: FMonthlyPlan = {messagePerDay: 0, enabled: true, extraFeePerMessage: 0, followerLimit: 0, name: '', price: 0};
  newOutRangePlan: FMonthlyPlanOutRange = {
    followerUnit: 0, followerUnitPrice: 0,
    enabled: true,
    extraFeePerMessage: 0,
    messagePerDay: 0,
    name: '',
    outRangeFollowers: 0,
    outRangePrice: 0,
    price: 0
  };
  constructor(private db: AngularFirestore,
              private ns: NotifyService) { }

  ngOnInit(): void {
    this.db
      .collection('system')
      .doc('plan')
      .collection<FMonthlyPlan>('monthlyPlans')
      .snapshotChanges().subscribe(x => {
      this.plans = x.map(y => {
        const res =  y.payload.doc.data();
        res.id = y.payload.doc.id;
        return res;
      });
    });
    this.db
      .collection('system')
      .doc('plan')
      .collection<FMonthlyPlanOutRange>('monthlyOutRangePlans')
      .snapshotChanges().subscribe(x => {
      this.outRangePlans = x.map(y => {
        const res =  y.payload.doc.data();
        res.id = y.payload.doc.id;
        return res;
      });
    });
  }
  addPlan(): void {
    this.db
      .collection('system')
      .doc('plan')
      .collection<FMonthlyPlan>('monthlyPlans')
      .add(this.newPlan).then(x => {
        this.ns.pop('已新增');
    });
  }
  addBigPlan(): void {
    this.db
      .collection('system')
      .doc('plan')
      .collection<FMonthlyPlanOutRange>('monthlyOutRangePlans')
      .add(this.newOutRangePlan).then(x => {
      this.ns.pop('已新增');
    });
  }
  toggleEnabled(plan: FMonthlyPlan): void {
    plan.enabled = !plan.enabled;
    this.db
      .collection('system')
      .doc('plan')
      .collection<FMonthlyPlan>('monthlyPlans')
      .doc(plan.id)
      .update(plan)
      .then(() => {
        this.ns.pop('已儲存');
      });
  }
  save(plan: FMonthlyPlan): void {
    this.db
      .collection('system')
      .doc('plan')
      .collection<FMonthlyPlan>('monthlyPlans')
      .doc(plan.id)
      .update(plan)
      .then(() => {
      this.ns.pop('已儲存');
    });
  }
  saveBigPlan(plan: FMonthlyPlanOutRange): void {
    this.db
      .collection('system')
      .doc('plan')
      .collection<FMonthlyPlanOutRange>('monthlyOutRangePlans')
      .doc(plan.id)
      .update(plan)
      .then(() => {
        this.ns.pop('已儲存');
      });
  }

}
