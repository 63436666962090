<div class="d-flex justify-content-between align-items-center mb-2"><h2 class="mb-0">官方帳號申請狀態</h2>
</div>
<hr>

<table class="table table-hover table-layout-fixed">
	<thead class="thead-light">
	<tr>
		<th class="">
      <span class="">
          申請人
      </span>
		</th>
		<th class="">
      <span class="">
          Line@
      </span>
		</th>
		<th class="">
      <span class="">
          申請時間
      </span>
		</th>
		<th class=""><span class="">
          驗證訊息狀態
    </span>
		</th>
		<th class="">
      <span class="">
          操作
      </span>
		</th>
	</tr>
	</thead>
	<tbody >
	<ng-container *ngFor="let auth of currentPage">
		<tr>
			<td>
				<div class="div3" *ngIf="auth.user | async as user">
					<div  class=" avatar avatar-sm rounded-circle div1">
						<div class="w-100 h-100" [ngStyle]="{'background': 'url(' + user.photoUrl + ') center center / cover'}"></div>
					</div>
					<div class="div2">
						<label class="user col-form-label text-sm-left font-weight-normal">{{user.displayName}}</label>
					</div>
				</div>
			</td>
			<td>
				<a [href]="'https://page.line.me/?accountId=' + auth.payload.doc.data().lineAt.substring(1,auth.payload.doc.data().lineAt.length)">
					{{auth.payload.doc.data().lineAt}}
				</a>
			</td>
			<td>
				{{auth.payload.doc.data().timestamp.toDate().toLocaleString()}}
			</td>
			<td>
				{{auth.payload.doc.data().sent ? '已傳送' : '未傳送'}}
			</td>
			<td>
				<button (click)="delete(auth)">
					<fa-icon [icon]="faTrashAlt" class="fas fa-trash-alt"></fa-icon>
				</button>
				<button (click)="reSent(auth)">
					<fa-icon [icon]="faRedoAlt" class="fas fa-redo-alt"></fa-icon>
				</button>
			</td>
		</tr>
	</ng-container>

	</tbody>
</table>
<hr>
<app-list-paginator [data]="auths" (listChanged)="currentPage =$event"></app-list-paginator>
