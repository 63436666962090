<div class="d-flex justify-content-between align-items-center mb-2"><h2 class="mb-0">搜尋好友</h2>
</div>

<hr>
<div class=" my-4">
	<div class="form-group row w-fix-400">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">名稱搜尋</label>
		<div class="col-sm-9">
			<div class="form-inline text-muted">
				<input [(ngModel)]="name" (ngModelChange)="nameSearch()" maxlength="15" type="text" name="name" placeholder="請輸入名稱" autocomplete="off" class="form-control w-fix-240">
				<span class="pl-1"><strong >{{name.length}}</strong><span >/15</span></span>
			</div>
		</div>
	</div>
</div>
<hr>

<div class="d-flex justify-content-end" >
	<span class="mx-1 text-muted">
		<fa-icon [icon]="faUserFriends" class="cursor-pointer"></fa-icon>
	</span>
	<label style="color: #00B900;">{{friends.length}}</label>
	<span class="mx-1 text-muted">
		<fa-icon [icon]="faBan" class="cursor-pointer"></fa-icon>
	</span>
	<label style="color: #00B900;">{{ss.currentShop.blocks}}</label>
</div>

<table class="table table-hover table-layout-fixed">
	<thead class="thead-light">
	<tr>
		<th class="">
      <span class="">
          使用者
      </span>
		</th>
		<th class="">
      <span class="">
          加入時間
      </span></th>
		<th class=""><span class="">
          標籤
    </span>
		</th>
	</tr>
	</thead>
	<tbody >
	<tr *ngIf="friends.length === 0">
		<td colspan="3">
			<div class="text-center my-4">
				請輸入搜尋條件
			</div>
		</td>
	</tr>
	<ng-container *ngFor="let user of currentPage; index as i">
		<tr *ngIf="user.user">
			<td>
				<div class="div3">
					<div  class=" avatar avatar-sm rounded-circle div1">
						<div class="w-100 h-100" [ngStyle]="{'background': 'url(' + user.user.photoUrl + ') center center / cover'}"></div>
					</div>
					<div class="div2">
						<label class="user col-form-label text-sm-left font-weight-normal">{{user.user.displayName}}</label>
						<!--            <label class="user col-form-label-xs text-sm-left font-weight-light">{{user.user.statusMessage }}</label>-->
					</div>
				</div>
			</td>
			<td>
				{{user.subscribeTime.toDate().toLocaleString()}}
			</td>
			<td>
				<tag-input  class=" d-flex justify-content-start flex-shrink-0 figure  position-relative"
				            [(ngModel)]="user.tags"  [onlyFromAutocomplete]="true" (ngModelChange)="tagChanged(user)"
				            [secondaryPlaceholder]= "'+新增標籤'" [placeholder]= "'+新增標籤'" [theme]="'foundation-theme'" >
					<tag-input-dropdown [autocompleteItems]="ss.tagsForDisplay" [showDropdownIfEmpty]="true" >
					</tag-input-dropdown>
				</tag-input>
			</td>
		</tr>
	</ng-container>
	</tbody>
</table>
<hr>
<app-list-paginator [data]="friends" (listChanged)="currentPage = $event"></app-list-paginator>
