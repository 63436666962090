<div class="d-flex justify-content-between align-items-center mb-2">
	<h2 class="mb-0">集點卡</h2></div>
<hr>
<form #form="ngForm">
	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">集點卡名稱
			<span class="badge badge-pin badge-pin-sm badge-primary ml-1"></span></label>
		<div class="col-sm-9">
			<div class="form-inline text-muted">
				<input [(ngModel)]="rewardCard.title" required="" maxlength="60" name="title" placeholder="名稱" type="text" autocomplete="off" class="form-control w-80 mr-2" aria-required="true" aria-invalid="false">
				<span class=""><strong class="">{{rewardCard.title.length}}</strong><span class="">/60</span></span>
			</div>
		</div>
	</div>
	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">樣式</label>
		<div class="col-sm-9">
			<select [(ngModel)]="rewardCard.type" required name="couponType" class="custom-select w-40">
				<option [ngValue]="0">土黃色</option>
				<option [ngValue]="1">藍色</option>
				<option [ngValue]="2">紫色</option>
				<option [ngValue]="3">粉色</option>
				<option [ngValue]="4">綠色</option>
				<option [ngValue]="">橘色</option>
			</select>
		</div>
	</div>
	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">結束日期</label>
		<div class="form-inline mb-2 col-sm-9">
			<input class="form-control mr-2 w-fix-200"  required [(ngModel)]="rewardCard.endTime" name="endTime"   [owlDateTime]="dt1" [min]="minDate" [owlDateTimeTrigger]="dt1" placeholder="結束日期">
			<span [owlDateTimeTrigger]="dt1">
					<fa-icon class="text-muted" [icon]="faCalendar"></fa-icon>
				</span>
			<owl-date-time  #dt1></owl-date-time>
		</div>
	</div>
	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">取卡回饋點數</label>
		<div class="col-sm-9">
			<select [(ngModel)]="rewardCard.firstPoint" required name="firstPoint" class="custom-select w-40">
				<option [ngValue]="0">0</option>
				<option [ngValue]="1">1</option>
				<option [ngValue]="2">2</option>
				<option [ngValue]="3">3</option>
				<option [ngValue]="4">4</option>
				<option [ngValue]="5">5</option>
				<option [ngValue]="6">6</option>
				<option [ngValue]="7">7</option>
				<option [ngValue]="8">8</option>
				<option [ngValue]="9">9</option>
				<option [ngValue]="10">10</option>
			</select>
		</div>
	</div>
	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">每天最大領取點數</label>
		<div class="col-sm-9">
			<select [(ngModel)]="rewardCard.periodPoint" required name="periodPoint" class="custom-select w-40">
				<option [ngValue]="1">1</option>
				<option [ngValue]="2">2</option>
				<option [ngValue]="3">3</option>
				<option [ngValue]="4">4</option>
				<option [ngValue]="5">5</option>
				<option [ngValue]="6">6</option>
				<option [ngValue]="7">7</option>
				<option [ngValue]="8">8</option>
				<option [ngValue]="9">9</option>
				<option [ngValue]="10">10</option>
			</select>
		</div>
	</div>

	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">獎項</label>
		<div class="col-sm-9">
			<ul class="list-group list-group-flush">
				<li *ngFor="let award of getAwards()" class="list-group-item-action d-flex align-items-center py-2">
					<div>{{award[0]+ ' 點' +award[1].title}} </div>
					<fa-icon [icon]="icon.faPen" class="fas fa-pen cursor-pointer p-2 text-primary" (click)="openCouponPicker(award[0])"></fa-icon>
					<fa-icon [icon]="icon.faTrash" class="fas fa-trash cursor-pointer p-2 text-danger" (click)="deleteAward(award[0])"></fa-icon>
				</li>
			</ul>
			<div class="form-inline">
				<label class="col-sm-2 col-form-label text-sm-right font-weight-light">集滿</label>
				<div class="kv-select form-group w-15 mr-2 d-flex align-items-end">
					<div class="flex-grow-1">
						<div class="input-group">
							<select #addAward name="selAddBenefit" class="form-control custom-select form-control-rg">
								<option *ngFor="let point of awardPoints" [ngValue]="point">{{point}}</option>
							</select>
						</div>
					</div>
				</div>
				<label class="col-sm-1 col-form-label text-sm-right font-weight-light">點</label>
				<button type="button" class="mr-2 btn btn-outline-primary" (click)="openCouponPicker(addAward.value)">新增獎項</button>
			</div>
		</div>
	</div>



	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">使用說明</label>
		<div class="col-sm-9">
			<div class="form-group">
				<div class="text-muted">
					<textarea [(ngModel)]="rewardCard.desc" [ngClass]="{'border-danger': rewardCard.desc.length === 0}" required name="notes" maxlength="1000" rows="5" class="form-control" aria-required="false" aria-invalid="false"></textarea> <span class="float-right"><strong class="">65</strong><span class="">/500</span></span>
				</div>
			</div>
		</div>
	</div>


	<hr class="my-4">
	<div class="d-flex justify-content-center my-5">
		<button [disabled]="form.invalid && this.getAwards().length === 0" (click)="save()" type="button" class="btn btn-primary px-5 mx-1">儲存</button>
	</div>
</form>

