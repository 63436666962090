<div *ngIf="shop" class="w-fix-520 text-info d-flex flex-column justify-content-center align-items-center overflow-y-auto">
  <div class="w-100 d-flex flex-column justify-content-center align-items-center" style="background-color: #20B454">
    <div class="mt-4 avatar rounded-circle mb-2 bg-accent">
      <div class="w-100 h-100" [ngStyle]="{'background': 'url(' + shop?.photoUrl + ') center center / cover'}"></div>
    </div>
    <div class="mb-2">
      <label class="text-white user col-form-label text-lg-center font-weight-bolder">{{shop.displayName}}</label>
    </div>
  </div>

  <div class="w-100 d-flex flex-column" style="background-color: #84C98E">
    <div class="d-flex mt-3">
      <div class="w-50">
        <div class="d-flex align-items-center" style="padding-left: 20%">
          <span class="mx-2 badge badge-pin badge-primary border-0"></span>
          <span class="text-white">好友數</span>
        </div>
      </div>
      <div class="w-50 d-flex">
        <span class="ml-4 text-white">{{shop.follower}}</span>
        <span class="ml-3" style="color: #c6621b">offset</span>
        <div class="d-flex ml-2">
          <span class="text-primary">{{shop.followerOffset}}</span>
          <fa-icon [icon]="icon.faPen" (click)="openOffsetDialog()" style="color: #1e7e34" class="fas fa-pen fa-sm ml-2"></fa-icon>
        </div>
      </div>
    </div>
    <hr style="width: 80%">
  </div>


  <div class="w-100 d-flex flex-column" style="background-color: #84C98E">
    <div class="d-flex">
      <div class="w-50">
        <div class="d-flex align-items-center" style="padding-left: 20%">
          <span class="mx-2 badge badge-pin badge-primary border-0"></span>
          <span class="text-white">加入時間</span>
        </div>
      </div>
      <div class="w-50">
        <span class="ml-4 text-white">{{$any(shop.timestamp).toDate().toLocaleString()}}</span>
      </div>
    </div>
    <hr style="width: 80%">
  </div>

  <div class="w-100 d-flex flex-column"  style="background-color: #84C98E">
    <div class="d-flex  align-items-center">
      <div class="w-50">
        <div class="d-flex align-items-center" style="padding-left: 20%">
          <span class="mx-2 badge badge-pin badge-primary border-0"></span>
          <span class="text-white">帳號擁有者</span>
        </div>
      </div>
      <div class="w-50  align-items-center ">
        <div class="ml-4 d-flex" *ngIf="manager">
          <div  class="avatar avatar-sm rounded-circle">
            <div class="w-100 h-100" [ngStyle]="{'background': 'url(' + manager?.photoUrl + ') center center / cover'}"></div>
          </div>
          <div class="ml-2 d-flex flex-column justify-content-center">
            <span class=" text-white text-center">{{manager.displayName}}</span>
          </div>
        </div>
      </div>
    </div>
    <hr style="width: 80%">
  </div>

  <div class="d-flex  flex-wrap justify-content-around w-100" style="background-color: #DADDDD">

    <div class="d-flex rounded flex-column w-40 m-3">
      <div class="d-flex flex-column  justify-content-center" style="background-color: #20B454; border-top-left-radius: 5px; border-top-right-radius: 5px">
        <label class="text-white user col-form-label text-md-center font-weight-bolder">月費方案</label>
      </div>
      <div class="d-flex flex-column py-2" style="background-color: #FFFFFF; border-bottom-left-radius: 5px; border-bottom-right-radius: 5px">
        <span class="text-sm-center">{{getPlanName(chargePlan?.status)}}</span>
        <hr style="width: 80%; margin: 5%">
        <div class="d-flex justify-content-center">
          <span>月費已付款</span>
          <mat-checkbox class="align-items-center ml-1 mt-1" [checked] = monthlyTotal?.payStatus (change)="toggleMonthPay($event)"></mat-checkbox>
        </div>
      </div>
    </div>

    <div class="d-flex rounded flex-column w-40 m-3">
      <div class="d-flex flex-column  justify-content-center" style="background-color: #20B454; border-top-left-radius: 5px; border-top-right-radius: 5px">
        <label class="text-white user col-form-label text-md-center font-weight-bolder">加值功能</label>
      </div>
      <div class="d-flex flex-column py-2" style="background-color: #FFFFFF; border-bottom-left-radius: 5px; border-bottom-right-radius: 5px">
        <div class="d-flex justify-content-center">
          <span>課程功能</span>
          <mat-checkbox class="ml-1" [checked] = chargePlan.permission.course (change)="toggleCourse($event)"></mat-checkbox>
        </div>
        <div class="d-flex justify-content-center">
          <span>商店功能</span>
          <mat-checkbox class="ml-1" [checked] = chargePlan.permission.store (change)="toggleStore($event)"></mat-checkbox>
        </div>
        <div class="d-flex justify-content-center">
          <span>訊息功能</span>
          <mat-checkbox class="ml-1" [checked] = chargePlan.permission.authMessage (change)="toggleMessagePermission($event)"></mat-checkbox>
        </div>
        <div class="d-flex justify-content-center">
          <span>集點卡功能</span>
          <mat-checkbox class="ml-1" [checked] = chargePlan.permission.rewardCard (change)="toggleRewardCardPermission($event)"></mat-checkbox>
        </div>
        <div class="d-flex justify-content-center">
          <span>活動功能</span>
          <mat-checkbox class="ml-1" [checked] = chargePlan.permission.event (change)="toggleEvent($event)"></mat-checkbox>
        </div>
        <div class="d-flex justify-content-center">
          <span>預約功能</span>
          <mat-checkbox class="ml-1" [checked] = chargePlan.permission.booking (change)="toggleBooking($event)"></mat-checkbox>
        </div>

      </div>
    </div>

    <div class="d-flex rounded flex-column w-40 m-3">
      <div class="d-flex flex-column  justify-content-center" style="background-color: #20B454; border-top-left-radius: 5px; border-top-right-radius: 5px">
        <label class="text-white user col-form-label text-md-center font-weight-bolder">推薦排序</label>
      </div>
      <div class="d-flex flex-column py-2" style="background-color: #FFFFFF; border-bottom-left-radius: 5px; border-bottom-right-radius: 5px">
        <div class="d-flex align-items-center justify-content-between" style="padding-left: 10%">
          <span class="text-sm-center">主頁排序</span>
          <select style="width: 65px" class="" [(ngModel)]="shop.homePageOrder" (change)="homePageOrderChanged()"  >
            <option [ngValue]="0">低</option>
            <option [ngValue]="100">中</option>
            <option [ngValue]="1000">高</option>
          </select>
        </div>

        <div *ngIf="shop.meetGift" class="d-flex align-items-center justify-content-between" style="padding-left: 10%">
          <span class="text-sm-center">見面禮排序</span>
          <select style="width: 65px" class="" [(ngModel)]="shop.meetGift.order" (change)="MeetGiftOrderChanged()"  >
            <option [ngValue]="0">低</option>
            <option [ngValue]="100">中</option>
            <option [ngValue]="1000">高</option>
          </select>
        </div>

        <div class="d-flex align-items-center justify-content-between" style="padding-left: 10%">
          <span class="text-sm-center">首次登入排序</span>
          <select style="width: 65px" class="" [(ngModel)]="shop.startPageOrder" (change)="startPageOrderChanged()"  >
            <option [ngValue]="0">低</option>
            <option [ngValue]="100">中</option>
            <option [ngValue]="1000">高</option>
          </select>
        </div>


      </div>
    </div>

  </div>
  <div class="d-flex justify-content-center w-100 pb-2" style="background-color: #DADDDD">
    <button (click)="hackShop()" type="submit" class="btn btn-primary mx-3">Hack</button>
  </div>

</div>
