<div class="d-flex justify-content-between align-items-center mb-2"><h2 class="mb-0">見面禮列表</h2>
</div>
<hr>
<!---->
<div class="d-flex justify-content-end" >
	<span class="mx-1 text-muted"><i class="cursor-pointer fas fa-user-friends"></i></span>
	<label style="color: #00B900;">{{shops.length}}</label>
</div>

<table class="table table-hover table-layout-fixed">
	<thead class="thead-light">
	<tr>
		<th class="">
      <span class="">
          顯示名稱
      </span>
		</th>
		<th class="">
      <span class="">
          加入時間
      </span>
		</th>
		<th class=""><span class="">
          好友數
    </span>
		</th>
		<th class="">
      <span class="">
          見面禮名稱
      </span>
		</th>
		<th class="">
      <span class="">
          推薦優先度
      </span>
		</th>
		<th class="">
      <span class="">
          停用
      </span>
		</th>
	</tr>
	</thead>
	<tbody >
	<ng-container *ngFor="let shop of currentPage">
		<tr *ngIf="shop.meetGift.coupon">
			<td>
				<div class="div3">
					<div  class=" avatar avatar-sm rounded-circle div1">
						<div class="w-100 h-100" [ngStyle]="{'background': 'url(' + shop.photoUrl + ') center center / cover'}"></div>
					</div>
					<div class="div2">
						<label class="user col-form-label text-sm-left font-weight-normal">{{shop.displayName}}</label>
					</div>
				</div>
			</td>
			<td>
				{{shop.timestamp.toDate().toLocaleString()}}
			</td>
			<td>
				{{shop.follower}}
			</td>
			<td>
				{{shop.meetGift.coupon.title}}
			</td>
			<td>
				<select class="w-fix-80 form-control custom-select form-control-rg" [(ngModel)]="shop.meetGift.order" (change)="priorityChanged(shop)"  >
					<option [ngValue]="0">低</option>
					<option [ngValue]="100">中</option>
					<option [ngValue]="1000">高</option>
				</select>
			</td>
			<td>
				<mat-checkbox [checked] = !shop.meetGift.enabled (change)="toggleEnabled($event, shop)"></mat-checkbox>
			</td>
		</tr>
	</ng-container>

	</tbody>
</table>
<hr>
<app-list-paginator [data]="shops" (listChanged)="currentPage = $event"></app-list-paginator>
