import { Injectable } from '@angular/core';
import {FOrder} from '../../firebase/models/order';
import {NotifyService} from '../../shared/services/notify.service';
import {ModalService} from '../../shared/services/modal.service';
import {DocumentChangeAction} from '@angular/fire/firestore/interfaces';
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;
import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  payUrl = 'https://n.gomypay.asia/ShuntClass.aspx?Send_Type=0&Pay_Mode_No=2&CustomerId=80013554& Order_No=自訂交易單號(例:2018001)';
  constructor(private notify: NotifyService,
              private ms: ModalService) {
  }
  payWithCreditCard(order: DocumentSnapshot<FOrder>) {
    this.ms.openConfirmDialog('信用卡支付確認', '即將進入信用卡支付頁面 確定嗎？').afterClosed().subscribe(res => {
      if (!res) { return; }
      const url = 'https://n.gomypay.asia/TestShuntClass.aspx?Send_Type=0&Pay_Mode_No=2&CustomerId=42638468&Order_No=' + order.ref.id + '&TransMode=1&Callback_Url=https://us-central1-circle-70720.cloudfunctions.net/test&Amount=' + order.data().price + '&Installment=0&TransCode=00&Buyer_Memo=' + order.data().desc;
      window.open(url, 'test', 'width=700,height=300');
    });
  }
}
