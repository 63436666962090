<div class="d-flex justify-content-between align-items-center mb-2"><h2 class="mb-0">加入好友的歡迎訊息</h2>
</div>
<hr>


<form #form="ngForm">
	<div class="position-relative my-4" *ngFor="let message of messages index as i ">
		<app-shop-message-content-card class="align-items-center" [(ngModel)]="messages[i]" [name]="'hello' + i"></app-shop-message-content-card>
		<button (click)="messages.splice(messages.indexOf(message,0),1)" type="button" class="position-absolute close" style="top: 1px !important; right: 0px;">
			<fa-icon [icon]="faWindowClose"></fa-icon>
		</button>
	</div>
	<div class="d-flex justify-content-center">
		<button [disabled]="messages.length>2" (click)="addMessage()" type="button" class="btn btn-outline-primary"><i class="lar la-plus"></i>
			新增訊息</button>
	</div>
	<hr>

	<div class="d-flex justify-content-center my-5">
		<button  (click)="save()" [disabled]="form.invalid" class="btn btn-lg btn-primary px-5 mx-1">儲存變更</button>
	</div>
</form>

