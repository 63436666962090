import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-others',
  templateUrl: './admin-others.component.html',
  styleUrls: ['./admin-others.component.styl']
})
export class AdminOthersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
