import { Component, OnInit } from '@angular/core';
import {DocumentChangeAction} from '@angular/fire/firestore';
import {FMonthlyTotal, getPlanName} from '../../../../../firebase/models/chargePlan';
import {ShopService} from '../../../../services/shop.service';
import {FAdOrder} from '../../../../../firebase/models/ad';
import {ShopPayAdWithPointRequest, ShopPayBillWithPointRequest} from '../../../../../../../functions/src/shop/point';
import {ModalService} from '../../../../../shared/services/modal.service';
import {NotifyService} from '../../../../../shared/services/notify.service';
import {AngularFireFunctions} from '@angular/fire/functions';

@Component({
  selector: 'app-shop-billing-bill-list-page',
  templateUrl: './shop-billing-bill-list-page.component.html',
  styleUrls: ['./shop-billing-bill-list-page.component.styl']
})
export class ShopBillingBillListPageComponent implements OnInit {

  constructor(private ss: ShopService,
              private ms: ModalService,
              private notify: NotifyService,
              private func: AngularFireFunctions) { }

  currentPage: DocumentChangeAction<FMonthlyTotal>[] = [];
  list: DocumentChangeAction<FMonthlyTotal>[] = [];
  getPlanName = getPlanName;

  ngOnInit(): void {
    this.ss.currentShopDoc.collection<FMonthlyTotal>('monthlyTotals').snapshotChanges().subscribe(list => {
      this.list = list;
    });
  }

  payWithPoint(bill: DocumentChangeAction<FMonthlyTotal>) {
    this.ms.openConfirmDialog('確認付款', '付款金額: ' + bill.payload.doc.data().totalFee).afterClosed().subscribe(res => {
      if (res) {
        const data: ShopPayBillWithPointRequest = {
          shopId: this.ss.currentShop.uid,
          billPath: bill.payload.doc.ref.path
        };
        const payFunction = this.func.httpsCallable('ShopPayBillWithPoint');
        payFunction(data).subscribe(x => {
          this.notify.pop(x);
        });
      }
    });
  }

}
