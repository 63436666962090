import { Component, OnInit } from '@angular/core';
import {FNewsAnalysis, FNewsContent} from '../../../../firebase/models/news';
import {ActivatedRoute, Router} from '@angular/router';
import {AngularFirestore} from '@angular/fire/firestore';
import {NotifyService} from '../../../services/notify.service';
import {RouteService} from '../../../services/route.service';
import * as firebase from 'firebase';

@Component({
  selector: 'app-news-analysis-page',
  templateUrl: './news-analysis-page.component.html',
  styleUrls: ['./news-analysis-page.component.styl']
})
export class NewsAnalysisPageComponent implements OnInit {

  News: FNewsContent;
  list: Array<firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>> = [];
  constructor(
      private activatedRoute: ActivatedRoute,
      private router: RouteService,
      private db: AngularFirestore,
  ) { }

  ngOnInit(): void {
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    this.db.collection('newsContents').doc(id).collection<FNewsAnalysis>('analysis').get().subscribe(news => {
      this.list = news.docs;
    });
    this.db.collection<FNewsContent>('newsContents').doc(id).get().subscribe(news => {
      // @ts-ignore
      this.News = news.data();
    });
  }

}
