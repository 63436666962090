<div>
	<div class="d-flex justify-content-between align-items-center mb-2">
		<h2 class="mb-0">領取紀錄</h2>
	</div>
	<hr>
	<section style="">
		<div>
			<table class="table table-layout-fixed">

				<thead class="thead-light">
				<tr>
					<th class="">
            <span class="">
              用戶
            </span></th>
					<th class="">
            <span class="cursor-pointer">
              領取時間
            </span>
					</th>
					<th class="">
            <span class=""><!---->
              點數
            </span>
					</th>
					<th class="">
            <span class=""><!---->
              類型
            </span>
					</th>
				</tr>
				</thead>
				<tbody>
				<tr *ngFor="let record of records">
					<td>
						<div class="div3">
							<div  class=" avatar avatar-sm rounded-circle div1">
								<div class="w-100 h-100" [ngStyle]="{'background': 'url(' + record.user.photoUrl + ') center center / cover'}"></div>
							</div>
							<div class="div2">
								<label class="user col-form-label text-sm-left font-weight-normal">{{record.user.displayName}}</label>
							</div>
						</div>
					</td>
					<td>
						{{record.createTime.toDate().toLocaleString()}}
					</td>
					<td>
						{{record.point}}
					</td>
					<td>
						{{getTypeString(record.type)}}
					</td>
				</tr>
				</tbody>
			</table>
			<hr>
			<app-list-paginator [data]="recordsSource" (listChanged)="records = $event"></app-list-paginator>
			<div>
			</div>
		</div>
	</section>
	<div>
	</div>
</div>
