<div class="modal-content h-100">
	<div class="modal-header flex-shrink-0">
		<h5 class="mb-0">活動</h5>
		<button (click)="dialogRef.close()" class="close">×</button>
	</div>
	<div class="modal-body mh-100 overflow-hidden">
		<hr class="m-0">
		<div class="overflow-auto h-fix-400">
			<div class="list-group list-group-flush pr-3">
				<div *ngFor="let qnr of events" class="list-group-item d-flex align-items-center px-0">
					<div class="figure figure-grid figure-grid-xs mr-4">
						<img [src]="qnr.payload.doc.data().photoUrl" class="figure-img">
					</div>
					<div class="flex-1 text-truncate">
						<div class="text-truncate">{{qnr.payload.doc.data().name}}</div>
						<small class="text-muted">
							{{qnr.payload.doc.data().endTime.toDate().toLocaleString()}}
						</small></div>
					<div class="flex-none ml-3">
						<button (click)="onSelect(qnr)" type="button" class="btn btn-outline-primary">選擇</button>
					</div>
				</div>
			</div>
			<div class="infinite-loading-container">
				<div *ngIf="events.length === 0" class="infinite-status-prompt">
					<div class="text-center py-5 my-5">尚無活動</div>
				</div>
			</div>
		</div>
	</div>
	<div class="d-flex flex-row flex-shrink-0 modal-footer flex-row-reverse">
		<!---->
		<button (click)="dialogRef.close()" type="button" class="btn btn-secondary">關閉</button>
	</div>
</div>
