<div class="d-flex justify-content-between align-items-center mb-2"><h2 class="mb-0">日曆</h2>
</div>
<hr>

<nz-calendar [(ngModel)]="selectDate" (ngModelChange)="onSelect($event)" class="w-fix-400">
  <ng-container *nzDateCell="let date" class="events">
      <ng-container>
        <li *ngFor="let item of getCell(date)">
          <div class="border d-flex flex-column justify-content-center align-items-center">
            <nz-badge [nzStatus]="badgeParser(item.payload.doc.data().status).type" [nzText]="badgeParser(item.payload.doc.data().status).content"></nz-badge>
            <span class="align-items-center">{{item.payload.doc.data().service.name}}</span>
            <span class="align-items-center">{{item.payload.doc.data().startTime.toDate().toLocaleTimeString([], {hour: '2-digit', minute:'2-digit', hour12: false}) + '~' + item.payload.doc.data().endTime.toDate().toLocaleTimeString([], {hour: '2-digit', minute:'2-digit', hour12: false})}}</span>
          </div>
        </li>
      </ng-container>
  </ng-container>
<!--  <ng-container *nzMonthCell="let month">-->
<!--    <div *ngIf="getMonthData(month) as monthData" class="notes-month">-->
<!--      <section>{{ monthData }}</section>-->
<!--      <span>Backlog number</span>-->
<!--    </div>-->
<!--  </ng-container>-->
</nz-calendar>
<hr>

<ng-container *ngIf="selectDate">
  <h2>
    {{selectDate?.toLocaleDateString()}}
  </h2>
  <table class="table table-hover table-layout-fixed">
    <thead class="thead-light">
    <tr>
      <th class="">
      <span class="">
        預約人
      </span>
      </th>
      <th class="">
      <span class="">
        服務
      </span>
      </th>
      <th class="">
      <span class="">
        時間
      </span>
      </th>
      <th class="">
      <span ><!---->
        狀態
      </span>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let booking of getCell(selectDate)" class="">
      <td>
        <span class=""><!---->
          {{booking.payload.doc.data().user.displayName}}
        </span>
        <span *ngIf="booking.payload.doc.data().user.phone" class="ml-2"><!---->
            {{booking.payload.doc.data().user.phone}}
        </span>
      </td>
      <td>
      <span class=""><!---->
        {{booking.payload.doc.data().service.name}}
      </span>
      </td>
      <td>
      <span class=""><!---->
        {{(booking.payload.doc.data().startTime.toDate().toLocaleTimeString())}}
        ~
        {{(booking.payload.doc.data().endTime.toDate().toLocaleTimeString())}}
      </span>
      </td>
      <td>
        {{badgeParser(booking.payload.doc.data().status).content}}

        <select [(ngModel)]="booking.payload.doc.data().status" (ngModelChange)="statusChanged($event, booking)">
          <option [ngValue]="4">取消</option>
          <option [ngValue]="2">報到</option>
        </select>
      </td>
    </tr>
    </tbody>
  </table>

  <hr>
  <h2>新增事件</h2>
  <div class="d-flex  mb-2">
    <label class="col-sm-2 col-form-label text-sm-right font-weight-light">時段</label>
    <div class="form-inline">
        <div class="form-inline text-muted">
          <input class="form-control w-80 mr-2 "  style="width: 100px !important;" required  [name]="'timeS'" [(ngModel)]="startTime"    [owlDateTime]="eventStartTime"  [owlDateTimeTrigger]="eventStartTime"  autocomplete="off">
          <span [owlDateTimeTrigger]="eventStartTime">
                <fa-icon class="text-muted" [icon]="icon.faCalendarAlt"></fa-icon>
              </span>
          <owl-date-time stepMinute="15" [pickerType]="'timer'" #eventStartTime></owl-date-time>
        </div>
        ~
        <div class="form-inline text-muted">
          <input class="form-control w-80 mr-2" style="width: 100px !important;" required [name]="'timeE'" [(ngModel)]="endTime"    [owlDateTime]="eventEndTime" [owlDateTimeTrigger]="eventEndTime"  autocomplete="off">
          <span [owlDateTimeTrigger]="eventEndTime">
					      <fa-icon class="text-muted" [icon]="icon.faCalendarAlt"></fa-icon>
				      </span>
          <owl-date-time  stepMinute="15" [startAt]="startTime" [pickerType]="'timer'" #eventEndTime></owl-date-time>
        </div>
    </div>
<!--    <button (click)="dontWannaWork()" [disabled]="endTime < startTime" type="button" class="btn btn-primary ">請假</button>-->
  </div>

  <div class="d-flex  mb-2">
    <label class="col-sm-2 col-form-label text-sm-right font-weight-light">類型</label>
    <select [(ngModel)]="selectType" class="form-control w-fix-120">
      <option [ngValue]="0">請假</option>
      <option [ngValue]="1">電話預約</option>
      <option [ngValue]="2">用戶預約</option>
    </select>
  </div>

  <div class="d-flex  mb-2" *ngIf="selectType !== 0">
    <label class="col-sm-2 col-form-label text-sm-right font-weight-light">服務</label>
    <select [(ngModel)]="selectService" class="form-control w-fix-120">
      <option *ngFor="let service of booking.services" [ngValue]="service">{{service.name}}</option>
    </select>
  </div>


  <div class="d-flex  mb-2" *ngIf="selectType === 0">
    <label class="col-sm-2 col-form-label text-sm-right font-weight-light"></label>
    <button (click)="dontWannaWork()" [disabled]="endTime < startTime" type="button" class="btn btn-primary ">請假</button>
  </div>


  <div *ngIf="selectType === 1">
    <form  #form ="ngForm">
      <div class="d-flex mb-2">
        <label  class="col-sm-2 col-form-label text-sm-right font-weight-light">姓名</label>
        <input [(ngModel)]="phoneReservation.name"  name="name" required class="form-control w-fix-200">
      </div>
      <div class="d-flex mb-2">
        <label class="col-sm-2 col-form-label text-sm-right font-weight-light">電話</label>
        <input [(ngModel)]="phoneReservation.phone" name="phone" required class="form-control w-fix-240">
      </div>
      <div class="d-flex mb-2">
        <label class="col-sm-2 col-form-label text-sm-right font-weight-light"></label>
        <button (click)="doPhoneReservation()" [disabled]="form.invalid" type="button" class="btn btn-primary ">預約</button>
      </div>

    </form>

  </div >
  <div *ngIf="selectType === 2">

    <form #form="ngForm">
      <div class="d-flex mb-2">
        <label class="col-sm-2 col-form-label text-sm-right font-weight-light">用戶</label>
        <div class="col-sm-9 d-flex">
          <div *ngIf="userReservation" class="div3">
            <div  class=" avatar avatar-sm rounded-circle div1">
              <div class="w-100 h-100" [ngStyle]="{'background': 'url(' + userReservation.photoUrl + ') center center / cover'}"></div>
            </div>
            <div class="div2">
              <label class="user col-form-label text-sm-left font-weight-normal">{{userReservation.displayName}}</label>
              <!--            <label class="user col-form-label-xs text-sm-left font-weight-light">{{user.user.statusMessage }}</label>-->
            </div>
          </div>
          <div class="form-inline text-muted">
            <button (click)="openFriendPicker()" type="button" class=" btn btn-primary w-min-100">選擇</button>
          </div>
        </div>
      </div>
      <div class="d-flex mb-2">
        <label class="col-sm-2 col-form-label text-sm-right font-weight-light"></label>
        <button (click)="doUserReservation()" [disabled]="form.invalid || !userReservation" type="button" class="btn btn-primary ">預約</button>
      </div>
    </form>

  </div>


</ng-container>


