import {Component, OnInit, Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {AngularFirestore} from '@angular/fire/firestore';

@Component({
  selector: 'app-shop-home-page',
  templateUrl: './shop-home-page.component.html',
  styleUrls: ['./shop-home-page.component.styl']
})
export class ShopHomePageComponent implements OnInit {

  htmlContent = '';
  constructor(private db: AngularFirestore) { }

  ngOnInit() {
    this.db.collection('system').doc('homePage').valueChanges().subscribe(x => {
      // @ts-ignore
      this.htmlContent = x.content;
    });
  }

}
@Pipe({
  name: 'html'
})
export class HtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {
  }
  transform(style) {
    return this.sanitizer.bypassSecurityTrustHtml(style);
  }
}
