import { Component, OnInit } from '@angular/core';
import {DocumentChangeAction} from '@angular/fire/firestore';
import {MatDialogRef} from '@angular/material/dialog';
import {ShopService} from '../../services/shop.service';
import {FRichContent} from '../../../firebase/models/message';

@Component({
  selector: 'app-rich-message-picker-dialog',
  templateUrl: './rich-message-picker-dialog.component.html',
  styleUrls: ['./rich-message-picker-dialog.component.styl']
})
export class RichMessagePickerDialogComponent implements OnInit {

  richMessages: DocumentChangeAction<FRichContent>[] = [];
  constructor(private ss: ShopService,
              public dialogRef: MatDialogRef<RichMessagePickerDialogComponent>) { }

  ngOnInit() {
    this.ss.currentShopDoc.collection('richMessages').snapshotChanges()
        .subscribe((x: DocumentChangeAction<FRichContent>[]) => this.richMessages = x);
  }
  onSelect(data: DocumentChangeAction<any>) {
    this.dialogRef.close(data);
  }

}
