import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AngularFirestore} from '@angular/fire/firestore';
import {DocumentChangeAction} from '@angular/fire/firestore/interfaces';
import {FBooking, FBookingReservation, FBookingService} from '../../../../../../firebase/models/booking';
import Timestamp = firebase.firestore.Timestamp;
import * as firebase from 'firebase';
import {IconService} from '../../../../../../shared/services/icon.service';
import {NotifyService} from '../../../../../../shared/services/notify.service';
import {FSubscribe} from '../../../../../../firebase/models/user';
import {ModalService} from '../../../../../../shared/services/modal.service';
import {ShopService} from '../../../../../services/shop.service';

@Component({
  selector: 'app-shop-booking-service-calendar',
  templateUrl: './shop-booking-service-calendar.component.html',
  styleUrls: ['./shop-booking-service-calendar.component.styl']
})
export class ShopBookingServiceCalendarComponent implements OnInit {
  selectType = 0;
  startTime;
  endTime;
  selectDate: Date;
  id: string;
  reservations: DocumentChangeAction<FBookingReservation>[] = [];
  userReservation = null;
  phoneReservation = {
    name: '',
    phone: ''
  };

  booking: FBooking;
  selectService: FBookingService;
  constructor(private activatedRoute: ActivatedRoute,
              private db: AngularFirestore,
              public icon: IconService,
              private notify: NotifyService,
              private ms: ModalService,
              private ss: ShopService) { }

  ngOnInit(): void {
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    this.id = id;
    this.db.collection('bookings').doc(id).collection<FBookingReservation>('reservations').snapshotChanges().subscribe(x => {
      this.reservations = x;
    });
    this.db.collection<FBooking>('bookings').doc(id).valueChanges().subscribe((x: FBooking) => {
      this.booking = x;
    });
  }
  getCell(date): DocumentChangeAction<FBookingReservation>[] {
    const res = this.reservations.filter(x =>
      (x.payload.doc.data().startTime as Timestamp).toDate().toLocaleDateString() === date.toLocaleDateString());
    return res;
  }
  badgeParser(status: number) {
    switch (status) {
      case 0: {
        return {
          type: 'processing',
          content: '已預約'
        };
      }
      case 1: {
        return {
          type: 'error',
          content: '已取消'
        };
      }
      case 2: {
        return {
          type: 'success',
          content: '已報到'
        };
      }
      case 3: {
        return {
          type: 'warning',
          content: '請假'
        };
      }
      case 4: {
        return {
          type: 'warning',
          content: '取消'
        };
      }
      case 5: {
        return {
          type: 'processing',
          content: '電話預約'
        };
      }
      default: {
        return {
          type: 'default',
          content: '其他'
        };
      }
    }
  }
  onSelect(date: Date) {
    this.startTime = new Date(date);
    this.startTime.setHours(0, 0, 0, 0);
    this.endTime = new Date(date);
    this.endTime.setHours(0, 0, 0, 0);
    console.log(date);
    console.log(this.startTime.toLocaleDateString());
    console.log(this.endTime);
    this.selectDate.setHours(0, 0, 0, 0);
  }
  dontWannaWork() {
    this.checkReservationAvailable().subscribe( data => {
      const res = data.docs.filter((x) => {
        console.log(this.startTime);
        console.log(x.data());
        const canceled = x.data().status === 1 || x.data().status === 4;
        // tslint:disable-next-line:max-line-length
        return !(canceled || ((this.startTime < x.data().startTime.toDate() && this.endTime < x.data().startTime.toDate()) || (this.startTime >  x.data().endTime.toDate() && this.endTime > x.data().endTime.toDate())));

      });
      if (res.length > 0) {
        this.notify.pop('該時段已有預約');
        return;
      } else {
        const data2: FBookingReservation = {
          endTime: this.endTime, service: {name: '請假'}, startTime: this.startTime, status: 3, user: {displayName: '請假'}
        };
        this.db.collection('bookings').doc(this.id).collection<FBookingReservation>('reservations').add(data2).then(() => {
          this.notify.pop('請假完成');
        });
      }
    });

  }
  statusChanged(status: number, booking: DocumentChangeAction<FBookingReservation>) {
    booking.payload.doc.ref.update({
      status
    });
    console.log(status);
  }
  doPhoneReservation() {
    this.checkReservationAvailable().subscribe( data => {
      const res = data.docs.filter((x) => {
        const canceled = x.data().status === 1 || x.data().status === 4;
        // tslint:disable-next-line:max-line-length
        return !(canceled || ((this.startTime < x.data().startTime.toDate() && this.endTime < x.data().startTime.toDate()) || (this.startTime >  x.data().endTime.toDate() && this.endTime > x.data().endTime.toDate())));
      });
      if (res.length > 0) {
        this.notify.pop('該時段已有預約');
        return;
      }
      const reservation: FBookingReservation = {
        booking: this.booking,
        endTime: this.endTime,
        service: this.selectService,
        startTime: this.startTime,
        status: 5,
        user: {
          displayName: this.phoneReservation.name,
          phone: this.phoneReservation.phone
        }
      };
      this.db.collection('bookings').doc(this.id).collection<FBookingReservation>('reservations').add(reservation).then(() => {
        this.notify.pop('預約成功');
      });
    });


  }
  doUserReservation() {
    this.checkReservationAvailable().subscribe( data => {
      const res = data.docs.filter((x) => {
        console.log(this.startTime);
        console.log(x.data());
        const canceled = x.data().status === 1 || x.data().status === 4;
        // tslint:disable-next-line:max-line-length
        return !(canceled || ((this.startTime < x.data().startTime.toDate() && this.endTime < x.data().startTime.toDate()) || (this.startTime >  x.data().endTime.toDate() && this.endTime > x.data().endTime.toDate())));

      });
      if (res.length > 0) {
        this.notify.pop('該時段已有預約');
        return;
      }
      const reservation: FBookingReservation = {
        booking: this.booking,
        endTime: this.endTime,
        service: this.selectService,
        startTime: this.startTime,
        status: 0,
        user: this.userReservation
      };
      this.db.collection('bookings').doc(this.id).collection<FBookingReservation>('reservations').add(reservation).then(() => {
        this.notify.pop('預約成功');
      });
    });

  }
  checkReservationAvailable() {
    const end = new Date(this.selectDate);
    end.setDate(end.getDate() + 1);
    return  this.db.collection('bookings').doc(this.id).collection<FBookingReservation>('reservations', ref =>
      ref.where('startTime', '>', this.selectDate).where('startTime', '<', end)).get();
  }
  openFriendPicker() {
    this.ms.openFriendPicker().afterClosed().subscribe((friend: DocumentChangeAction<FSubscribe>) => {
      this.userReservation = friend.payload.doc.data().user;
      console.log(this.userReservation);
    });
  }
}
