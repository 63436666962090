<div class="modal-content h-100 w-min-360">
	<div class="modal-header flex-shrink-0">
		<h5 class="mb-0">選擇版型</h5>
		<button (click)="onNoClick()" class="close">×</button>
	</div>
	<div class="modal-body mh-100 overflow-hidden">
		<div class="d-flex flex-column mx-1">
			<div class="d-flex">
				<div (click)="select(0)" class="h-fix-160 w-fix-160 mx-2 my-2">
					<div  class=" w-100 h-100 border border-primary rounded" style="box-sizing: border-box; border-width: 1px !important;">
						<div class="d-flex justify-content-center align-items-center w-100 h-100 bg-dark-20 h2 mb-0 text-white">
							<div>A</div>
						</div>
					</div>
				</div>
				<div (click)="select(1)" class="h-fix-160 w-fix-160 mx-2 my-2">
					<div  class=" w-100 h-100 border border-primary rounded" style="box-sizing: border-box; border-width: 1px !important;">
						<div class="d-flex align-items-stretch justify-content-center align-items-center w-100 h-100 bg-dark-20 h2 mb-0 text-white">
							<div class="d-flex justify-content-center align-items-center w-50">A</div>
							<div class="d-flex justify-content-center align-items-center w-50 border-left border-primary" style="border-width: 1px !important;">B</div>
						</div>
					</div>
				</div>
				<div (click)="select(2)" class="h-fix-160 w-fix-160 mx-2 my-2">
					<div  class=" w-100 h-100 border border-primary rounded" style="box-sizing: border-box; border-width: 1px !important;">
						<div class="d-flex flex-column w-100 h-100 bg-dark-20 h2 mb-0 text-white">
							<div class="d-flex justify-content-center align-items-center w-100 h-100">A</div>
							<div class="d-flex justify-content-center align-items-center w-100 h-100 border-top border-primary" style="border-width: 1px !important;">B</div>
						</div>
					</div>
				</div>
				<div (click)="select(3)" class="h-fix-160 w-fix-160 mx-2 my-2">
					<div  class=" w-100 h-100 border border-primary rounded" style="box-sizing: border-box; border-width: 1px !important;">
						<div class="d-flex flex-column w-100 h-100 bg-dark-20 h2 mb-0 text-white">
							<div class="d-flex justify-content-center align-items-center w-100 h-100">A</div>
							<div class="d-flex justify-content-center align-items-center w-100 h-100 border-top border-primary" style="border-width: 1px !important;">B</div>
							<div class="d-flex justify-content-center align-items-center w-100 h-100 border-top border-primary" style="border-width: 1px !important;">C</div>
						</div>
					</div>
				</div>
			</div>
			<div class="d-flex">
				<div (click)="select(4)" class="h-fix-160 w-fix-160 mx-2 my-2">
					<div  class=" w-100 h-100 border border-primary rounded" style="box-sizing: border-box; border-width: 1px !important;">
						<div class="d-flex align-items-stretch justify-content-center align-items-center w-100 h-50 bg-dark-20 h2 mb-0 text-white">
							<div class="d-flex justify-content-center align-items-center w-50">A</div>
							<div class="d-flex justify-content-center align-items-center w-50 border-left border-primary" style="border-width: 1px !important;">B</div>
						</div>
						<div class="d-flex align-items-stretch justify-content-center align-items-center w-100 h-50 bg-dark-20 h2 mb-0 text-white border-top border-primary" style="border-width: 1px !important;">
							<div class="d-flex justify-content-center align-items-center w-50">C</div>
							<div class="d-flex justify-content-center align-items-center w-50 border-left border-primary" style="border-width: 1px !important;">D</div>
						</div>
					</div>
				</div>
				<div (click)="select(5)" class="h-fix-160 w-fix-160 mx-2 my-2">
					<div  class=" w-100 h-100 border border-primary rounded" style="box-sizing: border-box; border-width: 1px !important;">
						<div class="d-flex justify-content-center align-items-center w-100 h-50 bg-dark-20 h2 mb-0 text-white">
							<div>A</div>
						</div>
						<div class="d-flex align-items-stretch justify-content-center align-items-center w-100 h-50 bg-dark-20 h2 mb-0 text-white border-top border-primary" style="border-width: 1px !important;">
							<div class="d-flex justify-content-center align-items-center w-50">B</div>
							<div class="d-flex justify-content-center align-items-center w-50 border-left border-primary" style="border-width: 1px !important;">C</div>
						</div>
					</div>
				</div>
				<div (click)="select(6)" class="h-fix-160 w-fix-160 mx-2 my-2">
					<div  class=" w-100 h-100 border border-primary rounded" style="box-sizing: border-box; border-width: 1px !important;">
						<div class="d-flex justify-content-center align-items-center w-100 h-50 bg-dark-20 h2 mb-0 text-white">
							<div>A</div>
						</div>
						<div class="d-flex flex-column w-100 h-50 bg-dark-20 h2 mb-0 text-white border-top border-primary" style="border-width: 1px !important;">
							<div class="d-flex justify-content-center align-items-center w-100 h-100"><span>B</span></div>
							<div class="d-flex justify-content-center align-items-center w-100 h-100 border-top border-primary" style="border-width: 1px !important;"><span>C</span></div>
						</div>
					</div>
				</div>
				<div (click)="select(7)" class="h-fix-160 w-fix-160 mx-2 my-2">
					<div  class=" w-100 h-100 border border-primary rounded" style="box-sizing: border-box; border-width: 1px !important;">
						<div class="d-flex align-items-stretch justify-content-center align-items-center w-100 h-50 bg-dark-20 h2 mb-0 text-white">
							<div class="d-flex justify-content-center align-items-center w-35"><span>A</span></div>
							<div class="d-flex justify-content-center align-items-center w-35 border-left border-primary" style="border-width: 1px !important;"><span>B</span></div>
							<div class="d-flex justify-content-center align-items-center w-35 border-left border-primary" style="border-width: 1px !important;"><span>C</span></div>
						</div>
						<div class="d-flex align-items-stretch justify-content-center align-items-center w-100 h-50 bg-dark-20 h2 mb-0 text-white border-top border-primary" style="border-width: 1px !important;">
							<div class="d-flex justify-content-center align-items-center w-35"><span>D</span></div>
							<div class="d-flex justify-content-center align-items-center w-35 border-left border-primary" style="border-width: 1px !important;"><span>E</span></div>
							<div class="d-flex justify-content-center align-items-center w-35 border-left border-primary" style="border-width: 1px !important;"><span>F</span></div>
						</div>
					</div>
				</div>
			</div>
			</div>
	</div>
</div>
