<div class="d-flex justify-content-between align-items-center mb-2"><h2 class="mb-0">標籤設定</h2>
</div>
<hr>
<div class=" my-4">
	<div class="form-group row w-fix-400">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">標籤</label>
		<div class="col-sm-9">
			<div class="form-inline text-muted">
				<input [(ngModel)]="tagName" required maxlength="15" type="text" name="statusMessage" placeholder="請輸入標籤" autocomplete="off" class="form-control w-fix-240">
				<span class="pl-1"><strong class="">{{tagName.length}}</strong><span class="">/15</span></span>
			</div>
		</div>
	</div>
	<div class="btn-group ">
		<button [disabled]="tagName === ''"  (click)="addTag()" type="button" style="margin-left: 12rem" class=" btn btn-primary w-min-100 h-35">建立新標籤</button>
	</div>
</div>
<hr>
<table class="table table-hover table-layout-fixed">
	<thead class="thead-light">
	<tr>
		<th class="">
      <span class="">
        標籤名稱
      </span>
		</th>
		<th class="">
      <span class="">
        動作
      </span>
		</th>
	</tr>
	</thead>
	<tbody>
	<tr *ngFor="let tag of tags" class="">
		<td>
      <span class=""><!---->
	      {{tag}}
      </span>
		</td>
		<td>
			<a class="text-primary" href="javascript:void(0)" (click)="removeTag(tag)"><!---->
				刪除
			</a>
		</td>
	</tr>
	</tbody>
</table>
