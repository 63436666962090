<div class="d-flex justify-content-between align-items-center mb-2"><h2 class="mb-0">點數卡列表</h2>
</div>
<hr>

<form #form="ngForm">
	<div class=" my-4">
		<div class="form-group row w-fix-400">
			<label class="col-sm-3 col-form-label text-sm-right font-weight-light">點數</label>
			<div class="col-sm-9">
				<div class="form-inline text-muted">
					<input [(ngModel)]="point" required maxlength="15" min="1" type="number" name="name" placeholder="請輸入點數" autocomplete="off" class="form-control w-fix-240">
				</div>
			</div>
		</div>
		<div class="form-group row w-fix-400">
			<label class="col-sm-3 col-form-label text-sm-right font-weight-light">到期日</label>
			<div class="col-sm-5">
				<div class="form-inline text-muted d-flex">
					<input class="form-control w-80 mr-2 " style="width: 400px !important;" required name="eventEndTime" [(ngModel)]="endTime"    [owlDateTime]="eventEndTime"  [owlDateTimeTrigger]="eventEndTime"  autocomplete="off">
					<span [owlDateTimeTrigger]="eventEndTime">
<!--					<fa-icon class="text-muted" [icon]="icon.faCalendarAlt"></fa-icon>-->
					</span>
					<owl-date-time  #eventEndTime></owl-date-time>
				</div>
			</div>
		</div>
		<div class="btn-group ">
			<button [disabled]="form.invalid" (click)="generate()" type="button" style="margin-left: 12rem" class=" btn btn-primary w-min-100 h-35">建立點數卡</button>
		</div>
	</div>
</form>
<hr>
<table class="table table-hover table-layout-fixed">
	<thead class="thead-light">
	<tr>
		<th class="">
      <span class="">
        建立時間
      </span>
		</th>
		<th class="">
      <span class=""><!---->
        到期時間
      </span>
		</th>
		<th class="w-fix-80">
      <span ><!---->
        點數
      </span>
		</th>
		<th class="">
      <span ><!---->
        序號
      </span>
		</th>
		<th class="">
      <span ><!---->
        狀態
      </span>
		</th>
		<th class="">
      <span ><!---->
        官方帳號
      </span>
		</th>
	</tr>
	</thead>
	<tbody>
	<tr *ngFor="let pointCard of currentPage" class="">
		<td>
			{{pointCard.payload.doc.data().timestamp.toDate().toLocaleString()}}
		</td>
		<td>
      <span class=""><!---->
	      {{pointCard.payload.doc.data().endTime.toDate().toLocaleString()}}
      </span>
		</td>
		<td class="w-fix-80">
			{{pointCard.payload.doc.data().point}}
		</td>
		<td>
			{{pointCard.payload.doc.id}}
		</td>
		<td>
			{{pointCard.payload.doc.data().used ? '已使用' : '未使用'}}
		</td>
		<td>
			<div class="div3" *ngIf="pointCard.payload.doc.data().shop as shop">
				<div  class=" avatar avatar-sm rounded-circle div1">
					<div class="w-100 h-100" [ngStyle]="{'background': 'url(' + shop.photoUrl + ') center center / cover'}"></div>
				</div>
				<div class="div2">
					<label class="user col-form-label text-sm-left font-weight-normal">{{shop.displayName}}</label>
					<!--            <label class="user col-form-label-xs text-sm-left font-weight-light">{{shop.brief }}</label>-->
				</div>
			</div>
		</td>

	</tr>
	</tbody>
</table>
<hr>
<app-list-paginator [data]="pointCards" (listChanged)="currentPage = $event"></app-list-paginator>
