import { Component, OnInit } from '@angular/core';
import {ShopService} from '../../../../services/shop.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {RouteService} from '../../../../../shared/services/route.service';
import {FAdOrder} from '../../../../../firebase/models/ad';

@Component({
  selector: 'app-shop-ad-purchase-page',
  templateUrl: './shop-ad-purchase-page.component.html',
  styleUrls: ['./shop-ad-purchase-page.component.styl']
})
export class ShopAdPurchasePageComponent implements OnInit {

  select = 1;
  days = 7;
  homePageLimit = 1000;
  startPageLimit = 1000;
  couponPageLimit = 1000;
  homePageDays = 7;
  couponDays = 7;
  startPageDays = 7;
  constructor(private ss: ShopService,
              private db: AngularFirestore,
              private router: RouteService) { }

  ngOnInit(): void {
  }
  getFriendLimit(days) {
    switch (days ) {
      case 7:
        return 1000;
      case 14:
        return 2000;
      case 30:
        return 5000;
      case 60:
        return 10000;
      case 90:
        return 16000;
    }
  }
  getHomePagePrice(days) {
    switch (days  ) {
      case 7:
        return 999;
      case 14:
        return 1888;
      case 30:
        return 3688;
      case 60:
        return 6888;
      case 90:
        return 9888;
    }
  }
  getPrice(days) {
    switch (days ) {
      case 7:
        return 1999;
      case 14:
        return 3999;
      case 30:
        return 6999;
      case 60:
        return 9999;
      case 90:
        return 15999;
    }
  }
  getCharge() {
    switch (this.select) {
      case 1:
        return this.getPrice(this.couponDays);
      case 2:
        return this.getHomePagePrice(this.homePageDays);
      case 3:
        return this.getPrice(this.startPageDays);
    }
  }
  getFollower() {
    switch (this.select) {
      case 1:
        return this.getFriendLimit(this.couponDays);
      case 2:
        return this.getFriendLimit(this.homePageDays);
      case 3:
        return this.getFriendLimit(this.startPageDays);
    }
  }
  getDays() {
    switch (this.select) {
      case 1:
        return this.couponDays;
      case 2:
        return this.homePageDays;
      case 3:
        return this.startPageDays;
    }
  }
  Order() {
    const startDate = new Date();
    startDate.setDate(startDate.getDate() + 1);
    startDate.setHours(0, 0, 0, 0);
    const endTime = new Date();
    endTime.setDate(endTime.getDate() + 1 + this.getDays());
    endTime.setHours(0, 0, 0, 0);
    const order: FAdOrder = {
      createTime: new Date(),
      follower: 0,
      note: '',
      shopId: this.ss.currentShop.uid,
      position: this.select,
      targetFollower: this.getFollower(),
      totalFee: this.getCharge(),
      pay: false,
      days: this.days,
      enabled: false
    };
    this.db.collection('advOrders').add(order).then(() => {
      this.router.navigateTo('/Billing/Payment');
    });
  }

}
