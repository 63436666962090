import { Component, OnInit } from '@angular/core';
import {FStoreOrder} from '../../../../../../firebase/models/store';
import {AngularFirestore, AngularFirestoreDocument} from '@angular/fire/firestore';
import {ShopService} from '../../../../../services/shop.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-shop-store-order-edit-page',
  templateUrl: './shop-store-order-edit-page.component.html',
  styleUrls: ['./shop-store-order-edit-page.component.styl']
})
export class ShopStoreOrderEditPageComponent implements OnInit {

  order: FStoreOrder;
  orderDoc: AngularFirestoreDocument;
  constructor(private ss: ShopService,
              private activatedRoute: ActivatedRoute,
              private db: AngularFirestore
  ) {

  }

  ngOnInit(): void {
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    this.orderDoc = this.db.collection('stores').doc(this.ss.currentShop.uid).collection<FStoreOrder>('storeOrders').doc(id);
    this.orderDoc.get().subscribe(order => {
      if (order.exists) {
        // @ts-ignore
        this.order = order.data();

      }
    });
  }
  getStatus(status: number) {
    switch (status) {
      case 1:
        return '尚未出貨';
      case 2:
        return '取消訂單';
      case 3:
        return '出貨中';
      case 4:
        return '退貨';
      case 5:
        return '退款';
      case 6:
        return '結案';

    }
  }

}
