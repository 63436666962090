<div class="d-flex justify-content-between align-items-center mb-2"><h2 class="mb-0">點數紀錄</h2>
</div>
<hr>
<div class="my-4 d-flex">
	<label class="ml-2 col-form-label text-sm-right font-weight-light">點數</label>
	<label class="ml-2 col-form-label text-sm-right font-weight-light text-primary">{{this.point}}</label>
</div>

<form #form="ngForm">
	<div class="my-4 d-flex">
		<label class="ml-2 col-form-label text-sm-right font-weight-light">序號</label>
		<div class="ml-2 form-inline text-muted">
			<input [(ngModel)]="sn" required type="text" name="name" placeholder="請輸入序號" autocomplete="off" style="width: 300px !important;" class="form-control w-fix-600">
		</div>
		<div class="btn-group ">
			<button [disabled]="form.invalid" (click)="deposit()" type="button" style="margin-left: 2rem" class=" btn btn-primary w-min-100">加值</button>
		</div>
	</div>
</form>
<hr>
<table class="table table-hover table-layout-fixed">
	<thead class="thead-light">
	<tr>
		<th class="">
      <span class="">
        時間
      </span>
		</th>
		<th class="">
      <span class=""><!---->
        動作
      </span>
		</th>
		<th class="">
      <span ><!---->
        點數
      </span>
		</th>
		<th class="">
      <span ><!---->
        序號
      </span>
		</th>
	</tr>
	</thead>
	<tbody>
	<tr *ngFor="let pointCard of currentPage" class="">
		<td>
			{{pointCard.payload.doc.data().timestamp.toDate().toLocaleString()}}
		</td>
		<td>
      <span class=""><!---->
	      {{getType(pointCard)}}
      </span>
		</td>
		<td>
			{{pointCard.payload.doc.data().point}}
		</td>
		<td>
			{{pointCard.payload.doc.id}}
		</td>
	</tr>
	</tbody>
</table>
<hr>
<app-list-paginator [data]="pointRecords" (listChanged)="currentPage = $event"></app-list-paginator>
