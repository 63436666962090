import { Component, OnInit } from '@angular/core';
import {RouteService} from '../../../services/route.service';
import {NotifyService} from '../../../services/notify.service';
import {AngularFirestore, AngularFirestoreDocument} from '@angular/fire/firestore';
import {ActivatedRoute} from '@angular/router';
import {FNewsContent, FNewsTags} from '../../../../firebase/models/news';
import {FroalaService} from "../../../services/froala.service";

@Component({
  selector: 'app-news-review-edit-page',
  templateUrl: './news-review-edit-page.component.html',
  styleUrls: ['./news-review-edit-page.component.styl']
})
export class NewsReviewEditPageComponent implements OnInit {

  NewsDoc: AngularFirestoreDocument;
  News: FNewsContent;
  tags = [];
  tagsSource = [];
  constructor(
      private activatedRoute: ActivatedRoute,
      private router: RouteService,
      private db: AngularFirestore,
      private notify: NotifyService,
      public froala: FroalaService
  ) { }
  ngOnInit(): void {
    this.getTagSource();
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    this.NewsDoc = this.db.collection<FNewsContent>('newsContents').doc(id);
    this.NewsDoc.get().subscribe(news => {
      if (news.exists) {
        this.initNews(news.data());
      } else {
        this.notify.pop('不存在');
        this.router.navigateTo('Home/Messages');
      }
    });
  }
  initNews(news) {
    this.News = news;
    this.tags = this.News.tagsA.map(tagId => {
      const name = this.tagsSource.filter(tag => tag.value === tagId)[0].display;
      return {
        display: name,
        value: tagId,
      };
    });
  }
  getTagSource() {
    this.db.collection<FNewsTags>('newsTags', ref => ref.where('order', '>=', 10)).valueChanges().subscribe(tags => {
      this.tagsSource = tags.map(x => {
        return {
          display: x.name,
          value: x.tagId,
        };
      });
    });
  }
  save() {
    this.News.tagsA = this.tags.map(tag => tag.value);
    if (this.News.status === 1) {
      this.News.pubTime = new Date();
      this.updateLatestNewsToNewsTag();
    }
    this.NewsDoc.update(this.News).then(() => {
      this.notify.pop('已儲存');
      this.router.toPreviousUrl();
    });
  }
  updateLatestNewsToNewsTag() {
    this.News.tagsA.forEach(tag => {
      this.db.collection('newsTags', ref => ref.where('tagId', '==', tag ))
          .get().subscribe(tags => {
        tags.forEach(newsTag => {
          newsTag.ref.update({
            news: this.News
          }).then();
        });
      });
    });
  }

}
