import { Component, OnInit } from '@angular/core';
import {AngularFirestore, DocumentChangeAction} from '@angular/fire/firestore';
import {FStore, FStoreProduct} from '../../../../../firebase/models/store';
import {ShopService} from '../../../../services/shop.service';

@Component({
  selector: 'app-shop-store-product-list-page',
  templateUrl: './shop-store-product-list-page.component.html',
  styleUrls: ['./shop-store-product-list-page.component.styl']
})
export class ShopStoreProductListPageComponent implements OnInit {

  products: DocumentChangeAction<FStoreProduct>[] = [];
  currentPage: DocumentChangeAction<FStoreProduct>[] = [];
  constructor(private ss: ShopService,
              private db: AngularFirestore
  ) { }

  ngOnInit(): void {
    this.db.collection('stores').doc(this.ss.currentShop.uid)
        .collection<FStoreProduct>('products').snapshotChanges().subscribe((products) => {
      this.products = products;
    });
  }

}
