import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-input-edit-dialog',
  templateUrl: './input-edit-dialog.component.html',
  styleUrls: ['./input-edit-dialog.component.styl']
})
export class InputEditDialogComponent implements OnInit {

  content = '';
  @Inject(MAT_DIALOG_DATA) public data: any;

  constructor(public dialogRef: MatDialogRef<InputEditDialogComponent>,
              @Inject(MAT_DIALOG_DATA) data: any) {
    this.data = data;
  }

  ngOnInit() {
  }
  onNoClick(): void {
    this.dialogRef.close(false);
  }
  confirm() {
    this.dialogRef.close(this.content);
  }

}
