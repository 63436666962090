import {Component, forwardRef, OnInit} from '@angular/core';
import {AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator} from '@angular/forms';
import {faAddressCard, faClipboard, faCommentAlt, faCommentDots, faImage, faWindowClose} from '@fortawesome/free-regular-svg-icons';
import {faGift, faVideo} from '@fortawesome/free-solid-svg-icons';
import {ModalService} from '../../../shared/services/modal.service';
import {
  FCouponContent, FCourseContent,
  FMessage,
  FMessageType,
  FPhotoContent, FQuestionnaireContent,
  FRichContent,
  FTextContent,
  FVideoContent
} from '../../../firebase/models/message';
import {StorageService} from '../../../shared/services/storage.service';
import {UploadImageDialogData} from '../../../shared/ui/upload-image-dialog/upload-image-dialog.component';
import {DocumentChangeAction} from '@angular/fire/firestore';
import {FCoupon} from '../../../firebase/models/coupon';
import {FQuestionnaire} from '../../../firebase/models/questionnaire';
import {FCourse} from '../../../firebase/models/course';
import {IconService} from '../../../shared/services/icon.service';

export const MESSAGE_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ShopMessageContentCardComponent),
  multi: true
};

export const MESSAGE_VALIDATORS: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => ShopMessageContentCardComponent),
  multi: true
};

@Component({
  selector: 'app-shop-message-content-card',
  templateUrl: './shop-message-content-card.component.html',
  styleUrls: ['./shop-message-content-card.component.styl'],
  providers: [MESSAGE_CONTROL_VALUE_ACCESSOR, MESSAGE_VALIDATORS]
})
export class ShopMessageContentCardComponent implements OnInit, ControlValueAccessor, Validator {
  message: FMessage = {
    content: {
      text: ''
    },
    timestamp: new Date(),
    type: 0,
    urlTags: null
  };
  valid = true;
  onChange: (value) => {};
  type = 'text';
  // icons
  faCommentAlt = faCommentAlt;
  faWindowClose = faWindowClose;
  faAddressCard = faAddressCard;
  faImage = faImage;
  faVideo = faVideo;
  faGift = faGift;
  faCommentDots = faCommentDots;
  faClipboard = faClipboard;


  cursorPosition = 0;
  FMessageType = FMessageType;
  constructor(private ms: ModalService,
              private ss: StorageService,
              public icon: IconService) {
  }

  ngOnInit(): void {
  }

  addEmoji() {
    this.ms.openEmojiPicker().afterClosed().subscribe(emoji => {
      (this.message.content as FTextContent).text =
          (this.message.content as FTextContent).text.substring(0, this.cursorPosition) +
          emoji +
          (this.message.content as FTextContent).text.substring(this.cursorPosition);
    });
  }
  uploadImage() {
    const info: UploadImageDialogData = {
      height: 640, type: 'image', width: 640

    };
    this.ss.openUploadDialog(info).afterClosed().subscribe(img => {
      if (img) {
        (this.message.content as FPhotoContent).photoUrl = img;
      } else {
        (this.message.content as FPhotoContent).photoUrl = '';
      }
      this.messageChanged();
    });
  }
  openUploadVideoDialog() {
    const info: UploadImageDialogData = {
      height: 640, type: 'video', width: 640
    };
    this.ss.openUploadDialog(info).afterClosed().subscribe(video => {
      (this.message.content as FVideoContent).videoUrl = video;
      this.messageChanged();
    });
  }
  openRichMessagePicker() {
    this.ms.openRichMessagePicker().afterClosed().subscribe(result => {
      if (result) {
        this.message.content = result.payload.doc.data();
        this.messageChanged();
      }
    });
  }
  openCouponPicker() {
    this.ms.openCouponPicker().afterClosed().subscribe((coupon?: DocumentChangeAction<FCoupon>) => {
      if (coupon) {
        (this.message.content as FCouponContent) = {
          endTime: coupon.payload.doc.data().endTime,
          photoUrl: coupon.payload.doc.data().photoUrl,
          ref: coupon.payload.doc.ref,
          title: coupon.payload.doc.data().title
        };
        this.messageChanged();
      }
    });
  }
  openEventPicker() {
    this.ms.openEventPicker().afterClosed().subscribe( (course?: DocumentChangeAction<FCourse>) => {
      (this.message.content as FCourseContent) = {
        endTime: course.payload.doc.data().endTime,
        eventEndTime: course.payload.doc.data().eventEndTime,
        eventStartTime: course.payload.doc.data().eventStartTime,
        name: course.payload.doc.data().name,
        photoUrl: course.payload.doc.data().photoUrl,
        ref: course.payload.doc.ref
      };
      this.messageChanged();
    });
  }
  openQnrPicker() {
    this.ms.openQnrPicker().afterClosed().subscribe( (qnr?: DocumentChangeAction<FQuestionnaire>) => {
    (this.message.content as FQuestionnaireContent) = {
        couponLink: qnr.payload.doc.data().couponLink,
        endTime: qnr.payload.doc.data().endTime,
        photoUrl: qnr.payload.doc.data().photoUrl,
        qnrRef: qnr.payload.doc.ref,
        title: qnr.payload.doc.data().title
    };
    this.messageChanged();
    });
  }
  openCoursePicker() {
    this.ms.openCoursePicker().afterClosed().subscribe( (course?: DocumentChangeAction<FCourse>) => {
      (this.message.content as FCourseContent) = {
        endTime: course.payload.doc.data().endTime,
        eventEndTime: course.payload.doc.data().eventEndTime,
        eventStartTime: course.payload.doc.data().eventStartTime,
        name: course.payload.doc.data().name,
        photoUrl: course.payload.doc.data().photoUrl,
        ref: course.payload.doc.ref

      };
      this.messageChanged();
    });
  }
  tabChanged() {
    switch (this.message.type) {
      case FMessageType.text:
        this.message.content = {
          text: ''
        };
        break;
      case FMessageType.photo:
        this.message.content = {
          urls: null,
          photoUrl: ''
        };
        break;
      case FMessageType.rich:
        (this.message.content as FRichContent) = {
          createTime: undefined, name: '', photoUrl: '', styleType: 0, urls: []
        };
        break;
      case FMessageType.video:
        (this.message.content as FVideoContent) = {
          link: '', linkName: '', videoUrl: ''

        };
        break;
      case FMessageType.coupon:
        (this.message.content as FCouponContent) = {
          endTime: undefined, photoUrl: '', ref: null, title: ''
        };
        break;
      case FMessageType.qnr:
        (this.message.content as FQuestionnaireContent) = {
          couponLink: '', endTime: undefined, photoUrl: '', qnrRef: null, title: ''
        };
        break;
      case FMessageType.course:
        (this.message.content as FCourseContent) = {
          endTime: undefined, eventEndTime: undefined, eventStartTime: undefined, name: '', photoUrl: '', ref: null
        };
        break;
      case FMessageType.event:
        (this.message.content as FCourseContent) = {
          endTime: undefined, eventEndTime: undefined, eventStartTime: undefined, name: '', photoUrl: '', ref: null
        };
        break;
    }
    this.messageChanged();
  }
  messageChanged() {
    console.log('changed');
    this.onChange(this.message);
  }



  validate(control: AbstractControl): ValidationErrors | null {
    const requiredError = {
      error: 'message most be filled'
    };
    if (!this.message) {
      return requiredError;
    }
    switch (this.message.type) {
      case FMessageType.text:
        this.valid = (this.message.content as FTextContent).text !== '';
        break;
      case FMessageType.photo:
        this.valid = (this.message.content as FPhotoContent).photoUrl !== '';
        break;
      case FMessageType.rich:
        this.valid = (this.message.content as FRichContent).photoUrl !== '';
        break;
      case FMessageType.video:
        this.valid = (this.message.content as FVideoContent).videoUrl !== '';
        break;
      case FMessageType.coupon:
        this.valid = (this.message.content as FCouponContent).photoUrl !== '';
        break;
      case FMessageType.qnr:
        this.valid = (this.message.content as FQuestionnaireContent).photoUrl !== '';
        break;
      case FMessageType.course:
        this.valid = (this.message.content as FCourseContent).photoUrl !== '';
        break;
      case FMessageType.event:
        this.valid = (this.message.content as FCourseContent).photoUrl !== '';
        break;
    }
    return this.valid ? null : requiredError;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(obj: any): void {
    console.log('wv');
    this.message = obj;
  }



}


