import { Injectable } from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {ShopService} from '../../shop/services/shop.service';

@Injectable({
  providedIn: 'root'
})
export class RouteService {

  private previousUrl: string;
  private currentUrl: string;

  constructor(private router: Router, private ss: ShopService) {
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      }
    });
  }

  public toPreviousUrl() {
    this.router.navigate([this.previousUrl]);
  }
  public toMessages() {
    this.router.navigate(['Account/' + this.ss.currentShop.lineAt + '/Home/Message']);
  }
  public navigateTo(path: string) {
    this.router.navigate(['Account/' + this.ss.currentShop.lineAt + path]);
  }
}
