<div *ngIf="!loading" class="d-flex justify-content-center align-items-center h-75v flex-column">
  <qrcode *ngIf="qrCode !== ''"  id="qrCodeData"  [qrdata]="qrCode" [size]="130" [level]="'M'"></qrcode>
  <br>
  <br>
  <div>
    <span>請先至AppStore或Google Play搜尋下載CastTalk</span>
  </div>
  <div>
    <span>註冊官方帳號後，掃描QR Code登入</span>
  </div>
  <br>
  <!--  <span>{{qrCode}}</span>-->
  <a  *ngIf="!production" [href]="qrCode">測試登入連結</a>
</div>
<div *ngIf="loading" class="d-flex justify-content-center align-items-center h-75v flex-column">
  <app-progress-spinner></app-progress-spinner>
</div>
