import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator} from '@angular/forms';
import {FActionModel} from '../../../firebase/models/shared';
import {ModalService} from '../../../shared/services/modal.service';
import {DocumentChangeAction} from '@angular/fire/firestore';
import {FCoupon} from '../../../firebase/models/coupon';
import {
  FCouponContent, FCourseContent,
  FMessageType,
  FPhotoContent,
  FQuestionnaireContent,
  FRichContent,
  FTextContent,
  FVideoContent
} from '../../../firebase/models/message';
import {FQuestionnaire} from '../../../firebase/models/questionnaire';
import {FCourse} from '../../../firebase/models/course';
import {faWindowClose} from '@fortawesome/free-regular-svg-icons';
import {FCategory, FStoreProduct} from '../../../firebase/models/store';

export const ACTION_MODEL_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ShopActionModelComponent),
  multi: true
};

export const ACTION_MODEL_VALIDATORS: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => ShopActionModelComponent),
  multi: true
};

@Component({
  selector: 'app-shop-action-model',
  templateUrl: './shop-action-model.component.html',
  styleUrls: ['./shop-action-model.component.styl'],
  providers: [ACTION_MODEL_CONTROL_VALUE_ACCESSOR, ACTION_MODEL_VALIDATORS]
})

export class ShopActionModelComponent implements OnInit, ControlValueAccessor, Validator  {

  @Input() actionText = false;
  action: FActionModel = {
    content: '',
    type: 0
  };
  valid = false;
  faWindowClose = faWindowClose;
  constructor(private ms: ModalService) {
    this.action = {
      content: '',
      text: '',
      type: 0
    };
  }

  ngOnInit(): void {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const requiredError = {
      error: 'message most be filled'
    };
    if (!this.action) {
      return requiredError;
    }
    switch (this.action.type) {
      case 0:
        this.valid = true;
        break;
      case 1:
        this.valid = this.action.content !== '';
        break;
      case 2:
        this.valid = (this.action.contentData !== null && this.action.contentData !== undefined && this.action.content !== '');
        break;
        case 3:
        this.valid = this.action.content !== '';
        break;
    }
    return this.valid ? null : requiredError;
  }

  writeValue(obj: FActionModel): void {
    if (obj) {
      this.action = obj;
    }
  }
  actionChanged() {
    this.onChange(this.action);
  }
  onChange(value) {}
  openCouponPicker() {
    this.ms.openCouponPicker().afterClosed().subscribe((result?: DocumentChangeAction<FCoupon>) => {
      if (result) {
        this.action.content = '/' + result.payload.doc.ref.path;
        this.action.contentData = result.payload.doc.data();
        this.actionChanged();
      }
    });
  }
  openQnrPicker() {
    this.ms.openQnrPicker().afterClosed().subscribe( (result?: DocumentChangeAction<FQuestionnaire>) => {
      if (result) {
        this.action.content = '/' + result.payload.doc.ref.path;
        this.action.contentData = result.payload.doc.data();
        this.actionChanged();
      }
    });
  }
  openCoursePicker() {
    this.ms.openCoursePicker().afterClosed().subscribe( (course?: DocumentChangeAction<FCourse>) => {
      if (course) {
        this.action.content = '/' + course.payload.doc.ref.path;
        this.action.contentData = course.payload.doc.data();

        this.actionChanged();
      }
    });
  }
  openEventPicker() {
    this.ms.openEventPicker().afterClosed().subscribe( (course?: DocumentChangeAction<FCourse>) => {
      if (course) {
        this.action.content = '/' + course.payload.doc.ref.path;
        this.action.contentData = course.payload.doc.data();
        this.actionChanged();
      }
    });
  }
  openRewardCardPicker() {
    this.ms.openRewardCardPicker().afterClosed().subscribe( (course?: DocumentChangeAction<FCourse>) => {
      if (course) {
        this.action.content = '/' + course.payload.doc.ref.path;
        this.action.contentData = course.payload.doc.data();
        this.actionChanged();
      }
    });
  }
  openProductPicker() {
    this.ms.openStoreProductPicker().afterClosed().subscribe( (course?: DocumentChangeAction<FStoreProduct>) => {
      if (course) {
        this.action.content = '/' + course.payload.doc.ref.path;
        this.action.contentData = course.payload.doc.data();
        this.actionChanged();
      }
    });
  }
  openStoreCategoryPicker() {
    this.ms.openStoreCategoryPicker().afterClosed().subscribe( (course?: DocumentChangeAction<FCategory>) => {
      if (course) {
        this.action.content = '/' + course.payload.doc.ref.path;
        this.action.contentData = course.payload.doc.data();
        this.actionChanged();
      }
    });
  }
  typeChanged() {
    this.action.content = '';
    this.action.contentData = null;
    this.actionChanged();
  }
}
