import { Component, OnInit } from '@angular/core';
import {ShopService} from '../../../../services/shop.service';
import {AngularFireFunctions} from '@angular/fire/functions';
import {PayAdWithPointRequest} from '../../../../../../../functions/src/agent/point';
import {ShopDepositPointRequest} from '../../../../../../../functions/src/shop/point';
import {NotifyService} from '../../../../../shared/services/notify.service';
import {DocumentChangeAction} from '@angular/fire/firestore/interfaces';
import {FShopPointRecord, FShopPointRecordType} from '../../../../../firebase/models/pointCard';

@Component({
  selector: 'app-shop-billing-point-page',
  templateUrl: './shop-billing-point-page.component.html',
  styleUrls: ['./shop-billing-point-page.component.styl']
})
export class ShopBillingPointPageComponent implements OnInit {

  pointRecords: DocumentChangeAction<FShopPointRecord>[] = [];
  currentPage: DocumentChangeAction<FShopPointRecord>[] = [];
  sn = '';
  point = 0;
  constructor(private ss: ShopService,
              private func: AngularFireFunctions,
              private notify: NotifyService) { }

  ngOnInit(): void {
    this.ss.currentShopDoc.collection<FShopPointRecord>('pointRecords').snapshotChanges().subscribe(x => {
      this.pointRecords = x;
    });
    if (this.ss.chargePlan.point) {
      this.point = this.ss.chargePlan.point;
    }
  }
  deposit() {
    const req: ShopDepositPointRequest = {shopId: this.ss.currentShop.uid, sn: this.sn};
    this.func.httpsCallable('ShopDepositPoint')(req).subscribe(res => {
      this.notify.pop(res.message);
    });
  }
  getType(t: DocumentChangeAction<FShopPointRecord>) {
    return FShopPointRecordType[t.payload.doc.data().type];
  }
}
