import { Component, OnInit } from '@angular/core';
import {NotifyService} from '../../../../../shared/services/notify.service';
import {AngularFirestore} from '@angular/fire/firestore';

@Component({
  selector: 'app-admin-shop-category-list-page',
  templateUrl: './admin-shop-category-list-page.component.html',
  styleUrls: ['./admin-shop-category-list-page.component.styl']
})
export class AdminShopCategoryListPageComponent implements OnInit {

  categoryName = '';
  categories = [];
  constructor(private db: AngularFirestore,
              private notify: NotifyService) { }

  ngOnInit(): void {
    this.db.collection('system').doc('info').valueChanges().subscribe(info => {
      // @ts-ignore
      this.categories = info.categories;
    });
  }
  addCategory() {
    this.categories.push(this.categoryName);
    this.updateCategories();
  }
  deleteCategory(categoryName: string) {
    const index = this.categories.indexOf(categoryName);
    this.categories.splice(index, 1);
    this.updateCategories();
  }
  updateCategories() {
    this.db.collection('system').doc('info').update({
      categories: this.categories
    }).then(() => {
      this.notify.pop('儲存成功');
    });
  }

}
