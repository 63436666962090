<form #form="ngForm" class="">
	<div  class="d-flex justify-content-between align-items-center mb-2">
		<h2 class="mb-0">見面禮</h2></div>
	<hr>
	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">見面禮狀態</label>
		<div class="col-sm-9 d-flex flex-md-column justify-content-center">
			<mat-slide-toggle required class="align-items-center d-flex mt-2"
			                  [checked]="meetGift.enabled"
			                  (change)="meetGift.enabled = $event.checked">
				{{meetGift.enabled ? '啟用':'停用'}}
			</mat-slide-toggle>
		</div>
	</div>
	<ng-container *ngIf="meetGift.enabled">
		<div  class="form-group row w-fix-800">
			<label class="col-sm-3 col-form-label text-sm-right font-weight-light">
			</label>
			<div class="col-sm-9">
				<div class="w-100">
					<div [ngClass]="{'border-danger': !meetGift.ref}" class="card-body position-relative overflow-auto">
						<div *ngIf=" meetGift.ref"  class="position-relative border">
							<div class="d-flex align-items-center">
								<div class="flex-none figure figure-grid figure-grid-sm"><img [src]="meetGift.coupon.photoUrl" class="figure-img"></div>
								<div class="flex-1 text-truncate p-3">
									<div class="text-truncate"><span class="text-dark">{{meetGift.coupon.title}}</span></div> <small class="text-muted">{{"有效期限: " + $any( meetGift.coupon.endTime).toDate().toLocaleString()}}</small></div>
							</div>
							<button (click)="meetGift.coupon = null; meetGift.ref = null" type="button" class="position-absolute close" style="top: 0px; right: 0px;">
								<fa-icon [icon]="faWindowClose"></fa-icon>
							</button>
						</div>
						<div *ngIf="!meetGift.ref" class="border text-center">
							<div class="figure figure-grid-sm my-4">
								<a (click)="openCouponPicker()" href="javascript:void(0)">選擇優惠券</a>
								<div class="text-muted opacity-30">
									<fa-icon [icon]="faGift" size="3x"></fa-icon>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
<!--		<div class="form-group row w-fix-800" *ngIf="meetGift.ref">-->
<!--			<label class="col-sm-3 col-form-label text-sm-right font-weight-light">-->
<!--			</label>-->
<!--			<div class="col-sm-9">-->
<!--				<div class="w-100">-->
<!--					<mat-checkbox [(ngModel)]="qnrFlag" >先填答問卷才可領取</mat-checkbox>-->
<!--					<div class="card-body position-relative overflow-auto">-->
<!--						<div *ngIf="meetGift.qnrRef === null" class="border text-center">-->
<!--							<div  class="figure figure-grid-sm my-4">-->
<!--								<a (click)="openQnrPicker()" href="javascript:void(0)">選擇問卷調查</a>-->
<!--								<div class="text-muted opacity-30">-->
<!--									<fa-icon [icon]="faClipboard" size="3x"></fa-icon>-->
<!--								</div>-->
<!--							</div>-->
<!--						</div>-->
<!--						<div class="position-relative border" *ngIf="meetGift.qnrRef">-->
<!--							<button (click)="meetGift.qnrRef = null" type="button" class="position-absolute close" style="top: 0px; right: 0px;">-->
<!--								<fa-icon [icon]="faWindowClose"></fa-icon>-->
<!--							</button>-->
<!--							<div class="d-flex align-items-center">-->
<!--								<div class="flex-none figure figure-grid figure-grid-sm">-->
<!--									<img [src]="meetGift.qnrRef.photoUrl" class="figure-img figure-img">-->
<!--								</div>-->
<!--								<div class="flex-1 text-truncate p-3">-->
<!--									<div class="text-truncate">-->
<!--										<span class="text-dark">{{$any(message.content).title}}</span>-->
<!--									</div>-->
<!--									<small class="text-muted">問卷調查的結束時間： {{$any(message.content).endTime.toDate().toLocaleString()}}</small>-->
<!--								</div>-->
<!--							</div>-->
<!--						</div>-->
<!--					</div>-->
<!--				</div>-->
<!--			</div>-->
<!--		</div>-->

	</ng-container>
	<hr lass="my-4">
	<div class="d-flex justify-content-center my-5">
		<button [disabled]="!meetGift.coupon && meetGift.enabled" (click)="save()" type="submit" class="btn btn-lg btn-primary px-5 mx-1">儲存</button>
	</div>
</form>
