import { Component, OnInit } from '@angular/core';
import {DocumentChangeAction} from '@angular/fire/firestore';
import {ShopService} from '../../../../services/shop.service';
import {FCardMessage} from '../../../../../firebase/models/cardMessage';

@Component({
  selector: 'app-shop-card-message-list-page',
  templateUrl: './shop-card-message-list-page.component.html',
  styleUrls: ['./shop-card-message-list-page.component.styl']
})
export class ShopCardMessageListPageComponent implements OnInit {

  list: DocumentChangeAction<FCardMessage>[] = [];
  currentPage: DocumentChangeAction<FCardMessage>[] = [];
  constructor(private ss: ShopService) { }

  ngOnInit() {
    this.ss.currentShopDoc.collection<FCardMessage>('cardBases').snapshotChanges().subscribe(messages => {
      this.list = messages;
    });
  }
  getTypeName(type: string) {
    switch (type) {
      case 'place':
        return '地點';
      case 'people':
        return '人物';
      case 'product':
        return '產品';
      case 'picture':
        return '影像';
    }
  }

}
