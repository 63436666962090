import {Component, EventEmitter, Input, IterableDiffers, OnChanges, OnInit, Output, DoCheck, SimpleChanges} from '@angular/core';
import {faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-list-paginator',
  templateUrl: './list-paginator.component.html',
  styleUrls: ['./list-paginator.component.styl']
})
export class ListPaginatorComponent implements OnInit, OnChanges, DoCheck {
  iterableDiffer;
  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;
  @Input() data = [];
  @Output() listChanged: EventEmitter<any[]> = new EventEmitter<any[]>();
  currentPage = 0;
  dataPerPage = 10;
  constructor(private iterableDiffers: IterableDiffers) {
    this.iterableDiffer = iterableDiffers.find([]).create(null);
  }

  ngOnInit(): void {
  }
  ngDoCheck() {
    const changes = this.iterableDiffer.diff(this.data);
    if (changes) {
      this.pageChanged();
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.data.currentValue !== changes.data.previousValue) {
      this.currentPage = 0;
      setTimeout(() => {
        this.pageChanged();
      });
    }
  }

  pre() {
    if (this.currentPage > 0) {
      this.currentPage = this.currentPage - 1;
      this.pageChanged();
    }
  }
  next() {
    if (this.currentPage < ((this.data.length) / this.dataPerPage)) {
      this.currentPage = this.currentPage + 1;
      this.pageChanged();
    }
  }
  pageSelect(index: number) {
    this.currentPage = index;
    this.pageChanged();
  }
  getPages() {
    const pages = Math.ceil((this.data.length) / this.dataPerPage);
    return Array(pages).fill(0).map((x, i) => i);
  }
  getPageNum() {
    return Math.ceil((this.data.length) / this.dataPerPage);
  }
  showPageNumber(index): boolean {
    const show = Math.abs(index - this.currentPage);
    return show < 3;
  }
  pageChanged() {
    this.listChanged.emit(this.getCurrentPage());
  }
  getCurrentPage() {
    return this.data.filter((element, index, array) => {
      return (index >= this.currentPage * this.dataPerPage && index < this.currentPage * this.dataPerPage + this.dataPerPage);
    });
  }

}
