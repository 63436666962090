import { Component, OnInit } from '@angular/core';
import {ShopService} from '../../../../../services/shop.service';
import {FDraftMessage, FMessage, FPushMessage, FMessageType} from '../../../../../../firebase/models/message';
import { faCalendarAlt, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import {ModalService} from '../../../../../../shared/services/modal.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {RouteService} from '../../../../../../shared/services/route.service';
import {ActivatedRoute} from '@angular/router';
import {NotifyService} from '../../../../../../shared/services/notify.service';

@Component({
  selector: 'app-shop-message-edit',
  templateUrl: './shop-message-edit.component.html',
  styleUrls: ['./shop-message-edit.component.styl']
})
export class ShopMessageEditComponent implements OnInit {
  minDate = new Date();
  create = false;
  tags = [];
  message: FDraftMessage;
  sentCount = 0;
  draftMessageDoc;
  FMessageType = FMessageType;

  // icons
  faCalendarAlt = faCalendarAlt;
  constructor(public ss: ShopService,
              private ms: ModalService,
              private db: AngularFirestore,
              private rs: RouteService,
              private activatedRoute: ActivatedRoute,
              private notify: NotifyService)
  {
    this.initMessage();
  }

  ngOnInit(): void {
    this.getDocument();
    this.sentCount = this.ss.currentShop.follower;
  }
  confirmSent(schedule: string) {
    this.ms.openConfirmDialog('訊息確認', '確認要發送訊息？（要不要再檢查一次？)').afterClosed().subscribe(result => {
      if (result) {
        if (schedule === '1') {
          this.saveSchedule();
        } else {
          this.sent();
        }
      }
    });
  }
  onTagSelectChange(scheduleString){
    scheduleString === 1 ? (this.sentCount = this.ss.currentShop.follower) : this.sentCount = 0;
  }
  newMessage() {
    const message: FMessage = {
      content: {
        text: ''
      },
      timestamp: new Date(),
      type: 0,
      urlTags: null
    };
    return message;
  }
  onTagChange() {
    this.tags.forEach(tag => {
      this.message.tags[tag.value] = true;
    });
    if (this.tags.length === 0) {
      this.sentCount = 0;
    } else {
      this.db.collectionGroup('subscribes', ref => {
        let query = ref.where('shopId', '==', this.ss.currentShop.uid)
            .where('block', '==', false);
        this.tags.forEach(tag => {
          query = query.where(`tags.${tag.value}`, '==', true);
        });
        return query;
      }).get().subscribe(friends => {
        this.sentCount = friends.size;
      });
    }
  }
  initMessage() {
    this.message = {
      messages: [this.newMessage()],
      schedule: null,
      sent: 0,
      shop: this.ss.shopInfo,
      tags: {},
      timestamp: new Date(),
      title: '傳了一則訊息給您'
    };
  }
  addMessage(){
    this.message.messages.push(this.newMessage());
  }
  getDocument() {
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    if (id === 'Create') {
      this.create = true;
    } else  {
      this.draftMessageDoc = this.ss.currentShopDoc.collection<FDraftMessage>('drafts').doc(id);
      this.draftMessageDoc.get().subscribe(draft => {
        if (draft.exists) {
          this.message = draft.data();
          this.tags = this.ss.tagForSearchToTag(this.message.tags);
        } else {
          this.notify.pop('訊息不存在');
          this.rs.navigateTo('/Home/Message');
        }
      });
    }
  }
  sent() {
    let count = 0;
    const time = new Date();
    this.message.messages.forEach(message => {
      this.ss.currentShopDoc.collection<FPushMessage>('messages').add({
        index: count,
        shop: this.ss.shopInfo,
        title: this.message.title,
        timestamp: time,
        sent: this.sentCount,
        type: message.type,
        content: message.content,
        tags: this.message.tags,
        urlTags: message.urlTags,
      }).then(data => console.log(data)).catch(error => console.log(error));
      time.setSeconds(time.getSeconds() + 1);
      count++;
    });
    this.rs.navigateTo('/Home/Message');

  }
  saveSchedule() {
    this.ss.currentShopDoc.collection<FDraftMessage>('schedules').add(this.message).then(() => this.rs.toMessages());
  }
  delete(){
    if (!this.create) {
      this.draftMessageDoc.delete().then(() => {
        this.notify.pop('刪除成功');
        this.rs.navigateTo('/Home/Message');
      });
    }
  }
  saveDraft(){
    if (this.create) {
      this.ss.currentShopDoc.collection('drafts').add(this.message).then(() => {
        this.rs.navigateTo('/Home/Message');
      });
    } else {
      this.draftMessageDoc.update(this.message).then(() => {
        this.rs.navigateTo('/Home/Message');
      });
    }
  }
  bind() {
  }
}
