import { Component, OnInit } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {FShop} from '../../../../../firebase/models/shop';
import {FChargePlan} from '../../../../../firebase/models/chargePlan';
import {faSearch, faUserFriends} from '@fortawesome/free-solid-svg-icons';
import {MatDialog} from '@angular/material/dialog';
import {AdminShopManageDialogComponent} from './admin-shop-manage-dialog/admin-shop-manage-dialog.component';

@Component({
  selector: 'app-admin-shop-list-page',
  templateUrl: './admin-shop-list-page.component.html',
  styleUrls: ['./admin-shop-list-page.component.styl']
})
export class AdminShopListPageComponent implements OnInit {

  faSearch = faSearch;
  faUserFriends = faUserFriends;
  shops: AdminShop[] = [];
  shopsSource: AdminShop[] = [];
  currentPage: AdminShop[] = [];
  constructor(private db: AngularFirestore,
              private dialog: MatDialog) { }

  ngOnInit(): void {
    this.db.collection<FShop>('shops').get().subscribe(shops => {
      shops.forEach((shop) => {
        const shopData = shop.data() as FShop;
        if (shopData.uid) {
          this.db.collection<FShop>('chargePlan').doc(shopData.uid).get().subscribe(chargePlan => {
            if (chargePlan.exists) {
              const obj = {
                shop: shopData,
                chargePlan: chargePlan.data() as FChargePlan
              };
              this.shopsSource.push(obj);
              this.shops.push(obj);
            }
          });
        }
      });
    });
  }
  clear() {
    this.shops = this.shopsSource;
  }
  searchByDisplayName(name: string) {
    this.shops = this.shopsSource.filter(x => x.shop.displayName.includes(name));
  }
  searchByPlan(plan: string) {
    const planNum = Number(plan);
    this.shops = this.shopsSource.filter(x => x.chargePlan.status === planNum);
  }
  searchByChangePlanThisMonth() {
    this.shops = this.shopsSource.filter(x => x.chargePlan.ordered);
  }
  searchByFollower(follower: string) {
    this.shops = this.shopsSource.filter(x => x.shop.follower >= Number(follower));
  }
  searchByRandom(order: string) {
    this.shops = this.shopsSource.filter(x => x.shop.homePageOrder === Number(order));
  }
  openShopManagementDialog(shop: FShop) {
    this.dialog.open(AdminShopManageDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: shop
    });
  }
  bind() {}
}
interface AdminShop {
  chargePlan: FChargePlan;
  shop: FShop;
}
