<div class="d-flex justify-content-between align-items-center mb-2">
	<h2 class="mb-0">加值服務價格設定</h2>
</div>
<hr>

<div class=" my-4">
	<div class="form-group row w-fix-400">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">服務</label>
		<div class="col-sm-9">
			<div class="kv-select form-group d-flex align-items-end">
				<div class="flex-grow-1">
					<div class="input-group">
						<div>
							<select style="width: 100px !important;" [(ngModel)]="type" class="form-control custom-select form-control-rg w-fix-360">
								<option value="store">商店</option>
								<option value="course">課程</option>
								<option value="event">活動</option>
								<option value="rewardCard">集點卡</option>
								<option value="booking">預約服務</option>
							</select>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="form-group row w-fix-400">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">價格</label>
		<div class="col-sm-9">
			<div class="form-inline text-muted">
				<input [(ngModel)]="price" required maxlength="15" type="number" name="price" placeholder="" autocomplete="off" class="form-control w-fix-240">
			</div>
		</div>
	</div>
	<div class="form-group row w-fix-400">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light"></label>
		<div class="col-sm-9">
			<div class="btn-group ">
				<button [disabled]="!(followerLimit > 0 && price > 0)"  (click)="addPlan()" type="button" style="" class=" btn btn-primary w-min-100 h-35">建立</button>
			</div>
		</div>
	</div>

</div>
<hr>
<table class="table table-hover table-layout-fixed">
	<thead class="thead-light">
	<tr>
		<th class="">
      <span class="">
        服務
      </span>
		</th>
		<th class="">
      <span class="">
        價格
      </span>
		</th>
    <th class="">
      <span class="">
        狀態
      </span>
    </th>
<!--		<th class="">-->
<!--      <span class="">-->
<!--        動作-->
<!--      </span>-->
<!--		</th>-->
	</tr>
	</thead>
	<tbody>
	<tr *ngFor="let plan of plans" class="">
		<td>
      <span class=""><!---->
	      {{getTypeName(plan.payload.doc.data().feature)}}
      </span>
		<td>
      <span class=""><!---->
	      {{plan.payload.doc.data().price}}
      </span>
		</td>
    <td>
      <span class="text-primary cursor-pointer" (click)="deletePlan(plan)"><!---->
        {{plan.payload.doc.data().enabled ? '啟用' : '停用'}}
      </span>
    </td>
<!--		<td>-->
<!--			<a class="text-primary cursor-pointer"  (click)="deletePlan(plan)">&lt;!&ndash;&ndash;&gt;-->
<!--				刪除-->
<!--			</a>-->
<!--		</td>-->
	</tr>
	</tbody>
</table>
