import { Component, OnInit } from '@angular/core';
import {ShopService} from '../../services/shop.service';
import {MatDialogRef} from '@angular/material/dialog';
import {FSubscribe, FUser} from '../../../firebase/models/user';
import {AngularFirestore, DocumentChangeAction} from '@angular/fire/firestore';

@Component({
  selector: 'app-friend-picker-dialog',
  templateUrl: './friend-picker-dialog.component.html',
  styleUrls: ['./friend-picker-dialog.component.styl']
})
export class FriendPickerDialogComponent implements OnInit {

  friends: DocumentChangeAction<FSubscribe>[] = [];
  currentPage: DocumentChangeAction<FSubscribe>[] = [];
  keyword = '';
  result = '';
  constructor(public ss: ShopService,
              public dialogRef: MatDialogRef<FriendPickerDialogComponent>,
              private db: AngularFirestore) { }

  ngOnInit(): void {
  }
  search() {
    this.db.collectionGroup<FSubscribe>('subscribes', ref =>
        ref.where('shopId', '==', this.ss.currentShop.uid).where('user.keywords', 'array-contains', this.keyword)
            .orderBy('subscribeTime', 'desc'))
        .snapshotChanges().subscribe(f => {
          this.friends = f;
          f.length === 0 ? this.result = '無符合條件的用戶' : this.result = '';
    });
  }
  onSelect(friend: DocumentChangeAction<FSubscribe>) {
    this.dialogRef.close(friend);
  }

}
