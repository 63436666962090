import { Component, OnInit } from '@angular/core';
import {ShopService} from '../../../../services/shop.service';
import {NotifyService} from '../../../../../shared/services/notify.service';
import {FShop} from '../../../../../firebase/models/shop';
import {AngularFirestore} from '@angular/fire/firestore';
import {StorageService} from '../../../../../shared/services/storage.service';
import {UploadImageDialogData} from '../../../../../shared/ui/upload-image-dialog/upload-image-dialog.component';
import {FPhotoContent} from '../../../../../firebase/models/message';
import {MatDialog} from '@angular/material/dialog';
import {ShopSettingThemePickerDialogComponent} from './shop-setting-theme-picker-dialog/shop-setting-theme-picker-dialog.component';

@Component({
  selector: 'app-shop-setting-setiing-page',
  templateUrl: './shop-setting-setiing-page.component.html',
  styleUrls: ['./shop-setting-setiing-page.component.styl']
})
export class ShopSettingSetiingPageComponent implements OnInit {

  shop: FShop;
  categories = [];
  constructor(public ss: ShopService,
              private notify: NotifyService,
              private db: AngularFirestore,
              private img: StorageService,
              private dialog: MatDialog) { }

  ngOnInit(): void {
    this.shop = this.ss.currentShop;
    this.shop.theme = '#' + this.shop.theme;
    this.shop.chatMenu = this.shop.chatMenu ? this.shop.chatMenu : {};
    this.getCategories();
  }
  getCategories() {
    this.db.collection('system').doc('info').get().subscribe(x => {
      this.categories = x.data().categories;
    });
  }
  openImgDialog() {
    const info: UploadImageDialogData = {
      height: 640, type: 'image', width: 640

    };
    this.img.openUploadDialog(info).afterClosed().subscribe(img => {
      if (img) {
        this.shop.photoUrl = img;
      }
    });
  }

  save() {
    this.shop.theme = this.shop.theme.toUpperCase().replace('#', '');
    this.ss.currentShopDoc.update(this.shop).then(() => {
      this.notify.pop('已儲存');
    });
  }

  openThemePicker() {
    const dialogRef = this.dialog.open(ShopSettingThemePickerDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: {
        color: this.shop.theme
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      this.shop.theme = result.color;
    });
  }

}
