import { Component, OnInit } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {faRedoAlt, faTrashAlt} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-admin-shop-auth-list-page',
  templateUrl: './admin-shop-auth-list-page.component.html',
  styleUrls: ['./admin-shop-auth-list-page.component.styl']
})
export class AdminShopAuthListPageComponent implements OnInit {

  faTrashAlt = faTrashAlt;
  faRedoAlt = faRedoAlt;
  auths: any[] = [];
  currentPage: any[] = [];
  constructor(private db: AngularFirestore) { }

  ngOnInit() {
    this.db.collection('lineAuth', ref => ref.orderBy('timestamp', 'desc')).snapshotChanges().subscribe(x => {
      this.auths = x.map(a => {
        // @ts-ignore
        a.user = this.db.doc('users/' + a.payload.doc.data().userId).valueChanges();
        return a;
      });
    });
  }
  delete(auth) {
    auth.payload.doc.ref.delete().then();
  }
  reSent(auth) {
    const data = auth.payload.doc.data();
    data.sent = false;
    this.delete(auth);
    this.db.collection('lineAuth').add(data).then();
  }

}
