import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {StorageService} from '../../../../../../../shared/services/storage.service';
import {UploadImageDialogData} from '../../../../../../../shared/ui/upload-image-dialog/upload-image-dialog.component';
import {FProduct} from '../../../../../../../firebase/models/cardMessage';


@Component({
  selector: 'app-card-product',
  templateUrl: './card-product.component.html',
  styleUrls: ['./card-product.component.scss']
})
export class CardProductComponent implements OnInit {

  @Input()
  pages: FProduct[];
  @Output()
  pagesChange: EventEmitter<FProduct[]> = new EventEmitter<FProduct[]>();
  constructor(private ss: StorageService) { }

  ngOnInit(): void {
  }
  openUploadImageDialog(index: number): void {
    const info: UploadImageDialogData = {height: 640, type: 'image', width: 640};
    this.ss.openUploadDialog(info).afterClosed().subscribe(result => {
      if (result) {
        (this.pages[index] as FProduct).images[0] = result;
      }
    });
  }
  addPage() {
    this.pages.push(NewProductCard());
  }
}
export function NewProductCard(): FProduct {
  return {
    actions: [
      {
        text: '',
        type: 0,
        content: ''
      },
      {
        text: '',
        type: 0,
        content: ''
      }],
    banner: '',
    bannerColor: '666F86',
    desc: '',
    header: '',
    images: [''],
    price: ''
  };
}
