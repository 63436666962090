<div tabindex="-1" >
	<div class="modal-content h-100">
		<div class="modal-header flex-shrink-0">
			<h5 class="mb-0">{{data.title}}</h5>
			<button (click)="onNoClick()" class="close">×</button>
		</div>
		<div class="modal-body mh-100 overflow-hidden"><p style="white-space: pre-line;" class="text-info">
			{{data.body}}
		</p></div>
		<div class="d-flex flex-row flex-shrink-0 flex-row-reverse">
			<button [mat-dialog-close]=true type="button" class="btn btn-lg rounded-0 flex-1 btn-primary">確定</button>
			<button (click)="onNoClick()" type="button" class="btn btn-secondary btn-lg rounded-0 flex-1">取消</button></div>
	</div>
</div>
