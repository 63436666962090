
<div *ngIf="actionText" class="form-group row w-max-800">
	<label class="col-sm-3 col-form-label text-sm-right font-weight-light">動作</label>
	<div class="col-sm-9 form-inline">
			<input [(ngModel)]="action.text" (ngModelChange)="actionChanged()" style="width: 320px !important;" required maxlength="30" name="text" placeholder="請輸入動作標籤的說明" autocomplete="off" class="w-fix-360 form-control">
			<span class=""><strong class="text-muted">{{action.text.length}}</strong><span class="">/30</span></span>
	</div>
</div>
<div class="form-group row w-max-800">
	<label class="col-sm-3 col-form-label text-sm-right font-weight-light">類型</label>
	<div class="col-sm-9 flex-1">
		<div class="kv-select form-group mb-2 d-flex align-items-end" aria-required="false" aria-invalid="false">
			<select [(ngModel)]="action.type" (ngModelChange)="typeChanged()" name="type" required class="form-control custom-select form-control-rg w-fix-120">
				<option [ngValue]="0">不設定</option>
				<option [ngValue]="1">連結</option>
				<option [ngValue]="2">優惠券</option>
				<option [ngValue]="3">文字</option>
				<option [ngValue]="4">問卷調查</option>
				<option [ngValue]="5">集點卡</option>
				<option [ngValue]="6">課程</option>
				<option [ngValue]="7">活動</option>
				<option [ngValue]="8">商品</option>
				<option [ngValue]="9">商品類別</option>
			</select>
		</div>
		<div *ngIf="action.type === 1" class="kv-input form-group d-flex align-items-end" aria-required="false" aria-invalid="false">
			<div class="flex-grow-1">
				<div class="input-group">
					<input [(ngModel)]="action.content" (ngModelChange)="actionChanged()" required name="content" placeholder="輸入網址" autocomplete="off" class="form-control form-control-rg">
				</div>
			</div>
		</div>
		<button (click)="openCouponPicker()" *ngIf="action.type === 2 && !action.contentData" type="button" class="btn btn-secondary">選擇
		</button>
		<div *ngIf="action.type === 2 && action.contentData" class="d-flex flex-row justify-content-between border">
			<div class="p-3">
				<div class="mb-1" style="overflow-wrap: break-word;">{{action.contentData.title}}</div>
				<small class="text-muted">
					{{'有效期間: '+ $any(action.contentData.startTime).toDate().toLocaleString() + "-" + $any(action.contentData.endTime).toDate().toLocaleString()}}
				</small>
			</div>
			<div>
				<button (click)="action.contentData = null" type="button" class="btn-link text-info btn btn- btn-sm"><fa-icon [icon]="faWindowClose" class="las fa-fw fa-xs la-times"></fa-icon></button>
			</div>
		</div>
		<div *ngIf="action.type === 3" class="form-group text-muted">
			<textarea [ngClass]="{'border-danger': action.content.length === 0 }" [(ngModel)]="action.content" (ngModelChange)="actionChanged()" required name="text0" placeholder="輸入文字" maxlength="50"  rows="3" class="form-control w-100 mr-2" aria-required="true" aria-invalid="false"></textarea>
			<span class="float-right"><strong class="">{{action.content.length}}</strong><span class="">/50</span></span>
		</div>

		<button (click)="openQnrPicker()" *ngIf="action.type === 4 && !action.contentData" type="button" class="btn btn-secondary">選擇
		</button>
		<div *ngIf="action.type === 4 && action.contentData" class="d-flex flex-row justify-content-between border">
			<div class="p-3">
				<div class="mb-1" style="overflow-wrap: break-word;">{{action.contentData.title}}</div>
			</div>
			<div>
				<button (click)="action.contentData = null" type="button" class="btn-link text-info btn btn- btn-sm"><fa-icon [icon]="faWindowClose" class="las fa-fw fa-xs la-times"></fa-icon></button>
			</div>
		</div>

		<button (click)="openRewardCardPicker()" *ngIf="action.type === 5 && !action.contentData" type="button" class="btn btn-secondary">選擇
		</button>
		<div *ngIf="action.type === 5 && action.contentData" class="d-flex flex-row justify-content-between border">
			<div class="p-3">
				<div class="mb-1" style="overflow-wrap: break-word;">{{action.contentData.title}}</div>
			</div>
			<div>
				<button (click)="action.contentData = null" type="button" class="btn-link text-info btn btn- btn-sm"><fa-icon [icon]="faWindowClose" class="las fa-fw fa-xs la-times"></fa-icon></button>
			</div>
		</div>

		<button (click)="openCoursePicker()" *ngIf="action.type === 6 && !action.contentData" type="button" class="btn btn-secondary">選擇
		</button>
		<div *ngIf="action.type === 6 && action.contentData" class="d-flex flex-row justify-content-between border">
			<div class="p-3">
				<div class="mb-1" style="overflow-wrap: break-word;">{{action.contentData.name}}</div>
			</div>
			<div>
				<button (click)="action.contentData = null" type="button" class="btn-link text-info btn btn- btn-sm"><fa-icon [icon]="faWindowClose" class="las fa-fw fa-xs la-times"></fa-icon></button>
			</div>
		</div>

		<button (click)="openEventPicker()" *ngIf="action.type === 7 && !action.contentData" type="button" class="btn btn-secondary">選擇
		</button>
		<div *ngIf="action.type === 7 && action.contentData" class="d-flex flex-row justify-content-between border">
			<div class="p-3">
				<div class="mb-1" style="overflow-wrap: break-word;">{{action.contentData.name}}</div>
			</div>
			<div>
				<button (click)="action.contentData = null" type="button" class="btn-link text-info btn btn- btn-sm"><fa-icon [icon]="faWindowClose" class="las fa-fw fa-xs la-times"></fa-icon></button>
			</div>
		</div>
		<button (click)="openProductPicker()" *ngIf="action.type === 8 && !action.contentData" type="button" class="btn btn-secondary">選擇
		</button>
		<div *ngIf="action.type === 8 && action.contentData" class="d-flex flex-row justify-content-between border">
			<div class="p-3">
				<div class="mb-1" style="overflow-wrap: break-word;">{{action.contentData.name}}</div>
			</div>
			<div>
				<button (click)="action.contentData = null" type="button" class="btn-link text-info btn btn- btn-sm"><fa-icon [icon]="faWindowClose" class="las fa-fw fa-xs la-times"></fa-icon></button>
			</div>
		</div>
		<button (click)="openStoreCategoryPicker()" *ngIf="action.type === 9 && !action.contentData" type="button" class="btn btn-secondary">選擇
		</button>
		<div *ngIf="action.type === 9 && action.contentData" class="d-flex flex-row justify-content-between border">
			<div class="p-3">
				<div class="mb-1" style="overflow-wrap: break-word;">{{action.contentData.name}}</div>
			</div>
			<div>
				<button (click)="action.contentData = null" type="button" class="btn-link text-info btn btn- btn-sm"><fa-icon [icon]="faWindowClose" class="las fa-fw fa-xs la-times"></fa-icon></button>
			</div>
		</div>
	</div>

</div>
