import { Component, OnInit } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {ShopService} from '../../../../services/shop.service';
import {ActivatedRoute} from '@angular/router';
import {DocumentChangeAction} from '@angular/fire/firestore/interfaces';
import {FBooking} from '../../../../../firebase/models/booking';

@Component({
  selector: 'app-shop-booking-service-list-page',
  templateUrl: './shop-booking-service-list-page.component.html',
  styleUrls: ['./shop-booking-service-list-page.component.styl']
})
export class ShopBookingServiceListPageComponent implements OnInit {

  currentPage: DocumentChangeAction<FBooking>[] = [];
  list: DocumentChangeAction<FBooking>[] = [];
  constructor(private db: AngularFirestore,
              private ss: ShopService,
              ) { }

  ngOnInit(): void {
    this.db.collection<FBooking>('bookings', ref => ref.where('shop.uid', '==', this.ss.shopInfo.uid))
      .snapshotChanges().subscribe(x => {
      this.list = x;
    });
  }

}
