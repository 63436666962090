<div class="d-flex justify-content-between align-items-center mb-2"><h2 class="mb-0">新聞稿審稿</h2>
</div>
<hr>
<table class="table table-hover table-layout-fixed">
	<thead class="thead-light">
	<tr>
		<th class="">
      <span class="">
        建立日期
      </span>
		</th>
		<th class="">
      <span class=""><!---->
        類別
      </span>
		</th>
		<th class="">
      <span class=""><!---->
        顯示
      </span>
		</th>
		<th class="">
      <span ><!---->
        標題
      </span>
		</th>
<!--		<th class="">-->
<!--      <span >&lt;!&ndash;&ndash;&gt;-->
<!--        作者-->
<!--      </span>-->
<!--		</th>-->
		<th class="">
      <span ><!---->
        來源
      </span>
		</th>
		<th class="">
      <span ><!---->
        狀態
      </span>
		</th>
		<th class="">
      <span ><!---->
        分析
      </span>
		</th>
	</tr>
	</thead>
	<tbody>
	<tr *ngFor="let News of NewsList" class="">
		<td>
      <span class=""><!---->
	      {{News.payload.doc.data().pubTime.toDate().toLocaleString()}}
      </span>
		</td>
		<td>
      <span class=""><!---->
	      {{getTags(News.payload.doc.data())}}
      </span>
		</td>
		<td>
      <span class=""><!---->
	      {{getDisplayWords(News.payload.doc.data())}}
      </span>
		</td>
		<td>
			<a class="" [routerLink]="News.payload.doc.id"><!---->
				{{News.payload.doc.data().title}}
			</a>
		</td>
<!--		<td>-->
<!--      <span class="">&lt;!&ndash;&ndash;&gt;-->
<!--	      {{News.payload.doc.data().author}}-->
<!--      </span>-->
<!--		</td>-->
		<td>
      <span class=""><!---->
	      {{News.payload.doc.data().source}}
      </span>
		</td>
		<td>
      <span class=""><!---->
	      {{getStatusString(News.payload.doc.data())}}
      </span>
		</td>
		<td>
			<a class="" [routerLink]="'Analysis/' + News.payload.doc.id"><!---->
				分析
			</a>
		</td>
	</tr>
	</tbody>
</table>
<app-list-paginator [data]="NewsListSource" (listChanged)="NewsList = $event"></app-list-paginator>

