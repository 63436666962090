import { Component, OnInit } from '@angular/core';
import {FMonthlyTotal, getPlanName} from 'src/app/firebase/models/chargePlan';
import {ActivatedRoute} from '@angular/router';
import {AngularFirestore, DocumentSnapshot} from '@angular/fire/firestore';
import {ShopService} from '../../../../../services/shop.service';

@Component({
  selector: 'app-shop-agent-fee-page',
  templateUrl: './shop-agent-fee-page.component.html',
  styleUrls: ['./shop-agent-fee-page.component.styl']
})
export class ShopAgentFeePageComponent implements OnInit {

  getPlanName = getPlanName;
  shops: FMonthlyTotal[] = [];
  currentPage: FMonthlyTotal[] = [];
  date: string;
  constructor(private activatedRoute: ActivatedRoute,
              private db: AngularFirestore,
              private ss: ShopService) { }

  ngOnInit(): void {
    this.date = this.activatedRoute.snapshot.paramMap.get('id');
    this.db.collection('shops', ref => ref.where('agentRef', '==', this.ss.currentShopDoc.ref))
        .snapshotChanges().subscribe(shops => {
      this.shops = [];
      shops.forEach(shop => {
        shop.payload.doc.ref.collection('monthlyTotals').doc(this.date).get().then((bill: DocumentSnapshot<FMonthlyTotal>) => {
          const result = bill.data();
          // @ts-ignore
          result.displayName = shop.payload.doc.data().displayName;
          this.shops.push(result);
        });
      });
    });
  }
}
