<div class="d-flex justify-content-between align-items-center mb-2"><h2 class="mb-0">官方帳號類別管理</h2>
</div>
<hr>

<div class=" my-4">
	<div class="form-group row w-fix-400">
		<div class="col-sm-9 d-flex flex-row">
			<div class="form-inline text-muted">
			</div>
		</div>
	</div>
</div>

<div class="form-inline mb-2">
	<div class="form-group ml-2 d-flex align-items-end" aria-required="false" aria-invalid="false">
		<div class="flex-grow-1">
			<div class="input-group">
				<input [(ngModel)]="categoryName" required maxlength="15" type="text" name="statusMessage" placeholder="請輸入類別名稱" autocomplete="off" class="form-control w-fix-240">
			</div>
		</div>
	</div>
	<button (click)="addCategory()" type="button" class="ml-2 btn btn-primary h-35">建立新類別</button>
</div>

<hr>



<table class="table table-hover table-layout-fixed">
	<thead class="thead-light">
	<tr>
		<th class="">
      <span class="">
        類別名稱
      </span>
		</th>
		<th class="">
      <span class="">

      </span>
		</th>
	</tr>
	</thead>
	<tbody>
	<tr *ngFor="let category of categories" class="">
		<td>
      <span class=""><!---->
	      {{category}}
      </span>
		</td>
		<td>
      <span (click)="deleteCategory(category)" class=""><!---->
        刪除
      </span>
		</td>
	</tr>
	</tbody>
</table>

