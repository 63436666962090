<div class="d-flex justify-content-between align-items-center mb-2">
	<h2 class="mb-0">使用量日報表</h2>
</div>
<hr>
<table class="table table-hover table-layout-fixed">
	<thead class="thead-light">
	<tr>
		<th class="">
      <span class="">
        日期
      </span>
		</th>
		<th class="">
      <span class=""><!---->
        當日免費則數
      </span>
		</th>
		<th class="">
      <span class=""><!---->
        當日使用則數
      </span>
		</th>
		<th class="">
      <span class="cursor-pointer"><!---->
        當日付費則數
      </span>
		</th>
	</tr>
	</thead>
	<tbody>
	<tr *ngFor="let daily of currentPage" class="">
		<td>
      <span class=""><!---->
	      {{daily.date}}
      </span>
		</td>
		<td>
      <span class=""><!---->
	      {{daily.quota}}
      </span>
		</td>
		<td>
      <span class=""><!---->
	      {{daily.sent}}
      </span>
		</td>
		<td>
      <span class=""><!---->
	      {{daily.extraMessages}}
      </span>
		</td>

	</tr>
	</tbody>
</table>
<hr>
<app-list-paginator [data]="dailyMessages" (listChanged)="currentPage = $event"></app-list-paginator>
