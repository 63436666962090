import {Component, Inject, OnInit, AfterContentInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import domtoimage from 'dom-to-image';

@Component({
  selector: 'app-qr-code-download-dialog',
  templateUrl: './qr-code-download-dialog.component.html',
  styleUrls: ['./qr-code-download-dialog.component.styl']
})
export class QrCodeDownloadDialogComponent implements OnInit, AfterContentInit {

  constructor(public dialogRef: MatDialogRef<QrCodeDownloadDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: string) { }

  ngOnInit(): void {
  }
  downloadImage() {
    domtoimage.toPng(document.getElementById('img'), { quality: 0.95 })
        .then( (dataUrl) => {
          const link = document.createElement('a');
          link.download = 'QRCode.png';
          link.href = dataUrl;
          link.click();
          this.dialogRef.close();
        });
  }

  ngAfterContentInit(): void {
    this.downloadImage();
  }

}
