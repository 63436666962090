<div class="d-flex justify-content-between align-items-center mb-2">
	<h2 class="mb-0">電子發票資訊</h2>
</div>
<p class="text-muted">您可於此確認或變更電子發票的登錄資訊。</p>
<hr>
<form #form = ngForm>
	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">姓名</label>
		<div class="col-sm-9">
			<div class="form-inline text-muted">
				<input  [(ngModel)]="receipt.name" name="name" type="text" required maxlength="20" placeholder="姓名" autocomplete="off" class="form-control form-control-rg">
			</div>
		</div>
	</div>
	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">電子郵件</label>
		<div class="col-sm-9">
			<div class="form-inline w-fix-440 text-muted">
				<input  [(ngModel)]="receipt.email" style="width: 400px !important;" name="email" type="text" required maxlength="20" placeholder="請輸入可接受電子發票的通知Email" autocomplete="off" class="form-control form-control-rg">
			</div>
		</div>
	</div>
	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">住址</label>
		<div class="col-sm-9">
			<div class="form-inline text-muted">
				<input  [(ngModel)]="receipt.address" style="width: 400px !important;" name="address" type="text" required maxlength="20" placeholder="住址" autocomplete="off" class="form-control form-control-rg">
			</div>
		</div>
	</div>
	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light"></label>
		<div class="col-sm-9">
			<div class="form-inline">
				<mat-radio-group class="example-radio-group" [(ngModel)]="receipt.type" name="type" aria-label="Select an option">
					<mat-radio-button class="example-radio-button" [value]=0>二聯式電子發票</mat-radio-button>
					<mat-radio-button class="example-radio-button" [value]=1>三聯式電子發票</mat-radio-button>
				</mat-radio-group></div>
		</div>
	</div>
	<hr>
	<div *ngIf="receipt.type === 0">
		<div class="form-group row w-fix-800" >
			<label class="col-sm-3 col-form-label text-sm-right font-weight-light"></label>
			<div class="col-sm-9">
				<div class="form-inline">
					<mat-radio-group class="example-radio-group" [(ngModel)]="$any(receipt.content).type" name="contentType" aria-label="Select an option">
						<mat-radio-button class="example-radio-button" [value]=0>自然人憑證</mat-radio-button>
						<mat-radio-button class="example-radio-button" [value]=1>手機</mat-radio-button>
					</mat-radio-group></div>
			</div>
		</div>
		<div class="form-group row w-fix-800">
			<label class="col-sm-3 col-form-label text-sm-right font-weight-light">載具條碼</label>
			<div class="col-sm-9">
				<div class="form-inline text-muted">
					<input  [(ngModel)]="receipt.content.code" name="contentCode" type="text" required maxlength="20" placeholder="載具條碼" autocomplete="off" class="form-control form-control-rg">
				</div>
			</div>
		</div>
	</div>

	<div *ngIf="receipt.type === 1">
		<div class="form-group row w-fix-800">
			<label class="col-sm-3 col-form-label text-sm-right font-weight-light">公司抬頭</label>
			<div class="col-sm-9">
				<div class="form-inline text-muted">
					<input  [(ngModel)]="$any(receipt.content).title" name="contentTitle" type="text" required maxlength="20" placeholder="公司抬頭" autocomplete="off" class="form-control form-control-rg">
				</div>
			</div>
		</div>
		<div class="form-group row w-fix-800">
			<label class="col-sm-3 col-form-label text-sm-right font-weight-light">統一編號</label>
			<div class="col-sm-9">
				<div class="form-inline text-muted">
					<input  [(ngModel)]="receipt.content.code" name="contentCode2" type="text" required maxlength="20" placeholder="統一編號" autocomplete="off" class="form-control form-control-rg">
				</div>
			</div>
		</div>
	</div>

	<hr>
	<div class="d-flex justify-content-center my-5">
		<button (click)="save()" [disabled]="form.invalid" class="btn btn-lg btn-primary px-5 mx-1">儲存</button>
	</div>
</form>

