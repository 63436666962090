import { Component, OnInit } from '@angular/core';
import {FAutoResponse} from '../../../../../../firebase/models/autoResponse';
import { faCalendarAlt  } from '@fortawesome/free-solid-svg-icons';
import {ActivatedRoute} from '@angular/router';
import {ModalService} from '../../../../../../shared/services/modal.service';
import {ShopService} from '../../../../../services/shop.service';
import {NotifyService} from '../../../../../../shared/services/notify.service';
import Timestamp = firebase.firestore.Timestamp;
import * as firebase from 'firebase';
import {RouteService} from '../../../../../../shared/services/route.service';

@Component({
  selector: 'app-shop-auto-response-edit-page',
  templateUrl: './shop-auto-response-edit-page.component.html',
  styleUrls: ['./shop-auto-response-edit-page.component.styl']
})
export class ShopAutoResponseEditPageComponent implements OnInit {
  autoResponse: FAutoResponse = {
    enabled: false,
    endDate: null,
    endTime: null,
    keywords: [],
    messages: [],
    schedule: false,
    shop: undefined,
    startDate: null,
    startTime: null,
    timestamp: undefined,
    title: ''
  };
  faCalendarAlt = faCalendarAlt;
  minDate = new Date();
  create = false;
  doc;
  constructor(private activatedRoute: ActivatedRoute,
              private ms: ModalService,
              private ss: ShopService,
              private notify: NotifyService,
              public rs: RouteService) { }

  ngOnInit(): void {
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    if (id === 'Create') {
      this.create = true;
    } else  {
      this.doc = this.ss.currentShopDoc.collection<FAutoResponse>('autoReplies').doc(id);
      this.doc.get().subscribe(autoResponse => {
        if (autoResponse.exists) {
          // @ts-ignore
          this.autoResponse = autoResponse.data();
          this.datetimeFix();
        } else {
          this.notify.pop('自動回應訊息不存在');
          this.rs.navigateTo('/Home/AutoResponse');
        }
      });
    }
  }

  addMessage(){
    this.autoResponse.messages.push(
      {
        content: {text: ''}, timestamp: new Date(), type: 0
      }
    );
  }
  save() {
    this.autoResponse.shop = this.ss.shopInfo;
    this.autoResponse.timestamp = new Date();
    if (this.create) {
      this.ss.currentShopDoc.collection('autoReplies').add(this.autoResponse).then(() => {
        this.notify.pop('已儲存');
        this.rs.navigateTo('/Home/AutoResponse');
      });
    } else {
      this.doc.update(this.autoResponse).then(() => {
        this.notify.pop('已儲存');
        this.rs.navigateTo('/Home/AutoResponse');
      });
    }
  }
  delete() {
    this.ms.openConfirmDialog('刪除訊息', '訊息一旦刪除即無法復原，確定要刪除訊息嗎？').afterClosed().subscribe(result => {
      if (result) {
        this.doc.delete().then(() => {
          this.notify.pop('已刪除');
          this.rs.navigateTo('/Home/AutoResponse');
        });
      }
    });
  }
  datetimeFix() {
    Object.entries(this.autoResponse).forEach(
        ([key, value]) => {
          if (value instanceof Timestamp){
            this.autoResponse[key] = value.toDate();
          }
        }
    );
  }
}
