import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginComponent} from './firebase/login/login.component';
import {InvitationPageComponent} from './shared/pages/invitation-page/invitation-page.component';
import {AuthGuard} from './shared/guards/auth.guard';
import {ShopAuthPageComponent} from './shared/pages/shop-auth-page/shop-auth-page.component';
import {ShopAuthResultPageComponent} from './shared/pages/shop-auth-page/shop-auth-result-page/shop-auth-result-page.component';


const routes: Routes = [
  { path: 'Login', component: LoginComponent,  },
  { path: 'invitation/:id', component: InvitationPageComponent, canActivate: [AuthGuard]},
  { path: 'Auth/:id', component: ShopAuthPageComponent,  },
  { path: 'AuthResult/:id', component: ShopAuthResultPageComponent,  },


  { path: '', redirectTo: 'Accounts', pathMatch: 'full'},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
