<nav aria-label="Page navigation">
	<ul class="pagination pagination-rg justify-content-center">
		<li class="page-item disabled" [ngClass]="{'disabled':currentPage === 0}">
			<a  aria-label="Previous" class="page-link" (click)="pre()">
        <span aria-hidden="true">
	        <fa-icon [icon]="faChevronLeft"></fa-icon>
        </span>
			</a></li>
		<ng-container *ngFor="let page of getPages(); index as i" >
			<li *ngIf="showPageNumber(i)"  class="page-item" [ngClass]="{'active':currentPage === i}"><a (click)="pageSelect(i)" class="page-link">{{i+1}}</a></li>
		</ng-container>
		<li class="page-item" [ngClass]="{'disabled':currentPage === getPageNum() - 1 || getPageNum() === 0}">
			<a aria-label="Next" (click)="next()" class="page-link">
	      <span aria-hidden="true">
	        <fa-icon [icon]="faChevronRight"></fa-icon>
	      </span>
			</a>
		</li>
	</ul>
</nav>
