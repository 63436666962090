import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AngularFirestore} from '@angular/fire/firestore';
import {AuthService} from '../../../firebase/auth.service';
import {AngularFireAuth} from '@angular/fire/auth';
import {environment} from '../../../../environments/environment';
import {FShop} from '../../../firebase/models/shop';

@Component({
  selector: 'app-invitation-page',
  templateUrl: './invitation-page.component.html',
  styleUrls: ['./invitation-page.component.styl']
})
export class InvitationPageComponent implements OnInit {

  constructor(private route: ActivatedRoute,
              private afs: AngularFirestore,
              private auth: AuthService,
              private afAuth: AngularFireAuth,
              private router: Router) { }
  userId = '';
  shop: FShop;
  shopRef;
  invitation;
  invitationRef;
  valid = false;
  invalid = false;
  loading = true;
  ngOnInit() {
    this.afAuth.user.subscribe(x => {
      this.userId = x.uid;
      const createTime = new Date();
      createTime.setDate(createTime.getDate() - 1);
      const id = this.route.snapshot.paramMap.get('id');
      this.afs.collection('invitations').doc(id).get().subscribe(invitation => {
        this.loading = false;
        if (invitation.exists) {
          this.invitation = invitation.data();
          this.invitationRef = invitation.ref;
          if (this.invitation.createTime.toDate() < createTime || this.invitation.receiver) {
            this.invalid = true;
          } else {
            this.afs.collection<FShop>('shops').doc(invitation.data().shopId).get().subscribe(shop => {
              // @ts-ignore
              this.shop = shop.data();
              this.valid = true;
            });
            this.shopRef =  this.afs.collection<FShop>('shops').doc(invitation.data().shopId);
          }
        } else {
          this.loading = false;
          this.invalid = true;
        }
      });
    });
  }
  onAccept() {
    this.invitationRef.update({
      receiver: this.userId
    }).then(() => {
      this.toHomePage();
    });
  }
  toHomePage() {
    this.router.navigate(['/']);
  }

}
