import { Component, OnInit } from '@angular/core';
import {ShopService} from '../../../services/shop.service';

@Component({
  selector: 'app-shop-home',
  templateUrl: './shop-home.component.html',
  styleUrls: ['./shop-home.component.styl']
})
export class ShopHomeComponent implements OnInit {

  constructor(public ss: ShopService) { }

  ngOnInit(): void {
  }

}
