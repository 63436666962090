import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AngularFireStorage, AngularFireUploadTask} from '@angular/fire/storage';

@Component({
  selector: 'app-upload-image-dialog',
  templateUrl: './upload-image-dialog.component.html',
  styleUrls: ['./upload-image-dialog.component.styl']
})
export class UploadImageDialogComponent implements OnInit {

  loading = false;
  task: AngularFireUploadTask;
  info: UploadImageDialogData;
  error = false;

  constructor(
      private storage: AngularFireStorage,
      public dialogRef: MatDialogRef<UploadImageDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: UploadImageDialogData,
  ) {
    this.info = data;
  }

  log(a) {
    console.log(a);
  }
  startUpload(event: any) {
    // The File object
    console.log(event);
    if (event.length === 0){
      this.error = true;
      return;
    }
    const file = event[0];
    this.loading = true;
    // Client-side validation example
    if (file.type.split('/')[0] !== 'image' && this.data.type !== 'video') {
      this.error = true;
      this.loading = false;
      return;
    }
    if (file.type.split('/')[0] !== 'video' && this.data.type === 'video') {
      this.error = true;
      this.loading = false;
      return;
    }
    this.error = false;
    // The storage path
    const path = `test/${new Date().getTime()}_${file.name}`;
    // Totally optional metadata
    const customMetadata = { app: 'Bryan' };
    // The main task
    this.task = this.storage.upload(path, file, { customMetadata });
    this.storage.upload(path, file, { customMetadata }).then(result => {
      this.storage.ref(path).getDownloadURL().subscribe(x => {
        this.dialogRef.close(x);
      });
    });
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
  }

}

export interface UploadImageDialogData {
  type: string;
  height: number;
  width: number;
}
