import { Component, OnInit } from '@angular/core';
import {NotifyService} from '../../../../../shared/services/notify.service';
import {ShopService} from '../../../../services/shop.service';
import {FShopReceipt} from '../../../../../firebase/models/shop';

@Component({
  selector: 'app-shop-billing-receipt-page',
  templateUrl: './shop-billing-receipt-page.component.html',
  styleUrls: ['./shop-billing-receipt-page.component.styl']
})
export class ShopBillingReceiptPageComponent implements OnInit {

  receipt: FShopReceipt;
  constructor(private ss: ShopService,
              private notify: NotifyService) { }

  ngOnInit() {
    this.receipt = this.ss.currentShop.receipt;
  }
  save() {
    this.ss.currentShopDoc.update({
      receipt: this.receipt
    }).then(() => {
      this.notify.pop('已儲存');
    });
  }
}
