import { Component, OnInit } from '@angular/core';
import {FAdOrder, getPlanString, getPlanStatus} from '../../../../../../firebase/models/ad';
import {ActivatedRoute} from '@angular/router';
import {AngularFirestore, DocumentChangeAction} from '@angular/fire/firestore';
import {FMonthlyTotal, getPlanName} from '../../../../../../firebase/models/chargePlan';
import {FAgentPoint} from '../../../../../../firebase/models/agent';
import {ShopService} from '../../../../../services/shop.service';
import {NotifyService} from '../../../../../../shared/services/notify.service';
import {AngularFireFunctions} from '@angular/fire/functions';
import {PayAdWithPointRequest, PayBillWithPointRequest} from '../../../../../../../../functions/src/agent/point';
import { GetShopData } from 'src/app/firebase/models/shop';
import {ModalService} from '../../../../../../shared/services/modal.service';

@Component({
  selector: 'app-shop-agent-client-payment-page',
  templateUrl: './shop-agent-client-payment-page.component.html',
  styleUrls: ['./shop-agent-client-payment-page.component.styl']
})
export class ShopAgentClientPaymentPageComponent implements OnInit {

  ads: DocumentChangeAction<FAdOrder>[] = [];
  adCurrentPage: DocumentChangeAction<FAdOrder>[] = [];

  bills: DocumentChangeAction<FMonthlyTotal>[] = [];
  billCurrentPage: DocumentChangeAction<FMonthlyTotal>[] = [];

  getPlanString = getPlanString;
  getPlanStatus = getPlanStatus;
  getPlanName = getPlanName;
  GetShopData = GetShopData;
  point = 0;
  targetShopId: string;
  constructor(private activatedRoute: ActivatedRoute,
              private db: AngularFirestore,
              private ss: ShopService,
              private notify: NotifyService,
              private functions: AngularFireFunctions,
              private ms: ModalService) { }

  ngOnInit(): void {
    this.targetShopId = this.activatedRoute.snapshot.paramMap.get('id');
    this.getPoint();
    this.getAds();
    this.getMonthlyBill();
  }
  getAds() {
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    this.db.collection<FAdOrder>('advOrders', ref => ref.where('shopId', '==', id))
        .snapshotChanges().subscribe(ads => {
      this.ads = ads;
    });
  }
  getMonthlyBill() {
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    this.db.collection('shops').doc(id).collection<FMonthlyTotal>('monthlyTotals').snapshotChanges().subscribe(list => {
      this.bills = list;
    });
  }
  getPoint() {
    this.db.collection<FAgentPoint>('agentPoints').doc(this.ss.currentShop.uid).valueChanges().subscribe((x: FAgentPoint) => {
      this.point = x.point;
    });
  }
  payAd(ad: DocumentChangeAction<FAdOrder>) {

    if (this.point < ad.payload.doc.data().totalFee) {
      this.notify.pop('點數不足');
      return;
    }
    this.ms.openConfirmDialog('確認付款', '付款金額: ' + ad.payload.doc.data().totalFee).afterClosed().subscribe(res => {
      if (res) {
        const data: PayAdWithPointRequest = {
          advId: ad.payload.doc.id,
          agentId: this.ss.currentShop.uid,
        };
        const payFunction = this.functions.httpsCallable('PayAdWithPoint');
        payFunction(data).subscribe(x => {
          this.notify.pop(x);
        });
      }
    });

  }
  // PayBillWithPoint
  payBill(bill: DocumentChangeAction<FMonthlyTotal>) {
    if (this.point < bill.payload.doc.data().totalFee) {
      this.notify.pop('點數不足');
      return;
    }

    this.ms.openConfirmDialog('確認付款', '付款金額: ' + bill.payload.doc.data().totalFee).afterClosed().subscribe(res => {
      if (res) {
        const data: PayBillWithPointRequest = {
          billPath: bill.payload.doc.ref.path,
          agentId: this.ss.currentShop.uid,
        };
        const payFunction = this.functions.httpsCallable('PayBillWithPoint');
        payFunction(data).subscribe(x => {
          this.notify.pop(x);
        });
      }
    });
  }

}
