<div class="d-flex justify-content-between align-items-center mb-2">
	<h2 class="mb-0">拆帳報表</h2>
</div>
<hr>

<table class="table table-hover table-layout-fixed">
	<colgroup>
		<col width="20%">
		<col width="20%">
		<col width="10%">
		<col width="10%">
		<col width="10%">
		<col width="10%">
		<col width="10%">
		<col width="10%">
		<col width="10%">
		<col width="10%">
	</colgroup>
	<thead class="thead-light">
	<tr>
		<th class="">
      <span class="">
        官方帳號
      </span>
		</th>
		<th class="">
      <span class=""><!---->
        月費方案
      </span>
		</th>
		<th class="">
      <span class=""><!---->
        月費金額
      </span>
		</th>
		<th class="">
      <span ><!---->
        上期加購則數
      </span>
		</th>
		<th class="">
      <span ><!---->
        上期加購金額
      </span>
		</th>
		<th class="">
      <span ><!---->
        本期應付總額
      </span>
		</th>
		<th class="">
      <span ><!---->
        稅額5%
      </span>
		</th>
		<th class="">
      <span ><!---->
        本期總額
      </span>
		</th>
		<th class="">
      <span ><!---->
        付款狀態
      </span>
		</th>
	</tr>
	</thead>
	<tbody>
	<tr *ngFor="let shop of shops" class="">
		<td>
      <span class=""><!---->
	      {{$any(shop).displayName}}
      </span>
		</td>
		<td>
      <span class=""><!---->
	      {{getPlanName(shop.status)}}
      </span>
		</td>
		<td>
      <span class=""><!---->
	      {{shop.monthlyFee}}
      </span>
		</td>
		<td>
      <span class=""><!---->
	      {{shop.extraMessages}}
      </span>
		</td>
		<td>
      <span class=""><!---->
	      {{shop.extraFee}}
      </span>
		</td>
		<td>
      <span class=""><!---->
	      {{shop.amount}}
      </span>
		</td>
		<td>
      <span class=""><!---->
	      {{shop.taxFee}}
      </span>
		</td>
		<td>
      <span class=""><!---->
	      {{shop.totalFee}}
      </span>
		</td>
		<td>
      <span class=""><!---->
	      {{shop.payStatus ? '已付款' : '未付款'}}
      </span>
		</td>
	</tr>
	</tbody>
</table>
