<div id="container" class="bg-light d-flex flex-column">
	<div id="wrapper" class="d-flex align-items-stretch flex-fill">
		<aside id="menu" class="overflow-auto w-fix-280 flex-shrink-0 flex-grow-0 position-relative border-right">
			<div class="container-fluid bg-light py-3 min-vh-100">
				<ul class="nav flex-column">
					<li class="nav-item"><a routerLink="Bill" routerLinkActive="router-link-exact-active active" class="nav-link d-inline-block">月費報表</a>
					</li>
					<li class="nav-item"><a routerLink="Message" routerLinkActive="router-link-exact-active active" class="nav-link d-inline-block">訊息報表</a>
					</li>
					<li class="nav-item"><a routerLink="Purchase" routerLinkActive="router-link-exact-active active" class="nav-link d-inline-block">購買方案</a>
					</li>
					<li class="nav-item"><a routerLink="Discount" routerLinkActive="router-link-exact-active active" class="nav-link d-inline-block">優惠方案</a>
					</li>

					<li class="nav-item"><a routerLink="Payment" routerLinkActive="router-link-exact-active active" class="nav-link d-inline-block">付款方式</a>
					</li>
					<li class="nav-item"><a routerLink="Receipt" routerLinkActive="router-link-exact-active active" class="nav-link d-inline-block">電子發票資訊</a>
					</li>
					<li class="nav-item"><a routerLink="ExtraFeatures" routerLinkActive="router-link-exact-active active" class="nav-link d-inline-block">加值功能</a>
					</li>
					<li class="nav-item"><a routerLink="Point" routerLinkActive="router-link-exact-active active" class="nav-link d-inline-block">點數</a>
					</li>
<!--					<li class="nav-item"><a routerLink="Orders" routerLinkActive="router-link-exact-active active" class="nav-link d-inline-block">訂單</a>-->
<!--					</li>-->
				</ul>
			</div>

		</aside>
		<div id="contents" class="w-100 d-flex flex-column position-relative bg-white" style="min-width: calc(100% - 280px);">
			<main class="m-5" >
				<router-outlet *ngIf="(ss.chargePlanLoaded| async ) && (ss.shopLoaded | async)"></router-outlet>
			</main>
		</div>

	</div>
</div>
