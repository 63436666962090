<div class="d-flex justify-content-between align-items-center mb-2"><h2 class="mb-0">訊息服務費報表</h2>
</div>
<h2>{{month}}月費明細</h2>
<button (click)="displayFree()" type="button" class="ml-2 btn btn-primary">顯示免付費店家</button>
<hr>
<table class="table table-hover table-layout-fixed">
	<thead class="thead-light">
	<tr>
		<th class="">
      <span class="">
        官方帳號
      </span>
		</th>
		<th class="">
      <span class=""><!---->
        代理商
      </span>
		</th>
		<th class="">
      <span class=""><!---->
        月費方案
      </span>
		</th>
		<th class="">
      <span class=""><!---->
        月費金額
      </span>
		</th>
		<th class="">
      <span ><!---->
        上期<br>加購則數
      </span>
		</th>
		<th class="">
      <span ><!---->
        上期<br>加購金額
      </span>
		</th>
		<th class="">
      <span ><!---->
        本期<br>應付總額
      </span>
		</th>
		<th class="">
      <span ><!---->
        稅額5%
      </span>
		</th>
		<th class="">
      <span ><!---->
        本期總額
      </span>
		</th>
		<th class="">
      <span ><!---->
        分潤金額
      </span>
		</th>
		<th class="">
      <span ><!---->
        付款狀態
      </span>
		</th>
	</tr>
	</thead>
	<tbody>
	<ng-container *ngFor="let news of currentPage">
		<tr  class="">
			<td>
				{{news.shopDisplayName}}
			</td>
			<td>
      <span class=""><!---->
	      {{news.agentDisplayName}}
      </span>
			</td>
			<td>
				{{getPlanName(news.status)}}
			</td>
			<td>
				{{news.monthlyFee}}
			</td>
			<td>
				{{news.extraMessages}}
			</td>
			<td>
				{{news.extraFee}}

			</td>
			<td>
				{{news.amount}}
			</td>
			<td>
				{{news.taxFee}}
			</td>
			<td>
				{{news.totalFee}}
			</td>
			<td>
				{{news.agentFee}}
			</td>
			<td>
				{{news.payStatus ? '已付款':'未付款'}}
			</td>
		</tr>
	</ng-container>
	</tbody>
</table>
<hr>
<app-list-paginator [data]="list" (listChanged)="currentPage = $event"></app-list-paginator>
