import { Component, OnInit } from '@angular/core';
import {FShop} from '../../../../../firebase/models/shop';
import {AngularFirestore} from '@angular/fire/firestore';
import {NotifyService} from '../../../../../shared/services/notify.service';

@Component({
  selector: 'app-admin-shop-meet-gift-list-page',
  templateUrl: './admin-shop-meet-gift-list-page.component.html',
  styleUrls: ['./admin-shop-meet-gift-list-page.component.styl']
})
export class AdminShopMeetGiftListPageComponent implements OnInit {

  shops: FShop[] = [];
  currentPage: FShop[] = [];
  constructor(private db: AngularFirestore,
              private notify: NotifyService) { }

  ngOnInit() {
    this.db.collection<FShop>('shops', ref => ref.orderBy('timestamp', 'desc')).valueChanges().subscribe(shops => {
      this.shops = shops.filter(shop => shop.meetGift && shop.meetGift.coupon);
    });
  }
  priorityChanged(shop: FShop) {

    this.db.doc('shops/' + shop.uid).update({
      'meetGift.order' : Number(shop.meetGift.order)
    }).then(res => {
      console.log(res);
      this.notify.pop('修改成功');

    }).catch(error => {
      console.log('error' + error);
    });
  }
  toggleEnabled(event, shop: FShop) {
    this.db.doc('shops/' + shop.uid).update({
      'meetGift.enabled': !event.checked
    });
    this.notify.pop('修改成功');
  }

}
