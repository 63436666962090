<nav id="nav" class="bg-white border-bottom pt-1 flex-shrink-0">
  <div class="container-fluid d-flex justify-content-between align-items-center">
    <ul class="nav nav-bordered">
      <ng-container *ngIf="ss.loaded | async">
        <li *ngIf="ss.permission === 0 || ss.permission === 1" class="nav-item position-relative">
          <a routerLink="Home" routerLinkActive="router-link-exact-active active-ignore active" class="nav-link">主頁</a>
        </li>
        <li *ngIf="ss.permission === 0 || ss.permission === 1" class="nav-item position-relative">
          <a routerLink="Friend" routerLinkActive="router-link-exact-active active-ignore active"  class="nav-link">好友管理</a>
        </li>
        <li *ngIf="ss.permission === 0 || ss.permission === 1" class="nav-item position-relative">
          <a routerLink="Ad" routerLinkActive="router-link-exact-active active-ignore active"  class="nav-link">廣告管理</a>
        </li>
        <li *ngIf="ss.permission === 0" class="nav-item position-relative">
          <a routerLink="Billing" routerLinkActive="router-link-exact-active active-ignore active"  class="nav-link">帳務專區</a>
        </li>
        <li class="nav-item position-relative">
          <a routerLink="Chat" routerLinkActive="router-link-exact-active active-ignore active" class="nav-link">1to1客服</a>
        </li>
        <li class="nav-item position-relative">
          <a routerLink="Store" routerLinkActive="router-link-exact-active active-ignore active" class="nav-link">商店</a>
        </li>
        <li *ngIf="ss.currentShop?.agent" class="nav-item position-relative">
          <a routerLink="Agent" routerLinkActive="router-link-exact-active active-ignore active"  class="nav-link">代理商</a>
        </li>
        <li *ngIf="ss.currentShop?.newsEditor" class="nav-item position-relative">
          <a routerLink="News" routerLinkActive="router-link-exact-active active-ignore active"  class="nav-link">新聞</a>
        </li>
        <li class="nav-item position-relative">
          <a routerLink="Course" routerLinkActive="router-link-exact-active active-ignore active"  class="nav-link">課程</a>
        </li>
        <li class="nav-item position-relative">
          <a routerLink="Event" routerLinkActive="router-link-exact-active active-ignore active"  class="nav-link">活動</a>
        </li>
        <li class="nav-item position-relative">
          <a routerLink="Booking" routerLinkActive="router-link-exact-active active-ignore active"  class="nav-link">預約服務</a>
        </li>
      </ng-container>



    </ul>
    <ul class="nav nav-bordered">
      <li *ngIf="ss.currentShop && ss.permission === 0" class="nav-item"><a routerLink="Setting" routerLinkActive="router-link-exact-active active-ignore active" class="nav-link"><i class="fas fa-cog mr-1 text-muted"></i>設定</a></li>
    </ul>
  </div>
</nav>
