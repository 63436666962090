<form #form="ngForm">
	<div class="d-flex mb-4">
		<div class="flex-1 ml-4 w-max-800">
			<div class="form-group row mx-0">
				<div class="col-sm-3 col-form-label pl-4">
					圖片
				</div>
				<div class="col-sm-9">
					<div class="form-inline">
						<div class="form-inline">
							<div>
								<div class="figure figure-grid cursor-pointer border position-relative mr-2">
									<img *ngIf="cardPeople.image !== ''" [src]="cardPeople.image" class="figure-img">
									<a *ngIf="cardPeople.image === ''" (click)="openUploadImageDialog()" href="javascript:void(0)">上傳圖片</a>
									<button *ngIf="cardPeople.image !== ''" type="button" class="position-absolute top-right close"><fa-icon [icon]="faWindowClose"></fa-icon></button>
									<input [(ngModel)]="cardPeople.image" (ngModelChange)="pagesChanged()" required name="img" class="d-none">
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="row mx-0">
				<div class="col-sm-3 col-form-label pl-4 mb-3">
					姓名
				</div>
				<div class="col-sm-9">
					<div class="form-inline">
						<div class="w-100">
							<input [(ngModel)]="cardPeople.name"  (ngModelChange)="pagesChanged()"  maxlength="20" required placeholder="輸入姓名" name="name" type="text" autocomplete="off" class="form-control w-80">
							<span class=""><strong class="text-muted">{{cardPeople.name?.length}}</strong><span class="">/20</span></span>
						</div>
						<!---->
					</div>
				</div>
			</div>

			<div class="form-group row mx-0">
				<div class="col-sm-3 col-form-label pl-0">
					<div class="kv-checkbox form-check custom-control custom-checkbox mb-3">
						<input [(ngModel)]="feature1Checked" (ngModelChange)="pagesChanged()" id="1hxo3b5i29x" name="f1c" type="checkbox" class="custom-control-input">
						<label for="1hxo3b5i29x" class="custom-control-label">人物特點1</label>
					</div>
				</div>
				<div class="col-sm-9">
					<div class="form-inline">
						<div class="w-100">
							<input [disabled]="!feature1Checked" [(ngModel)]="cardPeople.tags[0].text" (ngModelChange)="pagesChanged()" required maxlength="10" placeholder="輸入宣傳標語" name="feature1" type="text" autocomplete="off" class="form-control w-80" aria-required="true" aria-invalid="false">
							<span class=""><strong class="text-muted">{{cardPeople.tags[0]?.text.length}}</strong><span class="">/10</span></span>
						</div>
					</div>
					<app-shop-card-message-color-picker [color]="cardPeople.tags[0]?.color" (ColorChanged)="cardPeople.tags[0].color = $event; pagesChanged()"></app-shop-card-message-color-picker>
				</div>
			</div>

			<div class="form-group row mx-0">
				<div class="col-sm-3 col-form-label pl-0">
					<div class="kv-checkbox form-check custom-control custom-checkbox mb-3">
						<input [(ngModel)]="feature2Checked" (ngModelChange)="pagesChanged()" id="6urcokpdvip" name="f2c" type="checkbox" class="custom-control-input">
						<label for="6urcokpdvip" class="custom-control-label">人物特點2</label>
						<!---->
					</div>
				</div>
				<div class="col-sm-9 disabled-content">
					<div class="form-inline">
						<div class="w-100">
							<input [disabled]="!feature2Checked" [(ngModel)]="cardPeople.tags[1].text" (ngModelChange)="pagesChanged()" maxlength="10"  placeholder="輸入宣傳標語" name="feature2"  type="text" autocomplete="off" class="form-control w-80" aria-required="false" aria-invalid="false">
							<span class=""><strong class="text-muted">{{cardPeople.tags[1]?.text.length}}</strong><span class="">/10</span></span>
						</div>
						<!---->
					</div>
					<app-shop-card-message-color-picker [color]="cardPeople.tags[1]?.color" (ColorChanged)="cardPeople.tags[1].color = $event; pagesChanged()"></app-shop-card-message-color-picker>

				</div>
			</div>
			<div class="form-group row mx-0">
				<div class="col-sm-3 col-form-label pl-0">
					<div class="kv-checkbox form-check custom-control custom-checkbox mb-3">
						<input  [(ngModel)]="feature3Checked" (ngModelChange)="pagesChanged()" id="jx1owtdgdan" name="f3c" type="checkbox" class="custom-control-input">
						<label for="jx1owtdgdan" class="custom-control-label">人物特點3</label>
						<!---->
					</div>
				</div>
				<div class="col-sm-9 disabled-content">
					<div class="form-inline">
						<div class="w-100">
							<input [disabled]="!feature3Checked" [(ngModel)]="cardPeople.tags[2].text" (ngModelChange)="pagesChanged()" required maxlength="10" placeholder="輸入宣傳標語" name="iconName2" type="text" autocomplete="off" class="form-control w-80" aria-required="false" aria-invalid="false"> <span class="">
                    <strong class="text-muted">{{cardPeople.tags[2]?.text.length}}</strong><span class="">/10</span></span>
						</div>
						<!---->
					</div>
					<app-shop-card-message-color-picker [color]="cardPeople.tags[2]?.color" (ColorChanged)="cardPeople.tags[2].color = $event;pagesChanged()"></app-shop-card-message-color-picker>

				</div>
			</div>
			<div class="form-group row mx-0">
				<div class="col-sm-3 col-form-label pl-0">
					<div class="kv-checkbox form-check custom-control custom-checkbox mb-3">
						<input [(ngModel)]="descChecked" id="9tvbpqgp1ab" name="dc" type="checkbox" class="custom-control-input">
						<label for="9tvbpqgp1ab" class="custom-control-label">文字說明</label>
						<!---->
					</div>
				</div>
				<div class="col-sm-9">
					<div class="">
						<div class="">
							<div class="w-100">
								<textarea [disabled]="!descChecked" [(ngModel)]="cardPeople.desc" (ngModelChange)="pagesChanged()" maxlength="60" placeholder="輸入文字說明" name="description" rows="3" type="text" autocomplete="off" class="form-control w-100" aria-required="true" aria-invalid="false"></textarea> <span class="float-right">
                      <strong class="text-muted">{{cardPeople.desc.length}}</strong><span class="">/60</span></span>
							</div>
							<!---->
						</div>
						<!---->
					</div>
				</div>
				<!---->
			</div>
			<div>
				<div class="row mx-0">
					<div class="col-sm-3 col-form-label pl-0 pl-0">
						<div class="kv-checkbox form-check custom-control custom-checkbox mb-3">
							<input [(ngModel)]="action1Checked" (ngModelChange)="pagesChanged()" id="qb4m06lk597" name="actionText1" type="checkbox" class="custom-control-input">
							<label for="qb4m06lk597" class="custom-control-label">動作1</label>
						</div>
					</div>
				</div>

				<app-shop-action-model *ngIf="action1Checked" required [actionText]="true" [(ngModel)]="cardPeople.actions[0]" (ngModelChange)="pagesChanged()" name="action1"></app-shop-action-model>
			</div>
			<div>
				<div class="row mx-0">
					<div class="col-sm-3 col-form-label pl-0 pl-0">
						<div class="kv-checkbox form-check custom-control custom-checkbox mb-3">
							<input [(ngModel)]="action2Checked" (ngModelChange)="pagesChanged()" id="0txigjx8eugi" name="actionText2" type="checkbox" class="custom-control-input">
							<label for="0txigjx8eugi" class="custom-control-label">動作2</label>
						</div>
					</div>
				</div>
				<app-shop-action-model *ngIf="action2Checked" required [actionText]="true" [(ngModel)]="cardPeople.actions[1]" (ngModelChange)="pagesChanged()" name="action2"></app-shop-action-model>
			</div>
		</div>

	</div>

</form>
