<div class="btn-group mt-2">
	<button (click)="color = '666F86'; selectChanged()" type="button" class="cursor-pointer w-fix-40 h-fix-40 rounded-circle badge mr-2 btn btn-none badge-info">
		<fa-icon *ngIf="color === '666F86'; else check" [icon]="faCheck" class="lar la-check"></fa-icon>
	</button>
	<button (click)="color = 'FFFFFF'; selectChanged()" type="button" class="cursor-pointer w-fix-40 h-fix-40 rounded-circle badge mr-2 btn btn-none badge-outline-info">
		<fa-icon *ngIf="color === 'FFFFFF'; else check" [icon]="faCheck" class="lar la-check"></fa-icon>
	</button>
	<button (click)="color = 'EB4E3d'; selectChanged()" type="button" class="cursor-pointer w-fix-40 h-fix-40 rounded-circle badge mr-2 btn btn-none badge-danger">
		<fa-icon *ngIf="color === 'EB4E3d'; else check" [icon]="faCheck" class="lar la-check"></fa-icon>
	</button>
	<button (click)="color = 'ED8537'; selectChanged()" type="button" class="cursor-pointer w-fix-40 h-fix-40 rounded-circle badge mr-2 btn btn-none badge-warning">
		<fa-icon *ngIf="color === 'ED8537'; else check" [icon]="faCheck" class="lar la-check"></fa-icon>
	</button>
	<button (click)="color = '00B900'; selectChanged()" type="button" class="cursor-pointer w-fix-40 h-fix-40 rounded-circle badge mr-2 btn btn-none badge-primary">
		<fa-icon *ngIf="color === '00B900'; else check" [icon]="faCheck" class="lar la-check"></fa-icon>
	</button>
	<button (click)="color = '5B82DB'; selectChanged()" type="button" class="cursor-pointer w-fix-40 h-fix-40 rounded-circle badge mr-2 btn btn-none badge-success">
		<fa-icon *ngIf="color === '5B82DB'; else check" [icon]="faCheck" class="lar la-check"></fa-icon>
	</button>
</div>
<ng-template #check>
	<div>A</div>
</ng-template>
