<div class="d-flex justify-content-between align-items-center mb-2"><h2 class="mb-0">建立新訊息</h2>
</div>
<hr>
<div class="btn-toolbar justify-content-between my-4">
	<div aria-label="First group" class="btn-group">
		<button (click)="delete()" [disabled]="create" type="button" class="btn btn-secondary mr-2">刪除</button>
		<button (click)="saveDraft()"  type="button" class="btn btn-secondary mr-2">儲存草稿</button>
	</div>
</div>
<hr>

<form #form="ngForm" (ngSubmit)="confirmSent(selectDate.value)">
	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">傳送對象</label>
		<div class="col-sm-9">
			<div class="kv-select form-group d-flex align-items-end">
				<div class="flex-grow-1">
					<div class="input-group">
						<div class="select" >
							<select required #selectTag class="form-control custom-select form-control-rg" (change)="onTagSelectChange(selectTag.value)"  >
								<option [value]="0">所有好友</option>
								<option [value]="1">依標籤篩選</option>
							</select>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div *ngIf="selectTag.value === '1'" class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">標籤</label>
		<div class="col-sm-9">
			<div class="kv-select form-group d-flex align-items-end">
				<div class="flex-grow-1">
					<div class="input-group">
						<tag-input [theme]="'foundation-theme'"   [secondaryPlaceholder]= "'選取標籤'" [placeholder]="'選取標籤'"  name="tags" [(ngModel)]="tags" [onlyFromAutocomplete] = "true"  (ngModelChange)="onTagChange()" >
							<tag-input-dropdown [autocompleteItems]= "ss.tagsForDisplay" [showDropdownIfEmpty]= "true">
							</tag-input-dropdown>
						</tag-input>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">傳送時間</label>
		<div class="col-sm-9">
			<div class="kv-select form-group d-flex align-items-end">
				<div class="flex-grow-1">
					<div class="input-group">
						<div class="select" >
							<select #selectDate class="form-control custom-select form-control-rg" (change)="bind()" >
								<option [value]="0">立即傳送</option>
								<option [value]="1">預約時間</option>
							</select>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div *ngIf="selectDate.value === '1'" class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">時間</label>
		<div class="col-sm-9">
			<div class="form-inline text-muted">
				<input class="form-control w-80 mr-2 " style="width: 400px !important;" [required]="selectDate.value === '1'" name="schedule" [(ngModel)]="message.schedule" name="startTime"   [owlDateTime]="scheduleTimePicker" [min]="minDate" [owlDateTimeTrigger]="scheduleTimePicker" placeholder="預約時間" autocomplete="off">
				<span [owlDateTimeTrigger]="scheduleTimePicker">
					<fa-icon class="text-muted" [icon]="faCalendarAlt"></fa-icon>
				</span>
				<owl-date-time  #scheduleTimePicker></owl-date-time>
			</div>
		</div>
	</div>

	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">群發訊息則數</label>
		<div class="col-sm-9">
			<div class="kv-select form-group d-flex align-items-end">
				<div class="flex-grow-1">
					<div class="input-group">
						<label style="color: #00B900;padding-top: 7px;">{{sentCount}}</label>
						<label style="padding-top: 7px;">&nbsp;&nbsp;&nbsp;本日訊息額度為&nbsp;</label>
						<label style="color: #00B900;padding-top: 7px;">{{ss.chargePlan.quota}}</label>
						<label style="padding-top: 7px;">&nbsp;則，已經使用&nbsp;</label>
						<label style="color: #00B900;padding-top: 7px;">{{ss.chargePlan.sent}}</label>
						<label style="padding-top: 7px;">&nbsp;則</label>

					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">標題</label>
		<div class="col-sm-9">
			<div class="form-inline text-muted">
				<input required [maxLength]="30" name="title" [(ngModel)]="message.title" class="form-control w-80 mr-2 " style="width: 400px !important;" type="text" placeholder="訊息標題將顯示於推播通知" autocomplete="off">
				<span class="">
          <strong >{{ message.title.length}}</strong>
          <span class="">/30</span>
        </span>
			</div>
		</div>
	</div>

	<hr class="my-4">

	<div class="position-relative w-fix-880 my-4" *ngFor="let messageCard of message.messages; index as i">
		<button [disabled]="message.messages.length <= 1" (click)="message.messages.splice(message.messages.indexOf(messageCard,0),1)" type="button" class="position-absolute close" style="top: 1px !important; right: 0px;">
			<i class="las la-times-square"></i>
		</button>
		<app-shop-message-content-card [name]="'message' + i" [(ngModel)]="message.messages[i]"></app-shop-message-content-card>
	</div>
	<div class="d-flex justify-content-center">
		<button [disabled]="message.messages.length>2" (click)="addMessage()" type="button" class="btn btn-outline-primary"><i class="lar la-plus"></i>
			新增訊息</button>
	</div>



	<div class="d-flex justify-content-center my-5">
		<button type="submit" [disabled]="form.invalid"  class="btn btn-lg btn-primary px-5 mx-1">傳送</button>
	</div>
</form>







<div>
	<mat-accordion>
		<mat-expansion-panel class="card bg-info card-sliding" expanded="true" >
			<mat-expansion-panel-header class="card-header bg-dark d-flex justify-content-between align-items-center" >
				<mat-panel-title class="btn-link mb-0">
					<a style="padding-left: 10px; color:  #fff !important"> 預覽</a>
				</mat-panel-title>
			</mat-expansion-panel-header>
			<div class="card-body bg-white p-0 cursor-not-allowed no-select" style="width: 370px; height: 540px; max-height: none;">
				<div class="bg-info" style="min-height: 540px; padding: 1.25rem 0 1.25rem 1.25rem;">
					<div *ngFor="let message of message.messages" >
						<div class="chat chat-text-light chat-secondary" *ngIf="message.content"  >
							<div title="" class="avatar rounded-circle avatar-sm" *ngIf="message.type === FMessageType.text || message.type === FMessageType.photo">
								<div class="w-100 h-100" [ngStyle]="{'background': 'url(' + ss.currentShop.photoUrl + ') center center / cover'}"></div>
							</div>
							<div class="chat-content w-min-0" >
								<div class="chat-body" *ngIf="message.type === FMessageType.text || message.type === FMessageType.photo">
									<div class="chat-main w-min-0">
										<div class="chat-item baloon" *ngIf="message.type === 0">
											<div class="chat-item-text">
												<div  class="view">{{message.content.text}}</div>
											</div>
										</div>
										<div class="chat-item rounded" *ngIf="message.type === FMessageType.photo">
											<img [src]="message.content.photoUrl" width="200">
										</div>

									</div>
								</div>
								<div class="chat-body rich" *ngIf="message.type === FMessageType.rich">
									<div class="chat-main w-min-0">
										<div class="chat-item"><img [src]="message.content.photoUrl" class="img-fluid"></div>
									</div>
								</div>
								<div class="chat-body rich" *ngIf="message.type === FMessageType.video">
									<div class="chat-main w-min-0">
										<div class="chat-item"><video [src]="message.content.videoUrl" class="img-fluid"></video></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</mat-expansion-panel>
	</mat-accordion>
</div>
