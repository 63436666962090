import {Component, OnDestroy, OnInit} from '@angular/core';
import {DocumentChangeAction} from '@angular/fire/firestore';
import {FDraftMessage} from '../../../../../firebase/models/message';
import {FCoupon} from '../../../../../firebase/models/coupon';
import {ShopService} from '../../../../services/shop.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-shop-coupon-list',
  templateUrl: './shop-coupon-list.component.html',
  styleUrls: ['./shop-coupon-list.component.styl']
})
export class ShopCouponListComponent implements OnInit, OnDestroy {
  coupons: DocumentChangeAction<FCoupon>[] = [];
  couponCurrentPage: DocumentChangeAction<FCoupon>[] = [];
  subscription: Subscription;
  constructor(private ss: ShopService) { }

  ngOnInit(): void {
    this.getCoupons();
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  getStatusWord(start: Date, end: Date) {
    const now = new Date();
    if (now > start && now < end) {
      return '有效';

    } else if (now > end) {
      return '過期';
    } else {
      return '即將生效';
    }
  }
  getCoupons() {
    this.subscription = this.ss.currentShopDoc.collection<FCoupon>('coupons').snapshotChanges().subscribe(coupons => {
      this.coupons = coupons;
    });
  }

}
