import { Component, OnInit } from '@angular/core';
import {AngularFirestore, DocumentChangeAction} from '@angular/fire/firestore';
import {FAgentShare} from '../../../../../firebase/models/agent';
import {FShop} from '../../../../../firebase/models/shop';
import {ShopService} from '../../../../services/shop.service';
import {ActivatedRoute} from '@angular/router';
import {ModalService} from '../../../../../shared/services/modal.service';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import {FSubscribe} from '../../../../../firebase/models/user';
import {NotifyService} from '../../../../../shared/services/notify.service';
import {FacebookService} from '@jemys89/ngx-facebook';
import {IconService} from '../../../../../shared/services/icon.service';

@Component({
  selector: 'app-shop-friend-share-link-list-page',
  templateUrl: './shop-friend-share-link-list-page.component.html',
  styleUrls: ['./shop-friend-share-link-list-page.component.styl']
})
export class ShopFriendShareLinkListPageComponent implements OnInit {

  desc = '';
  shares: DocumentChangeAction<FAgentShare>[] = [];
  currentPage: DocumentChangeAction<FAgentShare>[] = [];
  shop: FShop;
  faDownload = faDownload;
  share: FAgentShare = {desc: '', shop: undefined, user: undefined, userRef: undefined, users: []};
  constructor(private ss: ShopService,
              private route: ActivatedRoute,
              private db: AngularFirestore,
              private ms: ModalService,
              private notify: NotifyService,
              public icon: IconService) { }

  ngOnInit(): void {
    this.ss.currentShopDoc.collection<FAgentShare>('agentShare', ref => ref.where('shop.uid', '==' , this.ss.currentShop.uid))
        .snapshotChanges().subscribe(shares => {
      this.shares = shares;
    });
    // @ts-ignore
    window.FB.XFBML.parse();
  }
  OnAddShareLink() {
    this.share.shop = this.ss.shopInfo;
    // @ts-ignore
    this.ss.currentShopDoc.collection<FAgentShare>('agentShare').doc(this.share.user.uid).set(this.share).then(() => {
      this.notify.pop('儲存成功');
    });
  }
  downloadQRCode(path: string) {
    this.ms.openQrCodeDownloadMadel(path);
  }

  openFriendPicker() {
    this.ms.openFriendPicker().afterClosed().subscribe((friend: DocumentChangeAction<FSubscribe>) => {
      this.share.user = friend.payload.doc.data().user;
      this.share.userRef = friend.payload.doc.ref;
    });
  }
  deleteShareLink(doc: DocumentChangeAction<FAgentShare>) {
    doc.payload.doc.ref.delete().then(() => {
      this.notify.pop('刪除成功');
    });
  }


}
