<div class="modal-content h-10 w-fix-600 ">
	<div class="modal-header flex-shrink-0">
		<h5 *ngIf="info.type !== 'video'" class="mb-0">上傳圖片</h5>
		<h5 *ngIf="info.type === 'video'" class="mb-0">上傳影片</h5>
		<button class="close" (click)="onNoClick()">×</button>
	</div>
  <div  class="modal-body mh-100 overflow-hidden">
      <div *ngIf="error" role="alert" class="alert alert-dismissible fade show alert-danger">
        不支援此檔案格式
      </div>
      <div #drop ngx-dropzone [multiple]="false" [accept]="info.type === 'video' ? 'video/*' : 'image/png,image/jpg,image/jpeg'" (change)="startUpload($event.addedFiles)" style="height: 360px !important;" class="h-fix-360 d-flex justify-content-center align-items-center border">
        <div class="text-center">
          <p>請將檔案拖放至此
            <br> 或
          </p>
          <div class="custom-file w-fix-200">.
            <div class="custom-file-input">
              <input *ngIf="info.type === 'video'"  accept="video/*" type="file" >
              <input *ngIf="info.type !== 'video'"  accept="image/png,image/jpg,image/jpeg" type="file" >
<!--              (change)="startUpload($event.target.files)"-->
            </div>
            <label  class="custom-file-label text-left">選擇檔案</label>
          </div>
        </div>
      </div>
      <small *ngIf="info.type === 'video'" class="form-text text-muted small">
        建議格式：MP4、MOV、WMV<br>
        檔案容量：200MB以下
      </small>
      <small *ngIf="info.type !== 'video'" class="form-text text-muted small">
        檔案格式：JPG、JPEG、PNG<br>
        檔案容量：10MB以下<br>
        建議圖片尺寸：{{info.width}}px × {{info.height}}px
      </small>
    </div>
	<div class="d-flex flex-row flex-shrink-0 modal-footer">
		<!---->
		<button type="button" class="btn btn-secondary " (click)="onNoClick()">取消</button>
	</div>
	<div class="bg" *ngIf="loading" >
		<div class="w-100 text-center">
			<mat-spinner [strokeWidth]="5"></mat-spinner>
		</div>
	</div>

</div>

