import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-shop-rich-message-type-picker',
  templateUrl: './shop-rich-message-type-picker.component.html',
  styleUrls: ['./shop-rich-message-type-picker.component.styl']
})
export class ShopRichMessageTypePickerComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ShopRichMessageTypePickerComponent>) { }

  ngOnInit(): void {
  }

  onNoClick() {
    this.dialogRef.close();
  }
  select(type: number) {
    this.dialogRef.close(type);
  }
}
