<div class="d-flex justify-content-between align-items-center mb-2"><h2 class="mb-0">代理商列表</h2>
</div>
<hr>
<!---->
<div class="d-flex justify-content-end" >
	<span class="mx-1 text-muted"><fa-icon [icon]="icon.faUserFriends" class="cursor-pointer fas fa-user-friends"></fa-icon></span>
	<label style="color: #00B900;">{{shops.length}}</label>
</div>

<table class="table table-hover table-layout-fixed">
	<thead class="thead-light">
	<tr>
		<th class="">
      <span class="">
          顯示名稱
      </span>
		</th>
		<th class="">
      <span class="">
          加入時間
      </span>
		</th>
		<th class="">
      <span class="">
          推薦碼
      </span>
		</th>
		<th class="">
      <span class="">
          分潤比例
      </span>
		</th>
		<th class="">
      <span class="">
          持有點數
      </span>
		</th>
	</tr>
	</thead>
	<tbody >
	<ng-container *ngFor="let shop of shops">
		<tr>
			<td>
				<div class="div3">
					<div  class=" avatar avatar-sm rounded-circle div1">
						<div class="w-100 h-100" [ngStyle]="{'background': 'url(' + shop.shop.payload.doc.data().photoUrl + ') center center / cover'}"></div>
					</div>
					<div class="div2">
						<label class="user col-form-label text-sm-left font-weight-normal">{{shop.shop.payload.doc.data().displayName}}</label>
						<!--            <label class="user col-form-label-xs text-sm-left font-weight-light">{{shop.brief }}</label>-->
					</div>
				</div>
			</td>
			<td>
				{{shop.shop.payload.doc.data().timestamp.toDate().toLocaleString()}}
			</td>
			<td>
				{{shop.shop.payload.doc.data().agent.code}}
			</td>
			<td>
				{{shop.shop.payload.doc.data().agent.percentage}}
				<i (click)="openPercentageDialog(shop.shop)" style="color: #1e7e34" class="fas fa-pen fa-sm"></i>
			</td>
			<td>
				<ng-container *ngIf="(shop.point | async) as point">
					<span class="text-primary" *ngIf="point.payload.exists">{{point.payload.data().point}}</span>
				</ng-container>
				<fa-icon ngbTooltip="加值" class="ml-2 text-primary" [icon]="icon.faPen" (click)="givePoint(shop.shop)"></fa-icon>
			</td>
		</tr>
	</ng-container>

	</tbody>
</table>
<hr>
<app-list-paginator [data]="shops" (listChanged)="currentPage = $event"></app-list-paginator>
