import { Component, OnInit } from '@angular/core';
import {ShopService} from '../../services/shop.service';
import {MatDialogRef} from '@angular/material/dialog';
import {FCourse} from '../../../firebase/models/course';
import {AngularFirestore, DocumentChangeAction} from '@angular/fire/firestore';
import {FStoreProduct} from '../../../firebase/models/store';

@Component({
  selector: 'app-store-product-picker-dialog',
  templateUrl: './store-product-picker-dialog.component.html',
  styleUrls: ['./store-product-picker-dialog.component.styl']
})
export class StoreProductPickerDialogComponent implements OnInit {

  products: DocumentChangeAction<FStoreProduct>[] = [];
  constructor(public ss: ShopService,
              public dialogRef: MatDialogRef<StoreProductPickerDialogComponent>,
              private db: AngularFirestore) { }

  ngOnInit(): void {
    this.db.collection('stores').doc(this.ss.currentShop.uid)
        .collection<FStoreProduct>('products').snapshotChanges().subscribe((products) => {
      this.products = products;
    });
  }
  onSelect(product: DocumentChangeAction<FStoreProduct>) {
    this.dialogRef.close(product);
  }

}
