<div class="modal-content h-100 w-min-360">
	<div class="modal-header flex-shrink-0">
		<h5 class="mb-0">優惠券</h5>
		<button (click)="dialogRef.close()" class="close">×</button>
	</div>
	<div class="modal-body mh-100 overflow-hidden">
		<p class="text-muted">「有效」及「即將生效」的優惠券將顯示於此。</p>
		<hr class="m-0">
		<div>
			<div  class="position-relative overflow-auto h-fix-400">
				<div class="list-group list-group-flush">
					<ng-container *ngFor="let coupon of coupons">
						<div *ngIf="getStatusWord(coupon.payload.doc.data().startTime.toDate(), coupon.payload.doc.data().endTime.toDate()) !== '過期'" class="list-group-item d-flex align-items-center justify-content-between px-0">
							<div class="figure figure figure-grid figure-grid-xs position-relative mr-2">
								<img [src]="coupon.payload.doc.data().photoUrl" class="figure-img">
							</div>
							<div class="flex-1 text-truncate mr-2">
								<div>
									<span class="text-warning">{{getStatusWord(coupon.payload.doc.data().startTime.toDate(), coupon.payload.doc.data().endTime.toDate())}}</span>
								</div>
								<div class="text-truncate">{{coupon.payload.doc.data().title}}</div> <small class="text-muted">{{'有效期間: ' + coupon.payload.doc.data().startTime.toDate().toLocaleString() + '-' + coupon.payload.doc.data().endTime.toDate().toLocaleString()}}</small></div>
							<div class="flex-none">
								<button (click)="onSelect(coupon)" type="button" class="mr-2 btn btn-outline-primary">選擇</button>
							</div>
						</div>

					</ng-container>
				</div>
			</div>
		</div>
	</div>
	<div class="d-flex flex-row flex-shrink-0 modal-footer flex-row-reverse">
		<!---->
		<button (click)="dialogRef.close()" type="button" class="btn btn-secondary">取消</button>
	</div>
</div>
