import { Component, OnInit } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {AngularFireStorage} from '@angular/fire/storage';
import {NotifyService} from '../../../../../shared/services/notify.service';
import {FroalaService} from '../../../../../shared/services/froala.service';

@Component({
  selector: 'app-admin-ui-extra-service-page',
  templateUrl: './admin-ui-extra-service-page.component.html',
  styleUrls: ['./admin-ui-extra-service-page.component.styl']
})
export class AdminUiExtraServicePageComponent implements OnInit {

  htmlContent = '';
  htmlContentTop = '';
  htmlContentBottom = '';

  constructor(private db: AngularFirestore, public storage: AngularFireStorage, private notify: NotifyService,
              public froala: FroalaService) {
    db.collection('system').doc('notSupport').get().subscribe(x => {
      this.htmlContent = x.data().content;
    });
    db.collection('system').doc('extraService').get().subscribe(x => {
      this.htmlContentTop = x.data().contentTop;
    });
    db.collection('system').doc('extraService').get().subscribe(x => {
      this.htmlContentBottom = x.data().contentBottom;
    });
  }

  ngOnInit() {
  }
  change() {
    this.db.collection('system').doc('notSupport').update({content: this.htmlContent}).then(() => {
      this.notify.pop('已儲存');
    });
  }
  changeTop() {
    this.db.collection('system').doc('extraService').update({htmlContentTop: this.htmlContentTop}).then(() => {
      this.notify.pop('已儲存');
    });
  }
  changeBottom() {
    this.db.collection('system').doc('extraService').update({contentBottom: this.htmlContentBottom}).then(() => {
      this.notify.pop('已儲存');
    });
  }

}
