import { Component, OnInit } from '@angular/core';
import {FAnswer, FQuestion, FQuestionnaire} from '../../../../../../firebase/models/questionnaire';
import { faCalendarAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import {StorageService} from '../../../../../../shared/services/storage.service';
import {ShopService} from '../../../../../services/shop.service';
import {NotifyService} from '../../../../../../shared/services/notify.service';
import {RouteService} from '../../../../../../shared/services/route.service';
import {UploadImageDialogData} from '../../../../../../shared/ui/upload-image-dialog/upload-image-dialog.component';
import { faWindowClose } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-shop-questionnare-edit-page',
  templateUrl: './shop-questionnare-edit-page.component.html',
  styleUrls: ['./shop-questionnare-edit-page.component.styl']
})
export class ShopQuestionnareEditPageComponent implements OnInit {

  minDate = new Date();
  faCalendarAlt = faCalendarAlt;
  faWindowClose = faWindowClose;
  faPlusSquare = faPlus;
  qnr: FQuestionnaire = {
    shop: undefined,
    couponLink: '', createTime: undefined, desc: '', endTime: undefined, photoUrl: '', questions: [], title: ''};
  constructor(private img: StorageService,
              public ss: ShopService,
              private notify: NotifyService,
              private rs: RouteService) { }

  ngOnInit(): void {
    this.addQuestion();
  }

  openImgUploadDialog() {
    const info: UploadImageDialogData = {height: 330, type: 'image', width: 500};
    this.img.openUploadDialog(info).afterClosed().subscribe(result => {
      if (result) {
        this.qnr.photoUrl = result;
      }
    });
  }
  addAnswer(question: FQuestion) {
    const answer: FAnswer = {tag: [], title: ''};
    question.answers.push(answer);
  }
  addQuestion() {
    const question: FQuestion = {answers: [], choiceType: 0, title: ''};
    this.addAnswer(question);
    this.addAnswer(question);
    this.qnr.questions.push(question);
  }
  save() {
    this.qnr.createTime = new Date();
    this.qnr.shop = this.ss.shopInfo;
    this.qnr.questions.forEach(q => {
      q.answers.forEach(ans => {
        // @ts-ignore
        ans.tag = ans.tag.map((x) => x.value);
      });
    });
    this.ss.currentShopDoc.collection('questionnaires').add(this.qnr).then(() => this.rs.navigateTo('/Home/Questionnaire'));
  }
  typeChanged(item: FQuestion) {
    item.answers = [];
    this.addAnswer(item);
    if (item.choiceType !== 2) {
      this.addAnswer(item);
    }
  }

}
