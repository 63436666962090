import { Component, OnInit } from '@angular/core';
import {ShopService} from '../../../services/shop.service';

@Component({
  selector: 'app-shop-main-nav',
  templateUrl: './shop-main-nav.component.html',
  styleUrls: ['./shop-main-nav.component.styl']
})
export class ShopMainNavComponent implements OnInit {

  constructor(public ss: ShopService) { }

  ngOnInit(): void {
  }

}
