<div class="d-flex justify-content-between align-items-center mb-2">
	<h2 class="mb-0">刊登服務</h2>
</div>
<hr>
<div class="mt-5">
	<input type="radio" [(ngModel)]="select" [value]="1">
	<label class="ml-1 mr-1">  見面禮推薦版位</label>
	<select [(ngModel)]="couponDays" class="ml-4 mr-2" style="width: 65px" class="custom-select">
		<option [ngValue]="7">7</option>
		<option [ngValue]="14">14</option>
		<option [ngValue]="30">30</option>
		<option [ngValue]="60">60</option>
		<option [ngValue]="90">90</option>
	</select>
	<label class="ml-2 mr-2" >天（目標人數上限</label>
	<label style="color: #1e7e34" class="ml-0 mr-0">{{getFriendLimit(couponDays)}}</label>
	<label class="ml-2 mr-2">人，</label>
	<label style="color: #1e7e34" class="ml-0 mr-0">{{getPrice(couponDays)}}</label>
	<label class="ml-2 mr-2">元（含稅）</label>
</div>
<div class="mt-1">
	<input type="radio" [(ngModel)]="select" [value]="2">
	<label class="ml-1 mr-1">  主頁推薦版位&nbsp;&nbsp;&nbsp;&nbsp;</label>
	<select [(ngModel)]="homePageDays" class="ml-4 mr-2" style="width: 65px" class="custom-select">
		<option [ngValue]="7">7</option>
		<option [ngValue]="14">14</option>
		<option [ngValue]="30">30</option>
		<option [ngValue]="60">60</option>
		<option [ngValue]="90">90</option>
	</select>
	<label class="ml-2 mr-2" >天（目標人數上限</label>
	<label style="color: #1e7e34" class="ml-0 mr-0 ">{{getFriendLimit(homePageDays)}}</label>
	<label class="ml-2 mr-2">人，</label>
	<label style="color: #1e7e34" class="ml-0 mr-0">{{getHomePagePrice(homePageDays)}}</label>
	<label class="ml-2 mr-2">元（含稅）</label>
</div>


<div class="mt-1">
	<input type="radio" [(ngModel)]="select" [value]="3">
	<label class="ml-1 mr-1">  登入推薦版位&nbsp;&nbsp;&nbsp;&nbsp;</label>
	<select [(ngModel)]="startPageDays" class="ml-4 mr-2" style="width: 65px" class="custom-select">
		<option [ngValue]="7">7</option>
		<option [ngValue]="14">14</option>
		<option [ngValue]="30">30</option>
		<option [ngValue]="60">60</option>
		<option [ngValue]="90">90</option>
	</select>
	<label class="ml-2 mr-2" >天（目標人數上限</label>
	<label style="color: #1e7e34" class="ml-0 mr-0">{{getFriendLimit(startPageDays)}}</label>
	<label class="ml-2 mr-2">人，</label>
	<label style="color: #1e7e34" class="ml-0 mr-0">{{getPrice(startPageDays)}}</label>
	<label class="ml-2 mr-2">元（含稅）</label>
</div>





<div class="d-flex justify-content-center my-5">
	<button (click)="Order()" class="btn btn-lg btn-primary px-5 mx-1">購買方案</button>
</div>
