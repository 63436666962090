import { Component, OnInit } from '@angular/core';
import {FProduct} from "../../../../../../../firebase/models/cardMessage";

@Component({
  selector: 'app-shop-card-message-product',
  templateUrl: './shop-card-message-product.component.html',
  styleUrls: ['./shop-card-message-product.component.styl']
})
export class ShopCardMessageProductComponent implements OnInit {

  product: FProduct = GetCardProduct();
  bannerChecked = false;
  descChecked = false;
  priceChecked = false;
  action1Checked = false;
  action2Checked = false;
  constructor() { }

  ngOnInit(): void {
  }
  pageChanged() {

  }
  openUploadImageDialog() {}

}
export function GetCardProduct() {
  return {
    actions: [null, null],
    banner: '',
    bannerColor: '666F86',
    desc: '',
    header: '',
    images: [''],
    price: ''
  };
}
