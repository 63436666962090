import { Component, OnInit } from '@angular/core';
import {DocumentChangeAction} from '@angular/fire/firestore';
import {ShopService} from '../../../../services/shop.service';
import {FCourse} from '../../../../../firebase/models/course';

@Component({
  selector: 'app-shop-event-list-page',
  templateUrl: './shop-event-list-page.component.html',
  styleUrls: ['./shop-event-list-page.component.styl']
})
export class ShopEventListPageComponent implements OnInit {


  events: DocumentChangeAction<FCourse>[] = [];
  currentPage: DocumentChangeAction<FCourse>[] = [];
  constructor(private ss: ShopService) { }

  ngOnInit(): void {
    this.ss.currentShopDoc.collection<FCourse>('events').snapshotChanges().subscribe(cs => {
      this.events = cs;
    });
  }

}
