<div class="d-flex justify-content-between align-items-center mb-2">
	<h2 class="mb-0">官方帳號列表</h2>
</div>
<hr>
<div class="form-group row w-fix-800">
	<label class="col-sm-2 col-form-label text-sm-right font-weight-light">搜尋條件</label>
	<div class="col-sm-9">
		<div class="kv-select form-group d-flex align-items-end">
			<div class="flex-grow-1">
				<div class="input-group">
					<div>
						<select style="width: 220px !important;"  #selectTag class="form-control custom-select form-control-rg w-fix-360" (change)="bind()">
							<option [value]="0">官方帳號名稱</option>
							<option [value]="1">付費方案</option>
							<option [value]="2">這個月更改方案</option>
							<option [value]="3">好友人數大於</option>
							<option [value]="4">首頁推薦排序</option>
						</select>
						<button (click)="clear()" type="button" class="ml-2 btn btn-secondary">清除</button>

					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="form-group row w-fix-800">
	<label class="col-sm-2 col-form-label text-sm-right font-weight-light"></label>
	<div class="col-sm-9">
		<div class="kv-select form-group d-flex align-items-end">
			<div class="flex-grow-1">
				<div class="input-group">

					<div *ngIf="selectTag.value === '0'">
						<div class="form-inline mb-2">
							<div class="kv-input form-group  d-flex align-items-end" aria-required="false" aria-invalid="false">
								<div class="flex-grow-1">
									<div class="input-group">
										<input #displayName type="text" name="keySearch" placeholder="官方帳號名稱"  autocomplete="off" class="form-control form-control-rg">
									</div>
								</div>
							</div>
							<button (click)="searchByDisplayName(displayName.value)" type="button" class="ml-2 btn btn-primary"><fa-icon [icon]="faSearch" class="text-white"></fa-icon></button>
						</div>
					</div>

					<div *ngIf="selectTag.value === '1'">
						<div class="form-inline mb-2">
							<div class="kv-input form-group  d-flex align-items-end" aria-required="false" aria-invalid="false">
								<div class="flex-grow-1">
									<div class="input-group">
										<select #plan class="w-fix-80 form-control custom-select form-control-rg"  >
											<option [value]="0">免費方案</option>
											<option [value]="1">每日一則</option>
											<option [value]="3">每日一三則</option>
											<option [value]="999">吃到飽</option>
										</select>
									</div>
								</div>
							</div>
							<button (click)="searchByPlan(plan.value)" type="button" class="ml-2 btn btn-primary"><fa-icon [icon]="faSearch" class="text-white"></fa-icon></button>
						</div>
					</div>

					<div *ngIf="selectTag.value === '2'">
						<div class="form-inline mb-2">
							<div class="kv-input form-group  d-flex align-items-end" aria-required="false" aria-invalid="false">
								<div class="flex-grow-1">
									<div class="input-group">
									</div>
								</div>
							</div>
							<button (click)="searchByChangePlanThisMonth()" type="button" class="ml-2 btn btn-primary"><fa-icon [icon]="faSearch" class="text-white"></fa-icon></button>
						</div>
					</div>

					<div *ngIf="selectTag.value === '3'">
						<div class="form-inline mb-2">
							<div class="kv-input form-group  d-flex align-items-end" aria-required="false" aria-invalid="false">
								<div class="flex-grow-1">
									<div class="input-group">
										<input #follower type="number" name="keySearch" placeholder="好友數"  autocomplete="off" class="form-control form-control-rg">
									</div>
								</div>
							</div>
							<button (click)="searchByFollower(follower.value)" type="button" class="ml-2 btn btn-primary"><fa-icon [icon]="faSearch" class="text-white"></fa-icon></button>
						</div>
					</div>

					<div *ngIf="selectTag.value === '4'">
						<div class="form-inline mb-2">
							<div class="kv-input form-group  d-flex align-items-end" aria-required="false" aria-invalid="false">
								<div class="flex-grow-1">
									<div class="input-group">
										<select #random class="w-fix-80 form-control custom-select form-control-rg"  >
											<option [value]="0">低</option>
											<option [value]="100">中</option>
											<option [value]="1000">高</option>
										</select>
									</div>
								</div>
							</div>
							<button (click)="searchByRandom(random.value)" type="button" class="ml-2 btn btn-primary"><fa-icon [icon]="faSearch" class="text-white"></fa-icon></button>
						</div>
					</div>

				</div>
			</div>
		</div>
	</div>
</div>



<hr>

<div class="d-flex justify-content-end" >
	<span class="mx-1 text-muted"><fa-icon [icon]="faUserFriends" class="cursor-pointer fas fa-user-friends"></fa-icon></span>
	<label style="color: #00B900;">{{shops.length}}</label>
</div>

<table class="table table-hover table-layout-fixed">
	<thead class="thead-light">
	<tr>
		<th class="">
        <span class="">
          顯示名稱
        </span>
		</th>
		<th class="">
        <span class="">
          加入時間
        </span>
		</th>
		<th class="">
        <span class="">
          好友數
        </span>
		</th>
	</tr>
	</thead>
	<tbody >
	<ng-container *ngFor="let shop of currentPage">
		<tr>
<!--			<td [routerLink]="shop.shop.uid">-->
			<td (click)="openShopManagementDialog(shop.shop)">
				<div class="div3">
					<div  class=" avatar avatar-sm rounded-circle div1">
						<div class="w-100 h-100" [ngStyle]="{'background': 'url(' + shop.shop.photoUrl + ') center center / cover'}"></div>
					</div>
					<div class="div2">
						<label class="user col-form-label text-sm-left font-weight-normal">{{shop.shop.displayName}}</label>
						<!--            <label class="user col-form-label-xs text-sm-left font-weight-light">{{shop.brief }}</label>-->
					</div>
				</div>
			</td>
			<td>
				{{shop.shop.timestamp.toDate().toLocaleString()}}
			</td>
			<td>
				{{shop.shop.follower}}
			</td>
		</tr>
	</ng-container>
	</tbody>
</table>
<hr>
<app-list-paginator [data]="shops" (listChanged)="currentPage = $event"></app-list-paginator>
