<div class="modal-content h-100 w-min-360">
	<div class="modal-header flex-shrink-0">
		<h5 class="mb-0">圖文訊息</h5>
		<button (click)="dialogRef.close()" class="close">×</button>
	</div>
	<div class="modal-body mh-100 overflow-hidden">
		<div class="position-relative overflow-auto h-fix-400" style="">
			<div class="list-group list-group-flush">
				<div *ngFor="let message of richMessages" class="list-group-item d-flex align-items-center justify-content-between px-0">
					<div class="figure figure-grid figure-grid-xs mr-3 align-items-center">
						<img [src]="message.payload.doc.data().photoUrl" class="figure-img">
					</div>
					<div class="flex-1 text-truncate p-0 align-content-center">
						<div class="text-truncate">{{message.payload.doc.data().name}}</div>
					</div>
					<div class="flex-none ml-3">
						<button (click)="onSelect(message)" type="button" class="btn btn-outline-primary">選擇</button>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="d-flex flex-row flex-shrink-0 modal-footer">
		<button (click)="dialogRef.close()" type="button" class="btn btn-secondary">關閉</button>
	</div>
</div>
