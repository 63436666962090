import { Component, OnInit } from '@angular/core';
import {AngularFirestore, AngularFirestoreDocument} from '@angular/fire/firestore';
import {FStoreProduct} from '../../../../../../firebase/models/store';
import {AngularFireStorage} from '@angular/fire/storage';
import {RouteService} from '../../../../../../shared/services/route.service';
import {ShopService} from '../../../../../services/shop.service';
import {ActivatedRoute} from '@angular/router';
import {NotifyService} from '../../../../../../shared/services/notify.service';
import {StorageService} from '../../../../../../shared/services/storage.service';
import {UploadImageDialogData} from '../../../../../../shared/ui/upload-image-dialog/upload-image-dialog.component';
import {FroalaService} from "../../../../../../shared/services/froala.service";

@Component({
  selector: 'app-shop-store-product-edit-page',
  templateUrl: './shop-store-product-edit-page.component.html',
  styleUrls: ['./shop-store-product-edit-page.component.styl']
})
export class ShopStoreProductEditPageComponent implements OnInit {

  categories = [];
  categoriesForDisplay = [];
  create = false;
  productDoc: AngularFirestoreDocument;
  product: FStoreProduct = {
    categories: [], pieceEnd: null, pieceStart: null,
    spec: [],
    desc: '',
    enabled: false,
    name: '',
    photoUrl: ''
    // spec: [],
    // stock: 0,
  };
  constructor(
      private storage: AngularFireStorage,
      private db: AngularFirestore,
      private router: RouteService,
      public ss: ShopService,
      private activatedRoute: ActivatedRoute,
      public notify: NotifyService,
      private img: StorageService,
      public froala: FroalaService
  ) {
  }
  ngOnInit() {
    this.getCategories();
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    if (id === 'Create') {
      this.create = true;
    } else  {
      this.productDoc = this.db.collection('stores').doc(this.ss.currentShop.uid).collection<FStoreProduct>('products').doc(id);
      this.productDoc.get().subscribe(product => {
        if (product.exists) {
          // @ts-ignore
          Object.assign(this.product, product.data());
          // @ts-ignore
          this.product.pieceStart = this.product.pieceStart ? this.product.pieceStart.toDate() : undefined;
          // @ts-ignore
          this.product.pieceEnd = this.product.pieceEnd ? this.product.pieceEnd.toDate() : undefined;
          this.categoriesForDisplay = this.product.categories ? this.categories.filter(x => {
            return this.product.categories.includes(x.value);
          }) : [];
        } else {
          this.notify.pop('商品不存在');
          this.router.navigateTo('/Store/Product');
        }
      });
    }
    this.getCategories();
  }
  save() {
    this.product.updateTime = new Date();
    this.product.categories = this.categoriesForDisplay.map(x => x.value);
    this.checkUndefined(this.product);
    if (this.create) {
      this.product.createTime = new Date();
      this.db.collection('stores').doc(this.ss.currentShop.uid).collection<FStoreProduct>('products').add(this.product).then(() => {
        this.router.navigateTo('/Store/Product');
      });
    } else {
      this.productDoc.update(this.product).then(() => {
        this.router.navigateTo('/Store/Product');
      });
    }
  }
  addSpec() {
    this.product.spec.push({
      piecePrice: 0,
      stock: 9999,
      name: '',
      price: 0
    });
  }

  getCategories() {
    this.db.collection('stores').doc(this.ss.currentShop.uid).collection('categories').snapshotChanges().subscribe(cs => {
      this.categories = cs.map(x => {
        return {
          display: x.payload.doc.data().name,
          value: x.payload.doc.id
        };
      });
    });
  }

  openImgDialog(): void {
    const info: UploadImageDialogData = {
      height: 640, type: 'image', width: 640
    };
    this.img.openUploadDialog(info).afterClosed().subscribe(result => {
      this.product.photoUrl = result;
    });
  }
  checkUndefined(obj: any) {
    Object.entries(obj).forEach(e => {
      console.log(e);
      if (e[1] === undefined) {
        delete obj[e[0]];
      }
    });
  }

}
