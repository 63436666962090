import { Component, OnInit } from '@angular/core';
import {AngularFirestore, DocumentChangeAction} from '@angular/fire/firestore';
import {FNewsTags} from '../../../../../firebase/models/news';
import {NotifyService} from '../../../../../shared/services/notify.service';
import {MatDialog} from '@angular/material/dialog';
import {Guid} from 'guid-typescript';
import {ModalService} from '../../../../../shared/services/modal.service';
import {faPen} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-admin-news-tag-page',
  templateUrl: './admin-news-tag-page.component.html',
  styleUrls: ['./admin-news-tag-page.component.styl']
})
export class AdminNewsTagPageComponent implements OnInit {

  faPen = faPen;
  tagName = '';
  tagDesc = '';
  tags: DocumentChangeAction<FNewsTags>[] = [];
  constructor(private db: AngularFirestore,
              private notify: NotifyService,
              private ms: ModalService,
  ) { }

  ngOnInit(): void {
    this.db.collection<FNewsTags>('newsTags').snapshotChanges().subscribe(tags => {
      this.tags = tags;
    });
  }
  addTag() {
    this.db.collection<FNewsTags>('newsTags').add({
      desc: this.tagDesc,
      enabled: true,
      name: this.tagName,
      news: null,
      order: 10,
      createTime: new Date(),
      tagId: Guid.create().toString()
    }).then(() => {
      this.notify.pop('建立成功');
    });

  }
  toggleEnabled(event, tag: DocumentChangeAction<FNewsTags>) {
    tag.payload.doc.ref.update({
      enabled: event.checked
    }).then(() => {
      this.notify.pop('修改成功');
    });
  }
  openTagNameDialog(tag: DocumentChangeAction<FNewsTags>) {
    this.ms.openInputEditor('修改標籤名稱').afterClosed().subscribe((result: string) => {
      if (result) {
        tag.payload.doc.ref.update({
          name: result
        }).then(() => {
          this.notify.pop('修改成功');
        });
      }
    });
  }
  openOrderDialog(tag: DocumentChangeAction<FNewsTags>) {
    this.ms.openInputEditor('修改順序').afterClosed().subscribe((result: string) => {
      if (result) {
        tag.payload.doc.ref.update({
          order: Number(result)
        }).then(() => {
          this.notify.pop('修改成功');
        });
      }
    });
  }

}
