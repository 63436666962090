<form #form="ngForm">
  <div  class="d-flex mb-4">
    <div  class="mr-4">
    </div>
    <div  class="flex-1 ml-4 w-max-800">
      <div class="form-group row mx-0">
        <div class="col-sm-3 col-form-label pl-0">
          <div class="kv-checkbox form-check custom-control custom-checkbox mb-3">
            <input [checked]="product.banner" id="2ob0n33v3ia" name="iconSetting" type="checkbox" class="custom-control-input">
            <label for="2ob0n33v3ia" class="custom-control-label">宣傳標語</label>
            <!---->
          </div>
        </div>
        <div class="col-sm-9">
          <div class="form-inline">
            <div class="w-100">
              <input [(ngModel)]="product.banner" maxlength="10" placeholder="輸入宣傳標語" name="iconName" type="text" autocomplete="off" class="form-control w-80" aria-required="true" aria-invalid="false">
              <span class=""><strong class="text-muted">{{product.banner.length}}</strong><span class="">/10</span></span>
            </div>
            <!---->
          </div>
          <app-shop-card-message-color-picker [color]="product.desc" (ColorChanged)="product.bannerColor= $event; pageChanged()"></app-shop-card-message-color-picker>

        </div>
      </div>
      <div class="form-group row mx-0">
        <div class="col-sm-3 col-form-label pl-4">
          圖片
        </div>
        <div class="col-sm-9">
          <div class="form-inline">
            <div class="form-inline">
              <div>
                <div class="figure figure-grid cursor-pointer border position-relative mr-2">
                  <img *ngIf="product.images[0] !== ''" [src]="product.images[0]" class="figure-img">
                  <a *ngIf="product.images[0] === ''" (click)="openUploadImageDialog()" href="javascript:void(0)">上傳圖片</a>
                  <button *ngIf="product.images[0] !== ''" type="button" class="position-absolute top-right close"><i class="fas fa-window-close"></i></button>
                </div>
              </div>
            </div>
          </div>
          <!---->
          <!---->
        </div>
      </div>
      <div class="row mx-0">
        <div class="col-sm-3 col-form-label pl-4 mb-3">
          頁面標題
        </div>
        <div class="col-sm-9">
          <div class="form-inline">
            <div class="w-100">
              <input [(ngModel)]="product.header" maxlength="20" placeholder="輸入標題" name="headerTitle" type="text" autocomplete="off" class="form-control w-80" aria-required="true" aria-invalid="false">
              <span class=""><strong class="text-muted">{{product.header.length}}</strong><span class="">/20</span></span>
            </div>
            <!---->
          </div>
        </div>
      </div>
      <div class="form-group row mx-0">
        <div class="col-sm-3 col-form-label pl-0">
          <div class="kv-checkbox form-check custom-control custom-checkbox mb-3">
            <input [checked]="product.desc !== ''" id="f20653w6chu" name="iconSetting" type="checkbox" class="custom-control-input">
            <label for="f20653w6chu" class="custom-control-label">文字說明</label>
            <!---->
          </div>
        </div>
        <div class="col-sm-9">
          <div class="">
            <div class="">
              <div class="w-100">
                <textarea [(ngModel)]="product.desc" maxlength="60" placeholder="輸入文字說明" name="description" rows="3" type="text" autocomplete="off" class="form-control w-100" aria-required="true" aria-invalid="false"></textarea>
                <span class="float-right"><strong class="text-muted">{{product.desc.length}}</strong><span class="">/60</span></span>
              </div>
              <!---->
            </div>
            <!---->
          </div>
        </div>
        <!---->
      </div>
      <div class="row mx-0">
        <div class="col-sm-3 col-form-label pl-0">
          <div class="kv-checkbox form-check custom-control custom-checkbox mb-3">
            <input [checked]="product.price !== ''" id="yc047ha0kmm" name="iconSetting" type="checkbox" class="custom-control-input">
            <label for="yc047ha0kmm" class="custom-control-label">價格</label>
            <!---->
          </div>
        </div>
        <div class="col-sm-9">
          <div class="form-inline">
            <div class="w-70">
              <input [(ngModel)]="product.price" maxlength="15" placeholder="00,000" type="text" autocomplete="off" name="price" class="form-control" aria-required="true" aria-invalid="false">
              <span class=""><strong class="text-muted">{{product.price.length}}</strong><span class="">/15</span></span>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="row mx-0">
          <div class="col-sm-3 col-form-label pl-0 pl-0">
            <div class="kv-checkbox form-check custom-control custom-checkbox mb-3">
              <input [checked]="product.actions[0].text != ''" id="qb4m06lk597" name="link" type="checkbox" class="custom-control-input">
              <label for="qb4m06lk597" class="custom-control-label">動作1</label>
            </div>
          </div>
        </div>
        <app-shop-action-model [(ngModel)]="product.actions[0]"></app-shop-action-model>
      </div>
      <div>
        <div class="row mx-0">
          <div class="col-sm-3 col-form-label pl-0 pl-0">
            <div class="kv-checkbox form-check custom-control custom-checkbox mb-3">
              <input [checked]="product.actions[1].text != ''" id="0txigjx8eugi" name="link" type="checkbox" class="custom-control-input">
              <label for="0txigjx8eugi" class="custom-control-label">動作2</label>
            </div>
          </div>
        </div>
        <app-shop-action-model [(ngModel)]="product.actions[1]"></app-shop-action-model>
      </div>
    </div>
  </div>
</form>
