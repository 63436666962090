
<div id="wrapper" class="fixed">
	<div id="content-secondary">
		<div class="d-flex flex-column bg-white h-100">
			<div class="border-bottom flex-grow-0 flex-shrink-0 p-2">
				<div class="d-flex align-items-center justify-content-between">
					<div class="w-50">
						<div class="input-group flex-fill">
							<input [(ngModel)]="searchText" (ngModelChange)="onSearch()" type="text" placeholder="搜尋" class="form-control">
						</div>
					</div>
				</div>
			</div>
			<div class="d-flex flex-column justify-content-center flex-fill h-min-0">
				<div appScrollable (scrollPosition)="onUserListScroll($event)" class="flex-fill overflow-y-auto">
					<div *ngIf="friends.length === 0" class="p-3 text-muted text-center">
						<div> <p>帳號中收到的訊息將顯示於此</p>
						</div>
					</div>
					<div class="list-group list-group-flush">
						<ng-container *ngFor="let friend of friends  | orderBy: '' ; index as i" >
							<ng-container *ngIf="i < userListLimit">
								<a  (click)="onSelect(friend)" [ngClass]="{'active': currentUser && currentUser.user.uid === friend.user.uid}" class="list-group-item list-group-item-action d-flex flex-nowrap border-0">
									<div class="avatars rounded-circle mr-2 flex-shrink-0 flex-grow-0 avatars-sm">
										<div class="avatars-one" [ngStyle]="{'background': 'url(' + friend.user.photoUrl + ') center center / cover'}"></div>
									</div>
									<div class="flex-1 align-self-center w-min-0">
										<div class="d-flex align-items-center mb-1">
											<h6 class="mb-0 text-truncate">{{friend.user.displayName}}</h6>
											<div  *ngIf="friend.serviceStatus==1" class="badge badge-sm badge-pill badge-outline-primary ml-2">待處理</div>
											<div *ngIf="friend.serviceStatus==2" class="badge badge-sm badge-pill badge-outline-info ml-2">處理完畢</div>
										</div>
										<div class="text-muted small text-truncate">{{friend.serviceBody}}
										</div>
									</div>
									<div class="flex-shrink-0 flex-grow-0 ml-3 d-flex flex-column justify-content-between">
										<div class="text-right small text-muted">{{friend.serviceTimestamp.toDate().toLocaleTimeString()}}</div>
										<div class="text-right">
											<span *ngIf="friend.serviceCountVal > 0" class="badge badge-pin badge-primary border-0"></span>
											<span *ngIf="friend.block" class="badge badge-pin badge-block border-0"></span>
										</div>
									</div>
									<div class="vue-portal-target "></div>
								</a>

							</ng-container>

						</ng-container>
						<div class="list-group-item text-center py-4 border-0" style="display: none;">
							<div class="loader loader-xs loader-secondary"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>



	<div  id="content-primary">
		<div *ngIf="currentUser && currentUser.user" class="p-2 border-bottom flex-grow-0 flex-shrink-0">
			<div class="d-flex justify-content-between align-items-center">
				<div class="w-50 d-flex align-items-center">
					<div class="avatars rounded-circle flex-shrink-0 mr-2 avatars-xs">
						<div class="avatars-one" [ngStyle]="{'background': 'url(' + currentUser.user.photoUrl + ') center center / cover'}"></div>
					</div>
					<h4 class="mb-0 text-truncate">{{currentUser.user.displayName || ''}}</h4>
				</div>
				<div class="d-flex align-items-center">
					<a (click)="getUserShops(currentUser.user.uid)" *ngIf="auth.user.admin" class="btn btn-sm btn-outline-info mr-2">
            <span>查看官方帳號
            </span>
					</a>
					<a (click)="statusChanged(1)" *ngIf="currentUser.serviceStatus==0 || currentUser.serviceStatus==1"
					   [ngClass]="{'btn-outline-success': currentUser.serviceStatus==1, 'btn-outline-info': currentUser.serviceStatus==0}" class="btn btn-sm mr-2 ">
            <span>待處理
            </span>
					</a>
					<a (click)="statusChanged(2)" *ngIf="currentUser.serviceStatus==0 || currentUser.serviceStatus==1" class="btn btn-sm btn-outline-info mr-2"><span>處理完畢
        </span></a>
					<!---->
					<a (click)="statusChanged(0)" *ngIf="currentUser.serviceStatus==2" class="btn btn-sm btn-outline-info mr-2"><span>訊息盒
        </span></a></div>
			</div>
			<!---->
		</div>
		<div *ngIf="!currentUser" class="p-3 text-center text-muted"><div class="mb-3"></div> <p>立即開始使用聊天功能吧！</p></div>
		<div *ngIf="currentUser" style="overflow: scroll;" #scrollMe [scrollTop]="scrollMe.scrollHeight" class="p-3 bg-white flex-fill overflow-y-auto">
			<div *ngFor="let message of currentMessages$ | async; let i = index">
				<!--    日期-->
				<div class="chatsys" *ngIf="showDate(i)">
					<div class="chatsys-content">
						<!--            {{message.timestamp.toDate() | date: 'MM/dd (EEEE)'}}-->
						{{message.timestamp.toDate().toLocaleDateString()}}
						<br>
					</div>
				</div>
				<!--      左側客戶-->
				<div class="chat chat-text-dark chat-secondary"  *ngIf="message.idFrom === currentUser.user.uid">
					<!--        頭像-->
					<div class="avatars rounded-circle flex-grow-0 flex-shrink-0 avatars-xs">
						<div class="avatars-one" [ngStyle]="{'background': 'url(' + currentUser.user.photoUrl + ') center center / cover'}"></div>
					</div>
					<div class="chat-content">
						<div class="chat-body w-max-520">
							<div class="chat-main">
								<div *ngIf="message.type === 1"  class="chat-item rounded">
									<div>
										<a ><img [src]="message.content.photoUrl" width="200"></a>
									</div>
								</div>
								<div *ngIf="message.type === 0" class="chat-item baloon">
									<div class="view chat-item-text user-select-text">{{message.content.text}}</div>
								</div>
							</div>
							<div class="chat-sub user-select-text">
								<span>{{message.timestamp.toDate().toLocaleTimeString()}}</span>
							</div>
						</div>
					</div>
				</div>
				<!--      右側-->
				<div *ngIf="message.idTo === currentUser.user.uid" class="chat chat-text-dark chat-reverse chat-primary" >
					<div class="chat-content">
						<div *ngIf="message.user" class="chat-header">{{message.user.displayName}}</div>
						<!---->
						<div class="chat-body w-max-520">
							<div class="chat-main">
								<div *ngIf="message.type === 1"  class="chat-item rounded">
									<div>
										<a><img [src]="message.content.photoUrl" width="200"></a>
									</div>
								</div>
								<div *ngIf="message.type === 0" class="chat-item baloon">
									<div class="view chat-item-text user-select-text">{{message.content.text}}</div>
								</div>
							</div>
							<div class="chat-sub user-select-text">
								<span *ngIf="message.timestamp.toDate().getTime() < currentUser.userCountValChangeTime">已讀</span>
								<span>{{message.timestamp.toDate().toLocaleTimeString()}}</span>
								<!---->
								<!---->
								<!---->
							</div>
						</div>
						<!---->
						<!---->
						<!---->
					</div>
				</div>
			</div>
		</div>
		<div *ngIf="currentUser" id="editable-unit" class="border-top flex-grow-0 flex-shrink-0 bg-light position-relative">
			<div class="d-flex justify-content-between align-items-center p-2">
				<div>
					<a (click)="onUploadClick()" class="text-info mr-2"><fa-icon [icon]="faImage" class="far fa-image fa-fw fa-lg"></fa-icon></a>
					<input type="file" multiple="multiple" class="d-none">
				</div>
				<!--        <div><a class="text-info mr-2"><i class="fas fa-plus fa-fw fa-lg"></i></a></div>-->
			</div>
			<div class="bg-white border-top wrapper">
				<textarea [contentEditable]="true"  [disabled]="currentUser.block" [(ngModel)]="inputText" (keyup.enter)="onKeyUp($event)" [placeholder]="currentUser.block ? '已被封鎖' : '輸入訊息'" style="resize: none" class=" p-2 h-min-80 h-max-160 overflow-y-auto d-inline-block w-100 border-0"></textarea>

			</div>
			<!---->
		</div>
	</div>
	<div *ngIf="currentUser && currentUser.user" id="content-thirdly">
		<div class="d-flex flex-column h-100 overflow-y-auto">
			<div class="border-bottom flex-grow-0 flex-shrink-0 bg-white position-relative py-5 px-3">
				<div class="text-center">
					<div class="avatars rounded-circle mb-3 avatars-xl">
						<div class="avatars-one" [ngStyle]="{'background': 'url(' + currentUser.user.photoUrl + ') center center / cover'}"></div>
					</div>
					<div>
						<div>
							<h3 class="mb-0 d-flex align-items-center justify-content-center">
								<span class="text-truncate user-select-text">{{currentUser.user.displayName}}</span>
								<a (click)="displayNameModal = true" href="javascript:void(0)" class="ml-1"><fa-icon [icon]="faPen" class="fas fa-pen fa-xs"></fa-icon></a>
							</h3></div>
						<!---->
						<div class="mt-3">
							<tag-input *ngIf="currentUser.tagsA"  class=" d-flex justify-content-start flex-shrink-0 figure  position-relative"
							           [(ngModel)]="currentUser.tagsA"  [onlyFromAutocomplete]="true" (ngModelChange)="tabChanged()"
							           [secondaryPlaceholder]= "'+新增標籤'" [placeholder]= "'+新增標籤'" [theme]="'foundation-theme'" >
								<tag-input-dropdown [autocompleteItems]="ss.tagsForDisplay" [showDropdownIfEmpty]="true" >>
								</tag-input-dropdown>
							</tag-input>
						</div>
						<!---->
						<!---->
						<!---->
					</div>
				</div>
			</div>
			<div class="p-3">
				<div class="d-flex justify-content-between align-items-center mb-3">
					<h5 class="mb-0">
						記事本
						<span class="text-muted ml-1">{{currentUser.notes.length}}</span>
					</h5>
					<a (click)="noteModal = true" ><fa-icon [icon]="faPen" title="Add" class="fas fa-plus fa-fw"></fa-icon></a></div>
				<div *ngFor="let note of currentUser.notes; let i = index" class="card mb-3 card mb-3">
					<div class="card-body">
						<p class="card-text user-select-text">{{note.text}}</p>
						<div class="d-flex justify-content-between align-items-center">
							<div class="small text-muted text-truncate">
								{{note.timestamp.toDate().toLocaleString()}}
							</div>
							<div class="text-nowrap ml-2">
								<a (click)="onDeleteNote(i)" class="small text-muted"><fa-icon [icon]="faTrash" class="fas fa-trash-alt fa-fw"></fa-icon></a>
							</div>
						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
</div>
<div *ngIf="noteModal"  class="modal overflow-y-auto vue-portal-target d-block bg-dark-50">
	<div tabindex="-1" class="modal-dialog modal-dialog-centered modal-rg">
		<div class="modal-content h-100">
			<div class="modal-header flex-shrink-0">
				<h5 class="mb-0">新增記事本</h5>
				<button (click)="closeNoteModal()" class="close">×</button>
			</div>
			<div class="modal-body mh-100 overflow-hidden">
				<div class="input-group input-group-vertical">
					<textarea [(ngModel)]="noteContent" required="" placeholder="您可於此新增用戶的相關資訊，且記事本中的資訊不會向對方公開。" maxlength="300" type="text" rows="8" class="form-control"></textarea>
					<!---->
					<div class="d-flex justify-content-end mt-2"><span><strong class="">{{noteContent.length}}</strong> <span class="text-muted">/300</span></span>
					</div>
				</div>
			</div>
			<div class="d-flex flex-row flex-shrink-0 flex-row-reverse">
				<button (click)="closeNoteModal(noteContent)" [disabled]="noteContent.length === 0" type="button" class="btn btn-lg rounded-0 flex-1 btn-primary">儲存</button>
				<button (click)="closeNoteModal()" type="button" class="btn btn-secondary btn-lg rounded-0 flex-1">取消</button>
			</div>
		</div>
	</div>
</div>
<div *ngIf="displayNameModal && currentUser" class="modal overflow-y-auto vue-portal-target d-block bg-dark-50">
	<div tabindex="-1" class="modal-dialog modal-dialog-centered modal-sm" >
		<div class="modal-content h-100">
			<div class="modal-header flex-shrink-0">
				<h5 class="mb-0">變更顯示名稱</h5>
				<button class="close">×</button>
			</div>
			<div class="modal-body mh-100 overflow-hidden">
				<div class="input-group">
					<input [(ngModel)]="displayNameContent" [placeholder]="currentUser.user.displayName" required maxlength="20" type="text" class="form-control form-control-lg">
					<div class="input-group-append"><span class="input-group-text"><strong class="">{{displayNameContent.length}}</strong>/20</span></div>
					<div class="invalid-feedback">
						最多只能輸入20個字
					</div>
					<!---->
				</div>
			</div>
			<div class="d-flex flex-row flex-shrink-0 flex-row-reverse">
				<button (click)="closeDisplayNameModal(displayNameContent)" [disabled]="displayNameContent.length < 1" type="button" class="btn btn-lg rounded-0 flex-1 btn-primary">儲存</button>
				<button (click)="closeDisplayNameModal()" type="button" class="btn btn-secondary btn-lg rounded-0 flex-1">取消</button>
			</div>
		</div>
	</div>
</div>
