<div class="d-flex justify-content-between align-items-center mb-2">
  <h2 class="mb-0">服務列表</h2>
  <button routerLink="Create" type="button" class="btn btn-primary w-min-120">建立</button>

</div>
<hr>

<table class="table table-hover table-layout-fixed">
  <thead class="thead-light">
  <tr>
    <th class="">
      <span class="">
        提供者
      </span>
    </th>
    <th class="">
      <span class="">
        狀態
      </span>
    </th>
    <th class="">
      <span class="">
        服務
      </span>
    </th>
    <th class="">
      <span ><!---->
        動作
      </span>
    </th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let booking of currentPage" class="">
    <td>
      <span class=""><!---->
        {{booking.payload.doc.data().name}}
      </span>
    </td>
    <td>
      <span class=""><!---->
        {{booking.payload.doc.data().enabled ? '啟用' : '停用'}}
      </span>
    </td>
    <td>
      <span class=""><!---->
        {{booking.payload.doc.data().services.length}}
      </span>
    </td>
    <td>
      <span class="mr-2" [routerLink]="booking.payload.doc.id">編輯</span>
      <span  [routerLink]="'Calender/' + booking.payload.doc.id">日曆</span>

    </td>
  </tr>
  </tbody>
</table>
<hr>
<app-list-paginator [data]="list" (listChanged)="currentPage = $event"></app-list-paginator>
