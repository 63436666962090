import { Injectable } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {UploadImageDialogComponent, UploadImageDialogData} from '../ui/upload-image-dialog/upload-image-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(private dialog: MatDialog) { }
  public openUploadDialog(uploadInfo: UploadImageDialogData) {
    return this.dialog.open(UploadImageDialogComponent, {
      panelClass: 'custom-dialog-container',
      data: uploadInfo
    });
  }
}
