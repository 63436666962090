import Timestamp = firebase.firestore.Timestamp;
import * as firebase from 'firebase';

export function datetimeFix(obj: any) {
  Object.entries(obj).forEach(
      ([key, value]) => {
        if (value instanceof Timestamp){
          obj[key] = value.toDate();
        }
      }
  );
}
