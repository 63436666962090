<nav id="nav" class="bg-white border-bottom pt-1 flex-shrink-0">
  <div class="container-fluid d-flex justify-content-between align-items-center">
    <ul class="nav nav-bordered">
      <li class="nav-item position-relative"><a routerLink="/Accounts" routerLinkActive="router-link-exact-active active-ignore active" class="nav-link">帳號</a>
      </li>
    </ul>
  </div>
</nav>


<div id="container" class="bg-light d-flex flex-column">
  <div id="wrapper" class="d-flex align-items-stretch flex-fill">
    <aside id="menu" class="overflow-auto w-fix-280 flex-shrink-0 flex-grow-0 position-relative border-right">
      <div class="container-fluid bg-light py-3 min-vh-100">
        <ul class="nav flex-column">
          <li class="nav-item"><a routerLink="/Accounts" routerLinkActive="router-link-exact-active active" class="nav-link d-inline-block">帳號一覽</a>
          </li>
        </ul>
      </div>
    </aside>
    <div id="contents" class="w-100 d-flex flex-column position-relative bg-white" style="min-width: calc(100% - 280px);">
      <main class="m-5" >
        <main>
          <div>
            <div class="d-flex justify-content-between align-items-center mb-2">
              <h2 class="mb-0">帳號一覽</h2></div>
            <hr>
            <div>
              <table class="table table-hover">
                <col>
                <thead class="thead-light">
                <tr>
                  <th class="">
                    <span class=""><!---->
                      帳號名稱
                    </span></th>
                  <th class=""><span class="cursor-pointer"><!---->
          好友人數
         <i class="fas fas-sm ml-1 fa-sort text-muted"></i></span></th>
                  <th class=""><span class="cursor-pointer"><!---->
          權限
         <i class="fas fas-sm ml-1 fa-sort-up"></i></span></th>
                  <th class=""><span class="cursor-pointer"><!---->
          方案
         <i class="fas fas-sm ml-1 fa-sort text-muted"></i></span></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngIf="shopService.shops.length === 0">
                  <td colspan="5">
                    <div class="text-center my-4">
                      尚無官方帳號，請至Line Cast App創建官方帳號
                    </div>
                  </td>
                </tr>
                <tr *ngFor="let shop of shopService.shops">
                  <td>
                    <a (click)="shopService.selectShop(shop)" [routerLink]="'/Account/' + shop.lineAt" href="javascript:void(0)"  class="hljs-link text-dark">
                      <div class="d-flex align-items-center">
                        <div class="badged-avatar d-inline-block position-relative overflow-hidden">
                          <div title="" class="avatar mr-2 rounded-circle avatar-xs">
                            <div class="w-100 h-100" [ngStyle]="{'background': 'url(' + shop.photoUrl + ') center center / cover'}"></div>
                          </div>
                        </div>
                        <div>{{shop.displayName}}</div>
                      </div>
                    </a>
                  </td>
                  <td>
                    {{shop.follower}}
                  </td>
                  <td>
                    {{permissionToSting(shop.permission)}}
                  </td>
                  <td>
                    輕用量
                  </td>
                </tr>
                </tbody>
              </table>
              <div style=""></div>
              <hr>

            </div>
          </div>
        </main>
      </main>
    </div>
  </div>
</div>



