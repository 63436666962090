<form #form="ngForm">
	<div class="d-flex justify-content-between align-items-center mb-2">
		<h2 class="mb-0">印製用的點數發放行動條碼設定</h2></div>
	<hr >
	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">行動條碼名稱
			<span class="badge badge-pin badge-pin-sm badge-primary ml-1"></span></label>
		<div class="col-sm-9">
			<div class="form-inline text-muted">
				<input [(ngModel)]="rewardCode.title" required="" maxlength="60" name="title" placeholder="名稱" type="text" autocomplete="off" class="form-control w-80 mr-2" aria-required="true" aria-invalid="false">
				<span class=""><strong class="">{{rewardCode.title.length}}</strong><span class="">/60</span></span>
			</div>
		</div>
	</div>
	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">發放點數</label>
		<div class="col-sm-9">
			<select [(ngModel)]="rewardCode.point" required name="couponType" class="custom-select w-40">
				<option [ngValue]="1">1</option>
				<option [ngValue]="2">2</option>
				<option [ngValue]="3">3</option>
				<option [ngValue]="4">4</option>
				<option [ngValue]="5">5</option>
				<option [ngValue]="6">6</option>
				<option [ngValue]="7">7</option>
				<option [ngValue]="8">8</option>
				<option [ngValue]="9">9</option>
			</select>
		</div>
	</div>
	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">掃描期限</label>
		<div class="form-inline mb-2 col-sm-9">
			<input class="form-control mr-2 w-fix-200"  required [(ngModel)]="rewardCode.endTime" name="endTime"   [owlDateTime]="dt1" [min]="minDate" [owlDateTimeTrigger]="dt1" placeholder="結束日期">
			<span [owlDateTimeTrigger]="dt1">
					<fa-icon class="text-muted" [icon]="faCalendar"></fa-icon>
				</span>
			<owl-date-time  #dt1></owl-date-time>
		</div>
	</div>


	<hr class="my-4">
	<div class="d-flex justify-content-center my-5">
		<button [disabled]="form.invalid" (click)="save()" type="button" class="btn btn-primary px-5 mx-1">儲存</button>
	</div>
	<div >
	</div>
</form>
