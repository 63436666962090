import { NgModule } from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ShopsListPageComponent} from './pages/shops-list-page/shops-list-page.component';
import {ShopRootPageComponent} from './pages/shop-root-page/shop-root-page.component';
import {ShopHomeComponent} from './pages/shop-root-page/shop-home/shop-home.component';
import {AuthGuard} from '../shared/guards/auth.guard';
import {ShopSharePageComponent} from './pages/shop-root-page/shop-home/shop-share-page/shop-share-page.component';
import {ShopMessageListComponent} from './pages/shop-root-page/shop-home/shop-message-list/shop-message-list.component';
import {ShopCouponListComponent} from './pages/shop-root-page/shop-home/shop-coupon-list/shop-coupon-list.component';
import {ShopCouponEditComponent} from './pages/shop-root-page/shop-home/shop-coupon-list/shop-coupon-edit/shop-coupon-edit.component';
import {ShopMessageEditComponent} from './pages/shop-root-page/shop-home/shop-message-list/shop-message-edit/shop-message-edit.component';
import {ShopWelcomeMessagePageComponent} from './pages/shop-root-page/shop-home/shop-welcome-message-page/shop-welcome-message-page.component';
import {ShopHomePageComponent} from './pages/shop-root-page/shop-home/shop-home-page/shop-home-page.component';
import {ShopAutoResponseListPageComponent} from './pages/shop-root-page/shop-home/shop-auto-response-list-page/shop-auto-response-list-page.component';
import {ShopAutoResponseEditPageComponent} from './pages/shop-root-page/shop-home/shop-auto-response-list-page/shop-auto-response-edit-page/shop-auto-response-edit-page.component';
import {ShopRichMessageListPageComponent} from './pages/shop-root-page/shop-home/shop-rich-message-list-page/shop-rich-message-list-page.component';
import {ShopRichMessageEditPageComponent} from './pages/shop-root-page/shop-home/shop-rich-message-list-page/shop-rich-message-edit-page/shop-rich-message-edit-page.component';
import {ShopMeetGiftPageComponent} from './pages/shop-root-page/shop-home/shop-meet-gift-page/shop-meet-gift-page.component';
import {ShopRewardCardListPageComponent} from './pages/shop-root-page/shop-home/shop-reward-card-list-page/shop-reward-card-list-page.component';
import {ShopRewardCardEditPageComponent} from './pages/shop-root-page/shop-home/shop-reward-card-list-page/shop-reward-card-edit-page/shop-reward-card-edit-page.component';
import {ShopRewardCodeListPageComponent} from './pages/shop-root-page/shop-home/shop-reward-code-list-page/shop-reward-code-list-page.component';
import {ShopRewardCodeEditPageComponent} from './pages/shop-root-page/shop-home/shop-reward-code-list-page/shop-reward-code-edit-page/shop-reward-code-edit-page.component';
import {ShopRewardCardAnalysisPageComponent} from './pages/shop-root-page/shop-home/shop-reward-card-list-page/shop-reward-card-analysis-page/shop-reward-card-analysis-page.component';
import {ShopRichMenuListPageComponent} from './pages/shop-root-page/shop-home/shop-rich-menu-list-page/shop-rich-menu-list-page.component';
import {ShopRichMenuEditPageComponent} from './pages/shop-root-page/shop-home/shop-rich-menu-list-page/shop-rich-menu-edit-page/shop-rich-menu-edit-page.component';
import {ShopCardMessageListPageComponent} from './pages/shop-root-page/shop-home/shop-card-message-list-page/shop-card-message-list-page.component';
import {ShopCardMessageEditPageComponent} from './pages/shop-root-page/shop-home/shop-card-message-list-page/shop-card-message-edit-page/shop-card-message-edit-page.component';
import {ShopCourseListPageComponent} from './pages/shop-root-page/shop-course/shop-course-list-page/shop-course-list-page.component';
import {ShopCourseEditPageComponent} from './pages/shop-root-page/shop-course/shop-course-list-page/shop-course-edit-page/shop-course-edit-page.component';
import {ShopCourseComponent} from './pages/shop-root-page/shop-course/shop-course.component';
import {ShopQuestionnareListPageComponent} from './pages/shop-root-page/shop-home/shop-questionnare-list-page/shop-questionnare-list-page.component';
import {ShopQuestionnareEditPageComponent} from './pages/shop-root-page/shop-home/shop-questionnare-list-page/shop-questionnare-edit-page/shop-questionnare-edit-page.component';
import {ShopFriendListPageComponent} from './pages/shop-root-page/shop-friend/shop-friend-list-page/shop-friend-list-page.component';
import {ShopFriendComponent} from './pages/shop-root-page/shop-friend/shop-friend.component';
import {ShopTagListPageComponent} from './pages/shop-root-page/shop-friend/shop-tag-list-page/shop-tag-list-page.component';
import {ShopAdComponent} from './pages/shop-root-page/shop-ad/shop-ad.component';
import {ShopAdPurchasePageComponent} from './pages/shop-root-page/shop-ad/shop-ad-purchase-page/shop-ad-purchase-page.component';
import {ShopAdListPageComponent} from './pages/shop-root-page/shop-ad/shop-ad-list-page/shop-ad-list-page.component';
import {ShopBillingComponent} from './pages/shop-root-page/shop-billing/shop-billing.component';
import {ShopBillingPurchasePageComponent} from './pages/shop-root-page/shop-billing/shop-billing-purchase-page/shop-billing-purchase-page.component';
import {ShopBillingPaymentPageComponent} from './pages/shop-root-page/shop-billing/shop-billing-payment-page/shop-billing-payment-page.component';
import {ShopBillingMessageListPageComponent} from './pages/shop-root-page/shop-billing/shop-billing-message-list-page/shop-billing-message-list-page.component';
import {ShopBillingBillListPageComponent} from './pages/shop-root-page/shop-billing/shop-billing-bill-list-page/shop-billing-bill-list-page.component';
import {ShopStoreComponent} from './pages/shop-root-page/shop-store/shop-store.component';
import {ShopStoreSettingPageComponent} from './pages/shop-root-page/shop-store/shop-store-setting-page/shop-store-setting-page.component';
import {ShopStoreProductListPageComponent} from './pages/shop-root-page/shop-store/shop-store-product-list-page/shop-store-product-list-page.component';
import {ShopStoreProductEditPageComponent} from './pages/shop-root-page/shop-store/shop-store-product-list-page/shop-store-product-edit-page/shop-store-product-edit-page.component';
import {ShopStoreOrderListPageComponent} from './pages/shop-root-page/shop-store/shop-store-order-list-page/shop-store-order-list-page.component';
import {ShopStoreOrderEditPageComponent} from './pages/shop-root-page/shop-store/shop-store-order-list-page/shop-store-order-edit-page/shop-store-order-edit-page.component';
// tslint:disable-next-line:max-line-length
import {ShopStoreCategoryPageComponent} from './pages/shop-root-page/shop-store/shop-store-category-page/shop-store-category-page.component';
import {ShopChatComponent} from './pages/shop-root-page/shop-chat/shop-chat.component';
import {ShopNewsComponent} from './pages/shop-root-page/shop-news/shop-news.component';
import {NewsReviewListPageComponent} from '../shared/pages/news-review-list-page/news-review-list-page.component';
import {NewsReviewEditPageComponent} from '../shared/pages/news-review-list-page/news-review-edit-page/news-review-edit-page.component';
import {NewsAnalysisPageComponent} from '../shared/pages/news-review-list-page/news-analysis-page/news-analysis-page.component';
import {ShopBillingReceiptPageComponent} from './pages/shop-root-page/shop-billing/shop-billing-receipt-page/shop-billing-receipt-page.component';
import {ShopSettingSetiingPageComponent} from './pages/shop-root-page/shop-setting/shop-setting-setiing-page/shop-setting-setiing-page.component';
import {ShopSettingPermissionPageComponent} from './pages/shop-root-page/shop-setting/shop-setting-permission-page/shop-setting-permission-page.component';
import {ShopSettingComponent} from './pages/shop-root-page/shop-setting/shop-setting.component';
import {ShopBillingDiscountPageComponent} from './pages/shop-root-page/shop-billing/shop-billing-discount-page/shop-billing-discount-page.component';
import {ShopAgentComponent} from './pages/shop-root-page/shop-agent/shop-agent.component';
import {ShopAgentSettingPageComponent} from './pages/shop-root-page/shop-agent/shop-agent-setting-page/shop-agent-setting-page.component';
import {ShopAgentFeeListPageComponent} from './pages/shop-root-page/shop-agent/shop-agent-fee-list-page/shop-agent-fee-list-page.component';
import {ShopAgentFeePageComponent} from './pages/shop-root-page/shop-agent/shop-agent-fee-list-page/shop-agent-fee-page/shop-agent-fee-page.component';
import {ShopAgentClientListPageComponent} from './pages/shop-root-page/shop-agent/shop-agent-client-list-page/shop-agent-client-list-page.component';
import {ShopAgentClientShareListPageComponent} from './pages/shop-root-page/shop-agent/shop-agent-client-list-page/shop-agent-client-share-list-page/shop-agent-client-share-list-page.component';
import {ShopFriendShareLinkListPageComponent} from './pages/shop-root-page/shop-friend/shop-friend-share-link-list-page/shop-friend-share-link-list-page.component';
import {ShopCourseShareListPageComponent} from './pages/shop-root-page/shop-course/shop-course-list-page/shop-course-share-list-page/shop-course-share-list-page.component';
import {ShopCourseDetailListPageComponent} from './pages/shop-root-page/shop-course/shop-course-list-page/shop-course-detail-list-page/shop-course-detail-list-page.component';
import {ShopAgentPointPageComponent} from './pages/shop-root-page/shop-agent/shop-agent-point-page/shop-agent-point-page.component';
import {ShopAgentClientPaymentPageComponent} from './pages/shop-root-page/shop-agent/shop-agent-client-list-page/shop-agent-client-payment-page/shop-agent-client-payment-page.component';
import {canActivate} from '@angular/fire/auth-guard';
import {ExtraServiceGuard} from './guards/extra-service.guard';
import {ShopBillingPointPageComponent} from './pages/shop-root-page/shop-billing/shop-billing-point-page/shop-billing-point-page.component';
import {ShopEventComponent} from './pages/shop-root-page/shop-event/shop-event.component';
import {ShopEventListPageComponent} from './pages/shop-root-page/shop-event/shop-event-list-page/shop-event-list-page.component';
import {ShopEventEditPageComponent} from './pages/shop-root-page/shop-event/shop-event-list-page/shop-event-edit-page/shop-event-edit-page.component';
import {ShopEventDetailListPageComponent} from './pages/shop-root-page/shop-event/shop-event-list-page/shop-event-detail-list-page/shop-event-detail-list-page.component';
import {ShopEventShareListPageComponent} from './pages/shop-root-page/shop-event/shop-event-list-page/shop-event-share-list-page/shop-event-share-list-page.component';
import {ShopNotSupportPageComponent} from './pages/shop-root-page/shop-not-support-page/shop-not-support-page.component';
import {ShopBillingExtraFeaturesPageComponent} from './pages/shop-root-page/shop-billing/shop-billing-extra-features-page/shop-billing-extra-features-page.component';
import {ShopBillingOrderListPageComponent} from './pages/shop-root-page/shop-billing/shop-billing-order-list-page/shop-billing-order-list-page.component';
import {ShopBookingComponent} from "./pages/shop-root-page/shop-booking/shop-booking.component";
import {ShopBookingServiceListPageComponent} from "./pages/shop-root-page/shop-booking/shop-booking-service-list-page/shop-booking-service-list-page.component";
import {ShopBookingServicePageComponent} from "./pages/shop-root-page/shop-booking/shop-booking-service-list-page/shop-booking-service-page/shop-booking-service-page.component";
import {ShopBookingServiceCalendarComponent} from './pages/shop-root-page/shop-booking/shop-booking-service-list-page/shop-booking-service-calendar/shop-booking-service-calendar.component';


const shopRoutes: Routes = [
  { path: 'Accounts', component: ShopsListPageComponent, canActivate: [AuthGuard]  },
  { path: 'Account/:id', component: ShopRootPageComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard], children:
      [
        { path: '', redirectTo: 'Home', pathMatch: 'full'},

        {
          path: 'Home', component: ShopHomeComponent, children:
          [
            { path: '', component: ShopHomePageComponent },
            { path: 'Share', component: ShopSharePageComponent },
            { path: 'Message', component: ShopMessageListComponent },
            { path: 'Message/:id', component: ShopMessageEditComponent },
            { path: 'MeetGift', component: ShopMeetGiftPageComponent },
            { path: 'Coupon', component: ShopCouponListComponent },
            { path: 'Coupon/:id', component: ShopCouponEditComponent },
            { path: 'Welcome', component: ShopWelcomeMessagePageComponent },
            { path: 'AutoResponse', component: ShopAutoResponseListPageComponent },
            { path: 'AutoResponse/:id', component: ShopAutoResponseEditPageComponent },
            { path: 'RichMessage', component: ShopRichMessageListPageComponent },
            { path: 'RichMessage/:id', component: ShopRichMessageEditPageComponent },
            { path: 'RewardCard', component: ShopRewardCardListPageComponent, canActivate: [ExtraServiceGuard],
              data: {
                role: 'RewardCard'
              }
            },
            { path: 'RewardCard/:id', component: ShopRewardCardEditPageComponent, canActivate: [ExtraServiceGuard],
              data: {
                role: 'RewardCard'
              }
            },
            { path: 'RewardCard/Analysis/:id', component: ShopRewardCardAnalysisPageComponent, canActivate: [ExtraServiceGuard],
              data: {
                role: 'RewardCard'
              }
            },
            { path: 'Questionnaire', component: ShopQuestionnareListPageComponent },
            { path: 'Questionnaire/:id', component: ShopQuestionnareEditPageComponent },
            { path: 'RewardCode', component: ShopRewardCodeListPageComponent, canActivate: [ExtraServiceGuard],
              data: {
                role: 'RewardCard'
              }
            },
            { path: 'RewardCode/:id', component: ShopRewardCodeEditPageComponent, canActivate: [ExtraServiceGuard],
              data: {
                role: 'RewardCard'
              }
            },
            { path: 'RichMenu', component: ShopRichMenuListPageComponent },
            { path: 'RichMenu/:id', component: ShopRichMenuEditPageComponent },
            { path: 'CardMessage', component: ShopCardMessageListPageComponent },
            { path: 'CardMessage/:id', component: ShopCardMessageEditPageComponent },
          ]
        },
        {
          path: 'Setting', component: ShopSettingComponent , children: [
            { path: 'Setting', component: ShopSettingSetiingPageComponent },
            { path: 'Permission', component: ShopSettingPermissionPageComponent },
            { path: '', redirectTo: 'Setting', pathMatch: 'full'},

          ]
        },
        {
          path: 'Course', component: ShopCourseComponent, children: [
            { path: 'Course', component: ShopCourseListPageComponent },
            { path: 'Course/:id', component: ShopCourseEditPageComponent },
            { path: 'Course/Member/:id', component: ShopCourseDetailListPageComponent },
            { path: 'Course/Share/:id', component: ShopCourseShareListPageComponent },
            { path: '', redirectTo: 'Course', pathMatch: 'full'},

          ], canActivate: [ExtraServiceGuard],
          data: {
            role: 'Course'
          }
        },
        {
          path: 'Event', component: ShopEventComponent, children: [
            { path: 'Event', component: ShopEventListPageComponent },
            { path: 'Event/:id', component: ShopEventEditPageComponent },
            { path: 'Event/Member/:id', component: ShopEventDetailListPageComponent },
            { path: 'Event/Share/:id', component: ShopEventShareListPageComponent },
            { path: '', redirectTo: 'Event', pathMatch: 'full'},

          ], canActivate: [ExtraServiceGuard],
          data: {
            role: 'Event'
          }
        },
        {
          path: 'Friend', component: ShopFriendComponent, children: [
            { path: 'Friend', component: ShopFriendListPageComponent },
            { path: 'Tag', component: ShopTagListPageComponent },
            { path: 'ShareLink', component: ShopFriendShareLinkListPageComponent },
            { path: '', redirectTo: 'Friend', pathMatch: 'full'},

          ]
        },
        {
          path: 'Ad', component: ShopAdComponent, children: [
            { path: 'Purchase', component: ShopAdPurchasePageComponent },
            { path: 'History', component: ShopAdListPageComponent },
            { path: '', redirectTo: 'Purchase', pathMatch: 'full'},

          ]
        },
        {
          path: 'Billing', component: ShopBillingComponent, children: [
            { path: 'Purchase', component: ShopBillingPurchasePageComponent },
            { path: 'Payment', component: ShopBillingPaymentPageComponent },
            { path: 'Message', component: ShopBillingMessageListPageComponent },
            { path: 'Bill', component: ShopBillingBillListPageComponent },
            { path: 'Receipt', component: ShopBillingReceiptPageComponent },
            { path: 'Discount', component: ShopBillingDiscountPageComponent },
            { path: 'Point', component: ShopBillingPointPageComponent },
            { path: 'Orders', component: ShopBillingOrderListPageComponent },
            { path: 'ExtraFeatures', component: ShopBillingExtraFeaturesPageComponent },
            { path: '', redirectTo: 'Bill', pathMatch: 'full'},

          ]
        },
        {
          path: 'Store', component: ShopStoreComponent, children: [
            { path: 'Setting', component: ShopStoreSettingPageComponent },
            { path: 'Product', component: ShopStoreProductListPageComponent },
            { path: 'Product/:id', component: ShopStoreProductEditPageComponent },
            { path: 'Order', component: ShopStoreOrderListPageComponent },
            { path: 'Order/:id', component: ShopStoreOrderEditPageComponent },
            { path: 'Category', component: ShopStoreCategoryPageComponent },
            { path: '', redirectTo: 'Setting', pathMatch: 'full'},
          ],
          canActivate: [ExtraServiceGuard] ,
          data: {
            role: 'Store'
          },
          // canActivateChild: [ExtraServiceGuard],
        },
        {
          path: 'Chat', component: ShopChatComponent
        },
        {
          path: 'News', component: ShopNewsComponent, children: [
            { path: 'NewsReview', component: NewsReviewListPageComponent },
            { path: 'NewsReview/:id', component: NewsReviewEditPageComponent },
            { path: 'NewsReview/Analysis/:id', component: NewsAnalysisPageComponent },
            { path: '', redirectTo: 'NewsReview', pathMatch: 'full'},

          ]
        },
        {
          path: 'Agent', component: ShopAgentComponent, children: [
            { path: 'Setting', component: ShopAgentSettingPageComponent },
            { path: 'Profit', component: ShopAgentFeeListPageComponent },
            { path: 'Profit/:id', component: ShopAgentFeePageComponent },
            { path: 'Client', component: ShopAgentClientListPageComponent },
            { path: 'Client/Share/:id', component: ShopAgentClientShareListPageComponent },
            { path: 'Client/Payment/:id', component: ShopAgentClientPaymentPageComponent },
            { path: 'Profit', component: ShopAgentFeeListPageComponent },
            { path: 'Profit/:id', component: ShopAgentFeePageComponent },
            { path: 'Point', component: ShopAgentPointPageComponent },
            { path: '', redirectTo: 'Setting', pathMatch: 'full'},

          ]
        },
        {
          path: 'Booking', component: ShopBookingComponent, children: [
            { path: 'Services', component: ShopBookingServiceListPageComponent },
            { path: 'Services/:id', component: ShopBookingServicePageComponent },
            { path: 'Services/Calender/:id', component: ShopBookingServiceCalendarComponent },
            { path: '', redirectTo: 'Services', pathMatch: 'full'},

          ], canActivate: [ExtraServiceGuard],
          data: {
            role: 'Booking'
          },
        },
        {
          path: 'NotSupport', component: ShopNotSupportPageComponent
        }
      ]
  },

];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild(shopRoutes)
  ],
  exports: [RouterModule]
})
export class ShopRoutingModule { }
