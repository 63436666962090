import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {EmojiPickerDialogComponent} from '../../../../../../shared/ui/emoji-picker-dialog/emoji-picker-dialog.component';
import {ShopRichMessageTypePickerComponent} from './shop-rich-message-type-picker/shop-rich-message-type-picker.component';
import {FRichContent} from '../../../../../../firebase/models/message';
import {ModalService} from '../../../../../../shared/services/modal.service';
import {StorageService} from '../../../../../../shared/services/storage.service';
import {UploadImageDialogData} from '../../../../../../shared/ui/upload-image-dialog/upload-image-dialog.component';
import {FActionModel} from '../../../../../../firebase/models/shared';
import {ActivatedRoute} from '@angular/router';
import {ShopService} from '../../../../../services/shop.service';
import {NotifyService} from '../../../../../../shared/services/notify.service';
import {RouteService} from '../../../../../../shared/services/route.service';

@Component({
  selector: 'app-shop-rich-message-edit-page',
  templateUrl: './shop-rich-message-edit-page.component.html',
  styleUrls: ['./shop-rich-message-edit-page.component.styl']
})
export class ShopRichMessageEditPageComponent implements OnInit {

  create = false;
  richMessageDoc: any;
  richMessage: FRichContent = {createTime: new Date(), name: '', photoUrl: '', styleType: 99, urls: []};
  constructor(private dialog: MatDialog,
              private ss: StorageService,
              private activatedRoute: ActivatedRoute,
              private shop: ShopService,
              private notify: NotifyService,
              public rs: RouteService) { }

  ngOnInit(): void {
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    if (id === 'Create') {
      this.create = true;
    } else  {
      this.shop.currentShopDoc.collection<FRichContent>('richMessages').doc(id).get().subscribe(message => {
        if (message.exists) {
          // @ts-ignore
          this.richMessage = message.data();
          this.richMessageDoc = message.ref;
        } else {
          this.notify.pop('圖文訊息不存在');
          this.rs.navigateTo('/Home/RichMessage');
        }
      });
    }
  }
  save() {
    if (this.create) {
      this.richMessage.createTime = new Date();
      this.shop.currentShopDoc.collection('richMessages').add(this.richMessage).then(() => {
        this.success();
      });
    } else  {
      this.richMessageDoc.update(this.richMessage).then(() => {
        this.success();
      });
    }
  }
  success() {
    this.notify.pop('已儲存');
    this.rs.navigateTo('/Home/RichMessage');
  }
  delete() {}
  openTypePicker() {
    this.dialog.open(ShopRichMessageTypePickerComponent, {
      panelClass: 'custom-dialog-container',
    }).afterClosed().subscribe(result => {
      if (result !== null && result !== undefined) {
        this.setExpander(result);
      }
    });
  }
  openUploadImageDialog() {
    const info: UploadImageDialogData = {height: 512, type: 'image', width: 512};
    this.ss.openUploadDialog(info).afterClosed().subscribe(result => {
      if (result) {
        this.richMessage.photoUrl = result;
      }
    });
  }
  getOrderChar(index: number) {
    return String.fromCharCode(index + 65);
  }
  setExpander(type: number) {
    console.log('before: ');
    console.log(this.richMessage.urls);
    this.richMessage.styleType = type;
    switch (type) {
      case 0:
        this.setActions(1);
        break;
      case 1:
        this.setActions(2);
        break;
      case 2:
        this.setActions(2);
        break;
      case 3:
        this.setActions(3);
        break;
      case 4:
        this.setActions(4);
        break;
      case 5:
        this.setActions(3);
        break;
      case 6:
        this.setActions(3);
        break;
      case 7:
        this.setActions(6);
        break;
      default:
        this.setActions(0);
        break;
    }
    console.log('after: ');
    console.log(this.richMessage.urls);
  }
  setActions(actionCount) {
    this.richMessage.urls = [];
    for (let i = 0; i < actionCount; i++) {
      this.richMessage.urls.push(this.getAction());
    }
  }
  getAction(): FActionModel{
    return {content: '', text: '', type: 0};
  }
  log(a) {
    console.log(a);
  }

}
