import { Component, OnInit } from '@angular/core';
import { FMonthlyTotal, getPlanName } from 'src/app/firebase/models/chargePlan';
import {ActivatedRoute} from '@angular/router';
import {AngularFirestore} from '@angular/fire/firestore';

@Component({
  selector: 'app-admin-accounts-mothly-income-page',
  templateUrl: './admin-accounts-mothly-income-page.component.html',
  styleUrls: ['./admin-accounts-mothly-income-page.component.styl']
})
export class AdminAccountsMothlyIncomePageComponent implements OnInit {

  getPlanName = getPlanName;
  month = '';
  listSource: FMonthlyTotal[] = [];
  list: FMonthlyTotal[] = [];
  currentPage: FMonthlyTotal[] = [];
  constructor(private activatedRoute: ActivatedRoute,
              private db: AngularFirestore) { }

  ngOnInit(): void {
    this.month = this.activatedRoute.snapshot.paramMap.get('id');
    this.parseBill();
  }
  parseBill() {
    this.db.collectionGroup('monthlyTotals').get().subscribe(x => {
      const data = this.groupBy(x.docs, 'id');
      const bill = data[this.month];
      this.listSource = bill.map(b => b.data())
      this.list = this.listSource.filter(y => y.totalFee > 0);
    });
  }

  groupBy(xs, key) {
    return xs.reduce((rv, x) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }
  displayFree() {
    this.list = this.listSource;
  }

}
