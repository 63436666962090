import { Component, OnInit } from '@angular/core';
import {FDailyTotal} from '../../../../../firebase/models/chargePlan';
import {ShopService} from '../../../../services/shop.service';

@Component({
  selector: 'app-shop-billing-message-list-page',
  templateUrl: './shop-billing-message-list-page.component.html',
  styleUrls: ['./shop-billing-message-list-page.component.styl']
})
export class ShopBillingMessageListPageComponent implements OnInit {

  dailyMessages: FDailyTotal[] = [];
  currentPage: FDailyTotal[] = [];
  constructor(private ss: ShopService) { }

  ngOnInit(): void {
    this.ss.currentShopDoc.collection<FDailyTotal>('dailyTotals').valueChanges().subscribe(list => {
      this.dailyMessages = list;
    });
  }

}
