<div class="d-flex justify-content-between align-items-center mb-2">
	<h2 class="mb-0">優惠券</h2></div>
<hr>

<form #form="ngForm" (ngSubmit)="submit()">

	<div class="form-group row w-fix-800">
		<label for="title" class="col-sm-3 col-form-label text-sm-right font-weight-light">優惠券名稱</label>
		<div class="col-sm-9">
			<div class="form-inline text-muted">
				<input [(ngModel)]="coupon.title" required  maxlength="60" name="title" id="title" placeholder="例：8折優惠券" type="text" autocomplete="off" class="form-control w-80 mr-2">
				<span class=""><strong class="">{{coupon.title.length}}</strong><span class="">/60</span></span>
			</div>
		</div>
	</div>

	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">優惠券類型</label>
		<div class="col-sm-9">
			<select [(ngModel)]="coupon.type" required type="number" name="couponType" class="custom-select w-40">
				<option [ngValue]="0">其他</option>
				<option [ngValue]="1">折扣</option>
				<option [ngValue]="2">免費</option>
				<option [ngValue]="3">贈品</option>
				<option [ngValue]="4">現金回饋</option>
			</select>
		</div>
	</div>

	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">有效期間</label>
		<div class="col-sm-9">
			<div class="form-inline mb-2">
				<span  class="col-form-label col-form-label-sm text-muted col-sm-2 pl-0 pr-0">開始日期 </span>
				<input class="form-control mr-2"  required [(ngModel)]="coupon.startTime" name="startTime"   [owlDateTime]="dt1" [min]="minDate" [owlDateTimeTrigger]="dt1" placeholder="開始日期">
				<span [owlDateTimeTrigger]="dt1">
					<fa-icon class="text-muted" [icon]="faCalendar"></fa-icon>
				</span>
				<owl-date-time  #dt1></owl-date-time>
			</div>
			<div *ngIf="coupon.startTime" class="form-inline mb-2"><span class="col-form-label col-form-label-sm text-muted col-sm-2 pl-0 pr-0">結束日期 </span>
				<input class="form-control mr-2"  required [(ngModel)]="coupon.endTime" name="endTime"   [owlDateTime]="dt2" [min]="coupon.startTime" [owlDateTimeTrigger]="dt2" placeholder="結束日期">
				<span [owlDateTimeTrigger]="dt2">
					<fa-icon class="text-muted" [icon]="faCalendar"></fa-icon>
				</span>
				<owl-date-time  #dt2></owl-date-time>
			</div>
		</div>
	</div>
	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">圖片</label>
		<div class="col-sm-9">
			<div >
				<div [ngClass]="{'border-danger': coupon.photoUrl==''}" class="figure figure-grid border figure-grid-xl mb-2 position-relative">
					<a *ngIf="coupon.photoUrl === '' " href="javascript:void(0)" (click)="openDialog()">上傳圖片</a>
					<img *ngIf="coupon.photoUrl != ''" [src]="coupon.photoUrl" class="figure-img cursor-pointer">
					<input required [(ngModel)]="coupon.photoUrl" name="photoUrl" class="d-none">
					<button *ngIf="coupon.photoUrl != ''" (click)="coupon.photoUrl=''" type="button" style="top: 0px; right: 0px;" class="btn btn-sm position-absolute close">
						<fa-icon [icon]="faWindowClose"></fa-icon>
					</button>
				</div>
			</div>
		</div>
	</div>
	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">使用說明</label>
		<div class="col-sm-9">
			<div class="form-group">
				<div class="text-muted">
					<textarea [ngClass]="{'border-danger': coupon.desc==''}" required [(ngModel)]="coupon.desc" name="notes" maxlength="1000" rows="5" class="form-control"></textarea>
					<span class="float-right"><strong class="">{{coupon.desc.length}}</strong><span class="">/500</span></span>
				</div>
			</div>
		</div>
	</div>

	<hr class="my-4">

	<div class="my-4">
		<h3 class="mb-0">進階設定</h3>
	</div>

	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">折扣設定</label>
		<div class="col-sm-9">
			<select [(ngModel)]="coupon.discountType" name="discountType" type="number" name="couponType" class="custom-select w-40">
				<option [ngValue]="0">不折扣</option>
				<option [ngValue]="1">折扣金額</option>
				<option [ngValue]="2">折扣折數</option>
			</select>
			<div *ngIf="coupon.discountType !== 0" class="mt-2">
				滿
				<input style="border: 1px solid;" [(ngModel)]="coupon.discountPrice " type="number" name="discountPrice">
				元，折扣
				<input style="border: 1px solid;" [(ngModel)]="coupon.discountValue" type="number" name="discountValue">
				{{coupon.discountType === 1 ? '元' : '折 (九折請輸入90, 八折輸入80 ...以此類推)'}}
			</div>
		</div>
	</div>

	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">抽獎</label>
		<div class="col-sm-9">
			<div class="form-control-plaintext">
				<div class=" form-check custom-control custom-radio mb-2">
					<input [(ngModel)]="coupon.lottery" id="a" name="winProbability" class="custom-control-input" type="radio" [value]="false">
					<label for="a" class="custom-control-label">停用</label>
				</div>
				<div class=" form-check custom-control custom-radio mb-2">
					<input [(ngModel)]="coupon.lottery" id="an" name="winProbability" class="custom-control-input" type="radio" [value]="true">
					<label for="an" class="custom-control-label"><span class="form-inline"><span class="mr-2">啟用</span></span>
					</label>
				</div>
				<div *ngIf="coupon.lottery" class="custom-control custom-radio">
					<div class="form-inline">
						<span class="mr-2">中獎機率：</span>
						<input style="border: 1px solid;" [disabled]="!coupon.lottery" [(ngModel)]="coupon.lotteryRate" class="mt-1 mb-1" name="lotteryRate" type="number" maxlength="3" max="100" min="0"><span> %</span>
					</div>
					<div class="form-inline">
						<span class="mr-2">中獎人數上限：</span>
						<input style="border: 1px solid;" [disabled]="!coupon.lottery" [(ngModel)]="coupon.lotteryMaxWinner" class="mt-1 mb-1" name="lotteryMaxWinner" type="number">
						<span>人 (0為不限)</span>
					</div>
				</div>

			</div>
		</div>
	</div>

	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">公開範圍</label>
		<div class="col-sm-9">
			<div class="form-control-plaintext">
				<div class="kv-radio form-check custom-control custom-radio mb-2">
					<input [(ngModel)]="coupon.privacy" id="public" name="public" type="radio" class="custom-control-input" [value]="0">
					<label for="public" class="custom-control-label"><span>所有人 </span></label>
				</div>
				<div class="kv-radio form-check custom-control custom-radio mb-2">
					<input [(ngModel)]="coupon.privacy" id="private" name="privacy" type="radio" class="custom-control-input" [value]="1">
					<label for="private" class="custom-control-label"><span>僅限好友 </span></label>
				</div>
			</div>
		</div>
	</div>



	<hr class="my-4">
	<div class="d-flex justify-content-center my-5">
		<button  [disabled]="form.invalid"  type="submit" class="btn btn-primary px-5 mx-1">儲存</button>
	</div>
</form>

