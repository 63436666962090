<div class="d-flex justify-content-between align-items-center mb-2">
	<h2 class="mb-0">問卷調查</h2>
</div>
<p class="text-muted">建立問卷調查後，您可透過群發訊息邀請LINE用戶回答，以統計並掌握用戶的寶貴意見。</p>
<hr>
<form #form="ngForm" >

	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">問卷調查名稱
			<span class="badge badge-pin badge-pin-sm badge-primary ml-1"></span></label>
		<div class="col-sm-9">
			<div class="form-inline text-muted">
				<input [(ngModel)]="qnr.title" required maxlength="30" name="title" placeholder="名稱" type="text" autocomplete="off" class="form-control w-80 mr-2" aria-required="true" aria-invalid="false">
				<span class=""><strong class="">{{qnr.title.length}}</strong><span class="">/30</span></span>
			</div>
		</div>
	</div>

	<div class="form-group row w-max-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">結束時間</label>
		<div class="col-sm-9">
			<div class="form-inline text-muted">
				<input class="form-control w-80 mr-2 " style="width: 400px !important;" required name="eventEndTime" [(ngModel)]="qnr.endTime"    [owlDateTime]="eventEndTime" [min]="minDate" [owlDateTimeTrigger]="eventEndTime"  autocomplete="off">
				<span [owlDateTimeTrigger]="eventEndTime">
					<fa-icon class="text-muted" [icon]="faCalendarAlt"></fa-icon>
				</span>
				<owl-date-time  #eventEndTime></owl-date-time>
			</div>
		</div>
	</div>

	<div class="form-group row w-max-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">圖片</label>
		<div class="col-sm-9">
			<div class="form-inline position-relative">
				<div>
					<div  [ngClass]="{'border-danger' : img.invalid}" *ngIf="qnr.photoUrl === ''" class="figure figure-grid cursor-pointer border position-relative mr-2">
						<a (click)="openImgUploadDialog()" href="javascript:void(0)">上傳圖片</a>
					</div>
					<div *ngIf="qnr.photoUrl !== ''" class="figure figure-grid cursor-pointer border position-relative mr-2">
						<img [src]="qnr.photoUrl" class="figure-img">
						<button (click)="qnr.photoUrl = ''" type="button" class="position-absolute top-right close x"><fa-icon [icon]="faWindowClose"></fa-icon></button>
					</div>
				</div>
				<input #img=ngModel class="d-none" name="img" [(ngModel)]="qnr.photoUrl" required>
			</div>
		</div>
	</div>

	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">謝禮(非必填)</label>
		<div class="col-sm-9">
			<div class="form-inline text-muted">
				<input [(ngModel)]="qnr.couponLink"  name="couponLink" maxlength="300" placeholder="請輸入填完問卷後連結取得LINE優惠券的網址" type="text" autocomplete="off" class="form-control w-80 mr-2" aria-required="true" aria-invalid="false">
			</div>
			<a href="http://biz.casttalk.me/circle/index.php/surveypluslinecoupon/" ><small>如何取得LINE優惠券網址？</small></a>
		</div>
	</div>
	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">
			問卷調查說明
		</label>
		<div class="col-sm-9">
			<div class="w-100 text-muted">
				<textarea required maxlength="150" [(ngModel)]="qnr.desc" placeholder="例：歡迎參與回答關於本服務的簡單問卷調查！" name="description" rows="5" class="form-control" aria-required="true" aria-invalid="false"></textarea>
				<span class="float-right"><strong class="">{{qnr.desc.length}}</strong><span class="">/150</span></span>
			</div>
		</div>
	</div>

	<hr class="my-4">
	<h3 class="mb-0">自訂問題</h3>
	<br>

	<mat-accordion multi="true" >
		<ng-container  *ngFor = "let item of qnr.questions; let i = index" >
			<mat-expansion-panel [expanded]="true" class="ard w-fix-800 my-3" >
				<mat-expansion-panel-header class="p-2" >
					<mat-panel-title class=" mb-0  p-1 pl-4 d-flex align-items-center justify-content-between">
						<h6 class="mb-0">{{'問題'+(i+1)}}</h6>
						<div class="btn-group">
							<button [disabled]="qnr.questions.length <= 1" (click)="qnr.questions.splice(qnr.questions.indexOf(item,0),1)"type="button" class="btn btn- disabled">
								<fa-icon [icon]="faWindowClose"></fa-icon>
							</button>
						</div>
					</mat-panel-title>
				</mat-expansion-panel-header>
				<div class="collapse show">
					<div class="card-body pt-0">
						<div class="card-body pt-0">
							<section>
								<div class="d-flex mt-3">
									<label class="w-fix-160 col-form-label text-sm-right font-weight-light">類型</label>
									<div class="flex-1 ml-4">
										<div class="form-inline w-100 text-muted">
											<select [(ngModel)]="item.choiceType" (ngModelChange)="typeChanged(item)" required="" id="62yf1fwsaat" [name]="'choiceType' + i" class="form-control custom-select form-control-rg">
												<option [ngValue]="0">單選</option>
												<option [ngValue]="1">複選</option>
												<option [ngValue]="2">開放式</option>
											</select>
										</div>
									</div>
								</div>
								<div class="d-flex mt-3 justify-content-between">
									<label class="w-fix-160 col-form-label text-sm-right font-weight-light">問題</label>
									<div class="flex-1 ml-4">
										<div class="form-inline w-100 text-muted">
											<input required maxlength="30" [(ngModel)]="item.title" placeholder="輸入問題" [name]="'q-title' + i" class="form-control w-85 mr-2" aria-required="true" aria-invalid="false">
											<span class=""><strong class="">{{item.title.length}}</strong><span class="">/30</span></span>
										</div>
									</div>
								</div>
								<div class="card my-3"  *ngFor = "let answer of item.answers index as j" >
									<div class="card-header p-1 pl-4">
										<div class="d-flex align-items-center justify-content-between">
											<h6 class="mb-0">{{'選項'+(i+j)}}</h6>
											<div class="btn-group">
												<button [disabled]="item.answers.length <= 2" (click)="item.answers.splice(item.answers.indexOf(answer,0),1)" type="button" class="btn btn- disabled">
													<fa-icon [icon]="faWindowClose"></fa-icon>
												</button>
											</div>
										</div>
									</div>
									<div class="card-body">
										<div class="d-flex mt-3">
											<label class="w-fix-160 col-form-label text-sm-right font-weight-light">選項</label>
											<div class="flex-1 ml-4">
												<div class="form-inline w-100 text-muted">
													<input required maxlength="30" [(ngModel)]="answer.title" placeholder="輸入選項" [name]="'ans-title' + i + j" autocomplete="off" class="form-control w-85 mr-2" aria-required="true" aria-invalid="false">
													<span class=""><strong class="">{{answer.title.length}}</strong><span class="">/30</span></span>
												</div>
											</div>
										</div>
										<div class="d-flex mt-3">
											<label class="w-fix-160 col-form-label text-sm-right font-weight-light">分組標籤</label>
											<div class="flex-1 ml-4">
												<div  class="d-flex mb-2 position-relative justify-content-sm-start">
													<tag-input  class=" d-flex justify-content-start flex-shrink-0 figure  position-relative"
													            [(ngModel)]="answer.tag" [name]="'tag' + i + j" [onlyFromAutocomplete]="true"
													            [secondaryPlaceholder]= "'+新增標籤'" [placeholder]= "'+新增標籤'" [theme]="'foundation-theme'" >
														<tag-input-dropdown [autocompleteItems]="ss.tagsForDisplay" [showDropdownIfEmpty]="true" >>
														</tag-input-dropdown>
													</tag-input>
												</div>
												<div>
													<p class="text-left text-muted no-background justify-content-sm-start ">
														<small>填答者勾選此選項將自動被貼上分組標籤，以供將來分組發送訊息或其他用途。</small>
														<br>
														<a routerLink="/Home/Tags" ><small>建立標籤請按此</small></a>
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
								<button (click)="addAnswer(item)" type="button" class="btn btn-outline-secondary">
									<fa-icon [icon]="faPlusSquare"></fa-icon>
									新增選項
								</button>
							</section>
						</div>
					</div>
				</div>
			</mat-expansion-panel>
		</ng-container>

		<div>
			<button (click)="addQuestion()" type="button" class="btn btn-outline-primary">
				<fa-icon [icon]="faPlusSquare"></fa-icon>
				新增問題</button>
		</div>
	</mat-accordion>

	<div class="d-flex justify-content-center p-4">
		<div class="p-4">
			<button (click)="save()" [disabled]="form.invalid" type="button" class="px-5 btn btn-primary btn-undefined">儲存</button>
		</div>
	</div>
</form>
