<div class="d-flex justify-content-between align-items-center mb-2"><h2 class="mb-0">推薦人邀請連結</h2>
</div>
<hr>
<form #form="ngForm">
	<div class=" my-4">
		<div class="form-group row w-fix-800">
			<label class="col-sm-2 col-form-label text-sm-right font-weight-light">推薦人</label>
			<div class="col-sm-9 d-flex">
				<div *ngIf="share.user" class="div3">
					<div  class=" avatar avatar-sm rounded-circle div1">
						<div class="w-100 h-100" [ngStyle]="{'background': 'url(' + share.user.photoUrl + ') center center / cover'}"></div>
					</div>
					<div class="div2">
						<label class="user col-form-label text-sm-left font-weight-normal">{{share.user.displayName}}</label>
						<!--            <label class="user col-form-label-xs text-sm-left font-weight-light">{{user.user.statusMessage }}</label>-->
					</div>
				</div>
				<div class="form-inline text-muted">
					<button (click)="openFriendPicker()" type="button" class=" btn btn-primary w-min-100">選擇</button>
				</div>
			</div>
		</div>
		<div class="form-group row w-fix-800">
			<label class="col-sm-2 col-form-label text-sm-right font-weight-light">連結描述</label>
			<div class="col-lg">
				<div class="form-inline text-muted">
					<input [(ngModel)]="share.desc" required maxlength="15" type="text" name="desc" placeholder="請輸入描述" autocomplete="off" class="form-control w-fix-240">
					<span class="pl-1"><strong >{{share.desc.length}}</strong><span >/15</span></span>
          <button [disabled]="form.invalid || !share.userRef" (click)="addShareLink()" type="button" style="margin-left: 2rem" class=" btn btn-primary w-min-100 h-35">建立新的邀請連結</button>

        </div>
			</div>
		</div>
		<div class="btn-group ">
		</div>
	</div>
</form>
<hr>

<table class="table table-hover table-layout-fixed">
	<thead class="thead-light">
	<tr>
		<th class="">
      <span class="">
          推薦人
      </span>
		</th>
		<th class="">
      <span class="">
          描述
      </span>
		</th>
		<th class=""><span class="">
          加入好友數
    </span>
		</th>
		<th class=""><span class="">
          分享連結
    </span>
		</th>
	</tr>
	</thead>
	<tbody >
	<ng-container *ngFor="let share of currentPage">
		<tr>
			<td>
				<div class="div3">
					<div  class=" avatar avatar-sm rounded-circle div1">
						<div class="w-100 h-100" [ngStyle]="{'background': 'url(' + share.payload.doc.data().user.photoUrl + ') center center / cover'}"></div>
					</div>
					<div class="div2">
						<label class="user col-form-label text-sm-left font-weight-normal">{{share.payload.doc.data().user.displayName}}</label>
						<!--            <label class="user col-form-label-xs text-sm-left font-weight-light">{{user.user.statusMessage }}</label>-->
					</div>
				</div>
			</td>
			<td>
				{{share.payload.doc.data().desc}}
			</td>
			<td>
				{{share.payload.doc.data().users.length}}
			</td>
			<td>
				{{share.payload.doc.data().shareUrl ? share.payload.doc.data().shareUrl : '產生中'}}
				<fa-icon *ngIf="share.payload.doc.data().shareUrl" (click)="downloadQRCode(share.payload.doc.data().shareUrl)" ngbTooltip="下載" class="p-2 cursor-pointer text-primary" [icon]="faDownload"></fa-icon>
				<fa-icon [icon]="icon.faTrash" class="cursor-pointer p-2 text-danger" (click)="deleteShareLink(share)" ngbTooltip="刪除"></fa-icon>
				<button *ngIf="share.payload.doc.data().shareUrl" ngbTooltip="分享到Facebook" style="color: #3b5998" mat-icon-button shareButton="facebook" [url]="share.payload.doc.data().shareUrl">
					<fa-icon [icon]="icon.faFacebookF" size="sm"></fa-icon>
				</button>
			</td>
		</tr>
	</ng-container>

	</tbody>
</table>
<hr>
<app-list-paginator [data]="list" (listChanged)="currentPage = $event"></app-list-paginator>
