<div class="d-flex justify-content-between align-items-center mb-2">
	<h2 class="mb-0">廣告報表</h2>
</div>
<hr>

<table class="table table-hover table-layout-fixed">
	<thead class="thead-light">
	<tr>
		<th class="">
      <span class="">
        購買日期
      </span>
		</th>
		<th class="">
      <span class=""><!---->
        刊登服務
      </span>
		</th>
		<th class="">
      <span class=""><!---->
        刊登狀態
      </span>
		</th>

		<th>
			價格
		</th>
		<th class="">
      <span class=""><!---->
        加入好友人數
      </span>
		</th>
		<th class="">
      <span class=""><!---->
        以點數折抵
      </span>
		</th>
		<th>
			付款狀態
		</th>
	</tr>
	</thead>
	<tbody>
	<tr *ngFor="let ad of ads" class="">
		<td>
      <span class=""><!---->
	      {{ad.payload.doc.data().createTime.toDate().toLocaleString()}}
      </span>
		</td>
		<td>
      <span class=""><!---->
	      {{getPlanString(ad.payload.doc.data()) + ad.payload.doc.data().days + '天'}}
      </span>
			<span *ngIf="ad.payload.doc.data().agent" style="color: #1e7e34">
        (代理商贈刊）
      </span>
		</td>
		<td>
      <span class=""><!---->
	      {{getPlanStatus(ad.payload.doc.data())}}
      </span>
		</td>

		<td>
      <span class="text-primary"><!---->
	      {{ad.payload.doc.data().totalFee}}
      </span>
		</td>
		<td>
      <span class=""><!---->
	      {{ad.payload.doc.data().follower}}
      </span>
		</td>
		<td>
      <a *ngIf="!ad.payload.doc.data().pay" href="javascript:void(0)" (click)="payWithPoint(ad)" class="text-primary"><!---->
	      以點數折抵
      </a>
		</td>
		<td>
      <span class=""><!---->
	      {{ad.payload.doc.data().pay ? '已付款' : '未付款'}}
      </span>
			<a href="javascript:void(0)" (click)="getPaymentLink(ad.payload.doc.data())" *ngIf="!ad.payload.doc.data().pay">立即付款</a>
		</td>
	</tr>
	</tbody>
</table>
