<div class="w-100 d-flex justify-content-between align-items-center mb-2">
	<h2 class="mb-0">圖文訊息</h2>
	<button routerLink="Create" type="button" class="btn btn-primary w-min-120 btn btn-primary">建立</button>
</div>
<p class="text-muted">您可以使用圖片傳送視覺效果更豐富的訊息進行宣傳。</p>
<hr>
<div class="position-relative overflow-auto" style="">
	<table class="table table-hover table-layout-fixed">
		<thead class="thead-light">
		<tr>
			<th>
				<span>
          標題
        </span>
			</th>
			<th>
				<span>
	        圖片
	      </span>
			</th>
			<th>
				<span class="cursor-pointer">
          建立時間
				</span>
			</th>
		</tr>
		</thead>
		<tbody>
		<tr  *ngFor="let message of richMessages">
			<td>
				<div class="text-truncate-2"><a [routerLink]="message.payload.doc.id" class="text-dark hljs-link">
					{{message.payload.doc.data().name}}
				</a></div>
			</td>
			<td>
				<a [routerLink]="message.payload.doc.id">
					<img [src]="message.payload.doc.data().photoUrl" width="80"></a>
			</td>
			<td>
				{{message.payload.doc.data().createTime.toDate().toLocaleString()}}
			</td>
		</tr>
		</tbody>
	</table>
</div>
<hr>
<app-list-paginator [data]="richMessages" (listChanged)="currentPage = ($event)" ></app-list-paginator>
