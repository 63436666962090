<div class="d-flex justify-content-between align-items-center mb-2">
	<h2 class="mb-0">問卷調查</h2>
	<button routerLink="Create" type="button" class="btn btn-primary w-min-120">建立</button>
</div>
<hr>

<table class="table table-layout-fixed">
	<thead class="thead-light">
	<tr>
		<th class=""><span class="">
          問卷調查名稱
						</span></th>
		<th class=""><span class="cursor-pointer">
          問卷調查期間
         <i class="fas fas-sm ml-1 fa-sort text-muted"></i></span></th>
		<th class=""><span class="">
          狀態
						</span></th>
    <th class=""><span class="">
          下載
						</span></th>
  </tr>
	</thead>
	<tbody>
	<tr *ngFor="let qnr of currentPage">
		<td>
				<div class="text-truncate-2">{{qnr.payload.doc.data().title}}</div>
		</td>
		<td>
			{{qnr.payload.doc.data().endTime.toDate().toLocaleString()}}
		</td>
		<td>
			<span class="text-success">{{getStatusString(qnr)}}</span>
		</td>
    <td>
      <span class="text-success" (click)="download(qnr, qnr.payload.doc.data().title)">下載</span>
    </td>
	</tr>
	</tbody>
</table>
<hr>
<app-list-paginator [data]="qnrs" (listChanged)="currentPage = $event"></app-list-paginator>
