import { Component, OnInit } from '@angular/core';
import {FRewardCard} from '../../../../../../firebase/models/rewardCard';
import {ActivatedRoute} from '@angular/router';
import {NotifyService} from '../../../../../../shared/services/notify.service';
import {ShopService} from '../../../../../services/shop.service';
import {AngularFirestoreDocument, DocumentChangeAction} from '@angular/fire/firestore';
import Timestamp = firebase.firestore.Timestamp;
import {RouteService} from '../../../../../../shared/services/route.service';
import {ModalService} from '../../../../../../shared/services/modal.service';
import {FCoupon} from '../../../../../../firebase/models/coupon';
import * as firebase from 'firebase';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import {IconService} from '../../../../../../shared/services/icon.service';

@Component({
  selector: 'app-shop-reward-card-edit-page',
  templateUrl: './shop-reward-card-edit-page.component.html',
  styleUrls: ['./shop-reward-card-edit-page.component.styl']
})
export class ShopRewardCardEditPageComponent implements OnInit {

  awardPoints = Array.from(Array(60), (_, i) => i + 1);
  faCalendar = faCalendar;
  minDate = new Date();
  create = false;
  rewardCardDoc: AngularFirestoreDocument<FRewardCard>;
  rewardCard: FRewardCard = {
    createTime: new Date(),
    desc: '',
    enabled: false,
    endTime: new Date(),
    firstPoint: 0,
    goals: 0,
    maxPoint: 0,
    periodPoint: 0,
    shop: undefined,
    title: '',
    type: 0
  };
  constructor(private notify: NotifyService,
              private activatedRoute: ActivatedRoute,
              private ss: ShopService,
              private re: RouteService,
              private ms: ModalService,
              public icon: IconService) { }
  ngOnInit(): void {
    this.initRewardCard();
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    if (id === 'Create') {
      this.create = true;
    } else  {
      this.rewardCardDoc = this.ss.currentShopDoc.collection('rewardCards').doc(id);
      this.rewardCardDoc.get().subscribe(coupon => {
        if (coupon.exists) {
          // @ts-ignore
          this.rewardCard = coupon.data();
          this.rewardCard.endTime = (this.rewardCard.endTime as Timestamp).toDate();
        } else {
          this.notify.pop('集點卡不存在');
          this.re.navigateTo('/Home/Rewards');
        }
      });
    }
  }
  initRewardCard() {
    this.rewardCard = {
      createTime: new Date(),
      desc: '',
      enabled: false,
      endTime: new Date(),
      firstPoint: 0,
      goals: {},
      maxPoint: 1,
      periodPoint: 10,
      shop: this.ss.shopInfo,
      title: '',
      type: 0
    };
  }
  deleteAward(award) {
    delete this.rewardCard.goals[award];
  }
  getAwards() {
    // console.log(Object.entries(this.rewardCard.goals));
    return Object.entries(this.rewardCard.goals);
  }
  openCouponPicker(key: string) {
    this.ms.openCouponPicker().afterClosed().subscribe((result: DocumentChangeAction<FCoupon>) => {
      if (result) {
        this.rewardCard.goals[key] = result.payload.doc.data();
        this.rewardCard.goals[key].documentId = result.payload.doc.ref.id;
      }
    });
  }
  save() {
    this.rewardCard.maxPoint = this.getMaxPoint();
    if (this.create) {
      this.ss.currentShopDoc.collection<FRewardCard>('rewardCards').add(this.rewardCard).then(() => {
        this.notify.pop('已儲存，請記得啟用集點卡才能開始活動！');
        this.re.navigateTo('/Home/RewardCards');
      });
    } else {
      this.rewardCardDoc.update(this.rewardCard).then(() => {
        this.notify.pop('已儲存，請記得啟用集點卡才能開始活動！');
        this.re.navigateTo('/Home/RewardCards');
      });
    }
  }
  getMaxPoint() {
    const maxPoint =  Object.keys(this.rewardCard.goals).map(x => Number(x)).sort( (a, b) => {
      return b - a;
    })[0];
    return maxPoint;
  }


}
