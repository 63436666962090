import { Component, OnInit } from '@angular/core';
import {FRewardCard} from '../../../../../firebase/models/rewardCard';
import {ShopService} from '../../../../services/shop.service';
import {DocumentChangeAction} from '@angular/fire/firestore';

@Component({
  selector: 'app-shop-reward-card-list-page',
  templateUrl: './shop-reward-card-list-page.component.html',
  styleUrls: ['./shop-reward-card-list-page.component.styl']
})
export class ShopRewardCardListPageComponent implements OnInit {

  rewardCards: DocumentChangeAction<FRewardCard>[] = [];
  constructor(private ss: ShopService) { }

  ngOnInit(): void {
    this.ss.currentShopDoc.collection<FRewardCard>('rewardCards').snapshotChanges().subscribe(rc => {
      this.rewardCards = rc;
    });
  }
  disableRewardCard() {
    this.rewardCards.forEach(card => {
      card.payload.doc.ref.update({
        enabled: false
      });
    });
  }
  enableRewardCard(rewardCard: DocumentChangeAction<FRewardCard>) {
    this.disableRewardCard();
    rewardCard.payload.doc.ref.update({
      enabled: true
    });
  }

}
