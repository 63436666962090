<script src="store-setting-page.component.spec.ts"></script>
<div class="d-flex justify-content-between align-items-center mb-2"><h2 class="mb-0">商店設定</h2>
</div>
<hr>
<form #form="ngForm">
	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">開啟商店</label>
		<div class="col-sm-9 d-flex flex-md-column justify-content-center">
			<mat-slide-toggle class="align-items-center d-flex mt-2"
			                  [checked]="store.enabled"
			                  (change)="store.enabled = $event.source.checked">
				{{store.enabled ? '啟用':'停用'}}
			</mat-slide-toggle>
		</div>
	</div>

  <div class="form-group row w-fix-800">
    <label class="col-sm-3 col-form-label text-sm-right font-weight-light">商店網址</label>
    <div class="col-sm-9 d-flex flex-column justify-content-center">
      <div>
        <a  [href]="'https://store.casttalk.me/' + ss.currentShop.lineAt + '/product?openExternalBrowser=1'">https://store.casttalk.me/{{ss.currentShop.lineAt}}/product</a>
      </div>
    </div>
  </div>

	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">商店名稱</label>
		<div class="col-sm-9">
			<div class="form-inline text-muted">
				<input  [(ngModel)]="store.name" name="name" type="text" required maxlength="20" placeholder="商店名稱" autocomplete="off" class="form-control w-fix-40">
				<span class="ml-1"><strong class="">{{store.name.length}}</strong><span class="">/20</span></span>
			</div>
		</div>
	</div>

	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">主頁標題</label>
		<div class="col-sm-9">
			<div class="form-inline text-muted">
				<input  [(ngModel)]="store.title" name="title" type="text" required maxlength="20" placeholder="主頁標題" autocomplete="off" class="form-control w-fix-40">
				<span class="ml-1"><strong class="">{{store.title.length}}</strong><span class="">/20</span></span>
			</div>
		</div>
	</div>

	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">主頁圖片
		</label>
		<div class="col-sm-9">
			<div class="field w-fix-500">
				<div class="control">
					<button (click)="openImgDialog()" type="button" class="btn btn-outline-secondary">編輯</button>
				</div>
				<img [src]="store.photoUrl" style="width: 150px">
				<input class="d-none" [(ngModel)]="store.photoUrl" required name="photoUrl">
			</div>
		</div>
	</div>

	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">主色</label>
		<div class="col-sm-9">
			<div class="form-inline text-muted">
				<input required [(colorPicker)]="store.primaryColor" [style.background]="store.primaryColor" [cpAlphaChannel]="'disabled'">
			</div>
		</div>
	</div>

	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">副色</label>
		<div class="col-sm-9">
			<div class="form-inline text-muted">
				<input  required [(colorPicker)]="store.secondColor" [style.background]="store.secondColor" [cpAlphaChannel]="'disabled'">
			</div>
		</div>
	</div>


	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">品牌故事</label>
		<div class="col-sm-9">
<!--			<div [froalaEditor]="froala.options" [(froalaModel)]="store.story"  ></div>-->
      <angular-editor [(ngModel)]="store.story" [config]="froala.config" name="desc" required></angular-editor>
		</div>
	</div>


	<div class="d-flex justify-content-between align-items-center mt-2 mb-2"><h2 class="mb-0">公司設定</h2>
	</div>
	<hr>

	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">公司名稱</label>
		<div class="col-sm-9">
			<div class="form-inline text-muted">
				<input  [(ngModel)]="store.companyName" name="companyName" type="text" required  placeholder="公司名稱" autocomplete="off" class="form-control w-fix-40">
			</div>
		</div>
	</div>

	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">公司電話</label>
		<div class="col-sm-9">
			<div class="form-inline text-muted">
				<input  [(ngModel)]="store.companyPhone" name="companyPhone" type="text" required placeholder="公司電話" autocomplete="off" class="form-control w-fix-40">
			</div>
		</div>
	</div>

	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">公司地址</label>
		<div class="col-sm-9">
			<div class="form-inline text-muted">
				<input  [(ngModel)]="store.companyAddr" name="companyAddr" type="text" required placeholder="公司地址" autocomplete="off" class="form-control w-fix-40">
			</div>
		</div>
	</div>

	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">公司電子郵件</label>
		<div class="col-sm-9">
			<div class="form-inline text-muted">
				<input  [(ngModel)]="store.companyEmail" name="companyEmail" type="text" required placeholder="公司電子郵件" autocomplete="off" class="form-control w-fix-40">
			</div>
		</div>
	</div>


	<div class="d-flex justify-content-between align-items-center mt-2 mb-2"><h2 class="mb-0">運費設定</h2>
	</div>
	<hr>

	<div *ngFor="let delivery of store.deliveries index as i" class="border position-relative w-fix-880 my-4">
		<button *ngIf="store.deliveries.length <= 1" [disabled]="store.deliveries.length <= 1" (click)="store.deliveries.splice(store.deliveries.indexOf(delivery,0),1)" type="button" class="position-absolute close" style="top: 1px !important; right: 0px;">
			<i class="las la-times-square"></i>
		</button>
		<div class="form-group row w-fix-800 mt-2">
			<label class="col-sm-3 col-form-label text-sm-right font-weight-light">名稱</label>
			<div class="col-sm-9">
				<div class="form-inline text-muted">
					<input  [(ngModel)]="delivery.name"  [name]="'d-name' + i" type="text" required maxlength="20" placeholder="ex: 本島運費" autocomplete="off" class="form-control w-fix-40">
					<span class="ml-1"><strong class="">{{delivery.name.length}}</strong><span class="">/20</span></span>
				</div>
			</div>
		</div>
		<div class="form-group row w-fix-800">
			<label class="col-sm-3 col-form-label text-sm-right font-weight-light">價格</label>
			<div class="col-sm-9">
				<div class="form-inline text-muted">
					<input  [(ngModel)]="delivery.price"  [name]="'d-price' + i" type="number" required   autocomplete="off" class="form-control w-fix-40">
				</div>
			</div>
		</div>
	</div>


	<div class="d-flex justify-content-center">
		<button  (click)="addDelivery()" type="button" class="btn btn-outline-primary"><i class="lar la-plus"></i>
			新增運送</button>
	</div>


	<hr>

	<div class="d-flex justify-content-center my-5">
		<button (click)="save()" [disabled]="form.invalid || store.deliveries.length === 0" type="submit" class="btn btn-lg btn-primary px-5 mx-1">儲存</button>
	</div>

</form>











