import { Component, OnInit } from '@angular/core';
import {ShopService} from '../../../../services/shop.service';
import {NotifyService} from '../../../../../shared/services/notify.service';
import {FAgent} from '../../../../../firebase/models/agent';

@Component({
  selector: 'app-shop-agent-setting-page',
  templateUrl: './shop-agent-setting-page.component.html',
  styleUrls: ['./shop-agent-setting-page.component.styl']
})
export class ShopAgentSettingPageComponent implements OnInit {

  agent: FAgent;
  constructor(private ss: ShopService,
              private notify: NotifyService) { }

  ngOnInit(): void {
    this.agent = this.ss.currentShop.agent;
  }
  save() {
    this.ss.currentShopDoc.update({
      agent : this.agent
    }).then(() => {
      this.notify.pop('已儲存');
    });
  }
}
