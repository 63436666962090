<ng-container *ngIf="loading">
	<app-progress-spinner></app-progress-spinner>
</ng-container>

<ng-container *ngIf="!loading">



	<div class="d-flex justify-content-between align-items-center mb-2">
		<h2 class="mb-0">加值服務狀態</h2>
	</div>
	<hr>
	<table class="table table-hover table-layout-fixed">
		<thead class="thead-light">
		<tr>
			<th class="">
      <span class="">
        服務
      </span>
			</th>
			<th class="">
      <span class="">
        到期日
      </span>
			</th>
			<th class="">
      <span class="">
        狀態
      </span>
			</th>
		</tr>
		</thead>
		<tbody>
		<tr  class="">
			<td>
	      <span class="">
		      活動
	      </span>
			</td>
			<td>
	      <span class=""><!---->
		      {{ss.chargePlan.serviceExpireDate?.event?.toDate().toLocaleDateString()}}
	      </span>
			</td>
			<td>
	      <span class=""><!---->
		      {{this.ss.chargePlan.permission.event ? '已啟用' : '未啟用'}}
	      </span>
			</td>
		</tr>
		<tr  class="">
			<td>
	      <span class="">
		      課程
	      </span>
			</td>
			<td>
	      <span class=""><!---->
		      {{ss.chargePlan.serviceExpireDate?.course?.toDate().toLocaleDateString()}}
				</span>
			</td>
			<td>
	      <span class=""><!---->
		      {{this.ss.chargePlan.permission.course ? '已啟用' : '未啟用'}}
	      </span>
			</td>
		</tr>
		<tr  class="">
			<td>
	      <span class="">
		      商店
	      </span>
			</td>
			<td>
	      <span class=""><!---->
		      {{ss.chargePlan.serviceExpireDate?.store?.toDate().toLocaleDateString()}}
				</span>
			</td>
			<td>
	      <span class=""><!---->
		      {{this.ss.chargePlan.permission.store ? '已啟用' : '未啟用'}}
	      </span>
			</td>
		</tr>
		<tr  class="">
			<td>
	      <span class="">
		      集點卡
	      </span>
			</td>
			<td>
	      <span class=""><!---->
		      {{ss.chargePlan.serviceExpireDate?.rewardCard?.toDate().toLocaleDateString()}}
				</span>
			</td>
			<td>
	      <span class=""><!---->
		      {{this.ss.chargePlan.permission.rewardCard ? '已啟用' : '未啟用'}}
	      </span>
			</td>
		</tr>
    <tr  class="">
      <td>
	      <span class="">
		      預約服務
	      </span>
      </td>
      <td>
	      <span class=""><!---->
          {{ss.chargePlan.serviceExpireDate?.booking?.toDate().toLocaleDateString()}}
				</span>
      </td>
      <td>
	      <span class=""><!---->
          {{this.ss.chargePlan.permission.booking ? '已啟用' : '未啟用'}}
	      </span>
      </td>
    </tr>
		</tbody>
	</table>



	<div class="d-flex justify-content-between align-items-center mb-2">
		<h2 class="mb-0">購買加值服務</h2>
	</div>
	<hr>
	<table class="table table-hover table-layout-fixed">
		<thead class="thead-light">
		<tr>
			<th class="">
      <span class="">
        服務
      </span>
			</th>
			<th class="">
      <span class="">
        天數
      </span>
			</th>
			<th class="">
      <span class="">
        價格
      </span>
			</th>
			<th class="">
      <span class="">
        動作
      </span>
			</th>
		</tr>
		</thead>
		<tbody>
		<tr *ngFor="let plan of plans" class="">
			<td>
      <span class=""><!---->
	      {{getTypeName(plan.payload.doc.data().feature)}}
      </span>
			</td>
			<td>
      <span class=""><!---->
	      {{plan.payload.doc.data().days}}
      </span>
			</td>
			<td>
      <span class=""><!---->
	      {{plan.payload.doc.data().price}}
      </span>
			</td>
			<td>
				<a class="text-primary" href="javascript:void(0)" (click)="buyPlan(plan)"><!---->
					購買方案
				</a>
			</td>
		</tr>
		</tbody>
	</table>

</ng-container>
