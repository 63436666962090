import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';

import {StorageService} from '../../../../../../../shared/services/storage.service';
import {UploadImageDialogData} from '../../../../../../../shared/ui/upload-image-dialog/upload-image-dialog.component';
import {FPlace} from '../../../../../../../firebase/models/cardMessage';
@Component({
  selector: 'app-card-place',
  templateUrl: './card-place.component.html',
  styleUrls: ['./card-place.component.scss']
})
export class CardPlaceComponent implements OnInit {

  @Input()
  pages: FPlace[];
  @Output()
  pagesChange: EventEmitter<FPlace[]> = new EventEmitter<FPlace[]>();
  constructor(private ss: StorageService) { }
  ngOnInit(): void {

  }
  pageChanged() {
    this.pagesChange.emit(this.pages);
  }
  openUploadImageDialog(index: number): void {
    const info: UploadImageDialogData = {height: 640, type: 'image', width: 640};
    this.ss.openUploadDialog(info).afterClosed().subscribe(result => {
      if (result) {
        (this.pages[index] as FPlace).images[0] = result;
      }
    });
  }
  addPage() {
    this.pages.push(NewPlaceCard());
    this.pageChanged();
  }
}
export function NewPlaceCard(): FPlace {
  return {
    banner: '',  // 宣傳標語 10
    bannerColor: '666F86',  // 宣傳標語顏色
    images: [''],  // 圖片
    header: '',  // 頁面標題 20
    address: '',  // 地址 60
    addressInfoHead: 'hours',  // 相關資訊時間/價格 hours , price
    addressInfoWord: '',  // 相關資訊內容
    actions: [
      {
        text: '',
        type: 0,
        content: ''
      },
      {
        text: '',
        type: 0,
        content: ''
      }],  // 動作
  };
}
