<main class="w-100 d-flex flex-column position-relative bg-white" style="min-width: calc(100% - 280px);">
	<div class="m-5">
		<div class="container h-70v d-flex justify-content-center align-items-center">
			<div class="text-center">
				<h3 *ngIf="loading">讀取中...</h3>
				<h3 *ngIf="invalid">此邀請已失效，請聯絡官方帳號管理員</h3>
				<ng-container *ngIf="valid">
					<h3>成員邀請</h3>
					<p class="text-pre-line">「{{shop?.displayName}}」官方帳號邀請您加入成為管理員，敬請確認。 若此官方帳號與您無關，請點選「取消」。
					</p>
					<div class="mt-4">
						<button (click)="onAccept()" type="button" class="w-fix-320 btn btn-primary btn-lg">接受邀請</button>
					</div>
					<div class="mt-3">
						<button (click)="toHomePage()" type="button" class="w-fix-320 btn btn-outline-secondary btn-lg">取消</button>
					</div>
				</ng-container>
			</div>
		</div>
	</div>
</main>
