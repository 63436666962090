import { Component, OnInit } from '@angular/core';
import { getPlanName } from 'src/app/firebase/models/chargePlan';
import {AngularFirestore} from '@angular/fire/firestore';
import {ShopService} from '../../../../services/shop.service';
import {NotifyService} from '../../../../../shared/services/notify.service';
import {RouteService} from '../../../../../shared/services/route.service';

@Component({
  selector: 'app-shop-billing-purchase-page',
  templateUrl: './shop-billing-purchase-page.component.html',
  styleUrls: ['./shop-billing-purchase-page.component.styl']
})
export class ShopBillingPurchasePageComponent implements OnInit {

  getPlanName = getPlanName;
  select = 0;
  constructor(public ss: ShopService,
              private db: AngularFirestore,
              private router: RouteService,
              private notify: NotifyService) { }

  ngOnInit(): void {
    if (this.ss.chargePlan.ordered) {
      this.notify.pop('您本月已經完成選購方案，如需修改方案，請於下月重新購買，謝謝！');
    }
  }
  order() {
    if (!this.ss.chargePlan.ordered) {
      this.db.collection('orders').add({
        shopId: this.ss.currentShop.uid,
        status: this.select,
        timestamp: new Date()
      }).then(() => {
        this.router.navigateTo('/Billing/Payment');
      });
    }
  }

}
