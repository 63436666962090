import Timestamp = firebase.firestore.Timestamp;
import * as firebase from 'firebase';
import dr = firebase.firestore.DocumentReference;
import {DocumentReference} from '@angular/fire/firestore';
import {FShop} from './shop';

export interface FPointCard {
  point: number;
  used: boolean;
  endTime: Date|Timestamp;
  timestamp: Date|Timestamp;
  shop?: FShop;
}

export interface FShopPointRecord {
  timestamp: Date|Timestamp;
  type: FShopPointRecordType;
  ref?: DocumentReference | dr;
  point: number;
}
export enum FShopPointRecordType {
  加值,
  抵用,
}
