import { Component, OnInit } from '@angular/core';
import {FOrder} from '../../../../../firebase/models/order';
import {ShopService} from '../../../../services/shop.service';
import {DocumentChangeAction} from '@angular/fire/firestore/interfaces';

@Component({
  selector: 'app-shop-billing-order-list-page',
  templateUrl: './shop-billing-order-list-page.component.html',
  styleUrls: ['./shop-billing-order-list-page.component.styl']
})
export class ShopBillingOrderListPageComponent implements OnInit {

  order: DocumentChangeAction<FOrder>[] = [];
  orderCurrentPage: DocumentChangeAction<FOrder>[] = [];
  constructor(private ss: ShopService) { }

  ngOnInit(): void {
    this.ss.currentShopDoc.collection<FOrder>('planOrders').snapshotChanges().subscribe(orders => {
      console.log(orders);
      this.order = orders;
    });
  }

}
