<div class="d-flex justify-content-between align-items-center mb-2"><h2 class="mb-0">商品設定</h2>
</div>
<hr>

<form #form="ngForm">
	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">上架</label>
		<div class="col-sm-9 d-flex flex-md-column justify-content-center">
			<mat-slide-toggle class="align-items-center d-flex mt-2"
			                  [checked]="product.enabled"
			                  (change)="product.enabled = $event.source.checked">
				{{product.enabled ? '上架':'未上架'}}
			</mat-slide-toggle>
		</div>
	</div>

	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">商品名稱</label>
		<div class="col-sm-9">
			<div class="form-inline text-muted">
				<input  [(ngModel)]="product.name" name="name" type="text" required maxlength="30" placeholder="商品名稱" autocomplete="off" class="form-control w-fix-40">
				<span class="ml-1"><strong class="">{{product.name.length}}</strong><span class="">/30</span></span>
			</div>
		</div>
	</div>

	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">商品類別</label>
		<div class="col-sm-9">
			<div class="kv-select form-group d-flex align-items-end">
				<div class="flex-grow-1">
					<div class="input-group">
						<tag-input [theme]="'foundation-theme'"   [secondaryPlaceholder]= "'選取類別'" [placeholder]="'選取類別'"  [(ngModel)]="categoriesForDisplay" name="tag" [onlyFromAutocomplete] = "true" >
							<tag-input-dropdown [autocompleteItems]= "categories" [showDropdownIfEmpty]= "true">
							</tag-input-dropdown>
						</tag-input>
					</div>
				</div>
			</div>
		</div>
	</div>


	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">商品圖片
		</label>
		<div class="col-sm-9">
			<div class="field w-fix-500">
				<div class="control">
					<button (click)="openImgDialog()" type="button" class="btn btn-outline-secondary">編輯</button>
				</div>
				<img [src]="product.photoUrl" style="width: 150px">
				<input class="d-none" [(ngModel)]="product.photoUrl" required name="img">
			</div>
		</div>
	</div>

	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">優惠價格開始時間</label>
		<div class="col-sm-9">
			<div class="form-inline text-muted">
				<input class="form-control w-80 mr-2 " style="width: 400px !important;"   [(ngModel)]="product.pieceStart" name="startTime"   [owlDateTime]="scheduleTimePicker"  [owlDateTimeTrigger]="scheduleTimePicker" placeholder="開始時間" autocomplete="off">
				<span [owlDateTimeTrigger]="scheduleTimePicker">
        <i class="fas fa-calendar-alt"></i>
				</span>
				<owl-date-time  #scheduleTimePicker></owl-date-time>
			</div>
		</div>
	</div>

	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">優惠價格結束時間</label>
		<div class="col-sm-9">
			<div class="form-inline text-muted">
				<input class="form-control w-80 mr-2 " style="width: 400px !important;"   [(ngModel)]="product.pieceEnd" name="endTime"  [owlDateTime]="scheduleTimePicker2"  [owlDateTimeTrigger]="scheduleTimePicker2" placeholder="結束時間" autocomplete="off">
				<span [owlDateTimeTrigger]="scheduleTimePicker2">
        <i class="fas fa-calendar-alt"></i>
				</span>
				<owl-date-time  #scheduleTimePicker2></owl-date-time>
			</div>
		</div>
	</div>

	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">商品詳情</label>
		<div class="col-sm-9">
<!--			<div [froalaEditor]="froala.options" [(froalaModel)]="product.desc"></div>-->
      <angular-editor [(ngModel)]="product.desc" [config]="froala.config"  placeholder="輸入商品詳情" name="desc" required></angular-editor>
			<!--<angular-editor [config]="editorConfig" [placeholder]="'輸入商品詳情'" [(ngModel)]="product.desc "></angular-editor>-->
		</div>
	</div>


	<hr>
	<div class="d-flex justify-content-between align-items-center mb-2"><h3 class="mb-0">商品規格</h3>
	</div>

	<div *ngFor="let spec of product.spec index as i" class="border position-relative w-fix-880 my-4">
		<button *ngIf="product.spec.length <= 1" [disabled]="product.spec.length <= 1" (click)="product.spec.splice(product.spec.indexOf(spec,0),1)" type="button" class="position-absolute close" style="top: 1px !important; right: 0px;">
			<i class="las la-times-square"></i>
		</button>
		<div class="form-group row w-fix-800">
			<label class="col-sm-3 col-form-label text-sm-right font-weight-light">名稱</label>
			<div class="col-sm-9">
				<div class="form-inline text-muted">
					<input  [(ngModel)]="spec.name" [name]="'specName' + i" type="text" required maxlength="30" placeholder="商品名稱" autocomplete="off" class="form-control w-fix-40">
					<span class="ml-1"><strong class="">{{spec.name.length}}</strong><span class="">/30</span></span>
				</div>
			</div>
		</div>
		<div class="form-group row w-fix-800">
			<label class="col-sm-3 col-form-label text-sm-right font-weight-light">價格</label>
			<div class="col-sm-9">
				<div class="form-inline text-muted">
					<input  [(ngModel)]="spec.price" [name]="'specPrice' + i" type="number" required   autocomplete="off" class="form-control w-fix-40">
				</div>
			</div>
		</div>
		<div class="form-group row w-fix-800">
			<label class="col-sm-3 col-form-label text-sm-right font-weight-light">優惠價</label>
			<div class="col-sm-9">
				<div class="form-inline text-muted">
					<input  [(ngModel)]="spec.piecePrice" [name]="'piecePrice' + i" type="number" required   autocomplete="off" class="form-control w-fix-40">
				</div>
			</div>
		</div>
		<div class="form-group row w-fix-800">
			<label class="col-sm-3 col-form-label text-sm-right font-weight-light">存貨</label>
			<div class="col-sm-9">
				<div class="form-inline text-muted">
					<input  [(ngModel)]="spec.stock" [name]="'pieceStock' + i" type="number" required   autocomplete="off" class="form-control w-fix-40">
				</div>
			</div>
		</div>

	</div>
	<div class="d-flex justify-content-center">
		<button  (click)="addSpec()" type="button" class="btn btn-outline-primary"><i class="lar la-plus"></i>
			新增規格</button>
	</div>


	<hr>


	<div class="d-flex justify-content-center my-5">
		<button [disabled]="form.invalid || product.spec.length === 0" (click)="save()" type="submit" class="btn btn-lg btn-primary px-5 mx-1">儲存</button>
	</div>

</form>











