import { Component, OnInit } from '@angular/core';
import {AngularFirestore, AngularFirestoreDocument, DocumentChangeAction} from '@angular/fire/firestore';
import {ActivatedRoute} from '@angular/router';
import {NotifyService} from '../../../../../../shared/services/notify.service';
import {RouteService} from '../../../../../../shared/services/route.service';
import {ShopService} from '../../../../../services/shop.service';
import {StorageService} from '../../../../../../shared/services/storage.service';
import {FroalaService} from '../../../../../../shared/services/froala.service';
import {datetimeFix} from '../../../../../../firebase/extensions/datetimeFixed';
import {UploadImageDialogData} from '../../../../../../shared/ui/upload-image-dialog/upload-image-dialog.component';
import { faWindowClose } from '@fortawesome/free-regular-svg-icons';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import {FCourse} from '../../../../../../firebase/models/course';
import {TagModel} from '../../../../../../shared/models/tags';
import {ModalService} from '../../../../../../shared/services/modal.service';
import {FQuestionnaire} from '../../../../../../firebase/models/questionnaire';
import {IconService} from '../../../../../../shared/services/icon.service';
@Component({
  selector: 'app-shop-event-edit-page',
  templateUrl: './shop-event-edit-page.component.html',
  styleUrls: ['./shop-event-edit-page.component.styl']
})
export class ShopEventEditPageComponent implements OnInit {

  minDate = new Date();
  faWindowClose = faWindowClose;
  faCalendarAlt = faCalendarAlt;
  topics = [];
  create = false;
  eventDoc: AngularFirestoreDocument;
  event: FCourse = {
    createTime: new Date(), shareUrl: '',
    attendPle: 0,
    address: '',
    desc: '',
    endTime: undefined,
    eventEndTime: undefined,
    eventStartTime: undefined,
    eventUrl: '',
    maxPle: 0,
    name: '',
    note: '',
    photoUrl: '',
    price: 0,
    shop: undefined,
    startTime: undefined,
    topics: [],
    type: 0
  };
  tag: TagModel[] = [];
  constructor(private activatedRoute: ActivatedRoute,
              private notify: NotifyService,
              private router: RouteService,
              public ss: ShopService,
              private img: StorageService,
              private db: AngularFirestore,
              public froala: FroalaService,
              private ms: ModalService,
              public icon: IconService,
  ) { }

  ngOnInit(): void {
    this.getTopics();
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    if (id === 'Create') {
      this.create = true;
    } else  {
      this.create = false;
      this.eventDoc = this.ss.currentShopDoc.collection<FCourse>('events').doc(id);
      this.eventDoc.get().subscribe(event => {
        if (event.exists) {
          // @ts-ignore
          this.event = event.data();
          if (this.event.tag) {
            this.tag = this.event.tag.map(x => this.ss.tagIdToTagForDisplay(x));
          }
          datetimeFix(this.event);
        } else {
          this.notify.pop('活動不存在');
          this.router.navigateTo('/Event');
        }
      });
    }
  }
  onTagChange() {
    this.event.tag = this.tag.map(x => x.value);
  }
  openImgUploadDialog() {
    const info: UploadImageDialogData = {height: 350, type: 'image', width: 640};
    this.img.openUploadDialog(info).afterClosed().subscribe(result => {
      if (result) {
        this.event.photoUrl = result;
      }
    });
  }
  openQnrPicker() {
    this.ms.openQnrPicker().afterClosed().subscribe((qnr: DocumentChangeAction<FQuestionnaire>) => {
      if (qnr) {
        this.event.qnrRef = qnr.payload.doc.ref;
        this.event.qnrData = qnr.payload.doc.data();
      }
    });
  }
  getTopics() {
    this.db.collection('system').doc('info').get().subscribe(x => {
      this.topics = x.data().eventTopics;
    });
  }
  save() {
    this.event.shop = this.ss.shopInfo;
    if (this.create) {
      this.event.createTime = new Date();
      this.ss.currentShopDoc.collection<FCourse>('events').add(this.event).then(() => this.router.navigateTo('/Event/Event'));
    } else {
      this.eventDoc.update(this.event).then(() => this.router.navigateTo('/Event/Event'));
    }
  }

}
