import { Component, OnInit } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';

@Component({
  selector: 'app-shop-not-support-page',
  templateUrl: './shop-not-support-page.component.html',
  styleUrls: ['./shop-not-support-page.component.styl']
})
export class ShopNotSupportPageComponent implements OnInit {

  constructor(private db: AngularFirestore) { }

  htmlContent = '';
  ngOnInit(): void {
    this.db.collection('system').doc('notSupport').valueChanges().subscribe(x => {
      // @ts-ignore
      this.htmlContent = x.content;
    });
  }

}
