import { Component, OnInit } from '@angular/core';
import {FCategory, FStoreProduct} from '../../../firebase/models/store';
import {ShopService} from '../../services/shop.service';
import {MatDialogRef} from '@angular/material/dialog';
import {AngularFirestore, DocumentChangeAction} from '@angular/fire/firestore';

@Component({
  selector: 'app-store-product-category-picker-dialog',
  templateUrl: './store-product-category-picker-dialog.component.html',
  styleUrls: ['./store-product-category-picker-dialog.component.styl']
})
export class StoreProductCategoryPickerDialogComponent implements OnInit {

  categories: DocumentChangeAction<FCategory>[] = [];
  constructor(public ss: ShopService,
              public dialogRef: MatDialogRef<StoreProductCategoryPickerDialogComponent>,
              private db: AngularFirestore) { }

  ngOnInit(): void {
    this.db.collection('stores').doc(this.ss.currentShop.uid).collection<FCategory>('categories').snapshotChanges().subscribe(cat => {
      this.categories = cat;
    });
  }
  onSelect(category: DocumentChangeAction<FCategory>) {
    this.dialogRef.close(category);
  }
}
