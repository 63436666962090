<div id="container" class="bg-light d-flex flex-column">
  <!--          <app-second-nav></app-second-nav>-->
  <div id="wrapper" class="d-flex align-items-stretch flex-fill">
    <aside id="menu" class="overflow-auto w-fix-280 flex-shrink-0 flex-grow-0 position-relative border-right">
      <div class="container-fluid bg-light py-3 min-vh-100">
        <ul class="nav flex-column">
          <li class="nav-item"><a  class="nav-link d-inline-block">群發訊息</a>
            <ul class="nav flex-column ml-3">
              <li class="nav-item"><a routerLink="Message" routerLinkActive="router-link-exact-active active" class="nav-link d-inline-block">訊息一覽</a></li>
            </ul>
          </li>
          <li class="nav-item"><a routerLink="Welcome" routerLinkActive="router-link-exact-active active" class="nav-link d-inline-block">加入好友的歡迎訊息</a></li>
          <li class="nav-item"><a routerLink="AutoResponse" routerLinkActive="router-link- exact-active active" class="nav-link d-inline-block">自動回應訊息</a></li>
          <li class="nav-item"><a routerLink="RichMessage" routerLinkActive="router-link- exact-active active" class="nav-link d-inline-block">圖文訊息</a>
            <hr class="my-2">
          </li>
<!--          <li class="nav-item"><a routerLink="CardMessage" routerLinkActive="router-link- exact-active active" class="nav-link d-inline-block">多頁訊息</a>-->
<!--          </li>-->

          <li class="nav-item"><a routerLink="RichMenu" routerLinkActive="router-link- exact-active active" class="nav-link d-inline-block">圖文選單</a></li>
          <li class="nav-item"><a routerLink="Coupon" routerLinkActive="router-link- exact-active active" class="nav-link d-inline-block">優惠券</a></li>
          <li class="nav-item"><a routerLink="MeetGift" routerLinkActive="router-link-exact-active active" class="nav-link d-inline-block">見面禮</a></li>
          <li class="nav-item"><a routerLink="Questionnaire" routerLinkActive="router-link-exact-active active" class="nav-link d-inline-block">問卷調查</a></li>
          <li class="nav-item"><a  class="nav-link d-inline-block">集點卡</a>
            <ul class="nav flex-column ml-3">
              <li class="nav-item"><a routerLink="RewardCard" routerLinkActive="router-link-exact-active active" class="nav-link d-inline-block">集點卡一覽</a></li>
              <li class="nav-item"><a routerLink="RewardCode" routerLinkActive="router-link-exact-active active"  class="nav-link d-inline-block">印製條碼
              </a></li>
            </ul>
          </li>
          <li class="nav-item"><a routerLink="Share" routerLinkActive="router-link-exact-active active" class="nav-link d-inline-block">加入好友</a></li>
        </ul>
      </div>

    </aside>
    <div id="contents" class="w-100 d-flex flex-column position-relative bg-white" style="min-width: calc(100% - 280px);">
      <main class="m-5" >
        <router-outlet *ngIf="(ss.chargePlanLoaded| async ) && (ss.shopLoaded | async)"></router-outlet>
      </main>
    </div>

  </div>
</div>
