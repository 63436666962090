import { Component, OnInit } from '@angular/core';
import {DocumentChangeAction} from '@angular/fire/firestore';
import {ShopService} from '../../../../services/shop.service';
import {NotifyService} from '../../../../../shared/services/notify.service';
import {RouteService} from '../../../../../shared/services/route.service';

@Component({
  selector: 'app-shop-rich-menu-list-page',
  templateUrl: './shop-rich-menu-list-page.component.html',
  styleUrls: ['./shop-rich-menu-list-page.component.styl']
})
export class ShopRichMenuListPageComponent implements OnInit {

  richMenus: DocumentChangeAction<any>[];
  constructor(private rs: RouteService,
              public ss: ShopService,
              private notify: NotifyService) { }

  ngOnInit() {
    this.getRichMenus();
  }
  getRichMenus() {
    this.ss.currentShopDoc.collection('richs').snapshotChanges().subscribe(richs => {
      console.log(richs);
      this.richMenus = richs;
    });
  }
  onSelect(rich: DocumentChangeAction<any>) {
    this.ss.currentShopDoc.update({
      advRef: rich.payload.doc.ref
    }).then(() => {
      this.notify.pop('已儲存');
    });
  }

}
