<div class="d-flex justify-content-between align-items-center mb-2"><h2 class="mb-0">月費報表</h2>
</div>
<hr>
<table class="table table-hover table-layout-fixed">
	<thead class="thead-light">
	<tr>
		<th class="">
      <span class="">
        計費期間
      </span>
		</th>
		<th class="">
      <span class=""><!---->
        訊息服務費
      </span>
		</th>
		<th class="">
      <span ><!---->
        分潤金額（含稅)
      </span>
		</th>
	</tr>
	</thead>
	<tbody>
	<tr *ngFor="let News of currentPage" class="">
		<td>
			<a [routerLink]="News.month" class=""><!---->
				{{News.month}}
			</a>
		</td>
		<td>
      <span class=""><!---->
	      {{News.totalFee}}
      </span>
		</td>
		<td>
      <span class=""><!---->
      </span>
		</td>
	</tr>
	</tbody>
</table>
<hr>
<app-list-paginator [data]="NewsListSource" (listChanged)="currentPage = $event"></app-list-paginator>
