<div class="d-flex justify-content-between align-items-center mb-2">
	<h2 class="mb-0">訂單記錄</h2>
</div>
<hr>

<table class="table table-hover table-layout-fixed">
	<thead class="thead-light">
	<tr>
		<th class="">
      <span class="">
        產生時間
      </span>
		</th>
		<th class="">
      <span class=""><!---->
        方案
      </span>
		</th>
		<th class="">
      <span class=""><!---->
        金額
      </span>
		</th>
	</tr>
	</thead>
	<tbody>
	<ng-container *ngFor="let bill of order" >
		<tr class="">
			<td>
      <span class=""><!---->
	      {{bill.payload.doc.data().createTime.toDate().toLocaleString()}}
      </span>
			</td>
			<td>
      <span class=""><!---->
	      test
      </span>
			</td>
			<td>
      <span class=""><!---->
	      {{bill.payload.doc.data().price}}
      </span>
			</td>
		</tr>

	</ng-container>
	</tbody>
</table>
<hr>
<app-list-paginator [data]="order" (listChanged)="orderCurrentPage = $event"></app-list-paginator>



<table class="table table-hover table-layout-fixed">
	<thead class="thead-light">
	<tr>
		<th class="">
      <span class="">
        產生時間
      </span>
		</th>
		<th class="">
      <span class=""><!---->
        方案
      </span>
		</th>
		<th class="">
      <span class=""><!---->
        金額
      </span>
		</th>
	</tr>
	</thead>
	<tbody>
	<ng-container *ngFor="let bill of order" >
		<tr *ngIf="bill.payload.doc.data().paid" class="">
			<td>
      <span class=""><!---->
	      {{bill.payload.doc.data().createTime.toDate().toLocaleString()}}
      </span>
			</td>
			<td>
      <span class=""><!---->
	      test
      </span>
			</td>
			<td>
      <span class=""><!---->
	      {{bill.payload.doc.data().price}}
      </span>
			</td>
		</tr>

	</ng-container>
	</tbody>
</table>
<hr>
<app-list-paginator [data]="order" (listChanged)="orderCurrentPage = $event"></app-list-paginator>

