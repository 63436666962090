<div class="d-flex justify-content-between align-items-center mb-2">
	<h2 class="mb-0">優惠方案</h2>
</div>
<hr>
<p class="mb-0">
  <span>
      您目前的好友人數共：
  </span>
	<span style="color: #1e7e34">
    {{ss.currentShop.follower}}
  </span>
	<span>
    人，使用方案為：
  </span>
	<span style="color: #1e7e34">
      {{getPlanName(ss.chargePlan.status)}}
  </span>
</p>


<p >
	請根據您目標使用的訊息發送量，選擇最適合您的優惠方案
</p>
<p class="mb-4">
  <span style="color: #1e7e34">
      代理商客戶將享受升級服務（選購1則升級3則，選購3則升級吃到飽）
  </span>
</p>


<table class="table table-hover table-layout-fixed">
	<colgroup>
		<col width="5%">
	</colgroup>
	<thead class="thead-light">
	<tr>
		<th class="">
      <span class="">
      </span>
		</th>
		<th class="">
      <span class="">
        目標好友上限*
      </span>
		</th>
		<th class="">
      <span class=""><!---->
          500人
      </span>
		</th>
		<th class="">
      <span class=""><!---->
          20,000人
      </span>
		</th>
		<th class="">
      <span class="cursor-pointer"><!---->
          50,000人
      </span>
		</th>
		<th class="">
      <span class=""><!---->
          80,000人
      </span>
		</th>
		<th class="">
      <span class=""><!---->
          80,001人以上
      </span>
		</th>
	</tr>
	</thead>
	<tbody>

	<tr class="">
		<td>
			<input type="radio" [(ngModel)]="select" [value]="1">
		</td>
		<td>
      <span class=""><!---->
        每日免費訊息數*1則
      </span>
		</td>
		<td>
      <span class=""><!---->
        NT$0
      </span>
		</td>
		<td>
      <span class=""><!---->
        NT$798
      </span>
		</td>
		<td>
      <span class=""><!---->
        NT$1,888
      </span>
		</td>
		<td>
      <span class=""><!---->
        NT$5,888
      </span>
		</td>
		<td>
      <span class=""><!---->
        NT$5,888＋每10,000人數＊500元
        （80,001人以上始計人數
      </span>
		</td>

	</tr>
	<tr class="">
		<td>
			<input type="radio" [(ngModel)]="select" [value]="3">
		</td>
		<td>
      <span class=""><!---->
        每日免費訊息數*3則
      </span>
		</td>
		<td>
      <span class=""><!---->
        NT$0
      </span>
		</td>
		<td>
      <span class=""><!---->
        NT$998
      </span>
		</td>
		<td>
      <span class=""><!---->
        NT$2,288
      </span>
		</td>
		<td>
      <span class=""><!---->
        NT$7,288
      </span>
		</td>
		<td>
      <span class=""><!---->
        NT$7,288＋每10,000人數＊1000元
        （80,001人以上始計人數）
      </span>
		</td>

	</tr>
	<tr class="">
		<td>
			<input type="radio" [(ngModel)]="select" [value]="999">
		</td>
		<td>
      <span class=""><!---->
        訊息吃到飽
      </span>
		</td>
		<td>
      <span class=""><!---->
        暫不提供
      </span>
		</td>
		<td>
      <span class=""><!---->
        NT$1,698
      </span>
		</td>
		<td>
      <span class=""><!---->
        NT$3,888
      </span>
		</td>
		<td>
      <span class=""><!---->
        NT$10,888
      </span>
		</td>
		<td>
      <span class=""><!---->
        NT$10,888＋
        每10,000人數＊1500元
        （80,001人以上始計人數）
      </span>
		</td>

	</tr>
	</tbody>
</table>
<hr>
<div class="text-sm-left text-muted">
	<p class="my-2 mb-0">
		超過每日免費訊息數以上的加購訊息每則0.2元
	</p>
	<p class="mb-0">
		選購方案、加購訊息費用將納入下期帳單請款
	</p>
</div>
<div class="d-flex justify-content-center my-5">
	<button [disabled]="ss.chargePlan.ordered" (click)="order()" class="btn btn-lg btn-primary px-5 mx-1">購買方案</button>
</div>
