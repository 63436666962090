import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {NotifyService} from '../../../../../../shared/services/notify.service';
import {RouteService} from '../../../../../../shared/services/route.service';
import {ShopService} from '../../../../../services/shop.service';
import {FCourse} from '../../../../../../firebase/models/course';
import { faWindowClose } from '@fortawesome/free-regular-svg-icons';
import {StorageService} from '../../../../../../shared/services/storage.service';
import {UploadImageDialogData} from '../../../../../../shared/ui/upload-image-dialog/upload-image-dialog.component';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import {AngularFirestore, AngularFirestoreDocument, DocumentChangeAction} from '@angular/fire/firestore';
import {datetimeFix} from '../../../../../../firebase/extensions/datetimeFixed';
import {FroalaService} from '../../../../../../shared/services/froala.service';
import {TagModel} from '../../../../../../shared/models/tags';
import {FQuestionnaire} from '../../../../../../firebase/models/questionnaire';
import {ModalService} from '../../../../../../shared/services/modal.service';
import {IconService} from '../../../../../../shared/services/icon.service';

@Component({
  selector: 'app-shop-course-edit-page',
  templateUrl: './shop-course-edit-page.component.html',
  styleUrls: ['./shop-course-edit-page.component.styl']
})
export class ShopCourseEditPageComponent implements OnInit {

  minDate = new Date();
  faWindowClose = faWindowClose;
  faCalendarAlt = faCalendarAlt;
  topics = [];
  create = false;
  courseDoc: AngularFirestoreDocument;
  course: FCourse = {
    createTime: new Date(), shareUrl: '',
    attendPle: 0,
    address: '',
    desc: '',
    endTime: undefined,
    eventEndTime: undefined,
    eventStartTime: undefined,
    eventUrl: '',
    maxPle: 0,
    name: '',
    note: '',
    photoUrl: '',
    price: 0,
    shop: undefined,
    startTime: undefined,
    topics: [],
    type: 0
  };
  tag: TagModel[] = [];
  constructor(private activatedRoute: ActivatedRoute,
              private notify: NotifyService,
              private router: RouteService,
              public ss: ShopService,
              private img: StorageService,
              private db: AngularFirestore,
              public froala: FroalaService,
              private ms: ModalService,
              public icon: IconService,
  ) { }

  ngOnInit(): void {
    this.getTopics();
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    if (id === 'Create') {
      this.create = true;
    } else  {
      this.create = false;
      this.courseDoc = this.ss.currentShopDoc.collection<FCourse>('courses').doc(id);
      this.courseDoc.get().subscribe(course => {
        if (course.exists) {
          // @ts-ignore
          this.course = course.data();
          if (this.course.tag) {
            this.tag = this.course.tag.map(x => this.ss.tagIdToTagForDisplay(x));
          }
          datetimeFix(this.course);
        } else {
          this.notify.pop('課程不存在');
          this.router.navigateTo('/Course');
        }
      });
    }
  }
  openImgUploadDialog() {
    const info: UploadImageDialogData = {height: 350, type: 'image', width: 640};
    this.img.openUploadDialog(info).afterClosed().subscribe(result => {
      if (result) {
        this.course.photoUrl = result;
      }
    });
  }
  openQnrPicker() {
    this.ms.openQnrPicker().afterClosed().subscribe((qnr: DocumentChangeAction<FQuestionnaire>) => {
      if (qnr) {
        this.course.qnrRef = qnr.payload.doc.ref;
        this.course.qnrData = qnr.payload.doc.data();
      }
    });
  }
  onTagChange() {
    this.course.tag = this.tag.map(x => x.value);
  }
  getTopics() {
    this.db.collection('system').doc('info').get().subscribe(x => {
      this.topics = x.data().courseTopics;
    });
  }
  save() {
    this.course.shop = this.ss.shopInfo;
    if (this.create) {
      this.course.createTime = new Date();
      this.ss.currentShopDoc.collection<FCourse>('courses').add(this.course).then(() => this.router.navigateTo('/Course/Course'));
    } else {
      this.courseDoc.update(this.course).then(() => this.router.navigateTo('/Course/Course'));
    }
  }

}
