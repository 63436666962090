import { Component, OnInit } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';

@Component({
  selector: 'app-shop-billing-discount-page',
  templateUrl: './shop-billing-discount-page.component.html',
  styleUrls: ['./shop-billing-discount-page.component.styl']
})
export class ShopBillingDiscountPageComponent implements OnInit {

  htmlContent = '';
  constructor(private db: AngularFirestore) { }

  ngOnInit() {
    this.db.collection('system').doc('discountPage').valueChanges().subscribe(x => {
      // @ts-ignore
      this.htmlContent = x.content;
    });
  }

}
