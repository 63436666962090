<div>
	<div class="d-flex justify-content-between align-items-center mb-2">
		<h2 class="mb-0">活動一覽</h2>
		<button routerLink="Create" type="button" class="btn btn-primary w-min-120">建立</button>
	</div>
	<hr>
	<section style="">
		<div>
			<table class="table table-layout-fixed">
				<thead class="thead-light">
				<tr>
					<th >
						<span>
              活動名稱
						</span>
					</th>
					<th >
						<span class="cursor-pointer">
	            建立時間
						</span>
					</th>
					<th style="width: 10%">
						<span class="cursor-pointer">
	            已報名人數
						</span>
					</th>
					<th >
						<span class="cursor-pointer">
	            邀請連結
						</span>
					</th>
					<th >
						<span class="cursor-pointer">
	            推薦人邀請連結
						</span>
					</th>
				</tr>
				</thead>
				<tbody>
				<tr *ngFor="let event of currentPage">
					<td>
						<a [routerLink]="event.payload.doc.id" class="d-flex align-items-center hljs-link text-dark">
							{{event.payload.doc.data().name}}
						</a>
					</td>
					<td>
						{{event.payload.doc.data().createTime.toDate().toLocaleString()}}
					</td>
					<td>
						<a [routerLink]="'Member/' + event.payload.doc.id" ngbTooltip="點擊查看" class="d-flex align-items-center hljs-link text-primary">
							{{event.payload.doc.data().attendPle}}

						</a>
					</td>
					<td>
						<a [href]="event.payload.doc.data().shareUrl" class="d-flex align-items-center hljs-link text-dark">
							{{event.payload.doc.data().shareUrl}}
						</a>
					</td>
					<td>
						<a [routerLink]="'Share/' + event.payload.doc.id" ngbTooltip="點擊新增推薦人連結" class="d-flex align-items-center hljs-link text-primary">
							建立
						</a>
					</td>
				</tr>
				</tbody>
			</table>
			<hr>
			<app-list-paginator [data]="events" (listChanged)="currentPage = $event"></app-list-paginator>
		</div>
	</section>
</div>
