<div class="d-flex justify-content-between align-items-center mb-2">
	<h2 class="mb-0">自動回應訊息</h2></div>
<p class="text-muted">若在此事先建立回應內容，當用戶傳訊息給您且符合設定條件時，系統將會自動回傳訊息。</p>
<hr>
<div class="btn-toolbar justify-content-between my-4">
	<div aria-label="First group" class="btn-group">
		<button (click)="delete()" *ngIf="!create" type="button" class="btn btn-secondary mr-2">刪除</button>
	</div>
	<div class="btn-group">
		<button (click)="save()" type="button" class="btn btn-primary">儲存</button>
	</div>
</div>
<hr class="my-4">
<form #form=ngForm>
	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">標題</label>
		<div class="col-sm-9">
			<div class="form-group mb-0 text-muted">
				<input [(ngModel)]="autoResponse.title" name="title" required placeholder="輸入標題" maxlength="20" type="text" autocomplete="off" class="form-control w-100 mr-2" aria-required="true" aria-invalid="false">
				<span class="float-right">
        <strong class="text-muted">{{autoResponse.title.length}}</strong>
        <span class="">/20</span></span>
			</div>
		</div>
	</div>
	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">狀態</label>
		<div class="col-sm-9">
			<div class="form-control-plaintext">
				<div class="kv-radio form-check custom-control custom-radio mr-4 form-check-inline">
					<input [(ngModel)]="autoResponse.enabled" id="statusTrue" name="statusTrue" type="radio" class="custom-control-input" [value]="true">
					<label for="statusTrue" class="custom-control-label">開啟</label>
				</div>
				<div class="kv-radio form-check custom-control custom-radio mr-4 mt-2 form-check-inline">
					<input [(ngModel)]="autoResponse.enabled" id="statusFalse" name="statusFalse" type="radio" class="custom-control-input" [value]="false">
					<label for="statusFalse" class="custom-control-label">關閉</label>
				</div>
			</div>
		</div>
	</div>
	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">指定日期或時間</label>
		<div class="col-sm-9">
			<div class="form-control-plaintext">
				<div class="kv-checkbox form-check custom-control custom-checkbox mb-3">
					<input [(ngModel)]="autoResponse.schedule"  id="wfbtwwocx8p" name="timeline" type="checkbox" class="custom-control-input">
					<label for="wfbtwwocx8p" class="custom-control-label">指定日期或時間</label>
				</div>
			</div>
			<div *ngIf="autoResponse.schedule" class="form-control-plaintext" style="margin-top: -1.25rem;">
				<div class="d-flex">
					<div class="kv-radio form-check custom-control custom-radio mt-3">
						<input #date (click)="autoResponse.startTime = null; autoResponse.endTime = null"  id="8r22uficr8s" [checked]="autoResponse.startDate || autoResponse.endDate" name="schedule" type="radio" [disabled]="!autoResponse.schedule" class="custom-control-input" value="period">
						<label for="8r22uficr8s" class="custom-control-label">
						</label>
					</div>
					<div class="form-inline mt-2">
						<input class="form-control w-80 mr-2 " style="width: 100px !important;" required [disabled]="!autoResponse.schedule || !date.checked" name="scheduleStartDate" [(ngModel)]="autoResponse.startDate" [owlDateTime]="scheduleStartDatePicker" [min]="minDate" [owlDateTimeTrigger]="scheduleStartDatePicker" placeholder="預約日期" autocomplete="off">
						<span [owlDateTimeTrigger]="scheduleStartDatePicker">
							<fa-icon class="text-muted" [icon]="faCalendarAlt"></fa-icon>
						</span>
						<owl-date-time pickerType="calendar" #scheduleStartDatePicker></owl-date-time>

						<span class="mx-2">~</span>

						<input class="form-control w-80 mr-2 " style="width: 100px !important;" required [disabled]="!autoResponse.schedule || !date.checked" name="scheduleEndDate" [(ngModel)]="autoResponse.endDate" [owlDateTime]="scheduleEndDatePicker" [min]="autoResponse.startDate" [owlDateTimeTrigger]="scheduleEndDatePicker" placeholder="預約日期" autocomplete="off">
						<span [owlDateTimeTrigger]="scheduleEndDatePicker">
							<fa-icon class="text-muted" [icon]="faCalendarAlt"></fa-icon>
						</span>
						<owl-date-time pickerType="calendar" #scheduleEndDatePicker></owl-date-time>

					</div>
				</div>
				<div class="d-flex">
					<div class="kv-radio form-check custom-control custom-radio mt-3">
						<input #time (click)="autoResponse.startDate = null; autoResponse.endDate = null" [checked]="autoResponse.startTime || autoResponse.endTime" id="ge1upnmghp8" name="schedule" type="radio" [disabled]="!autoResponse.schedule" class="custom-control-input" value="everyday">
						<label for="ge1upnmghp8" class="custom-control-label">
						</label>
					</div>
					<div class="form-inline mt-2">
						<input class="form-control w-80 mr-2 " style="width: 100px !important;" required [disabled]="!autoResponse.schedule || !time.checked" name="scheduleStartTime" [(ngModel)]="autoResponse.startTime" [owlDateTime]="scheduleStartTimePicker" [owlDateTimeTrigger]="scheduleStartTimePicker" placeholder="預約時間" autocomplete="off">
						<span [owlDateTimeTrigger]="scheduleStartTimePicker">
							<fa-icon class="text-muted" [icon]="faCalendarAlt"></fa-icon>
						</span>
						<owl-date-time pickerType="timer" #scheduleStartTimePicker></owl-date-time>

						<span class="mx-2">~</span>

						<input class="form-control w-80 mr-2 " style="width: 100px !important;" required [disabled]="!autoResponse.schedule || !time.checked" name="scheduleEndTime" [(ngModel)]="autoResponse.endTime" [owlDateTime]="scheduleEndTimePicker"  [min]="autoResponse.startTime" [owlDateTimeTrigger]="scheduleEndTimePicker" placeholder="預約時間" autocomplete="off">
						<span [owlDateTimeTrigger]="scheduleEndTimePicker">
							<fa-icon class="text-muted" [icon]="faCalendarAlt"></fa-icon>
						</span>
						<owl-date-time pickerType="timer" #scheduleEndTimePicker></owl-date-time>

					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="form-group row w-fix-800">
		<label class="col-sm-3 col-form-label text-sm-right font-weight-light">關鍵字</label>
		<div class="col-sm-9">
			<div class="form-control-plaintext" style="margin-top: -1rem;">
				<div class="d-flex">
					<tag-input  class=" d-flex justify-content-start flex-shrink-0 figure  position-relative"
					            [(ngModel)]="autoResponse.keywords"  [modelAsStrings]="true" name="keywords"
					            [secondaryPlaceholder]= "'輸入關鍵字'" [placeholder]= "'輸入關鍵字'" [theme]="'foundation-theme'" >
					</tag-input>
				</div> <small class="form-text text-muted">輸入關鍵字後，請按Enter鍵或點選畫面上的「新增」鍵以完成設定。<br>關鍵字的字數上限為30個字。</small></div>
		</div>
	</div>
	<hr class="my-4">
	<div class="mb-2" *ngFor="let message of autoResponse.messages index as i">
		<app-shop-message-content-card  [(ngModel)]="autoResponse.messages[i]" [name]="'message' + i" ></app-shop-message-content-card>
	</div>
	<br>
	<div>
		<button [disabled]="autoResponse.messages.length>2" (click)="addMessage()" type="button" class="btn btn-outline-primary">
			<i class="las la-plus mr-1"></i> 新增訊息
		</button>
	</div>
	<div class="d-flex justify-content-center my-5">
		<button (click)="save()" [disabled]="form.invalid || autoResponse.messages.length === 0" class="btn btn-lg btn-primary px-5 mx-1">儲存</button>
	</div>
</form>
<div class="my-4"><a (click)="rs.navigateTo('/Home/AutoResponse')" class="active"><a href="" class="text-dark border-dark border-bottom"><i class="fas fa-chevron-left mr-1"></i> 返回一覽</a></a>
</div>
