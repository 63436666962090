import {Component, OnInit, OnDestroy, PipeTransform, Pipe, ViewChild} from '@angular/core';
import {ShopService} from '../../../services/shop.service';
import {FSubscribe} from '../../../../firebase/models/user';
import Timestamp = firebase.firestore.Timestamp;
import {FChatMessage, FShopInMessage} from '../../../../firebase/models/message';
import DocumentReference = firebase.firestore.DocumentReference;
import {AuthService} from '../../../../firebase/auth.service';
import {AngularFirestore, AngularFirestoreDocument} from '@angular/fire/firestore';
import {NotifyService} from '../../../../shared/services/notify.service';
import {BehaviorSubject, Observable} from 'rxjs';
import * as firebase from 'firebase';
import {StorageService} from '../../../../shared/services/storage.service';
import {UploadImageDialogData} from '../../../../shared/ui/upload-image-dialog/upload-image-dialog.component';
import {faImage} from '@fortawesome/free-regular-svg-icons';
import {faPen, faPlus, faTrashAlt} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-shop-chat',
  templateUrl: './shop-chat.component.html',
  styleUrls: ['./shop-chat.component.styl']
})
export class ShopChatComponent implements OnInit, OnDestroy {

  faImage = faImage;
  faPen = faPen;
  faPlus = faPlus
  faTrash = faTrashAlt
  @ViewChild('scrollMe') chatView;
  userListLimit = 20;
  tags = [];
  autocompleteItems = [];
  noteModal = false;
  noteContent = '';
  displayNameModal = false;
  displayNameContent = '';
  inputText = '';
  shop;
  currentUser: FSubscribe;
  currentUserSub: any;
  currentUserSubscribeRef: DocumentReference;
  currentMessages = new BehaviorSubject<FChatMessage[]>([]);
  currentMessages$: Observable<FChatMessage[]>;
  currentMessagesRefSub: any;
  friends: FSubscribe[] = [];
  friendsSource: FSubscribe[] = [];
  searchText = '';
  firstMessage = true;
  chaMessageRef: AngularFirestoreDocument;
  constructor(public auth: AuthService,
              public ss: ShopService,
              private db: AngularFirestore,
              private notify: NotifyService,
              private img: StorageService) { }
  log(e) {
    console.log(e);
  }
  ngOnInit() {
    // this.tags = this.data.shopClass.tags;
    // console.log(this.tags);
    this.getList();
    this.chaMessageRef = this.db.collection('chatMessages').doc(this.ss.currentShop.uid);
  }
  ngOnDestroy(): void {
    if (this.currentMessages$) {
      this.currentMessagesRefSub.unsubscribe();
    }
  }
  getList() {
    this.db.collectionGroup<FSubscribe>('subscribes', ref => ref.where('shopId', '==', this.ss.currentShop.uid))
        .snapshotChanges().subscribe(subscribes => {
      this.friendsSource = subscribes.map(x => x.payload.doc.data());
      this.onSearch();
    });
  }
  onUserListScroll(event) {
    if (event === 'bottom') {
      this.userListLimit = this.userListLimit + 10;
    }
  }
  onSearch() {
    if (this.searchText === '') {
      this.friends = this.friendsSource.filter(friend => friend.serviceBody !== '');
    } else {
      this.friends = this.friendsSource.filter(x => x.user).filter(friend => friend.user.displayName.includes(this.searchText));
    }
  }
  onSelect(friend: FSubscribe) {
    console.log(friend);
    if (this.currentUser) {
      this.currentUser.countVal = 0;
    }
    if (this.currentMessages$) {
      this.currentMessagesRefSub.unsubscribe();
    }
    if (this.currentUserSub) {
      this.currentUserSub.unsubscribe();
    }
    if (!friend.notes) {
      friend.notes = [];
    }
    this.firstMessage = true;
    this.currentUser = friend;
    this.currentUserSubscribeRef = this.db.collection('users')
        .doc(this.currentUser.user.uid)
        .collection('subscribes')
        .doc(this.ss.currentShop.uid).ref;
    this.currentUserSub = this.db.collection('users')
        .doc(this.currentUser.user.uid)
        .collection('subscribes').doc<FSubscribe>(this.ss.currentShop.uid).valueChanges().subscribe(user => {
          this.currentUser = user;
          this.parseTags();
        });
    // this.parseTags();
    this.getMessage();
  }
  parseTags() {
    if (this.currentUser && this.currentUser.tagsA) {
      // @ts-ignore
      this.currentUser.tagsA = this.currentUser.tagsA.map(id => {
        return {
          display: this.ss.tagsForDisplay.filter(x => x.value === id)[0].display,
          value: id
        };
      });
    }
  }
  getMessage() {
    this.currentMessages$ = this.chaMessageRef.collection<FChatMessage>(this.currentUser.user.uid,
        ref => ref.orderBy('timestamp')).valueChanges();
    this.currentMessagesRefSub = this.currentMessages$.subscribe(messages => {
      if (this.firstMessage ||
          // tslint:disable-next-line:max-line-length
          this.currentMessages.getValue()[this.currentMessages.getValue().length - 1].timestamp !== messages[messages.length - 1].timestamp) {
        this.currentMessages.next(messages);
        this.scrollToBottom();
        this.messageRead();
        this.firstMessage = false;
      }
    });
  }
  showDate(index: number): boolean {
    if (index === 0) {
      return true;
    } else if ((this.currentMessages.getValue()[index].timestamp as Timestamp).toDate().getDate() !==
        (this.currentMessages.getValue()[index - 1].timestamp as Timestamp).toDate().getDate()) {
      return true;
    }
    return false;
  }
  // parseDate(date: Date): string {
  //   const result = moment(date).locale('zh-tw').format('MM/D (dd)');
  //   return result;
  // }
  // ParseTime(date: Date): string {
  //   return  moment(date).locale('zh-tw').format('LT');
  // }
  onUploadClick() {
    const shopInfo: FShopInMessage = {
      uid: this.ss.currentShop.uid,
      displayName: this.ss.currentShop.displayName,
      photoUrl: this.ss.currentShop.photoUrl,
      desc: this.ss.currentShop.desc,
      lineAt: this.ss.currentShop.lineAt,
      category: this.ss.currentShop.category,
    };
    const userInfo = {
      uid: this.auth.user.uid,
      displayName: this.auth.user.displayName,
      photoUrl: this.auth.user.photoUrl
    };
    const info: UploadImageDialogData = {
      height: 640, type: 'image', width: 640
    };
    this.img.openUploadDialog(info).afterClosed().subscribe(result => {
      this.chaMessageRef.collection(this.currentUser.user.uid).add({
        content: {photoUrl: result},
        idFrom: this.ss.currentShop.uid,
        idTo: this.currentUser.user.uid,
        type: 1,
        timestamp: new Date(),
        shop: shopInfo,
        user: userInfo
      });
    });
  }
  onKeyUp(event) {
    const shopInfo: FShopInMessage = {
      uid: this.ss.currentShop.uid,
      displayName: this.ss.currentShop.displayName,
      photoUrl: this.ss.currentShop.photoUrl,
      desc: this.ss.currentShop.desc,
      lineAt: this.ss.currentShop.lineAt,
      category: this.ss.currentShop.category,
    };
    const userInfo = {
      uid: this.auth.user.uid,
      displayName: this.auth.user.displayName,
      photoUrl: this.auth.user.photoUrl
    };
    if (event.key === 'Enter' && !event.shiftKey && this.inputText.replace(/[\n\r]/g, '').length > 0) {
      this.chaMessageRef.collection(this.currentUser.user.uid).add({
        shop: shopInfo,
        content: {text: this.inputText},
        idFrom: this.ss.currentShop.uid,
        idTo: this.currentUser.user.uid,
        type: 0,
        timestamp: new Date(),
        user: userInfo
      }).then(() => this.inputText = '');
      this.inputText = '';
    }
  }
  closeNoteModal(content?: string) {
    if (content) {
      this.validNote();
      this.currentUser.notes.push({
        name: this.auth.user.displayName,
        text: this.noteContent,
        timestamp: new Date(),
      });
      this.updateNotes();
    }
    this.noteModal = false;
  }
  closeDisplayNameModal(content?: string) {
    if (content) {
      this.currentUserSubscribeRef.update({
        'user.displayName': content
      });
    }
    this.displayNameModal = false;
    this.noteContent = '';
  }

  validNote() {
    if (!this.currentUser.notes) {
      this.currentUser.notes = [];
    }
  }
  updateNotes() {
    console.log(this.currentUser.notes);
    this.currentUserSubscribeRef.update({
      notes: this.currentUser.notes
    }).then(res => console.log(res)).catch(error => console.log(error));
  }
  onDeleteNote(index) {
    this.currentUser.notes.splice(index, 1);
    this.updateNotes();
  }
  messageRead() {
    this.currentUser.serviceTimestamp = Timestamp.fromDate(new Date());
    this.currentUserSubscribeRef.update({
      serviceCountVal: 0,
      serviceCountValChangeTime: new Date().getTime()
    });
  }
  statusChanged(status: number) {
    this.currentUserSubscribeRef.update({
      serviceStatus: status
    });
  }
  tabChanged() {
    const tags = {};
    this.currentUser.tagsA.forEach(tag => {
      // @ts-ignore
      tags[tag.value] = true;
    });
    this.currentUserSubscribeRef.update({
      updateTime: new Date(),
      // @ts-ignore
      tagsA: this.currentUser.tagsA.map(tag => tag.value),
      tags
    }).then(x => console.log(x)).catch(x => console.log(x));
  }
  scrollToBottom() {
    setTimeout(() => {
      try {
        console.log('scrollToBottom called');
        this.chatView.nativeElement.top = this.chatView.nativeElement.scrollHeight;
      } catch (err) {}
    }, 500);
  }
  getUserShops(userId: string) {
    const manager = 'managers.' + userId + '.enabled';
    this.db.collection('shops', ref => ref.where(manager, '==', true)).get().subscribe(x => {
      let shops = '';
      x.forEach((shop) => {
        shops = shops + ', ' + shop.data().displayName;
      });
      this.notify.pop(shops);
    });
  }
}

@Pipe({name: 'orderBy', pure: false})
export class OrderBy implements PipeTransform {
  transform(array: FSubscribe[], orderBy: any, asc = true) {
    array.sort((n1, n2) => {
      if (n1.serviceTimestamp > n2.serviceTimestamp) {
        return -1;
      }

      if (n1.serviceTimestamp < n2.serviceTimestamp) {
        return 1;
      }

      return 0;
    });
    return array;
  }
}
