import { Component, OnInit } from '@angular/core';
import {ShopService} from '../../../../services/shop.service';
import {FCategory} from '../../../../../firebase/models/store';
import {NotifyService} from '../../../../../shared/services/notify.service';
import {AngularFirestore, AngularFirestoreDocument} from '@angular/fire/firestore';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { faSave } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-shop-store-category-page',
  templateUrl: './shop-store-category-page.component.html',
  styleUrls: ['./shop-store-category-page.component.styl']
})
export class ShopStoreCategoryPageComponent implements OnInit {

  faTrash = faTrash;
  faSave = faSave;
  name = '';
  categories: Category[] = [];
  constructor(private ss: ShopService,
              private notify: NotifyService,
              private db: AngularFirestore) { }
  ngOnInit(): void {
    this.db.collection('stores').doc(this.ss.currentShop.uid).collection<FCategory>('categories').snapshotChanges().subscribe(cs => {
      // @ts-ignore
      this.categories = cs.map(x => {
        return {
          data: x.payload.doc.data(),
          ref: x.payload.doc.ref
        };
      });
    });
  }
  addCategory(categoryName: string) {
    this.db.collection('stores').doc(this.ss.currentShop.uid).collection<FCategory>('categories').add({
      name: categoryName,
      order: 0, // #順序
      createTime: new Date() //  #建立時間
    });
  }
  save(category: Category) {
    category.ref.update(category.data).then(() => {
      this.notify.pop('已儲存');
    });
  }
  delete(category: Category) {
    category.ref.delete().then(() => {
      this.notify.pop('已刪除');
    });
  }

}
interface Category {
  data: FCategory;
  ref: AngularFirestoreDocument;
}
