import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ProgressSpinnerComponent} from './progress-spinner/progress-spinner.component';
import {NotifyService} from '../services/notify.service';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatIconModule} from '@angular/material/icon';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { ListPaginatorComponent } from './list-paginator/list-paginator.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import { UploadImageDialogComponent } from './upload-image-dialog/upload-image-dialog.component';
import { EmojiPickerDialogComponent } from './emoji-picker-dialog/emoji-picker-dialog.component';
import {PickerModule} from '@ctrl/ngx-emoji-mart';
import {CursorPositionDirective} from '../directives/cursor-position.directive';
import {HtmlPipe} from '../../shop/pages/shop-root-page/shop-home/shop-home-page/shop-home-page.component';
import { QrCodeDownloadDialogComponent } from './qr-code-download-dialog/qr-code-download-dialog.component';
import {QRCodeModule} from 'angularx-qrcode';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import {NewsReviewListPageComponent} from '../pages/news-review-list-page/news-review-list-page.component';
import {NewsReviewEditPageComponent} from '../pages/news-review-list-page/news-review-edit-page/news-review-edit-page.component';
import {NewsAnalysisPageComponent} from '../pages/news-review-list-page/news-analysis-page/news-analysis-page.component';
import {FormsModule} from '@angular/forms';
import {TagInputModule} from 'ngx-chips';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {AngularEditorModule} from '@kolkov/angular-editor';
import {RouterModule} from '@angular/router';
import {ShareButtonsConfig} from 'ngx-sharebuttons';
import {ShareButtonsModule} from 'ngx-sharebuttons/buttons';
import {FroalaService} from '../services/froala.service';
import {NgxDropzoneModule} from 'ngx-dropzone';
import { NzCalendarModule } from 'ng-zorro-antd/calendar';

const customConfig: ShareButtonsConfig = {
  include: ['facebook'],
  exclude: ['tumblr', 'stumble', 'vk'],
  theme: 'modern-light',
  gaTracking: true,
  twitterAccount: 'twitterUsername'
};

@NgModule({
    declarations: [
      ProgressSpinnerComponent,
      ListPaginatorComponent,
      ConfirmDialogComponent,
      UploadImageDialogComponent,
      EmojiPickerDialogComponent,
      CursorPositionDirective,
      HtmlPipe,
      QrCodeDownloadDialogComponent,
      NewsReviewListPageComponent,
      NewsReviewEditPageComponent,
      NewsAnalysisPageComponent,
    ],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatSnackBarModule,
    MatDialogModule,
    FontAwesomeModule,
    PickerModule,
    QRCodeModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    FormsModule,
    TagInputModule,
    MatCheckboxModule,
    AngularEditorModule,
    RouterModule,
    ShareButtonsModule.withConfig(customConfig),
    NgxDropzoneModule,
    NzCalendarModule
  ],
    exports: [
        ProgressSpinnerComponent,
        ListPaginatorComponent,
        CursorPositionDirective,
        HtmlPipe,
    ],
  providers: [
    NotifyService,
    FroalaService
  ]
})
export class UiModule { }
