<form #form="ngForm">
	<div>
		<div class="d-flex justify-content-between align-items-center mb-2">
			<h2 class="mb-0">圖文選單</h2> </div>
		<p class="text-muted">您可建立具有視覺效果的互動型選單，於聊天室中提供優惠券、網址連結及相關行銷資訊以進行宣傳。</p>
		<hr>
	</div>
	<div style="">
		<div class="btn-toolbar justify-content-between my-4">
			<div aria-label="First group" role="group" class="btn-group">
				<button (click)="delete()" *ngIf="!create" type="button" class="btn btn-secondary">刪除</button>
			</div>
			<div class="input-group">
				<button [disabled]="form.invalid" (click)="save()" type="button" class="w-fix-120 btn btn-primary">儲存</button>
			</div>
		</div>
		<hr class="my-4">
		<section>
			<div class="my-3">
				<h3 class="mb-0">顯示設定</h3></div>
			<div>
				<div class="form-group row">
					<label class="col-sm-3 col-form-label text-sm-right font-weight-light">
						標題
					</label>
					<div class="col-sm-9">
						<div class="form-inline text-muted">
							<input  [(ngModel)]="richMenu.title" required maxlength="30" placeholder="輸入標題" name="title" autocomplete="off" type="text" class="form-control w-80 mr-2" aria-required="true" aria-invalid="true"> <span class=""><strong class="">{{richMenu.title.length}}</strong><span class="">/30</span></span>
						</div>
					</div>
				</div>
				<div data-v-10e553f0="" class="form-group row">
					<label class="col-sm-3 col-form-label text-sm-right font-weight-light">
						選單列顯示文字
					</label>
					<div class="col-sm-9">
						<div class="form-inline text-muted">
							<input [(ngModel)]="richMenu.label" required maxlength="14" placeholder="選單文字" name="label" autocomplete="off" type="text" class="form-control w-80 mr-2" aria-required="true" aria-invalid="true"> <span class=""><strong class="">{{richMenu.label.length}}</strong><span class="">/14</span></span>
						</div>
					</div>
				</div>
				<div class="form-group row">
					<label class="col-sm-3 col-form-label text-sm-right font-weight-light">
						預設顯示方式
					</label>
					<div class="col-sm-9">
						<div class="form-control-plaintext">
							<div class="kv-radio form-check custom-control custom-radio mb-2">
								<input [(ngModel)]="richMenu.behavior" id="yoi9r8ie4" name="default-menu-display" type="radio" class="custom-control-input" value="shown">
								<label for="yoi9r8ie4" class="custom-control-label">顯示</label>
							</div>
							<div class="kv-radio form-check custom-control custom-radio">
								<input [(ngModel)]="richMenu.behavior" id="sgal8od0ml" name="default-menu-none" type="radio" class="custom-control-input" value="collapsed">
								<label for="sgal8od0ml" class="custom-control-label">隱藏</label>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<hr class="my-4">
		<div class="d-flex align-items-center mt-4 mb-3">
			<h3 class="w-fix-240 mb-0">訊息設定</h3>
		</div>
		<section>
			<div class="d-flex">
				<div class="w-fix-240">
					<div class="wrapper d-flex justify-content-center align-items-center position-relative cursor-pointer bg-white mb-2"  aria-required="false" aria-invalid="true" [style.height.px]="160">
						<div *ngIf="richMenu.photoUrl === ''" class="text-center text-muted mb-0"><small>
							請選擇版型並上傳背景圖片。
						</small></div>
						<!---->
						<img *ngIf="richMenu.photoUrl !== ''" [src]="richMenu.photoUrl" class="w-100 h-100">
						<table *ngIf="richMenu.styleType === 11" class="position-absolute w-100 h-100 rounded border border-primary text-center bg-dark-20">
							<tr>
								<td class="h2 text-white border-left border-primary" style="width: 33.33%;">A</td>
								<td class="h2 text-white border-left border-primary" style="width: 33.33%;">B</td>
								<td class="h2 text-white border-left border-primary" style="width: 33.34%;">C</td>
							</tr>
							<tr class="border-top border-primary">
								<td class="h2 text-white border-left border-primary" style="width: 33.33%;">D</td>
								<td class="h2 text-white border-left border-primary" style="width: 33.33%;">E</td>
								<td class="h2 text-white border-left border-primary" style="width: 33.34%;">F</td>
							</tr>
						</table>
						<table *ngIf="richMenu.styleType === 12" class="position-absolute w-100 h-100 rounded border border-primary text-center bg-dark-20">
							<tr>
								<td class="h2 text-white border-left border-primary" style="width: 50%;">A</td>
								<td class="h2 text-white border-left border-primary" style="width: 50%;">B</td>
							</tr>
							<tr class="border-top border-primary">
								<td class="h2 text-white border-left border-primary" style="width: 50%;">C</td>
								<td class="h2 text-white border-left border-primary" style="width: 50%;">D</td>
							</tr>
						</table>
						<!---->
						<table *ngIf="richMenu.styleType === 13" class="position-absolute w-100 h-100 rounded border border-primary text-center bg-dark-20">
							<tr>
								<td colspan="3" class="h2 text-white border-left border-primary" style="width: 100%;">A</td>
							</tr>
							<tr class="border-top border-primary">
								<td class="h2 text-white border-left border-primary" style="width: 33.33%;">B</td>
								<td class="h2 text-white border-left border-primary" style="width: 33.33%;">C</td>
								<td class="h2 text-white border-left border-primary" style="width: 33.34%;">D</td>
							</tr>
						</table>
						<table *ngIf="richMenu.styleType === 14" class="position-absolute w-100 h-100 rounded border border-primary text-center bg-dark-20">
							<tr>
								<td rowspan="2" class="h2 text-white border-left border-primary" style="width: 66.66%;">A</td>
								<td class="h2 text-white border-left border-primary" style="width: 33.34%;">B</td>
							</tr>
							<tr class="border-top border-primary">
								<td class="h2 text-white border-left border-primary" style="width: 33.34%;">C</td>
							</tr>
						</table>
						<table *ngIf="richMenu.styleType === 15" class="position-absolute w-100 h-100 rounded border border-primary text-center bg-dark-20">
							<tr>
								<td class="h2 text-white border-left border-primary" style="width: 100%;">A</td>
							</tr>
							<tr class="border-top border-primary">
								<td class="h2 text-white border-left border-primary" style="width: 100%;">B</td>
							</tr>
						</table>
						<table *ngIf="richMenu.styleType === 16" class="position-absolute w-100 h-100 rounded border border-primary text-center bg-dark-20">
							<tr>
								<td class="h2 text-white border-left border-primary" style="width: 50%;">A</td>
								<td class="h2 text-white border-left border-primary" style="width: 50%;">B</td>
							</tr>
						</table>
						<table *ngIf="richMenu.styleType === 17" class="position-absolute w-100 h-100 rounded border border-primary text-center bg-dark-20">
							<tr>
								<td class="h2 text-white border-left border-primary" style="width: 100%;">A</td>
							</tr>
						</table>
						<table *ngIf="richMenu.styleType === 21" class="position-absolute w-100 h-100 rounded border border-primary text-center bg-dark-20">
							<tr>
								<td class="h2 text-white border-left border-primary" style="width: 33.33%;">A</td>
								<td class="h2 text-white border-left border-primary" style="width: 33.33%;">B</td>
								<td class="h2 text-white border-left border-primary" style="width: 33.34%;">C</td>
							</tr>
						</table>
						<table *ngIf="richMenu.styleType === 22" class="position-absolute w-100 h-100 rounded border border-primary text-center bg-dark-20">
							<tr>
								<td class="h2 text-white border-left border-primary" style="width: 33.34%;">A</td>
								<td class="h2 text-white border-left border-primary" style="width: 66.66%;">B</td>
							</tr>
						</table>
						<table *ngIf="richMenu.styleType === 23" class="position-absolute w-100 h-100 rounded border border-primary text-center bg-dark-20">
							<tr>
								<td class="h2 text-white border-left border-primary" style="width: 66.67%;">A</td>
								<td class="h2 text-white border-left border-primary" style="width: 33.33%;">B</td>
							</tr>
						</table>
						<table *ngIf="richMenu.styleType === 24" class="position-absolute w-100 h-100 rounded border border-primary text-center bg-dark-20">
							<tr>
								<td class="h2 text-white border-left border-primary" style="width: 50%;">A</td>
								<td class="h2 text-white border-left border-primary" style="width: 50%;">B</td>
							</tr>
						</table>
						<table *ngIf="richMenu.styleType === 25" class="position-absolute w-100 h-100 rounded border border-primary text-center bg-dark-20">
							<tr>
								<td class="h2 text-white border-left border-primary" style="width: 100%;">A</td>
							</tr>
						</table>
					</div>
					<div class="my-2">
						<button (click)="stylePicker = true" type="button" class="btn-block btn btn-secondary">選擇版型</button>
					</div>
					<div *ngIf="richMenu.styleType > 10" class="my-2">
						<button *ngIf="richMenu.styleType < 20" (click)="openUploadImageDialogBig()" type="button" class="btn-block btn btn-secondary">上傳背景圖片</button>
						<button *ngIf="richMenu.styleType > 20" (click)="openUploadImageDialog()" type="button" class="btn-block btn btn-secondary">上傳背景圖片</button>
					</div>
				</div>
				<div class="flex-1 ml-4 w-max-800">
					<div>
						<div data-vv-name="atLestLink" class="d-flex justify-content-between align-items-center mb-3" aria-required="false" aria-invalid="true">
							<h4 class="mb-0">動作</h4>
						</div>
						<mat-accordion multi>
							<ng-container  *ngFor = "let item of richMenu.urls; index as i" >
								<mat-expansion-panel *ngIf="i < richMenu.actionNum" class="ard w-fix-520 my-3" >
									<mat-expansion-panel-header class="p-2" >
										<mat-panel-title class=" mb-0  p-1 pl-4 d-flex align-items-center justify-content-between">
											<a style="padding-left: 10px"> {{getOrderChar(i)}}</a>
										</mat-panel-title>
									</mat-expansion-panel-header>
									<div class="pt-3" >
										<app-shop-action-model required [(ngModel)]="richMenu.urls[i]" [name]="'action' + i"></app-shop-action-model>
									</div>
								</mat-expansion-panel>
							</ng-container>
						</mat-accordion>

					</div>
				</div>
			</div>
		</section>
		<hr class="my-4">
		<div class="d-flex justify-content-center my-5">
			<button [disabled]="form.invalid" (click)="save()" type="button" class="px-5 mx-1 btn btn-primary btn-lg">儲存</button>
		</div>
	</div>

<!--	validation-->
	<input class="d-none" [(ngModel)]="richMenu.styleType" type="number" name="styleType" required minlength="2">
</form>



<div *ngIf="stylePicker" class="modal overflow-y-auto d-block bg-dark-50">
	<div tabindex="-1" class="modal-dialog modal-dialog-centered modal-md modal-rg">
		<div class="modal-content h-100">
			<div class="modal-header flex-shrink-0">
				<h5 class="mb-0">選擇版型</h5>
				<button (click)="stylePicker = false" class="close">×</button>
			</div>
			<div class="modal-body mh-100 overflow-y-auto">
				<div>
					<p class="lead">大型</p>
					<div class="d-flex flex-wrap prevent-user-select">
						<figure (click)="onFigureClick(11, 6)" class="figure mx-2 my-2">
							<img src="assets/images/richMenu/b1.png" width="120"></figure>
						<figure (click)="onFigureClick(12, 4)" class="figure mx-2 my-2">
							<img src="assets/images/richMenu/b2.png" width="120"></figure>
						<figure (click)="onFigureClick(13, 4)" class="figure mx-2 my-2">
							<img src="assets/images/richMenu/b3.png" width="120"></figure>
						<figure (click)="onFigureClick(14, 3)" class="figure mx-2 my-2">
							<img src="assets/images/richMenu/b4.png" width="120"></figure>
						<figure (click)="onFigureClick(15, 2)"class="figure mx-2 my-2">
							<img src="assets/images/richMenu/b5.png" width="120"></figure>
						<figure (click)="onFigureClick(16, 2)" class="figure mx-2 my-2">
							<img src="assets/images/richMenu/b6.png" width="120"></figure>
						<figure (click)="onFigureClick(17, 1)" class="figure mx-2 my-2">
							<img src="assets/images/richMenu/b7.png" width="120"></figure>
					</div>
					<p class="lead mt-3">小型</p>
					<div class="d-flex flex-wrap mx-1 prevent-user-select">
						<figure (click)="onFigureClick(21, 3)" class="figure mx-2 my-2">
							<img src="assets/images/richMenu/s1.png" width="120"></figure>
						<figure (click)="onFigureClick(22, 2)" class="figure mx-2 my-2">
							<img src="assets/images/richMenu/s2.png" width="120"></figure>
						<figure (click)="onFigureClick(23, 2)" class="figure mx-2 my-2">
							<img src="assets/images/richMenu/s3.png" width="120"></figure>
						<figure (click)="onFigureClick(24, 2)" class="figure mx-2 my-2">
							<img src="assets/images/richMenu/s4.png" width="120"></figure>
						<figure (click)="onFigureClick(25, 1)" class="figure mx-2 my-2">
							<img src="assets/images/richMenu/s5.png" width="120"></figure>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
