<div>
  <div class="mt-n3 p-2 d-flex justify-content-end">
    <button *ngIf="pages.length < 9" (click)="addPage()" type="button" class="btn btn-outline-primary">新增頁面</button>
  </div>
  <mat-tab-group>
    <mat-tab *ngFor="let page of pages; index as i" [label]="i">
      <ng-template matTabContent>
        <div class="btn-toolbar justify-content-end my-4">
          <div class="p-3">
            <!--                  <button type="button" class="hide-box-shadow btn bg-transparent"><i class="far fa-copy"></i></button>-->
            <!--                  <button disabled="disabled" type="button" class="hide-box-shadow btn bg-transparent"><i class="lar la-chevron-left"></i></button>-->
            <!--                  <button type="button" class="hide-box-shadow btn bg-transparent"><i class="lar la-chevron-right"></i></button>-->
            <button type="button" class="hide-box-shadow btn bg-transparent"><i class="lar la-times"></i></button>
          </div>
        </div>
        <div class="d-flex mb-4">
          <div class="flex-1 ml-4 w-max-800">
            <div class="form-group row mx-0">
              <div class="col-sm-3 col-form-label pl-0">
                <div class="kv-checkbox form-check custom-control custom-checkbox mb-3">
                  <input [checked]="page.banner !== ''" id="7ffsvr1f0j4" name="iconSetting" type="checkbox" class="custom-control-input">
                  <label for="7ffsvr1f0j4" class="custom-control-label">宣傳標語</label>
                  <!---->
                </div>
              </div>
              <div class="col-sm-9">
                <div class="form-inline">
                  <div class="w-100">
                    <input [(ngModel)]="page.banner" maxlength="10" placeholder="輸入宣傳標語" name="iconName" type="text" autocomplete="off" class="form-control w-80" aria-required="true" aria-invalid="false"> <span class=""><strong class="text-muted">{{page.banner.length}}</strong><span class="">/10</span></span>
                  </div>
                  <!---->
                </div>
                <div class="btn-group mt-2">
                  <button type="button" class="cursor-pointer w-fix-40 h-fix-40 rounded-circle badge mr-2 btn btn-none badge-info"><i class="lar la-check"></i></button>
                  <button type="button" class="cursor-pointer w-fix-40 h-fix-40 rounded-circle badge mr-2 btn btn-none badge-outline-info">
                    <div>A</div>
                  </button>
                  <button type="button" class="cursor-pointer w-fix-40 h-fix-40 rounded-circle badge mr-2 btn btn-none badge-danger">
                    <div>A</div>
                  </button>
                  <button type="button" class="cursor-pointer w-fix-40 h-fix-40 rounded-circle badge mr-2 btn btn-none badge-warning">
                    <div>A</div>
                  </button>
                  <button type="button" class="cursor-pointer w-fix-40 h-fix-40 rounded-circle badge mr-2 btn btn-none badge-primary">
                    <div>A</div>
                  </button>
                  <button type="button" class="cursor-pointer w-fix-40 h-fix-40 rounded-circle badge mr-2 btn btn-none badge-success">
                    <div>A</div>
                  </button>
                </div>
              </div>
            </div>
            <div class="form-group row mx-0">
              <div class="col-sm-3 col-form-label pl-4">
                圖片
              </div>
              <div class="col-sm-9">
                <div class="form-inline">
                  <div class="form-inline">
                    <div>
                      <div class="figure figure-grid cursor-pointer border position-relative mr-2">
                        <img *ngIf="page.image !== ''" [src]="page.image" class="figure-img">
                        <a *ngIf="page.image === ''" (click)="openUploadImageDialog(i)" href="javascript:void(0)">上傳圖片</a>
                        <button *ngIf="page.image !== ''" type="button" class="position-absolute top-right close"><i class="fas fa-window-close"></i></button>
                      </div>
                    </div>
                  </div>
                </div>
                <!---->
                <!---->
              </div>
            </div>
            <app-shop-action-model [(ngModel)]="page.action"></app-shop-action-model>
          </div>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
