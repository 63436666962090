<div>
  <div class="mt-n3 p-2 d-flex justify-content-end">
    <button *ngIf="pages.length < 9" (click)="addPage()" type="button" class="btn btn-outline-primary">新增頁面</button>
  </div>
  <mat-tab-group>
    <mat-tab *ngFor="let page of pages; index as i" [label]="i">
      <ng-template matTabContent>
        <div class="btn-toolbar justify-content-end my-4">
          <div class="p-3">
            <!--                  <button type="button" class="hide-box-shadow btn bg-transparent"><i class="far fa-copy"></i></button>-->
            <!--                  <button disabled="disabled" type="button" class="hide-box-shadow btn bg-transparent"><i class="lar la-chevron-left"></i></button>-->
            <!--                  <button type="button" class="hide-box-shadow btn bg-transparent"><i class="lar la-chevron-right"></i></button>-->
            <button type="button" class="hide-box-shadow btn bg-transparent"><i class="lar la-times"></i></button>
          </div>
        </div>
        <div class="d-flex mb-4">
          <div class="flex-1 ml-4 w-max-800">
            <div class="form-group row mx-0">
              <div class="col-sm-3 col-form-label pl-0">
                <div class="kv-checkbox form-check custom-control custom-checkbox mb-3">
                  <input [checked]="page.banner != ''" id="one" name="iconSetting" type="checkbox" class="custom-control-input">
                  <label for="one" class="custom-control-label">宣傳標語</label>
                  
                </div>
              </div>
              <div class="col-sm-9 disabled-content">
                <div class="form-inline">
                  <div class="w-100">
                    <input [(ngModel)]="page.banner" maxlength="10" placeholder="輸入宣傳標語" name="iconName" type="text" autocomplete="off" class="form-control w-80" aria-required="false" aria-invalid="false"> <span class=""><strong class="text-muted">{{page.banner.length}}</strong><span class="">/10</span></span>
                  </div>
                  
                </div>
                <div class="btn-group mt-2">
                  <button type="button" class="cursor-pointer w-fix-40 h-fix-40 rounded-circle badge mr-2 btn btn-none badge-info">
                    <i class="lar la-check"></i>
                  </button>
                  <button type="button" class="cursor-pointer w-fix-40 h-fix-40 rounded-circle badge mr-2 btn btn-none badge-outline-info">
                    <div>A</div>
                  </button>
                  <button type="button" class="cursor-pointer w-fix-40 h-fix-40 rounded-circle badge mr-2 btn btn-none badge-danger">
                    <div>A</div>
                  </button>
                  <button type="button" class="cursor-pointer w-fix-40 h-fix-40 rounded-circle badge mr-2 btn btn-none badge-warning">
                    <div>A</div>
                  </button>
                  <button type="button" class="cursor-pointer w-fix-40 h-fix-40 rounded-circle badge mr-2 btn btn-none badge-primary">
                    <div>A</div>
                  </button>
                  <button type="button" class="cursor-pointer w-fix-40 h-fix-40 rounded-circle badge mr-2 btn btn-none badge-success">
                    <div>A</div>
                  </button>
                </div>
              </div>
            </div>
            <div class="form-group row mx-0">
              <div class="col-sm-3 col-form-label pl-4">
                圖片
              </div>
              <div class="col-sm-9">
                <div class="form-inline">
                  <div class="form-inline">
                    <div>
                      <div class="figure figure-grid cursor-pointer border position-relative mr-2">
                        <img *ngIf="page.images[0] !== ''" [src]="page.images[0]" class="figure-img">
                        <a *ngIf="page.images[0] === ''" (click)="openUploadImageDialog(i)" href="javascript:void(0)">上傳圖片</a>
                        <button *ngIf="page.images[0] !== ''" type="button" class="position-absolute top-right close"><i class="fas fa-window-close"></i></button>
                      </div>
                    </div>
                  </div>
                </div>
                
                
              </div>
            </div>
            <div class="row mx-0">
              <div class="col-sm-3 col-form-label pl-4 mb-3">
                頁面標題
              </div>
              <div class="col-sm-9">
                <div class="form-inline">
                  <div class="w-100">
                    <input required maxlength="20" [(ngModel)]="page.header" placeholder="輸入標題" name="headerTitle" type="text" autocomplete="off" class="form-control w-80" aria-required="true" aria-invalid="false">
                    <span class=""><strong class="text-info">{{page.header.length}}</strong><span class="">/20</span></span>
                  </div>
                  
                </div>
              </div>
            </div>
            <div class="form-group row mx-0">
              <div class="col-sm-3 col-form-label pl-0">
                <div class="kv-checkbox form-check custom-control custom-checkbox mb-3">
                  <input [checked]="page.address != ''" id="address" name="iconSetting" type="checkbox" class="custom-control-input">
                  <label for="address" class="custom-control-label">地址</label>
                  
                </div>
              </div>
              <div class="col-sm-9 disabled-content">
                <div class="w-90 d-flex flex-row">
                  <div class="w-70 pr-2">
                    <div class="w-100">
                      <textarea [(ngModel)]="(page).address" maxlength="60" placeholder="輸入地址" name="place"  rows="3" type="text" autocomplete="off" class="form-control w-100" aria-required="false" aria-invalid="false"></textarea>
                      <span class="float-right"><strong class="text-muted">{{page.address.length}}</strong><span class="">/60</span></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mx-0">
              <div class="col-sm-3 col-form-label pl-0">
                <div class="kv-checkbox form-check custom-control custom-checkbox mb-3">
                  <input [checked]="page.addressInfoWord != ''" id="addressInfo" name="locationSetting" type="checkbox" class="custom-control-input">
                  <label for="addressInfo" class="custom-control-label">相關資訊</label>
                </div>
              </div>
              <div class="col-sm-9 disabled-content">
                <div class="form-inline">
                  <div class="kv-select form-group w-25 mr-2 d-flex align-items-end">
                    <div class="flex-grow-1">
                      <div class="input-group">
                        <select [(ngModel)]="page.addressInfoHead" name="locationInfo" class="form-control custom-select form-control-rg">
                          <option value="hours">時間</option>
                          <option value="price">價格</option>
                        </select>
                        
                      </div>
                      
                    </div>
                  </div>
                  <div class="w-70">
                    <input [(ngModel)]="page.addressInfoWord" maxlength="30" placeholder="輸入資訊" type="text" autocomplete="off" name="info" class="form-control w-75" aria-required="false" aria-invalid="false"> <span class=""><strong class="text-muted">{{page.addressInfoWord.length}}</strong><span class="">/15</span></span>
                  </div>
                  
                </div>
              </div>
            </div>
            <div>
              <div class="row mx-0">
                <div class="col-sm-3 col-form-label pl-0 pl-0">
                  <div class="kv-checkbox form-check custom-control custom-checkbox mb-3">
                    <input [checked]="page.actions[0].text != ''" id="qb4m06lk597" name="link" type="checkbox" class="custom-control-input">
                    <label for="qb4m06lk597" class="custom-control-label">動作1</label>
                  </div>
                </div>
              </div>
              <app-shop-action-model [(ngModel)]="page.actions[0]"></app-shop-action-model>
            </div>
            <div>
              <div class="row mx-0">
                <div class="col-sm-3 col-form-label pl-0 pl-0">
                  <div class="kv-checkbox form-check custom-control custom-checkbox mb-3">
                    <input [checked]="page.actions[1].text != ''" id="0txigjx8eugi" name="link" type="checkbox" class="custom-control-input">
                    <label for="0txigjx8eugi" class="custom-control-label">動作2</label>
                  </div>
                </div>
              </div>
              <app-shop-action-model [(ngModel)]="page.actions[1]"></app-shop-action-model>

            </div>
          </div>
        </div>

      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
