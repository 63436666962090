import * as firebase from 'firebase';
import Timestamp = firebase.firestore.Timestamp;

export interface FChargePlan {
  shopId: string; // 店家ID
  quota: number; // 每日可發送總數
  sent: number; // 每日已發送則數
  status: number; //  方案 0: 免費   1: 則數   3: 則數
  permission: IPermission; // 權限
  serviceExpireDate?: IServiceExpireDate;
  updateTime: Timestamp; // 建立時間
  ordered: boolean;
  virtualAccount: string;
  point?: number;
}
export interface FDailyTotal {
  shopId: string; // 店家ID
  follower: number; // 本日好友數
  date: string; // 日期 yyyymmdd
  quota: number; // 每日可發送總數
  sent: number; // 每日發送總數
  extraMessages: number; // 每日超發訊息
  createTime: Timestamp; // 建立時間
}
export interface FMonthlyTotal {
  shopId: string; // 店家ID
  shopDisplayName: string;
  agentDisplayName: string;
  follower: number; // 好友人數(月初第1天填入用以計算後面的金額)
  date: string; // 日期 yyyymmdd
  status?: number; //  方案 0: 免費   1: 則數   3: 則數
  monthlyFee?: number; // 月費
  extraMessages?: number;
  extraFee?: number; // 超收的金額(0.2*N)  無條件進入
  payStatus?: boolean;  // true 已付  false未付
  taxFee?: number;
  totalFee?: number;
  amount?: number;
  createTime: Timestamp; // 建立時間
  agentFee: number;
}
export interface FAgentChargePlan {
  shopId: string; // 店家ID
  date: string; // 日期 yyyymm
  messageFee: number; // 訊息服務費
  advFee: number; // 廣告服務費
  totalFee: number; // 收費總額(含稅)
  createTime: Timestamp; // 建立時間
}
export interface IPermission {
  authMessage: boolean; // 可否群發訊息
  course?: boolean;
  rewardCard?: boolean;
  store?: boolean;
  event?: boolean;
  booking?: boolean;
}
export interface IServiceExpireDate {
  course?: Timestamp | Date;
  rewardCard?: Timestamp | Date;
  store?: Timestamp | Date;
  event?: Timestamp | Date;
  booking?: Timestamp | Date;
}
export function getPlanName(type: number) {
  switch (type) {
    case 0:
      return '免費方案每日1500則';
    case 1:
      return '每日每好友一則';
    case 3:
      return '每日每好友三則';
    case 999:
      return '訊息吃到飽';
    default:
      return 'error';
  }
}
