import { BrowserModule } from '@angular/platform-browser';
import {ErrorHandler, NgModule} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {ShopModule} from './shop/shop.module';
import { LoginComponent } from './firebase/login/login.component';
import {QRCodeModule} from 'angularx-qrcode';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {environment} from '../environments/environment';
import {AngularFireModule} from '@angular/fire';
import { MainNavComponent } from './shared/main-nav/main-nav.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {UiModule} from './shared/ui/ui.module';
import {MatIconModule} from '@angular/material/icon';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CursorPositionDirective } from './shared/directives/cursor-position.directive';
import {ErrorLogHandler} from './shared/errorHandler/ErrorLogHandler';
import { NewsReviewListPageComponent } from './shared/pages/news-review-list-page/news-review-list-page.component';
import { NewsReviewEditPageComponent } from './shared/pages/news-review-list-page/news-review-edit-page/news-review-edit-page.component';
import {TagInputModule} from 'ngx-chips';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {FormsModule} from '@angular/forms';
import {AngularEditorModule} from '@kolkov/angular-editor';
import { NewsAnalysisPageComponent } from './shared/pages/news-review-list-page/news-analysis-page/news-analysis-page.component';
import {AdminModule} from './admin/admin.module';
import { InvitationPageComponent } from './shared/pages/invitation-page/invitation-page.component';
import { ShopAuthPageComponent } from './shared/pages/shop-auth-page/shop-auth-page.component';
import { ShopAuthResultPageComponent } from './shared/pages/shop-auth-page/shop-auth-result-page/shop-auth-result-page.component';
import { InputEditDialogComponent } from './shared/dialogs/input-edit-dialog/input-edit-dialog.component';
import { HttpClientModule } from '@angular/common/http';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { zh_TW } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import zh from '@angular/common/locales/zh';

registerLocaleData(zh);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MainNavComponent,
    InvitationPageComponent,
    ShopAuthPageComponent,
    ShopAuthResultPageComponent,
    InputEditDialogComponent,

  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    BrowserModule,
    ShopModule,
    AdminModule,
    AppRoutingModule,
    QRCodeModule,
    NgbModule,
    BrowserAnimationsModule,
    MatIconModule,
    UiModule,
    FontAwesomeModule,
    TagInputModule,
    MatCheckboxModule,
    FormsModule,
    AngularEditorModule,
    HttpClientModule,
  ],
  providers: [{provide: ErrorHandler, useClass: ErrorLogHandler}, { provide: NZ_I18N, useValue: zh_TW }],
  exports: [
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
