import {FCoupon} from './coupon';
import Timestamp = firebase.firestore.Timestamp;
import * as firebase from 'firebase';
import {DocumentReference} from '@angular/fire/firestore';
import {FAgent} from './agent';
import {FMessage} from './message';

export interface FShop {

  analysis: boolean;
  advRef?: DocumentReference;
  permission?: number;
  chatMode: boolean;
  admin: boolean;
  brief: string;
  desc: string;
  displayName: string;
  enabled: boolean;
  follower: number;
  followerOffset: number;
  lineAt: string;
  photoUrl: string;
  theme: string;
  timestamp: Timestamp|Date;
  uid: string;
  welcomeMessage: FMessage[];
  tags: FTags;
  category: string;
  managers: FShopManagers;
  receipt?: FShopReceipt;
  blocks: number;
  meetGift: FMeetGift;
  agent?: FAgent;
  agentRef?: DocumentReference;
  homePageOrder: number; // 主頁推廌排序 高 1000 中 100 低 0
  startPageOrder: number;  // 首次登入推廌排序  高 1000 中 100 低 0
  lineAtFlag: boolean;
  shareLink: string;
  richMenuFlag: boolean;
  newsEditor?: boolean;
  officialWeb?: string;
  chatMenu?: {
    store?: boolean;
    event?: boolean;
    course?: boolean;
    reward?: boolean;
    booking?: boolean;
  };

  course?: boolean;
  rewardCard?: boolean;
  store?: boolean;
  event?: boolean;
}
export interface FShopManagers {
  [propName: string]: FShopManager;
}
export interface FShopManager {
  owner: boolean;
  enabled: boolean;
  permission: number;
}
export interface FShopReceipt {
  name: string;
  email: string;
  address: string;
  type: number;
  content: IReceiptTwo | IReceiptThree;
}
export interface IReceiptTwo {
  type: number;
  code: string;
}
export interface IReceiptThree {
  title: string;
  code: string;
}

export interface  FShopData {
  category: string;
  desc: string;
  displayName: string;
  lineAt: string;
  photoUrl: string;
  uid: string;
}
export interface  FMeetGift {
  order?: number; //  排序數字
  enabled: boolean; //  是否啟用見面禮
  ref?: DocumentReference; //  優惠券的Ref
  qnrRef?: DocumentReference; //  問卷的Ref
  coupon?: FCoupon; //  優惠券的整個Model
}

export interface FTag {
  follower: number;
  timestamp: Timestamp|Date|string;
  createTime?: Timestamp|Date|string;
  desc: string;
  name: string;
  id: string;
}
export interface FTags {
  [propName: string]: FTag;
}

export function GetShopData(shop: FShop): FShopData {
  return {
    category: shop.category,
    desc: shop.desc,
    displayName: shop.displayName,
    lineAt: shop.lineAt,
    photoUrl: shop.photoUrl,
    uid: shop.uid,
  };
}
