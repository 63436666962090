<div class="d-flex justify-content-between align-items-center mb-2">
	<h2 class="mb-0">訂單一覽</h2>
</div>
<hr>
<table class="table table-layout-fixed">
	<thead class="thead-light">
	<tr>
		<th class="">
			建立時間
		</th>
		<th class="">
			訂單狀態
		</th>

		<th class="">
			付款狀態
		</th>
		<th class="">
			金額
		</th>

	</tr>
	</thead>
	<tbody>
	<tr *ngFor="let order of currentPage">
		<td>
			<a [routerLink]="order.payload.doc.id" class="d-flex align-items-center hljs-link text-dark">
				{{order.payload.doc.data().createTime.toDate().toLocaleString()}}
			</a>
		</td>
		<td>
			{{getStatus(order.payload.doc.data().status)}}
		</td>
		<td>
			{{order.payload.doc.data().payStatus ? '已付款' : '尚未付款'}}
		</td>
		<td>
			{{order.payload.doc.data().totalPrice}}
		</td>
	</tr>
	</tbody>
</table>
<hr>
<app-list-paginator [data]="orders" (listChanged)="currentPage = $event"></app-list-paginator>
