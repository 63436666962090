import { Component, OnInit } from '@angular/core';
import {AngularFirestore, DocumentChangeAction} from '@angular/fire/firestore';
import {FStoreOrder} from '../../../../../firebase/models/store';
import {ShopService} from '../../../../services/shop.service';

@Component({
  selector: 'app-shop-store-order-list-page',
  templateUrl: './shop-store-order-list-page.component.html',
  styleUrls: ['./shop-store-order-list-page.component.styl']
})
export class ShopStoreOrderListPageComponent implements OnInit {

  orders: DocumentChangeAction<FStoreOrder>[] = [];
  currentPage: DocumentChangeAction<FStoreOrder>[] = [];
  constructor(private ss: ShopService,
              private db: AngularFirestore) { }

  ngOnInit(): void {
    this.db.collection('stores').doc(this.ss.currentShop.uid).collection<FStoreOrder>('storeOrders').snapshotChanges().subscribe(orders => {
      this.orders = orders;
    });
  }
  getStatus(status: number) {
    switch (status) {
      case 1:
        return '尚未出貨';
      case 2:
        return '取消訂單';
      case 3:
        return '出貨中';
      case 4:
        return '退貨';
      case 5:
        return '退款';
      case 6:
        return '結案';

    }
  }

}
