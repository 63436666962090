<div>
  <div class="mt-n3 p-2 d-flex justify-content-end">
    <button *ngIf="pages.length < 9" (click)="addPage()" type="button" class="btn btn-outline-primary">新增頁面</button>
  </div>
  <mat-tab-group>
    <mat-tab *ngFor="let page of pages; index as i" [label]="i">
      <ng-template matTabContent>

      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
